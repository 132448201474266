"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Map = exports.UnionWinStatus = void 0;
var state_1 = require("../../Core/state");
var Const_1 = require("../Const");
var transition_1 = require("../Controler/transition");
var DataConfig_1 = require("../DataConfig");
var State_1 = require("../State");
var map_1 = require("../Throne/map");
var Utils_1 = require("../Utils");
var DefaultTroopRecoverTime = 60 * 30;
var DurabilityRecoverTime = 60 * 30;
var UnionWinStatus;
(function (UnionWinStatus) {
    UnionWinStatus["Normal"] = "Normal";
    UnionWinStatus["WaitToWin"] = "WaitToWin";
    UnionWinStatus["HaveWin"] = "HaveWin";
})(UnionWinStatus = exports.UnionWinStatus || (exports.UnionWinStatus = {}));
var Map = /** @class */ (function () {
    function Map(gState, seasonState, rewardGlobalState, tokenPriceInfo) {
        this.gState = gState;
        this.blockStates = {};
        var mapId = seasonState.mapId;
        this.mapId = mapId;
        this.mapConfig = (0, DataConfig_1.getMapConfigFromGDS)(mapId);
        this.mapOffset = (0, DataConfig_1.getMapOffset)(mapId);
        this.parameter = DataConfig_1.parameterConfig;
        this.seasonConfig = DataConfig_1.SeasonConfigFromGDS;
        this.seasonState = seasonState;
        this.tokenPriceInfo = tokenPriceInfo;
        this.rewardGlobalState = rewardGlobalState;
    }
    Map.prototype.setBoost = function (boost) {
        this.boost = boost;
    };
    Map.prototype.setGeneral = function (general) {
        this.general = general;
    };
    Map.prototype.setCity = function (city) {
        this.city = city;
    };
    Map.prototype.getMapGDS = function (x_id, y_id) {
        return this.mapConfig.get(x_id, y_id);
    };
    Map.prototype.loadBlockStates = function (states) {
        for (var _i = 0, states_1 = states; _i < states_1.length; _i++) {
            var state = states_1[_i];
            var x_id = state.x_id;
            var y_id = state.y_id;
            this.blockStates[x_id + "^" + y_id] = state;
        }
    };
    Map.prototype.getBlockState = function (x_id, y_id) {
        return this.blockStates[x_id + "^" + y_id];
    };
    Map.prototype.getBlockInitState = function (x_id, y_id) {
        var mapId = this.mapId;
        var state = State_1.InitState["".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(x_id, "^").concat(y_id)];
        return state;
    };
    Map.prototype.getBlockBaseInfo = function (x_id, y_id) {
        /*
        this.mapOffset.x = 10;
        this.mapOffset.y = 20;
        x_id,t_id - > xIndex, yIndex;
        9,9 -> 9,11
        -3,1 -> 6,19
        -3,-1 -> 6,21
        -2,2 -> 6, 18
        */
        // let xIndex = x_id + this.mapOffset.x;
        // let yIndex = Math.floor((y_id + this.mapOffset.y) / 2)
        var mapId = this.mapId;
        var campInfoKey = 'campInfo_' + mapId;
        var campInfo = this.gState[campInfoKey] || [];
        var yIndex = this.mapOffset.y - y_id;
        var xIndex = (this.mapOffset.x + x_id - Math.abs(x_id % 2)) / 2;
        return {
            xIndex: xIndex,
            yIndex: yIndex,
            campInfoKey: campInfoKey,
            campInfo: campInfo
        };
    };
    Map.prototype.getBelongInfo = function (x_id, y_id) {
        // let xIndex = x_id + this.mapOffset.x;
        // let yIndex = Math.floor((y_id + this.mapOffset.y) / 2)
        // if(xIndex < 0 || yIndex < 0){
        //     return 0
        // }
        // let campInfo = this.gState[campInfoKey] || [];
        // if(campInfo.length > xIndex && campInfo[xIndex].length > yIndex ){
        //     return campInfo[xIndex][yIndex].unionId
        // }
        var _a = this.getBlockBaseInfo(x_id, y_id), xIndex = _a.xIndex, yIndex = _a.yIndex, campInfo = _a.campInfo;
        if (xIndex < 0 || yIndex < 0) {
            return 0;
        }
        if (campInfo.length > yIndex && campInfo[yIndex].length > xIndex) {
            // console.log('getBelongInfo ', campInfo[yIndex], {x_id, y_id }, {yIndex, xIndex});
            // console.log('getBelongInfo ', campInfo[yIndex][xIndex]);
            return campInfo[yIndex][xIndex].unionId;
        }
        return 0;
    };
    Map.prototype.getBlockBattleStatus = function (x_id, y_id) {
        // let mapId = this.mapId;
        // let campInfoKey = 'campInfo_' + mapId;
        // let xIndex = x_id + this.mapOffset.x;
        // let yIndex = Math.floor((y_id + this.mapOffset.y) / 2)
        // let defalutRe : CampInfo ={
        //     unionId: 0,
        //     attackEndTime: -1,
        //     protectEndTime: -1
        // }
        // if(xIndex < 0 || yIndex < 0){
        //     return defalutRe
        // }
        // let campInfo = this.gState[campInfoKey] || [];
        // if(campInfo.length > xIndex && campInfo[xIndex].length > yIndex ){
        //     return campInfo[xIndex][yIndex]
        // }
        var _a = this.getBlockBaseInfo(x_id, y_id), xIndex = _a.xIndex, yIndex = _a.yIndex, campInfo = _a.campInfo;
        var defalutRe = {
            unionId: 0,
            attackEndTime: -1,
            protectEndTime: -1
        };
        if (xIndex < 0 || yIndex < 0) {
            return defalutRe;
        }
        if (campInfo.length > yIndex && campInfo[yIndex].length > xIndex) {
            return campInfo[yIndex][xIndex];
        }
        return defalutRe;
    };
    Map.prototype.changeBelongInfo = function (x_id, y_id, unionId, protectEnd) {
        // let mapId = this.mapId;
        // let campInfoKey = 'campInfo_' + mapId;
        // let xIndex = x_id  + this.mapOffset.x;
        // let yIndex = Math.floor((y_id  + this.mapOffset.y) / 2)
        // let infoMap = this.gState[campInfoKey] || [];
        // infoMap[xIndex][yIndex].unionId = unionId
        // infoMap[xIndex][yIndex].protectEndTime = protectEnd
        // this.gState.update(
        //     {
        //         [campInfoKey]: infoMap
        //     }
        // )
        var _a;
        var _b = this.getBlockBaseInfo(x_id, y_id), xIndex = _b.xIndex, yIndex = _b.yIndex, campInfoKey = _b.campInfoKey, campInfo = _b.campInfo;
        campInfo[yIndex][xIndex].unionId = unionId;
        campInfo[yIndex][xIndex].protectEndTime = protectEnd;
        this.gState.update((_a = {},
            _a[campInfoKey] = campInfo,
            _a));
    };
    Map.prototype.changeGlobalLastAttack = function (x_id, y_id, attackEnd) {
        // let mapId = this.mapId;
        // let campInfoKey = 'campInfo_' + mapId;
        // let xIndex = x_id  + this.mapOffset.x;
        // let yIndex = Math.floor((y_id  + this.mapOffset.y) / 2)
        // let infoMap = this.gState[campInfoKey] || [];
        // infoMap[xIndex][yIndex].attackEndTime = attackEnd
        // this.gState.update(
        //     {
        //         [campInfoKey]: infoMap
        //     }
        // )
        var _a;
        var _b = this.getBlockBaseInfo(x_id, y_id), xIndex = _b.xIndex, yIndex = _b.yIndex, campInfoKey = _b.campInfoKey, campInfo = _b.campInfo;
        campInfo[yIndex][xIndex].attackEndTime = attackEnd;
        this.gState.update((_a = {},
            _a[campInfoKey] = campInfo,
            _a));
    };
    Map.prototype.getAllMyHarbors = function (unionId) {
        //get data base on 
        var allMyHarbors = [];
        var _a = this.getBlockBaseInfo(1, 1), xIndex = _a.xIndex, yIndex = _a.yIndex, campInfoKey = _a.campInfoKey, campInfo = _a.campInfo;
        for (var _i = 0, campInfo_1 = campInfo; _i < campInfo_1.length; _i++) {
            var item = campInfo_1[_i];
            for (var _b = 0, item_1 = item; _b < item_1.length; _b++) {
                var subItem = item_1[_b];
                //harbor + capital sit by ocean + river;
                var isHarbor = subItem['type'] === 8 || (subItem['type'] == 2 && subItem['parameter'] == 14);
                if (isHarbor && subItem['unionId'] === unionId) {
                    allMyHarbors.push(subItem);
                }
            }
        }
        return allMyHarbors;
    };
    Map.prototype.checkBetween = function (unionId, x_id, y_id) {
        var allMyHarbors = this.getAllMyHarbors(unionId);
        var campInfo = this.getBlockBaseInfo(x_id, y_id).campInfo;
        var isTargetBlockIsHarbor = campInfo['type'] === 8 || (campInfo['type'] == 2 && campInfo['parameter'] == 14);
        console.log('checkBetween:', { campInfo: campInfo, unionId: unionId, isTargetBlockIsHarbor: isTargetBlockIsHarbor });
        console.log('checkBetween allMyHarbors:', allMyHarbors);
        //only attack non-occupy-harbor when own a harbor
        if (allMyHarbors.length > 0 && isTargetBlockIsHarbor && campInfo.unionId !== unionId) {
            return true;
        }
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var re = false;
        for (var i = 0; i < 6; i++) {
            var tempX = x_id + xOffset[i];
            var tempY = y_id + yOffset[i];
            if (this.getBelongInfo(tempX, tempY) == unionId) {
                re = true;
            }
        }
        return true;
    };
    Map.prototype.defenseBlock = function (x_id, y_id, info) {
        var blockState = this.getBlockState(x_id, y_id);
        var defenseList = blockState.defenseList;
        defenseList.push(info);
        blockState.update({
            'defenseList': defenseList
        });
    };
    Map.prototype.cancelDefenseBlock = function (x_id, y_id, username, generalId) {
        var blockState = this.getBlockState(x_id, y_id);
        var defenseList = blockState.defenseList;
        var remainTroop = 0;
        for (var i = 0; i < defenseList.length; i++) {
            if (defenseList[i].username == username && defenseList[i].generalId == generalId) {
                remainTroop = defenseList[i].troops;
                defenseList.splice(i, 1);
                break;
            }
        }
        blockState.update({
            'defenseList': defenseList
        });
        return remainTroop;
    };
    Map.prototype.getDefenseList = function (x_id, y_id, defaultDefense) {
        var time = (0, Utils_1.getTimeStamp)();
        var blockState = this.getBlockState(x_id, y_id);
        if (!blockState) {
            return [];
        }
        var mapId = this.mapId;
        if (defaultDefense) {
            if (time - blockState.lastAttachTime > DefaultTroopRecoverTime) {
                return (0, DataConfig_1.GenBlockDefenseTroop)(x_id, y_id, mapId);
            }
            else {
                return blockState.defaultDefense;
            }
        }
        else {
            return blockState.defenseList;
        }
    };
    Map.prototype.transBlockDefenseInfoToGeneralDefense = function (info) {
        var re = {
            generalId: info.generalId,
            generalLevel: info.generalLevel,
            generalType: info.generalType,
            attack: info.attack,
            defense: info.defense,
            troop: info.troops,
            silver: 0,
            defenseMaxTroop: info.troops
        };
        return re;
    };
    Map.prototype.checkIfCanAttack = function (x_id, y_id) {
        var blockState = this.getBlockState(x_id, y_id);
        var blockInfo = this.getMapGDS(x_id, y_id);
        console.log('getBlockState:', { x_id: x_id, y_id: y_id }, blockState, blockInfo);
        var isMont = blockInfo.type === 6;
        if (isMont) {
            return false;
        }
        var time = (0, Utils_1.getTimeStamp)();
        if (blockState.belong.updateTime == -1 || time - blockState.belong.updateTime > this.parameter.occupy_block_protect_times) {
            return true;
        }
        return false;
    };
    Map.prototype.getProtectRemainTime = function (x_id, y_id) {
        var blockState = this.getBlockState(x_id, y_id);
        var time = (0, Utils_1.getTimeStamp)();
        if (blockState.belong.updateTime == -1 || time - blockState.belong.updateTime > this.parameter.occupy_block_protect_times) {
            return 0;
        }
        return blockState.belong.updateTime + this.parameter.occupy_block_protect_times - time;
    };
    Map.prototype.attackBlocksAround = function (x_id, y_id, generalId, remainTroop, isCod, onBelongChange) {
        if (!this.checkIfCanAttack(x_id, y_id)) {
            return {
                result: false,
                error: 'block-is-be-protected'
            };
        }
        var stamina = this.parameter.attack_plots_need_stamina;
        if (!isCod && !(this.general.useGeneralStamina(generalId, stamina))) {
            return {
                result: false,
                error: 'general-stamina-error'
            };
        }
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var centerBlockState = this.getBlockState(x_id, y_id);
        var unionId = centerBlockState.belong.unionId;
        var records = [];
        var cancelList = [];
        // let remainTroop = -1
        var isAttackNeighbor = false;
        var re = this.attackBlock(x_id, y_id, generalId, remainTroop, isAttackNeighbor, isCod);
        console.log('attackBlocksAround block state:', { x_id: x_id, y_id: y_id, isCod: isCod }, centerBlockState);
        console.log('attackBlocksAround result:', { isCod: isCod }, re);
        if (re['error']) {
            return re;
        }
        cancelList = cancelList.concat(re['cancelList']);
        records = records.concat(re['records']);
        remainTroop = re['remainTroop'];
        if (unionId != 0 && remainTroop > 0) {
            var attackTroops = remainTroop + 0;
            for (var i = 0; i < 6; i++) {
                if (attackTroops <= 0) {
                    break;
                }
                var tempX = x_id + xOffset[i];
                var tempY = y_id + yOffset[i];
                var tempBlockState = this.getBlockState(tempX, tempY);
                if (tempBlockState && tempBlockState.belong.unionId == unionId && attackTroops > 0) {
                    console.log('attackBlock Around block going:', { x_id: x_id, y_id: y_id }, { unionId: unionId, tempX: tempX, tempY: tempY, generalId: generalId, attackTroops: attackTroops }, ' blockInfo:', tempBlockState);
                    var isAttackNeighbor_1 = true;
                    var tempRe = this.attackBlock(tempX, tempY, generalId, attackTroops, isAttackNeighbor_1, isCod);
                    if (tempRe['error']) {
                        return tempRe;
                    }
                    cancelList = cancelList.concat(tempRe['cancelList']);
                    records = records.concat(tempRe['records']);
                    attackTroops = tempRe['remainTroop'];
                }
            }
        }
        var durabilityReduce = 0;
        if (remainTroop > 0) {
            durabilityReduce = this.reduceDurability(x_id, y_id, remainTroop, this.general.state.unionId, onBelongChange);
            if (records.length != 0) {
                var lastRecord = records[records.length - 1];
                var newRecord = JSON.parse(JSON.stringify(lastRecord));
                newRecord.attackInfo.gloryGet += Math.floor(durabilityReduce / 50);
                newRecord.blockInfo.durabilityReduce = durabilityReduce;
                records.push(newRecord);
            }
        }
        return {
            records: records,
            cancelList: cancelList,
            durabilityReduce: durabilityReduce
        };
    };
    Map.prototype.recoveryBlockDefense = function (x_id, y_id) {
        var blockState = this.getBlockState(x_id, y_id);
        var time = parseInt(new Date().getTime() / 1000 + '');
        if (time - blockState.lastAttachTime < DefaultTroopRecoverTime) {
            console.log('attackBlock recoveryBlockDefense fail:', time);
            return;
        }
        var mapId = this.mapId;
        var defaultDefense = (0, DataConfig_1.GenBlockDefenseTroop)(x_id, y_id, mapId);
        blockState.update({
            'defaultDefense': defaultDefense,
            'lastAttachTime': time
        });
        // this.changeGlobalLastAttack(x_id, y_id, time + DefaultTroopRecoverTime)
        console.log('attackBlock recoveryBlockDefense ok:', { x_id: x_id, y_id: y_id }, blockState);
    };
    Map.prototype.attackBlock = function (x_id, y_id, generalId, remainTroop, isAttackNeighbor, isCod) {
        if (remainTroop === void 0) { remainTroop = -1; }
        //attack order: playerTroops > defaultDefense > duration
        //isAttackNeighbor only attack playerTroops;
        console.log('attackBlock args:', { x_id: x_id, y_id: y_id, generalId: generalId, remainTroop: remainTroop });
        this.recoveryBlockDefense(x_id, y_id);
        var time = parseInt(new Date().getTime() / 1000 + '');
        var blockState = this.getBlockState(x_id, y_id);
        var list = [];
        var generalRow = this.general.getGeneralQualification(generalId);
        if (remainTroop == -1) {
            remainTroop = this.general.getMaxAttackTroop();
        }
        var isDefaultDefense = false;
        var playerDefenseTroops = this.getDefenseList(x_id, y_id, isDefaultDefense);
        var cancelList = [];
        console.log('attackBlock playerDefenseTroops:', { isAttackNeighbor: isAttackNeighbor, hasPlayerDefense: playerDefenseTroops.length > 0 });
        if (playerDefenseTroops.length > 0) {
            for (var i = 0; i < playerDefenseTroops.length; i++) {
                var info = this.transBlockDefenseInfoToGeneralDefense(playerDefenseTroops[i]);
                var unionId = this.general.state.unionId;
                var unionIds = {
                    attackUnionId: unionId,
                    defenseUnionId: playerDefenseTroops[i].unionId
                };
                var useStamina = false;
                var bre = this.general.battle(generalId, unionIds, info, remainTroop, useStamina);
                if (!isCod) {
                    var attackTroopReduce = bre['attackTroopReduce'] || 0;
                    this.city.useTroop(attackTroopReduce);
                    this.city.updateInjuredTroops(attackTroopReduce, 'battle');
                }
                console.log('updateInjuredTroops battle result:', { isCod: isCod }, bre);
                if (!bre['result']) {
                    return bre;
                }
                else {
                    bre = bre;
                    var battleRecord = {
                        attackInfo: {
                            username: (0, Utils_1.parseStateId)(this.general.state.getId()).username,
                            generalId: generalId,
                            generalLevel: this.general.getGeneralLevel(generalId),
                            generalType: generalRow.general_type,
                            troopReduce: bre.attackTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.attackGloryGet,
                            unionId: unionId,
                            iconId: this.general.state.iconId
                        },
                        defenseInfo: {
                            username: playerDefenseTroops[i].username,
                            generalId: info.generalId,
                            generalLevel: info.generalLevel,
                            generalType: info.generalType,
                            troopReduce: bre.defenseTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.defenseGloryGet,
                            unionId: playerDefenseTroops[i].unionId,
                            iconId: playerDefenseTroops[i].iconId
                        },
                        leader: '',
                        recordType: isCod ? transition_1.BattleRecordType.Assembly : transition_1.BattleRecordType.Block,
                        timestamp: (0, Utils_1.getTimeStamp)(),
                        blockInfo: {
                            x_id: x_id,
                            y_id: y_id,
                            durabilityReduce: 0
                        },
                        txHash: (0, Utils_1.getTxHash)(),
                        result: bre.win
                    };
                    list.push(battleRecord);
                    if (bre.win) {
                        cancelList.push({
                            generalId: playerDefenseTroops[i].generalId,
                            username: playerDefenseTroops[i].username
                        });
                        playerDefenseTroops.shift();
                        i--;
                        remainTroop -= bre.attackTroopReduce;
                    }
                    else {
                        playerDefenseTroops[i].troops -= bre.defenseTroopReduce;
                        remainTroop -= bre.attackTroopReduce;
                        break;
                    }
                }
            }
            blockState.update({
                'defenseList': playerDefenseTroops
            });
            this.changeGlobalLastAttack(x_id, y_id, time + DefaultTroopRecoverTime);
        }
        if (remainTroop <= 0 || isAttackNeighbor) {
            return {
                records: list,
                cancelList: cancelList,
                remainTroop: remainTroop
            };
        }
        //attack defaultDefense
        if (!isAttackNeighbor) {
            var isDefaultDefense_1 = true;
            var defaultDefense = this.getDefenseList(x_id, y_id, isDefaultDefense_1);
            console.log('attackBlock defaultDefense:', { isAttackNeighbor: isAttackNeighbor, hasDefaultDefense: defaultDefense.length > 0 });
            for (var i = 0; i < defaultDefense.length; i++) {
                var info = this.transBlockDefenseInfoToGeneralDefense(defaultDefense[i]);
                var unionId = this.general.state.unionId;
                var unionIds = {
                    attackUnionId: unionId,
                    defenseUnionId: 0
                };
                var useStamina = false;
                var bre = this.general.battle(generalId, unionIds, info, remainTroop, useStamina);
                if (!isCod) {
                    var attackTroopReduce = bre['attackTroopReduce'] || 0;
                    this.city.useTroop(attackTroopReduce);
                    this.city.updateInjuredTroops(attackTroopReduce, 'battle');
                }
                console.log('updateInjuredTroops battle result:', { isCod: isCod }, bre);
                if (!bre['result']) {
                    return bre;
                }
                else {
                    bre = bre;
                    var battleRecord = {
                        attackInfo: {
                            username: (0, Utils_1.parseStateId)(this.general.state.getId()).username,
                            generalId: generalId,
                            generalLevel: this.general.getGeneralLevel(generalId),
                            generalType: generalRow.general_type,
                            troopReduce: bre.attackTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.attackGloryGet,
                            unionId: unionId,
                            iconId: this.general.state.iconId
                        },
                        defenseInfo: {
                            username: '',
                            generalId: info.generalId,
                            generalLevel: info.generalLevel,
                            generalType: info.generalType,
                            troopReduce: bre.defenseTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.defenseGloryGet,
                            unionId: 0,
                            iconId: -1
                        },
                        leader: '',
                        recordType: isCod ? transition_1.BattleRecordType.Assembly : transition_1.BattleRecordType.Block,
                        timestamp: (0, Utils_1.getTimeStamp)(),
                        blockInfo: {
                            x_id: x_id,
                            y_id: y_id,
                            durabilityReduce: 0
                        },
                        txHash: (0, Utils_1.getTxHash)(),
                        result: bre.win
                    };
                    list.push(battleRecord);
                    if (bre.win) {
                        defaultDefense.shift();
                        i--;
                        remainTroop -= bre.attackTroopReduce;
                    }
                    else {
                        defaultDefense[i].troops -= bre.defenseTroopReduce;
                        remainTroop -= bre.attackTroopReduce;
                        break;
                    }
                }
            }
            blockState.update({
                'defaultDefense': defaultDefense,
                'lastAttachTime': time
            });
            this.changeGlobalLastAttack(x_id, y_id, time + DefaultTroopRecoverTime);
        }
        return {
            records: list,
            cancelList: cancelList,
            remainTroop: remainTroop
        };
    };
    Map.prototype._attackBlocksAroundCod = function (x_id, y_id, generalId, remainTroop, onBelongChange) {
        if (!this.checkIfCanAttack(x_id, y_id)) {
            return {
                result: false,
                txType: Const_1.StateTransition.AttackBlock,
                error: 'block-is-be-protected'
            };
        }
        // cod battle
        // let stamina = this.parameter.attack_plots_need_stamina;
        // if(!(this.general.useGeneralStamina(generalId, stamina))){
        //     return{
        //         result: false,
        //         txType: StateTransition.AttackBlock,
        //         error: 'general-stamina-error'
        //     }
        // }
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var centerBlockState = this.getBlockState(x_id, y_id);
        var unionId = centerBlockState.belong.unionId;
        var records = [];
        var cancelList = [];
        // let remainTroop = -1
        var re = this._attackBlockCod(x_id, y_id, generalId, remainTroop);
        console.log('attackBlocksAroundCod result 1:', remainTroop, re);
        console.log('attackBlocksAroundCod block state:', { x_id: x_id, y_id: y_id }, centerBlockState);
        if (re['error']) {
            return re;
        }
        cancelList = cancelList.concat(re['cancelList']);
        records = records.concat(re['records']);
        remainTroop = re['remainTroop'];
        if (unionId != 0) {
            for (var i = 0; i < 6; i++) {
                if (remainTroop <= 0) {
                    break;
                }
                var tempX = x_id + xOffset[i];
                var tempY = y_id + yOffset[i];
                var tempBlockState = this.getBlockState(tempX, tempY);
                if (tempBlockState && tempBlockState.belong.unionId == unionId) {
                    var tempRe = this._attackBlockCod(tempX, tempY, generalId, remainTroop);
                    if (tempRe['error']) {
                        return tempRe;
                    }
                    cancelList = cancelList.concat(tempRe['cancelList']);
                    records = records.concat(tempRe['records']);
                    remainTroop = tempRe['remainTroop'];
                }
            }
        }
        var durabilityReduce = 0;
        if (remainTroop > 0) {
            durabilityReduce = this.reduceDurability(x_id, y_id, remainTroop, this.general.state.unionId, onBelongChange);
            if (records.length != 0) {
                var lastRecord = records[records.length - 1];
                lastRecord.attackInfo.gloryGet += Math.floor(durabilityReduce / 50);
                lastRecord.blockInfo.durabilityReduce = durabilityReduce;
            }
        }
        console.log('attackBlocksAroundCod result 2:', durabilityReduce, ' ', records, cancelList);
        return {
            txType: Const_1.StateTransition.AttackBlock,
            records: records,
            cancelList: cancelList,
            durabilityReduce: durabilityReduce
        };
    };
    Map.prototype._attackBlockCod = function (x_id, y_id, generalId, remainTroop) {
        if (remainTroop === void 0) { remainTroop = -1; }
        var time = parseInt(new Date().getTime() / 1000 + '');
        var blockState = this.getBlockState(x_id, y_id);
        var defaultDefense = this.getDefenseList(x_id, y_id, true);
        var firstBlock = true;
        var list = [];
        var generalRow = this.general.getGeneralQualification(generalId);
        if (remainTroop == -1) {
            remainTroop = this.general.getMaxAttackTroop();
            // firstBlock = true
        }
        if (firstBlock) {
            for (var i = 0; i < defaultDefense.length; i++) {
                var info = this.transBlockDefenseInfoToGeneralDefense(defaultDefense[i]);
                var unionId = this.general.state.unionId;
                var unionIds = {
                    attackUnionId: unionId,
                    defenseUnionId: 0
                };
                var bre = this.general.battle(generalId, unionIds, info, remainTroop, false);
                console.log('attackBlocksAroundCod attackBlockCod 1:', remainTroop, bre);
                if (!bre['result']) {
                    return bre;
                }
                else {
                    bre = bre;
                    var battleRecord = {
                        attackInfo: {
                            username: (0, Utils_1.parseStateId)(this.general.state.getId()).username,
                            generalId: generalId,
                            generalLevel: this.general.getGeneralLevel(generalId),
                            generalType: generalRow.general_type,
                            troopReduce: bre.attackTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.attackGloryGet,
                            unionId: unionId,
                            iconId: this.general.state.iconId
                        },
                        defenseInfo: {
                            username: '',
                            generalId: info.generalId,
                            generalLevel: info.generalLevel,
                            generalType: info.generalType,
                            troopReduce: bre.defenseTroopReduce,
                            silverGet: 0,
                            gloryGet: bre.defenseGloryGet,
                            unionId: 0,
                            iconId: -1
                        },
                        leader: '',
                        recordType: transition_1.BattleRecordType.Assembly,
                        timestamp: (0, Utils_1.getTimeStamp)(),
                        blockInfo: {
                            x_id: x_id,
                            y_id: y_id,
                            durabilityReduce: 0
                        },
                        txHash: (0, Utils_1.getTxHash)(),
                        result: bre.win
                    };
                    list.push(battleRecord);
                    if (bre.win) {
                        defaultDefense.shift();
                        i--;
                        remainTroop -= bre.attackTroopReduce;
                    }
                    else {
                        defaultDefense[i].troops -= bre.defenseTroopReduce;
                        remainTroop -= bre.attackTroopReduce;
                        break;
                    }
                }
            }
            blockState.update({
                'defaultDefense': defaultDefense,
                'lastAttachTime': time
            });
            this.changeGlobalLastAttack(x_id, y_id, time + DefaultTroopRecoverTime);
        }
        if (remainTroop <= 0) {
            return {
                txType: Const_1.StateTransition.AttackBlock,
                records: list,
                cancelList: [],
                remainTroop: remainTroop
            };
        }
        var defenseInfos = this.getDefenseList(x_id, y_id, false);
        console.log('attackBlocksAroundCod attackBlockCod defenseInfos:', { x_id: x_id, y_id: y_id }, defenseInfos);
        var cancelList = [];
        for (var i = 0; i < defenseInfos.length; i++) {
            var info = this.transBlockDefenseInfoToGeneralDefense(defenseInfos[i]);
            var unionId = this.general.state.unionId;
            var unionIds = {
                attackUnionId: unionId,
                defenseUnionId: defenseInfos[i].unionId
            };
            var bre = this.general.battle(generalId, unionIds, info, remainTroop, false);
            console.log('attackBlocksAroundCod attackBlockCod 2:', remainTroop, bre);
            if (!bre['result']) {
                return bre;
            }
            else {
                bre = bre;
                var battleRecord = {
                    attackInfo: {
                        username: (0, Utils_1.parseStateId)(this.general.state.getId()).username,
                        generalId: generalId,
                        generalLevel: this.general.getGeneralLevel(generalId),
                        generalType: generalRow.general_type,
                        troopReduce: bre.attackTroopReduce,
                        silverGet: 0,
                        gloryGet: bre.attackGloryGet,
                        unionId: unionId,
                        iconId: this.general.state.iconId
                    },
                    defenseInfo: {
                        username: defenseInfos[i].username,
                        generalId: info.generalId,
                        generalLevel: info.generalLevel,
                        generalType: info.generalType,
                        troopReduce: bre.defenseTroopReduce,
                        silverGet: 0,
                        gloryGet: bre.defenseGloryGet,
                        unionId: defenseInfos[i].unionId,
                        iconId: defenseInfos[i].iconId
                    },
                    leader: '',
                    recordType: transition_1.BattleRecordType.Assembly,
                    timestamp: (0, Utils_1.getTimeStamp)(),
                    blockInfo: {
                        x_id: x_id,
                        y_id: y_id,
                        durabilityReduce: 0
                    },
                    txHash: (0, Utils_1.getTxHash)(),
                    result: bre.win
                };
                list.push(battleRecord);
                if (bre.win) {
                    cancelList.push({
                        generalId: defenseInfos[i].generalId,
                        username: defenseInfos[i].username
                    });
                    defenseInfos.shift();
                    i--;
                    remainTroop -= bre.attackTroopReduce;
                }
                else {
                    defenseInfos[i].troops -= bre.defenseTroopReduce;
                    remainTroop -= bre.attackTroopReduce;
                    break;
                }
            }
        }
        blockState.update({
            'defenseList': defenseInfos
        });
        console.log('attackBlocksAroundCod attackBlockCod result:', remainTroop, ' records:', list);
        return {
            txType: Const_1.StateTransition.AttackBlock,
            records: list,
            cancelList: cancelList,
            remainTroop: remainTroop
        };
    };
    Map.prototype.genDurabilityRecord = function (x_id, y_id, generalId, gloryGet, durabilityReduce) {
        var generalRow = this.general.getGeneralQualification(generalId);
        var battleRecord = {
            attackInfo: {
                username: (0, Utils_1.parseStateId)(this.general.state.getId()).username,
                generalId: generalId,
                generalLevel: this.general.getGeneralLevel(generalId),
                generalType: generalRow.general_type,
                troopReduce: 0,
                silverGet: 0,
                gloryGet: gloryGet,
                unionId: this.general.state.unionId,
                iconId: this.general.state.iconId
            },
            defenseInfo: {
                username: '',
                generalId: -1,
                generalLevel: 0,
                generalType: 0,
                troopReduce: 0,
                silverGet: 0,
                gloryGet: 0,
                unionId: 0,
                iconId: -1
            },
            leader: '',
            recordType: transition_1.BattleRecordType.Block,
            timestamp: (0, Utils_1.getTimeStamp)(),
            blockInfo: {
                x_id: x_id,
                y_id: y_id,
                durabilityReduce: durabilityReduce
            },
            txHash: (0, Utils_1.getTxHash)(),
            result: true
        };
        return battleRecord;
    };
    Map.prototype.miningable = function (x_id, y_id, times) {
        var blockState = this.getBlockState(x_id, y_id);
        var row = this.getMapGDS(x_id, y_id);
        if (row.gather_silver_speed <= 0) {
            return false;
        }
        if (blockState.remainSilver >= row.gather_silver_speed * times) {
            return true;
        }
        return false;
    };
    Map.prototype.miningBlock = function (x_id, y_id, times) {
        var blockState = this.getBlockState(x_id, y_id);
        var row = this.getMapGDS(x_id, y_id);
        var silvers = row.gather_silver_speed * times;
        if (this.miningable(x_id, y_id, times)) {
            blockState.update({
                'remainSilver': blockState.remainSilver - silvers
            });
        }
        return silvers;
    };
    Map.prototype.getDurability = function (x_id, y_id) {
        var time = parseInt(new Date().getTime() / 1000 + '');
        var blockState = this.getBlockState(x_id, y_id);
        var row = this.getMapGDS(x_id, y_id);
        console.log('getDurability time diff:', { x_id: x_id, y_id: y_id, time: time }, blockState);
        if (time - blockState.lastAttachTime > DurabilityRecoverTime) {
            return row.durability;
        }
        else {
            return blockState.durability;
        }
    };
    Map.prototype.reduceDurability = function (x_id, y_id, remainTroop, unionId, onBelongChange) {
        var time = parseInt(new Date().getTime() / 1000 + '');
        var blockState = this.getBlockState(x_id, y_id);
        var row = this.getMapGDS(x_id, y_id);
        var durability = this.getDurability(x_id, y_id);
        var update = 0;
        console.log('reduceDurability change:', { x_id: x_id, y_id: y_id }, { durability: durability, remainTroop: remainTroop, unionId: unionId });
        if (durability - remainTroop <= 0) {
            var newBelong = {
                unionId: unionId,
                updateTime: time
            };
            blockState.update({
                'durability': row.durability,
                'belong': newBelong,
                'lastAttachTime': -1
            });
            this.changeBelongInfo(x_id, y_id, unionId, time + this.parameter.occupy_block_protect_times);
            onBelongChange && onBelongChange();
            update = durability;
        }
        else {
            blockState.update({
                'durability': durability - remainTroop
            });
            update = remainTroop;
        }
        return update;
    };
    Map.prototype.getBlocksBelongInfo = function () {
        var re = {};
        for (var id in this.mapConfig.config) {
            var row = this.mapConfig.config[id];
            re[id] = (0, state_1.copyObj)(this.getBlockBattleStatus(row.x_id, row.y_id));
        }
        return re;
    };
    Map.prototype.getBuffList = function (unionId) {
        var list = [];
        for (var id in this.mapConfig.config) {
            var row = this.mapConfig.config[id];
            if (unionId == this.getBelongInfo(row.x_id, row.y_id)) {
                if (row.buff_id != 0) {
                    list.push(row.buff_id);
                }
            }
        }
        return list;
    };
    Map.prototype.getCapitalsBlocks = function () {
        var mapId = this.mapId;
        var capitalsKey = 'capitals_' + mapId;
        var blockMap = State_1.InitState[Const_1.StateName.Capitals][capitalsKey] || {};
        console.log('checkUnionWin by capitalsKey:', { mapId: mapId, capitalsKey: capitalsKey }, blockMap);
        return blockMap;
    };
    Map.prototype.checkUnionWinForSeperateCapitals = function () {
        console.log('checkUnionWin start');
        var time = (0, Utils_1.getTimeStamp)();
        var status = UnionWinStatus.WaitToWin;
        var endTime = 0;
        var mapId = this.mapId;
        console.log('checkUnionWin:', { mapId: mapId, time: time, unionWinId: this.gState.unionWinId });
        if (this.gState.unionWinId != 0) {
            return {
                unionWin: true,
                unionId: this.gState.unionWinId,
                status: UnionWinStatus.HaveWin,
                remainTime: 0
            };
        }
        var capticals = this.getCapitalsBlocks();
        console.log('checkUnionWin capticals:', { mapId: mapId }, capticals);
        var blockStates = this.blockStates;
        console.log('checkUnionWin capticals blockStates:', { mapId: mapId }, blockStates);
        var winId = 0;
        var unionWin = true;
        if (!capticals || JSON.stringify(capticals) == '{}') {
            unionWin = false;
        }
        for (var blockId in capticals) {
            var blockIds = blockId.split('^');
            var x_id = parseInt(blockIds[0]);
            var y_id = parseInt(blockIds[1]);
            var blockState = this.getBlockState(x_id, y_id);
            // let ownerId = this.getBelongInfo(x_id, y_id);
            // console.log('checkUnionWin blockId:', { blockId, x_id, y_id, ownerId });
            console.log('checkUnionWin blockId:', { blockId: blockId, x_id: x_id, y_id: y_id, mapId: mapId }, blockState);
            if (!blockState) {
                throw "error blockState when check unionWin";
            }
            if (blockState.belong.unionId == 0) {
                unionWin = false;
                status = UnionWinStatus.Normal;
                break;
            }
            if (winId == 0 || blockState.belong.unionId == winId) {
                winId = blockState.belong.unionId;
                if (time - blockState.belong.updateTime < this.parameter.victory_need_occupy_times) {
                    unionWin = false;
                }
                var tempEndTime = blockState.belong.updateTime + this.parameter.victory_need_occupy_times;
                endTime = tempEndTime > endTime ? tempEndTime : endTime;
            }
            else {
                unionWin = false;
                status = UnionWinStatus.Normal;
                break;
            }
        }
        console.log('checkUnionWin status:', { unionWin: unionWin, status: status }, UnionWinStatus);
        if (!unionWin && status == UnionWinStatus.Normal) {
            return {
                unionWin: unionWin,
                unionId: 0,
                status: UnionWinStatus.Normal,
                remainTime: 0
            };
        }
        if (!unionWin && status == UnionWinStatus.WaitToWin) {
            return {
                unionWin: unionWin,
                unionId: winId,
                status: UnionWinStatus.WaitToWin,
                remainTime: endTime - time
            };
        }
        if (unionWin) {
            return {
                unionWin: unionWin,
                unionId: winId,
                status: UnionWinStatus.HaveWin,
                remainTime: 0
            };
        }
    };
    Map.prototype.checkUnionWin = function () {
        return this.checkUnionWinForSeperateCapitals();
        //old
        var time = (0, Utils_1.getTimeStamp)();
        var status = UnionWinStatus.WaitToWin;
        var endTime = 0;
        if (this.gState.unionWinId != 0) {
            return {
                unionWin: true,
                unionId: this.gState.unionWinId,
                status: UnionWinStatus.HaveWin,
                remainTime: 0
            };
        }
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var xList = [];
        var yList = [];
        var centerX = 0;
        var centerY = 0;
        xList.push(centerX);
        yList.push(centerY);
        for (var i = 0; i < 6; i++) {
            xList.push(centerX + xOffset[i]);
            yList.push(centerY + yOffset[i]);
        }
        //xList, yList get by gds(maybe not neighbors,buy seperate ones)
        var unionWin = true;
        var winId = 0;
        for (var i = 0; i < 7; i++) {
            var blockState = this.getBlockState(xList[i], yList[i]);
            if (!blockState) {
                throw "error when check unionWin";
            }
            if (blockState.belong.unionId == 0) {
                unionWin = false;
                status = UnionWinStatus.Normal;
                break;
            }
            if (winId == 0 || blockState.belong.unionId == winId) {
                winId = blockState.belong.unionId;
                if (time - blockState.belong.updateTime < this.parameter.victory_need_occupy_times) {
                    unionWin = false;
                }
                var tempEndTime = blockState.belong.updateTime + this.parameter.victory_need_occupy_times;
                endTime = tempEndTime > endTime ? tempEndTime : endTime;
            }
            else {
                unionWin = false;
                status = UnionWinStatus.Normal;
                break;
            }
        }
        if (!unionWin) {
            if (status == UnionWinStatus.Normal) {
                return {
                    unionWin: unionWin,
                    unionId: 0,
                    status: UnionWinStatus.Normal,
                    remainTime: 0
                };
            }
            else {
                return {
                    unionWin: unionWin,
                    unionId: winId,
                    status: UnionWinStatus.WaitToWin,
                    remainTime: endTime - time
                };
            }
        }
        else {
            return {
                unionWin: unionWin,
                unionId: winId,
                status: UnionWinStatus.HaveWin,
                remainTime: 0
            };
        }
    };
    Map.prototype.getSeasonState = function () {
        return this.seasonState;
    };
    Map.prototype.getSeasonStatus = function () {
        var time = (0, Utils_1.getTimeStamp)();
        var config = this.seasonState;
        var re = {
            status: map_1.SeasonStatus.Reservation,
            remaintime: config.season_ready - time
        };
        //hotfix 
        if (config.seasonId == "prod-bsc-2023-12-25-1-2") {
            config.season_end = 1704636000;
        }
        else if (config.seasonId == "prod-bsc-2023-12-22-2-1") {
            config.season_end = 1704463200;
        }
        if (time < config.season_ready) {
            re = {
                status: map_1.SeasonStatus.Reservation,
                remaintime: config.season_ready - time
            };
        }
        else if (time < config.season_open) {
            re = {
                status: map_1.SeasonStatus.Ready,
                remaintime: config.season_open - time
            };
        }
        else if (time < config.season_end) {
            re = {
                status: map_1.SeasonStatus.Open,
                remaintime: config.season_end - time
            };
        }
        else {
            re = {
                status: map_1.SeasonStatus.End,
                remaintime: -1
            };
        }
        return re;
    };
    Map.prototype.setUnionWin = function (unionId) {
        if (!(this.checkUnionWin().unionId == unionId)) {
            return {
                result: false,
                txType: Const_1.StateTransition.SetUnionWin,
                error: "this-union-do-not-win"
            };
        }
        this.setRewardResult(unionId);
        this.gState.update({
            'seasonEnd': true,
            'unionWinId': unionId
        });
        return {
            txType: Const_1.StateTransition.SetUnionWin,
            result: true
        };
    };
    Map.prototype.setRewardResult = function (unionId) {
        var addressList = [];
        var gloryList = [];
        var unionSumGlory = 0;
        for (var _i = 0, _a = this.rewardGlobalState.globalGloryRankInfo; _i < _a.length; _i++) {
            var item = _a[_i];
            addressList.push(item.username);
            gloryList.push(item.glory);
        }
        if (unionId != 0) {
            for (var _b = 0, _c = this.rewardGlobalState.unionGloryRankInfo[unionId - 1]; _b < _c.length; _b++) {
                var item = _c[_b];
                unionSumGlory += item.glory;
                if (addressList.indexOf(item.username) == -1) {
                    addressList.push(item.username);
                    gloryList.push(item.glory);
                }
            }
        }
        var unionRewardResult = [];
        var gloryRewardResult = [];
        var rankReward = this.genRankResultList();
        var rewardIndex = 0;
        var reward = rankReward[rewardIndex];
        for (var i in this.rewardGlobalState.globalGloryRankInfo) {
            if (parseInt(i) + 1 > reward.end) {
                rewardIndex++;
                if (rewardIndex >= rankReward.length) {
                    break;
                }
                reward = rankReward[rewardIndex];
            }
            var temp = {
                username: this.rewardGlobalState.globalGloryRankInfo[i].username,
                unionId: this.rewardGlobalState.globalGloryRankInfo[i].unionId,
                glory: this.rewardGlobalState.globalGloryRankInfo[i].glory,
                count: reward.count
            };
            gloryRewardResult.push(temp);
        }
        if (unionId != 0 && unionSumGlory != 0) {
            for (var _d = 0, _e = this.rewardGlobalState.unionGloryRankInfo[unionId - 1]; _d < _e.length; _d++) {
                var item = _e[_d];
                var temp = {
                    username: item.username,
                    glory: item.glory,
                    unionId: item.unionId,
                    count: item.glory * this.seasonState.unionRewardValue / unionSumGlory
                };
                unionRewardResult.push(temp);
            }
        }
        this.rewardGlobalState.update({
            contractAddressInput: addressList,
            contractGloryInput: gloryList,
            unionGlorySum: unionSumGlory,
            unionWinId: unionId,
            seasonEnd: true,
            unionRewardResult: unionRewardResult,
            gloryRewardResult: gloryRewardResult,
        });
    };
    Map.prototype.setSeasonEnd = function () {
        if (this.getSeasonStatus().status == map_1.SeasonStatus.End) {
            console.log("endSeason success");
            this.setRewardResult(0);
            this.gState.update({
                'seasonEnd': true
            });
            return {
                txType: Const_1.StateTransition.SetSeasonEnd,
                result: true
            };
        }
        else {
            console.log("it-is-not-time-to-end");
            return {
                result: false,
                txType: Const_1.StateTransition.SetSeasonEnd,
                error: 'it-is-not-time-to-end'
            };
        }
    };
    Map.prototype.genRankResultList = function () {
        var re = [];
        if (this.seasonState.rankConfigValue.length * 2 != this.seasonState.rankConfigFromTo.length) {
            return re;
        }
        for (var i = 0; i < this.seasonState.rankConfigValue.length; i++) {
            var temp = {
                type: 1,
                name: "rose",
                from: this.seasonState.rankConfigFromTo[i * 2],
                end: this.seasonState.rankConfigFromTo[i * 2 + 1],
                count: this.seasonState.rankConfigValue[i]
            };
            re.push(temp);
        }
        return re;
    };
    Map.prototype.addGloryAndSum = function (value) {
        this.general.addGlory(value);
        var unionId = this.general.state.unionId;
        var glorySum = this.rewardGlobalState.unionGlorySumRuntime;
        glorySum[unionId - 1] += value;
        this.rewardGlobalState.update({
            unionGlorySumRuntime: glorySum
        });
    };
    return Map;
}());
exports.Map = Map;
