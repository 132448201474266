import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { Throne } from 'throne-underlying';
import * as API from './api';

import { logTrace } from '../utils/log'
import * as cache from '../utils/cache'

let isInit = false;
export const throneInit = createAsyncThunk("throne/init", async (data) => {
    // console.log('throneInit', isInit, data);
    if(isInit){
        return;
    }
    // console.log('throneInit Promise');
    const response = await new Promise((resolve, reject) => {
        isInit = true;
        Throne.instance().init(data, (res) => {
            console.log('throneInit', data, res);
            let result = res.result || {};
            let userInfo = result.result || {};
            // console.log(res, userInfo);
            if(userInfo){
                resolve(res.result);                
            }else{
                reject(res);
            }
            logTrace({ data, res }, 'app.init 2');
        });
    });
    return response;
});

export const getServerStatus = createAsyncThunk("server/status", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getServerStatus(function(res){
            res = res || {};
            resolve(res);
        });
        
    });
    return response;
});

let cacheKey = '_debugConfig_';
export const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    appReady: false,
    version: Throne.instance().version,
    avatar: '',
    maintenanceInfo: {},
    debugConfig: {
        // 'auto-zoom-map': false
    },
    currentUser: {}
});

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        getConfig: function(state, action){
            let { seasonId } = action.payload;
            let config = {
                'guide-player': localStorage[seasonId + cacheKey + 'guide-player'] || false,
                'guide-season': localStorage[seasonId + cacheKey + 'guide-season'] || false
            }
            state.debugConfig = config;
        },
        addConfig: function(state, action){
            let config = action.payload;
            let seasonId = config['seasonId'];
            for(var prop in config){
                localStorage[seasonId + cacheKey + prop] = config[prop];
                state.debugConfig[prop] = config[prop];                
            }
        },
        resetConfig: function(state, action){
            state.debugConfig = {};
        }
    },
    extraReducers: builder => {
        builder.addCase(throneInit.fulfilled, (state, action) => {
            state.appReady = true;
            if(action.payload){
                let info = action.payload;
                    info.address = info.username.toLowerCase();
                state.currentUser = info;                
            }
            logTrace(action, 'app.init.fulfilled');
        });
        builder.addCase(getServerStatus.fulfilled, (state, action) => {
            state.maintenanceInfo = action.payload || false;
            logTrace(action, 'getServerStatus.fulfilled');
        });
    }
});

export const { getConfig, addConfig, resetConfig } = appSlice.actions
export default appSlice.reducer;
