"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Activity = void 0;
var state_1 = require("../../Core/state");
var Const_1 = require("../Const");
var DataConfig_1 = require("../DataConfig");
var Utils_1 = require("../Utils");
var Activity = /** @class */ (function () {
    function Activity(state) {
        this.state = state;
        this.seasonGDS = DataConfig_1.SeasonConfigFromGDS;
        this.activityGDS = DataConfig_1.ActivityTypeConfigFromGDS;
        this.rewardNumber = 200;
    }
    Activity.prototype.setCity = function (city) {
        this.city = city;
    };
    Activity.prototype.setMap = function (map) {
        this.map = map;
    };
    Activity.prototype.setChainIndex = function (chainIndex) {
        this.chainIndex = chainIndex;
    };
    Activity.prototype.setBoost = function (boost) {
        this.boost = boost;
    };
    Activity.prototype.getActivityConfig = function () {
        var chainIndex = this.chainIndex || 1;
        return this.seasonGDS.get(chainIndex).activities || [];
    };
    Activity.prototype.getActivityInfo = function (id) {
        var activities = this.getActivityConfig();
        if (id < 0 || id >= activities.length) {
            throw "activity id error";
        }
        var seasonState = this.map.getSeasonState();
        var act = activities[id];
        var relativeTimes = act.relativeTime.split('_');
        var startTime = seasonState.season_open + (parseInt(relativeTimes[0]) - 1) * 24 * 60 * 60 + parseInt(relativeTimes[1]) * 60 * 60;
        var row = this.activityGDS.get(act.type);
        var info = {
            activityId: id,
            type: act.type,
            relativeTime: act.relativeTime,
            startTime: startTime,
            totalReward: row.activity_pond,
            lastTime: row.activity_last * 60 * 60
        };
        return info;
    };
    Activity.prototype.checkActivityAble = function (id) {
        var time = (0, Utils_1.getTimeStamp)();
        var info = this.getActivityInfo(id);
        console.log('checkActivityAble ', time, info);
        if (time >= info.startTime && time < info.startTime + info.lastTime) {
            return true;
        }
        else {
            return false;
        }
    };
    Activity.prototype.getAbleActivities = function () {
        var activities = this.getActivityConfig();
        var re = [];
        for (var i = 0; i < activities.length; i++) {
            if (this.checkActivityAble(i)) {
                re.push(this.getActivityInfo(i));
            }
        }
        return re;
    };
    Activity.prototype.getBeforeActivities = function () {
        var activities = this.getActivityConfig();
        var re = [];
        var time = (0, Utils_1.getTimeStamp)();
        for (var i = 0; i < activities.length; i++) {
            var info = this.getActivityInfo(i);
            // if(time > info.startTime){
            re.push(this.getActivityInfo(i));
            // }
        }
        return re;
    };
    Activity.prototype.getBeforeActivitiesForReward = function (seasonState) {
        var _a = seasonState.season_open, season_open = _a === void 0 ? 0 : _a;
        if (season_open === 0) {
            return [];
        }
        var activities = this.getActivityConfig();
        var re = [];
        for (var i = 0; i < activities.length; i++) {
            var act = activities[i];
            var relativeTimes = act.relativeTime.split('_');
            var startTime = seasonState.season_open + (parseInt(relativeTimes[0]) - 1) * 24 * 60 * 60 + parseInt(relativeTimes[1]) * 60 * 60;
            var row = this.activityGDS.get(act.type);
            var info = {
                activityId: i,
                type: act.type,
                relativeTime: act.relativeTime,
                startTime: startTime,
                totalReward: row.activity_pond,
                lastTime: row.activity_last * 60 * 60
            };
            re.push(info);
        }
        return re;
    };
    Activity.prototype.addDataToActivity = function (id, username, oldValue, newValue) {
        if (!this.checkActivityAble(id)) {
            return {
                result: false,
                error: "activity-is-not-able:id:" + id
            };
        }
        var insert = {
            username: username,
            value: newValue
        };
        var list = (0, state_1.copyObj)(this.state.activityData[id]);
        //console.log("addDataToActivity: id:", id, "list after copy:", list)
        var sumValue = this.state.sumValue[id] || 0;
        do {
            if (list.length == 0) {
                list.push(insert);
                sumValue = newValue;
                break;
            }
            var origin_1 = (0, Utils_1.indexOfSortedList)(list, username, oldValue, 'value');
            if (origin_1.exist) {
                list.splice(origin_1.index, 1);
                sumValue = sumValue - oldValue;
            }
            var newInfo = (0, Utils_1.indexOfSortedList)(list, username, newValue, 'value');
            list.splice(newInfo.index, 0, insert);
            sumValue = sumValue + newValue;
            if (list.length > 200) {
                sumValue -= list[list.length - 1].value;
            }
        } while (false);
        var data = this.state.activityData;
        data[id] = list;
        var sumValueList = this.state.sumValue;
        sumValueList[id] = sumValue || 0;
        console.log('addDataToActivity ', oldValue, ' sumValueList ', sumValueList);
        this.state.update({
            'activityData': data,
            'sumValue': sumValueList
        });
        return {
            result: true
        };
    };
    Activity.prototype.getActivityRank = function (id, username, value) {
        var list = this.state.activityData[id] || [];
        var origin = (0, Utils_1.indexOfSortedList)(list, username, value, 'value');
        var info = this.getActivityInfo(id);
        var rank = -1;
        var rankReward = 0;
        if (origin.exist) {
            rank = origin.index + 1;
        }
        if (rank != -1) {
            rankReward = info.totalReward * value / this.state.sumValue[id];
        }
        return { rank: rank, rankReward: rankReward, value: value };
    };
    Activity.prototype.donateSilver = function (id, amount) {
        if (amount <= 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.DonateSilver,
                error: "amount-error"
            };
        }
        if (!this.checkActivityAble(id)) {
            return {
                result: false,
                txType: Const_1.StateTransition.DonateSilver,
                error: "donateSilver:activity-is-not-able:id:" + id
            };
        }
        var info = this.getActivityInfo(id);
        if (info.type != 3) {
            return {
                result: false,
                txType: Const_1.StateTransition.DonateSilver,
                error: "activity-type-error"
            };
        }
        var oldValue = this.city.getActivityData(id);
        if (!this.city.useSilver(amount)) {
            return {
                result: false,
                txType: Const_1.StateTransition.DonateSilver,
                error: 'silver-is-not-enough'
            };
        }
        var newValue = 0;
        if (oldValue == -1) {
            newValue = amount;
        }
        else {
            newValue = oldValue + amount;
        }
        this.addDataToActivity(id, (0, Utils_1.parseStateId)(this.city.state.id).username, oldValue, newValue);
        this.city.setActivityData(id, newValue);
        var rankInfo = this.getActivityRank(id, (0, Utils_1.parseStateId)(this.city.state.id).username, newValue);
        console.log("donateSilver: after donate:", this.city.state);
        return {
            result: true,
            txType: Const_1.StateTransition.DonateSilver,
            donateAmount: newValue,
            rank: rankInfo.rank,
            rankReward: rankInfo.rankReward
        };
    };
    Activity.prototype.updateAbleActivities = function () {
        var list = this.getAbleActivities();
        for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
            var item = list_1[_i];
            if (item.type == 3) {
                continue;
            }
            var oldValue = this.city.getActivityData(item.activityId);
            var newValue = 0;
            if (item.type == 1) {
                newValue = this.boost.getProduction(Const_1.ResouceType.Silver);
            }
            else if (item.type == 2) {
                newValue = this.boost.getProduction(Const_1.ResouceType.Troop);
            }
            newValue = parseInt(newValue + '');
            this.addDataToActivity(item.activityId, (0, Utils_1.parseStateId)(this.city.state.id).username, oldValue, newValue);
            this.city.setActivityData(item.activityId, newValue);
        }
    };
    return Activity;
}());
exports.Activity = Activity;
