import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { Throne, ComponentType } from 'throne-underlying';

import * as Contract from '../wallets/contract';
import * as EthFuns from '../wallets/ethereum';

import { logTrace } from '../utils/log';

import Web3 from 'web3'
var web3 = new Web3(window.ethereum);

//init city component
let facilities = {};
let facilitiyMap = {};
let facilitiyOrder = [];
let cityInstance;
let cityInited = false;

export const cityInit = createAsyncThunk("city/init", async () => {
    if(cityInited){
        return;
    }
    const response = await new Promise((resolve, reject) => {
        // if(!cityInstance){
            cityInited = true;
            Throne.instance().initComponent(ComponentType.City, (city) => {
                cityInstance = city;
                cityInstance.updateResource();
                facilitiyOrder = cityInstance.getFacilityOrder();
                resolve(cityInstance);
            });
        // }
    });
    return response;
});

export const resourceUpdate = createAsyncThunk("resource/update", async () => {
    if(!cityInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.onStateUpdate(() => {
            let facilities = cityInstance.getFacilityList();
            let resources = cityInstance.getResource();
            let golds = cityInstance.getGold();
            resolve({facilities, resources, golds});
        });
    });
    return response;
});

export const resourceRefresh = createAsyncThunk("resource/refresh", async () => {
    if(!cityInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        let facilities = cityInstance.getFacilityList();
        let resources = cityInstance.getResource();
        let golds = cityInstance.getGold();
        resolve({facilities, resources, golds});
    });
    return response;
});

export const buildingUpdate = createAsyncThunk("building/update", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    return await new Promise((resolve, reject) => {
        cityInstance.doUpgradeFacility(data.type, data.index,(res)=>{
            let { result } = res;
            result.time = new Date();
            resolve(result);
        });
    });
});

export const recruitTroops = createAsyncThunk("troops/recruit", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.doRecruit(data.amount, (res)=>{
            resolve(res.result);  
        });
    });
    return response;
});

export const recruitTroopsOver = createAsyncThunk("troops/recruitOver", async () => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.receiveTroop((res)=>{
            let { result } = res;
            resolve(result);
        });
    });
    return response;
});

export const recharge = createAsyncThunk("recharge/confirm", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        let { seasonBasicInfo, crossInfo, seasonId, amount, rechargeId, wallet, isCrossChain } = data;
        rechargeId += '';

        if(isCrossChain){
            let { contract_addr } = seasonBasicInfo;
            let { tss_address, recharege_fee = 0 } = crossInfo;
            let value = recharege_fee/1 + amount;
            const txData = EthFuns.prepareData(
                contract_addr,
                ["uint8","string","uint256"],
                ["2", seasonId , rechargeId]
            );

            console.log('cross_chain_protocol', data.from, { tss_address, value, contract_addr, seasonId,  rechargeId });

            EthFuns.sendTransaction({
                from: data.from,
                to: tss_address, 
                data: txData, 
                value: value,
                wallet: wallet
            }, function(result){
                console.log('cross_chain_protocol result:', result);
                resolve(result);    
            })
            return;
        }

        Contract.getRechargeToken(data, function(token){
            data.token = token;
            Contract.recharge(data, function(res){
                res.time = new Date();
                resolve(res);
            });
        });
    });
    return response;
});

export const getRechargeInfo = createAsyncThunk("recharge/info", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        Contract.getRechargeInfo(data, function(result){
            resolve(result);
        });
    });
    return response;
});

export const addTestResource = createAsyncThunk("testResource/add", async () => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.addTestResource((res)=>{
            if(res.result.result){
                resolve(true);                
            }else{
                reject(false);
            }
        });
    });
    return response;
});

export const onReceiveChat = createAsyncThunk("message/new", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.onReceiveChat(data.channel ,function(newMessage){
            resolve(newMessage);
        });
    });
    return response;
});

export const sendMessage = createAsyncThunk("message/send", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.chat({
            channel: data.channel,
            content: data.content
        }, function(res){
            res.result = !!res.id;
            resolve(res);
        });
    });
    return response;
});

export const getHistoryMessages = createAsyncThunk("message/his", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.getHistoryChatData({
            // channel: data.channel,
            unionId: data.unionId || ''
        }, function(messageList){
            resolve(messageList);
        });
    });
    return response;
});

export const donateSilver = createAsyncThunk("silver/donate", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.donateSilver(
            data.activityId,
            data.amount,
            function(res){
                let { result } = res;
                result.activityId = data.activityId;
                resolve(result);
            }
        );
    });
    return response;
});

export const setGuideStep = createAsyncThunk("guideStep/set", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        cityInstance.setGuideStep(
            data.type,
            data.step,
            function(res){
                let result = res.result || {};
                result.time = new Date();
                // result.step = data.step;
                resolve(result);
            }
        );
    });
    return response;
});

export const healTroopsStart = createAsyncThunk("troops/heal", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    return await new Promise((resolve, reject) => {
        let { type, amount } = data;
        cityInstance.healTroopsStart(type, amount,
            function(res){
                resolve(res);
                // console.log('healTroops reslut', data, res)
            }
        );
    });
});

export const buyOffer = createAsyncThunk("offer/buy", async (data) => {
    if(!cityInstance){ 
        return; 
    }
    return await new Promise((resolve, reject) => {
        let offerId = data.offer_id;
        cityInstance.buyOffer(offerId, function(res){
            console.log('buyOffer', data, res);
            resolve(res);
        });
    });
});

//update my city
export const _adapter = createEntityAdapter();

let initData = getFlattenCityData(facilities);
const initialState = _adapter.getInitialState({
    cityReady: false,
    cities: initData.cities,
    canCityUpgrade: initData.canCityUpgrade,
    goodsList: [],
    golds: 0,
    chargeResult: {},
    rechargeInfo: {},
    summary: initData.summary,
    resources: {troop:{}, silver:{}},
    resourceCoolDownTime: -1,
    resourceResult: false,
    recruitResult: {},
    recruitOverResult: {},
    buildingUpdateResult: {},
    newMessages: {
        '1': false,
        '2': false,
        'reset': false
    },
    messageListWorld: [],
    messageListUnion: [],
    messageResult: {},
    activities: [],
    newActivities: false,
    donateResult: {},
    guideStep: {
        back: false,
        play: 0,
        season: 0
    },
    guideStepResult: {},
    silversNeed: 0,
    healEstimateResult: {},
    healTroopsCount: { value: 0 },
    healResult: { back: false },
    recruitEstimateResult: {},
    offers: [],
    buyOfferRecords: {},
    offerResult: {}
});

export const buildingSlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        getGold: function(state, action){
            if(!cityInstance){
                return;
            }
            let golds = cityInstance.getGold();
            state.golds = golds;
        },
        getRecruitNeed: function(state, action){
            if(!cityInstance){
                return;
            }
            let silvers = cityInstance.getRecruitNeed();
            state.silversNeed = silvers;
        },
        getGoodsList: function(state, action){
            if(!cityInstance){
                return;
            }
            let { chainName } = action.payload;
            let goodsList = cityInstance.getRechargeConfigs(chainName);
            state.goodsList = goodsList;
        },
        resetRecruitResult: function(state, action){
            state.recruitResult = {};
            state.recruitOverResult = {};
        },
        resetBuildingUpdateResult: function(state, action){
            state.buildingUpdateResult = {};
        },
        resetChargeResult: function(state, action){
            state.chargeResult = {};
        },
        resetMessageResult: function(state, action){
            state.messageResult = {};
        },
        getRecruitState: function(state, action){
            if(cityInstance){
                let recruitState = cityInstance.getRecruitState();
                state.recruitResult = recruitState;
            }
        },
        getTestResourceCoolDownTime: function(state, action){
            if(cityInstance){
                let resourceCoolDownTime = cityInstance.getTestResourceCoolDownTime();
                state.resourceCoolDownTime = resourceCoolDownTime;
            }
        },
        getAbleActivityInfo: function(state, action){
            let seasonBasicInfo = action.payload;
            let chain = seasonBasicInfo.chain;
            let chains = {
                'emerald' : 1,
                'bsctest' : 2,
                'bsc' : 3
            };
            if(cityInstance && seasonBasicInfo){
                let start_ts = seasonBasicInfo.start_ts;
                let activities = cityInstance.getAbleActivityInfo(chains[chain]) || [];
                let list = [];
                let now = new Date().getTime()/1000;
                let newActivities = false;
                activities.forEach(function(acti){
                    let { startTime, lastTime, relativeTime } = acti;
                    // let relativeTimes = relativeTime.split('_');
                    // let startTime = start_ts/1 + (relativeTimes[0] - 1)*24*60*60 + relativeTimes[1]*60*60;
                    let endTime = startTime/1 + lastTime/1;
                    let remainTime = -1;

                    let status = 'closed';
                    let sort = 10000000000 - startTime;
                    if(now < startTime){
                        status = 'waiting';
                        sort += 10000000000;
                        remainTime = startTime - now;
                    }
                    if(now >= startTime && now <= endTime){
                        status = 'opening';
                        sort += 20000000000;
                        remainTime = lastTime - now + startTime;
                    }

                    acti.startDate = new Date(startTime/0.001);
                    acti.status = status;
                    acti.remainTime = Math.ceil(remainTime);
                    acti.startTime = startTime;
                    acti.endTime = endTime;
                    acti.sort = sort;
                    //status 
                    if(acti.redPoint){
                        newActivities = true;
                    }
                    if(acti.status !== 'waiting'){
                        list.push(acti);
                    }
                }); 
                console.log('activities slice', {activities, seasonBasicInfo, list});
                state.activities = list.sort(function(a, b){
                    return b.sort - a.sort;
                });
                state.newActivities = newActivities;
            }
        },
        removeActivityNew: function(state, action){
            if(!cityInstance){
                return;
            }
            let { activityId } = action.payload;
            cityInstance.readActivity(activityId);
        },
        getGuideStep: function(state, action){
            if(cityInstance){
                let data = {
                    play: cityInstance.getGuideStep('play') || 1,
                    season: cityInstance.getGuideStep('season') || 1,
                    back: true
                }
                state.guideStep = data;
            }
        },
        checkNewMessage: function(state, action){
            if(cityInstance){
                let result = {
                    '1': cityInstance.getChatRedPoint(1),
                    '2': cityInstance.getChatRedPoint(2),
                    'reset': false
                }
                state.newMessages = result;
            }
        },
        setNewMessageRead: function(state, action){
            let { channel, message } = action.payload;
            cityInstance.updateChatRedPoint(channel, message);
            state.newMessages[channel] = false;
            state.newMessages['reset'] = true;
        },
        refreshCityData: function(state, action){
            if(!cityInstance){
                return;
            }
            let facilities = cityInstance.getFacilityList();
            let initData = getFlattenCityData(facilities);
            state.cities = initData.cities;
            state.healTroopsCount = cityInstance.getInjuredTroops() || {};
        },
        healEstimate: function(state, action){
            if(!cityInstance){ 
                return; 
            }
            let { amount } = action.payload;
            let res = cityInstance.healEstimate(amount);
            state.healEstimateResult = {
                silver: Math.ceil(res.silver),
                gold: Math.ceil(res.gold*10)/10
            };
        },
        resetHealResult: function(state, action){
            state.healResult = { back: false };
        },
        recruitEstimate: function(state, action){
            if(!cityInstance){ 
                return; 
            }
            let { amount } = action.payload;
            let res = cityInstance.recruitEstimate(amount);
            state.recruitEstimateResult = res;
        },
        getOfferList: function(state, action){
            if(!cityInstance){ 
                return; 
            }
            let data = cityInstance.getOfferList();
            state.offers = data.all;
            state.buyOfferRecords = data.buyOfferRecords || {};
        },
        resetOfferResult: function(state, action){
            state.offerResult = { };
        }
    },
    extraReducers: builder => {
        builder.addCase(cityInit.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let resources = cityInstance.getResource();
            state.resources = resources;
            state.cityReady = true;
        });
        builder.addCase(buildingUpdate.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            state.buildingUpdateResult = action.payload || {};

            let facilities = cityInstance.getFacilityList();
            let initData = getFlattenCityData(facilities);
            state.cities = initData.cities;
            state.summary = initData.summary;
            state.canCityUpgrade = initData.canCityUpgrade;
        });
        builder.addCase(recruitTroops.fulfilled, (state, action) => {
            let data = action.payload;
                data.status = 'going';
            state.recruitResult = data;
        });
        builder.addCase(recruitTroopsOver.fulfilled, (state, action) => {
            let data = action.payload;
            state.recruitOverResult = data;
        });
        builder.addCase(resourceUpdate.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let { facilities, resources, golds } = action.payload || {};

            // let facilities = cityInstance.getFacilityList();
            // let resources = cityInstance.getResource();

            let initData = getFlattenCityData(facilities);
            
            state.cities = initData.cities;
            state.summary = initData.summary;
            state.canCityUpgrade = initData.canCityUpgrade;
            state.resources = resources;
            state.golds = golds;
        });
        builder.addCase(resourceRefresh.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let { resources, golds } = action.payload || {};
            state.resources = resources;
            state.golds = golds;
        });
        builder.addCase(recharge.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            state.chargeResult = action.payload || {};
        });
        builder.addCase(getRechargeInfo.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            state.rechargeInfo = action.payload || {};
        });
        builder.addCase(addTestResource.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let result = action.payload || {};
            state.resourceResult = result;
        });
        builder.addCase(onReceiveChat.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let newMessage = action.payload || {};
            let message = updateNewMessage(newMessage);
            let { id, channel } = message;
            if(!id){
                return;
            }
            if(channel === 1){
                state.messageListWorld.push(message);
            }
            if(channel === 2){
                state.messageListUnion.push(message);
            }
        });
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let result = action.payload || {};
            state.messageResult = result;
        });
        builder.addCase(getHistoryMessages.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let messageList = action.payload || [];
            let { messageListWorld, messageListUnion } = updateMessages(messageList);
            state.messageListWorld = messageListWorld;
            state.messageListUnion = messageListUnion;
        });
        builder.addCase(donateSilver.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let res = action.payload || [];
            state.donateResult = res;
        });
        builder.addCase(setGuideStep.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let res = action.payload || [];
            state.guideStepResult = res;
        });
        builder.addCase(healTroopsStart.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let res = action.payload || [];
                res.back = true;
            state.healResult = res;
        });
        builder.addCase(buyOffer.fulfilled, (state, action) => {
            if(!cityInstance){
                return;
            }
            let { result } = action.payload;
                result.back = true;
            state.offerResult = result;
        });
    }
});

export const { 
    recruitEstimate,
    resetRecruitResult, getRecruitState, 
    resetBuildingUpdateResult, getTestResourceCoolDownTime, 
    resetChargeResult,
    getGoodsList, getGold,

    checkNewMessage, setNewMessageRead,
    resetMessageResult,
    
    getAbleActivityInfo, removeActivityNew,
    getGuideStep,
    getRecruitNeed,
    refreshCityData,
    healEstimate,
    resetHealResult,

    getOfferList, resetOfferResult
} = buildingSlice.actions

export default buildingSlice.reducer;

//cal flatten city list
function getFlattenCityData(facilities){
    if(!facilities){
        return {
            cities: [],
            summary: {}
        };
    }
    //type, level
    let cities = [];
    let need_troops = 0;
    let canCityUpgrade = false;
    facilitiyOrder.forEach(function(type){
        let subCities = facilities[type] || [];
        let typeInfos = cityInstance.getAllUpgradeInfo(type);
        //typeInfos = 10 levels array
        facilitiyMap[type] = typeInfos;
        subCities.forEach(function(level, index){
            // console.log('_.each', level, index);
            let id = type + '-' + index;
            let info = typeInfos[level-1];
            let canUpgrade = cityInstance.checkUpgradeFacility(type, index);
            let item = {
                id: id,
                index: index,
                type: type,
                level: info.level,
                maxLevel: typeInfos.length,
                canUpgrade: canUpgrade,
                info: info,
                nextInfo: typeInfos[level] || {}
            };
            need_troops += (info.need_troop || 0);
            cities.push(item);

            if(canUpgrade && info.level < typeInfos.length){
                canCityUpgrade = true;
            }
        })
    });
    let summary =  {
        need_troops: need_troops
    };
    return {cities, summary, canCityUpgrade};
}

let messageHis = {};
function updateMessages(messageList){
    //new message, his message, mine send message
    //merge all into a message List with page.
    let messageListWorld = [];
    let messageListUnion = [];
    messageHis = {};
    messageList.forEach(function(message, index){
        let { id, channel } = message;
        if(!messageHis[id]){
            messageHis[id] = message;
            if(channel === 1){
                messageListWorld.push(message);
            }
            if(channel === 2){
                messageListUnion.push(message);
            }
        }
    });
    return { messageListWorld, messageListUnion };
}
function updateNewMessage(message){
    let { id } = message;
    if(messageHis[id]){
        return {};
    }
    messageHis[id] = message;
    return message;
}

