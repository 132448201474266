"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.City = exports.RecruitStatus = void 0;
var Const_1 = require("../Const");
var DataConfig_1 = require("../DataConfig");
var Utils_1 = require("../Utils");
var state_1 = require("../../Core/state");
var strategy_1 = require("./strategy");
var RecruitStatus;
(function (RecruitStatus) {
    RecruitStatus["None"] = "none";
    RecruitStatus["Going"] = "going";
    RecruitStatus["Ready"] = "ready";
})(RecruitStatus = exports.RecruitStatus || (exports.RecruitStatus = {}));
var City = /** @class */ (function () {
    function City(state) {
        console.log('city constructor facilities:', state);
        this.state = state;
        this.cityConfig = DataConfig_1.CityConfigFromGDS;
        this.rechargeConfig = DataConfig_1.RechargeConfigFromGDS;
        this.parameter = DataConfig_1.parameterConfig;
        this.offers = DataConfig_1.offerConfigFromGDS;
    }
    City.prototype.loadState = function (state) {
        this.state.update(state);
    };
    City.prototype.setBoost = function (boost) {
        this.boost = boost;
    };
    City.prototype.setMap = function (map) {
        this.map = map;
    };
    City.prototype.setGeneral = function (general) {
        this.general = general;
    };
    City.prototype.getResource = function (typ) {
        var time = parseInt(new Date().getTime() / 1000 + '');
        if (typ == Const_1.ResouceType.Silver) {
            if (!this.state.resources[typ]) {
                return 0;
            }
            var info = this.state.resources[typ];
            var value = info['value'] || 0;
            if (info['lastUpdate'] != -1) {
                var hour = (time - info['lastUpdate']) / 3600;
                value = hour * this.boost.getProduction(typ) + value;
            }
            return Math.max(0, value);
        }
        else {
            var resources = this.state.resources || {};
            var troops = resources[Const_1.ResouceType.Troop] || {};
            return troops['value'] || 0;
        }
    };
    City.prototype.updateResource = function (typ) {
        var _a, _b;
        var time = parseInt(new Date().getTime() / 1000 + '');
        if (typ == Const_1.ResouceType.Silver) {
            this.state.update((_a = {},
                _a["resources.".concat(typ)] = {
                    lastUpdate: time,
                    value: this.getResource(typ) || 0,
                },
                _a));
        }
        else {
            var recruit = this.state.recruit;
            var troop = this.state.resources[Const_1.ResouceType.Troop];
            var troopAdd = 0;
            var productReduce = 0;
            var reduceCount = 0;
            for (var i = 0; i < recruit.length; i++) {
                if (recruit[i].endtime <= time) {
                    troopAdd += recruit[i].amount;
                    recruit.splice(i - reduceCount, 1);
                    reduceCount++;
                }
            }
            productReduce = troopAdd * 3;
            var nowValue = this.getResource(Const_1.ResouceType.Silver);
            if (reduceCount != 0) {
                this.state.update((_b = {
                        'recruit': recruit
                    },
                    _b["resources.".concat(Const_1.ResouceType.Silver)] = {
                        lastUpdate: time,
                        value: nowValue || 0,
                    },
                    _b["resources.".concat(Const_1.ResouceType.Troop)] = {
                        lastUpdate: time,
                        value: (troop.value + troopAdd) || 0,
                    },
                    _b));
            }
        }
    };
    City.prototype.getUpgradeInfo = function (typ, targetLevel) {
        var row = this.cityConfig.facilityConfig[typ].get((targetLevel - 2).toString());
        return row;
    };
    City.prototype.getAllUpgradeInfo = function (type) {
        var re = [];
        var i = 1;
        while (true) {
            // console.log(type, this.cityConfig.facilityConfig)
            var row = null;
            if (this.cityConfig.facilityConfig[type]) {
                row = this.cityConfig.facilityConfig[type].get((i - 1).toString());
            }
            if (row) {
                re.push(row);
            }
            else {
                break;
            }
            i++;
        }
        return re;
    };
    City.prototype.checkUpgradeFacility = function (typ, index) {
        var _a;
        if (index === void 0) { index = 0; }
        var levelList = (_a = this.state.facilities[typ]) !== null && _a !== void 0 ? _a : [];
        var maxCount = this.cityConfig.limit[typ].max_count;
        if (index >= maxCount) {
            return false;
        }
        var tartgetLevel = 1;
        if (index < levelList.length) {
            tartgetLevel = levelList[index] + 1;
        }
        var row = this.getUpgradeInfo(typ, tartgetLevel);
        var nextRow = this.getUpgradeInfo(typ, tartgetLevel + 1);
        if (row == undefined || nextRow == undefined) {
            return false;
        }
        if (this.getResource(Const_1.ResouceType.Silver) >= row.need_silver && this.getResource(Const_1.ResouceType.Troop) >= row.need_troop) {
            return true;
        }
        return false;
    };
    City.prototype.calculatePoduction = function (typ) {
        var re = 0;
        switch (typ) {
            case Const_1.ResouceType.Silver:
                if (this.state.facilities[Const_1.CityFacility.Home]) {
                    var list = this.state.facilities[Const_1.CityFacility.Home];
                    for (var i = 0; i < list.length; i++) {
                        var level = list[i];
                        var production = this.cityConfig.facilityConfig[Const_1.CityFacility.Home].get(level - 1 + '').product_silver;
                        re += production;
                    }
                }
                re -= this.state.resources[Const_1.ResouceType.Troop].value;
                break;
            case Const_1.ResouceType.Troop:
                if (this.state.facilities[Const_1.CityFacility.TrainingCenter]) {
                    var list = this.state.facilities[Const_1.CityFacility.TrainingCenter];
                    for (var i = 0; i < list.length; i++) {
                        var level = list[i];
                        var production = this.cityConfig.facilityConfig[Const_1.CityFacility.TrainingCenter].get(level - 1 + '').get_troop;
                        re += production;
                    }
                }
                break;
        }
        return re;
    };
    City.prototype.upgradeFacility = function (typ, index) {
        var _a;
        var _b, _c;
        if (index === void 0) { index = 0; }
        this.updateResource(Const_1.ResouceType.Silver);
        if (!this.checkUpgradeFacility(typ, index)) {
            return {
                result: false,
                txType: Const_1.StateTransition.UpgradeFacility,
                "error": "checkUpgradeFacility-error"
            };
        }
        var levelList = (_c = (_b = this.state.facilities[typ]) === null || _b === void 0 ? void 0 : _b.concat()) !== null && _c !== void 0 ? _c : [];
        var maxCount = this.cityConfig.limit[typ].max_count;
        if (index >= maxCount) {
            return {
                result: false,
                txType: Const_1.StateTransition.UpgradeFacility,
                "error": "index-over-max"
            };
        }
        var tartgetLevel = 1;
        if (index == levelList.length) {
            levelList.push(1);
        }
        else {
            tartgetLevel = levelList[index] + 1;
            levelList[index] = tartgetLevel;
        }
        var row = this.cityConfig.facilityConfig[typ].get((tartgetLevel - 2).toString());
        var info = this.state.resources[Const_1.ResouceType.Silver];
        var silver = {
            lastUpdate: info.lastUpdate,
            value: (info.value - row.need_silver) || 0
        };
        this.state.update((_a = {},
            _a["facilities.".concat(typ)] = levelList,
            _a["resources.".concat(Const_1.ResouceType.Silver)] = silver,
            _a));
        this.useTroop(row.need_troop);
        return {
            result: true,
            txType: Const_1.StateTransition.UpgradeFacility,
            facility: typ,
            levelTo: tartgetLevel
        };
    };
    City.prototype.getFacilityOrder = function () {
        var re = new Array(Object.keys(this.cityConfig.limit).length).fill('');
        for (var key in this.cityConfig.limit) {
            var index = this.cityConfig.limit[key].order - 1;
            re[index] = key;
        }
        console.log('city.data cityConfig:', this.cityConfig);
        console.log('city.data cityConfig re:', re);
        return re;
    };
    City.prototype.getGeneralMaxAble = function () {
        var fortresslevel = this.state.facilities.fortress[0];
        return this.cityConfig.facilityConfig[Const_1.CityFacility.Fortress].get((fortresslevel - 1).toString()).employ_count;
    };
    City.prototype.updateInjuredTroops = function (amount, type) {
        var username = (0, Utils_1.parseStateId)(this.general.state.getId()).username;
        var injuredTroops = this.state.injuredTroops;
        console.log(username, ' troops injured 1: ', { amount: amount, type: type, injuredTroops: injuredTroops });
        amount = amount || 0;
        amount = amount || 0;
        var dayMsLong = 24 * 60 * 60;
        var updateTime = injuredTroops.updateTime;
        var timeNow = Math.floor(new Date().getTime() / 1000);
        var isSameDay = Math.floor(updateTime / dayMsLong) === Math.floor(timeNow / dayMsLong);
        var value = (injuredTroops.value || 0) + amount;
        var today = injuredTroops.today;
        if (type === 'heal') {
            updateTime = timeNow;
            if (isSameDay) {
                today += amount;
            }
            else {
                today = amount;
            }
        }
        if (type === 'battle' && !isSameDay) {
            today = 0;
        }
        console.log(username, ' troops injured 2: sameday:', isSameDay, { today: today, type: type });
        console.log(username, ' troops injured 3: ', { amount: amount, type: type, value: value, today: today, updateTime: updateTime });
        this.state.update({
            injuredTroops: { value: value, today: today, updateTime: updateTime }
        });
        return { value: value, today: today, updateTime: updateTime };
    };
    City.prototype.getInjuredTroops = function () {
        return this.updateInjuredTroops(0, 'heal');
        // let injuredTroops: InjuredTroops = this.state.injuredTroops || { updateTime: 0, today: 0, value : 0};
        // return injuredTroops;
    };
    City.prototype.healEstimate = function (amount) {
        var unit1 = this.parameter["healing_troops_need_silver"];
        var unit2 = this.parameter["healing_troops_need_gold"];
        return {
            silver: Math.ceil(amount * unit1),
            gold: Math.ceil(amount * unit2)
        };
    };
    City.prototype.healTroopsBySilver = function (amount) {
        var injuredTroops = this.getInjuredTroops();
        if (amount <= 0 || injuredTroops.value < amount) {
            return {
                txType: Const_1.StateTransition.HealTroops,
                err: 'heal amount err.'
            };
        }
        var silversNeed = this.healEstimate(amount).silver;
        if (silversNeed > this.getResource(Const_1.ResouceType.Silver)) {
            return {
                txType: Const_1.StateTransition.HealTroops,
                err: 'silvers not enough.'
            };
        }
        this.useSilver(silversNeed);
        this.useTroop(-1 * amount);
        this.updateInjuredTroops(-1 * amount, 'heal');
        return {
            txType: Const_1.StateTransition.HealTroops,
            result: true
        };
    };
    City.prototype.healTroopsByGold = function (amount) {
        var injuredTroops = this.getInjuredTroops();
        if (amount <= 0 || injuredTroops.value < amount) {
            return {
                txType: Const_1.StateTransition.HealTroops,
                err: 'heal count err.'
            };
        }
        var nowGlod = this.state.gold;
        var goldsNeed = this.healEstimate(amount).gold;
        ;
        if (goldsNeed > nowGlod) {
            return {
                txType: Const_1.StateTransition.HealTroops,
                err: 'gold not enough.'
            };
        }
        this.useGold(goldsNeed);
        this.useTroop(-1 * amount); //plus
        this.updateInjuredTroops(-1 * amount, 'heal'); //minus
        return {
            txType: Const_1.StateTransition.HealTroops,
            result: true
        };
    };
    City.prototype.useSilver = function (amount) {
        var _a;
        console.log('useSilver ', amount);
        amount = amount || 0;
        var info = this.state.resources[Const_1.ResouceType.Silver];
        console.log('useSilver ', amount, info);
        if (amount <= this.getResource(Const_1.ResouceType.Silver)) {
            this.state.update((_a = {},
                _a["resources.".concat(Const_1.ResouceType.Silver, ".value")] = (info.value - amount) || 0,
                _a));
            this.updateResource(Const_1.ResouceType.Silver);
            return true;
        }
        return false;
    };
    City.prototype.useTroop = function (amount) {
        var _a;
        var info = this.state.resources[Const_1.ResouceType.Troop];
        if (amount <= info.value) {
            var username = (0, Utils_1.parseStateId)(this.general.state.getId()).username;
            console.log(username, ' troops resources:', info.value, ' + ', amount, ' = ', (info.value - amount) || 0);
            this.state.update((_a = {},
                _a["resources.".concat(Const_1.ResouceType.Troop, ".value")] = (info.value - amount) || 0,
                _a));
            this.updateResource(Const_1.ResouceType.Silver);
            return true;
        }
        return false;
    };
    City.prototype.updateBoost = function () {
        this.boost.setTroop(this.getResource(Const_1.ResouceType.Troop), this.getMaintainNeedTroop());
        this.boost.setProduction(Const_1.StateName.City, Const_1.ResouceType.Silver, this.calculatePoduction(Const_1.ResouceType.Silver));
        this.boost.setProduction(Const_1.StateName.City, Const_1.ResouceType.Troop, this.calculatePoduction(Const_1.ResouceType.Troop));
    };
    City.prototype.getRecruitNeed = function (amount) {
        return 100 * amount;
    };
    City.prototype.recruit = function (amount) {
        var cost = this.getRecruitNeed(amount);
        if (amount <= 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.Recruit,
                error: 'amount-err'
            };
        }
        if (cost > this.getResource(Const_1.ResouceType.Silver)) {
            return {
                result: false,
                txType: Const_1.StateTransition.Recruit,
                error: 'silver-not-enough'
            };
        }
        var recruit = this.state.recruit;
        var product = this.boost.getProduction(Const_1.ResouceType.Troop);
        var time = (0, Utils_1.getTimeStamp)();
        var endtime = Math.floor(amount / product * 3600) + time;
        console.log('recruit', product, amount, Math.floor(amount / product * 3600));
        if (!this.useSilver(cost)) {
            return {
                result: false,
                txType: Const_1.StateTransition.Recruit,
                error: 'silver-not-enough'
            };
        }
        recruit.push({
            amount: amount,
            endtime: endtime
        });
        this.state.update({
            'recruit': recruit
        });
        return {
            result: true,
            txType: Const_1.StateTransition.Recruit,
            amount: amount,
            endtime: endtime
        };
    };
    City.prototype.recruitEstimate = function (amount) {
        var cost = this.getRecruitNeed(amount);
        var product = this.boost.getProduction(Const_1.ResouceType.Troop);
        var time = Math.floor(amount / product * 3600);
        return {
            amount: amount,
            cost: cost,
            time: time
        };
    };
    //1= infantry ；2=cavalry ；3=archer
    City.prototype.getBattleStatus = function (general_type) {
        var re = {
            attack: 0,
            defense: 0
        };
        switch (general_type) {
            case 1:
                var level1 = this.state.facilities[Const_1.CityFacility.InfantryCamp][0];
                var row1 = this.cityConfig.facilityConfig[Const_1.CityFacility.InfantryCamp].get((level1 - 1).toString());
                re.attack = row1.infantry_attack;
                re.defense = row1.infantry_defense;
                break;
            case 2:
                var level2 = this.state.facilities[Const_1.CityFacility.CavalryCamp][0];
                var row2 = this.cityConfig.facilityConfig[Const_1.CityFacility.CavalryCamp].get((level2 - 1).toString());
                re.attack = row2.cavalry_attack;
                re.defense = row2.cavalry_defense;
                break;
            case 3:
                var level3 = this.state.facilities[Const_1.CityFacility.ArcherCamp][0];
                var row3 = this.cityConfig.facilityConfig[Const_1.CityFacility.ArcherCamp].get((level3 - 1).toString());
                re.attack = row3.archer_attack;
                re.defense = row3.archer_defense;
                break;
        }
        return re;
    };
    City.prototype.getSaveSilverAmount = function () {
        var saveAmount = 0;
        for (var i = 0; i < this.state.facilities[Const_1.CityFacility.Store].length; i++) {
            var level = this.state.facilities[Const_1.CityFacility.Store][i];
            saveAmount += this.cityConfig.facilityConfig[Const_1.CityFacility.Store].get((level - 1).toString()).silver_save;
        }
        return saveAmount;
    };
    City.prototype.robSilver = function (amount) {
        var re = 0;
        var saveAmount = this.getSaveSilverAmount();
        var ifStore = this.boost.getStrategyStatus(strategy_1.StrategyType.Store);
        if (ifStore) {
            saveAmount = saveAmount * 2;
        }
        var sliverCanRob = Math.max(this.getResource(Const_1.ResouceType.Silver) - saveAmount, 0);
        if (this.useSilver(Math.min(amount, sliverCanRob))) {
            re = Math.min(amount, this.getResource(Const_1.ResouceType.Silver) - saveAmount);
            re = Math.max(re, 0);
        }
        return re;
    };
    City.prototype.getMaxDefenseTroop = function () {
        var wallLevel = this.state.facilities[Const_1.CityFacility.Wall][0];
        var row = this.cityConfig.facilityConfig[Const_1.CityFacility.Wall].get((wallLevel - 1).toString());
        return row.scale_of_troop_defense;
    };
    City.prototype.getMaxAttackTroop = function () {
        var wallLevel = this.state.facilities[Const_1.CityFacility.MilitaryCenter][0];
        var row = this.cityConfig.facilityConfig[Const_1.CityFacility.MilitaryCenter].get((wallLevel - 1).toString());
        return row.scale_of_troop_attack;
    };
    City.prototype.getMaintainNeedTroop = function () {
        var troop = 0;
        var facilities = this.state.facilities;
        console.log('getMaintainNeedTroop state facilities:', this.state);
        console.log('getMaintainNeedTroop facilities:', facilities);
        console.log('getMaintainNeedTroop facilities facilityConfig:', this.cityConfig.facilityConfig);
        for (var key in Const_1.CityFacility) {
            var type = Const_1.CityFacility[key];
            var levelList = this.state.facilities[type] || [];
            // console.log('getMaintainNeedTroop', this.state.facilities, type, levelList);
            for (var _i = 0, levelList_1 = levelList; _i < levelList_1.length; _i++) {
                var level = levelList_1[_i];
                var row = this.cityConfig.facilityConfig[type].get((level - 1).toString());
                troop += row.maintain_need_troop;
            }
        }
        return troop;
    };
    City.prototype.getRechargeConfigs = function (chainName) {
        var all = (0, state_1.copyObj)(this.rechargeConfig.config);
        console.log('getRechargeConfigs', chainName, all);
        var config = [];
        for (var key in all) {
            var item = all[key];
            if (item.chain === chainName) {
                config.push(item);
            }
        }
        return config;
    };
    City.prototype.recharge = function (rechargeId, amount) {
        var tempConfig = undefined;
        tempConfig = this.rechargeConfig.get(rechargeId);
        console.log("recharge config ", tempConfig, " rechargeId ", rechargeId, " amount ", amount * 1e6);
        if (!tempConfig || tempConfig.internal_id != rechargeId) {
            return {
                result: false,
                txType: Const_1.StateTransition.Recharge,
                error: 'recharge-config-error'
            };
        }
        var nowGlod = this.state.gold;
        this.state.update({
            gold: nowGlod + tempConfig.gold + tempConfig.extra_gold
        });
        return {
            result: true,
            txType: Const_1.StateTransition.Recharge
        };
    };
    City.prototype.finishOutChainUserActivity = function (type, action, strategy) {
        //upgrade_fortress_share_activity_reward ,attack_territory_share_activity_reward ,accquire_energy_share_activity_reward 
        if (action == "accquire_energy") {
            console.log("OutChainUserActivityArgs accquire_energy");
            strategy.offsetStrategyPoint(1, true);
            return {
                txType: Const_1.StateTransition.FinishOutChainUserActivity,
                result: true
            };
        }
        var actionReward = this.parameter[action + "_" + type + "_reward"];
        console.log("OutChainUserActivityArgs ", type, " action ", action, ' actionReward', actionReward);
        // actionReward is number
        if (typeof actionReward === 'number' && !isNaN(actionReward)) {
            if (!this.state.rewardClaimed) {
                this.state.update({ rewardClaimed: {} });
            }
            if (this.state.rewardClaimed[action]) {
                return {
                    result: false,
                    txType: Const_1.StateTransition.FinishOutChainUserActivity,
                    error: "reward-already-claimed"
                };
            }
            this.state.rewardClaimed[action] = true;
            var nowGlod = this.state.gold;
            this.state.update({
                gold: nowGlod + actionReward,
            });
            return {
                result: true
            };
        }
        else {
            return {
                result: false,
                txType: Const_1.StateTransition.FinishOutChainUserActivity,
                error: "action-reward-error"
            };
        }
    };
    City.prototype.addPreRegisterGold = function () {
        console.log('addUserScoresAndExtraGeneral addPreRegisterGold:', { gold: this.state.gold, register_reward_gold: this.parameter.register_reward_gold });
        this.state.update({
            gold: this.state.gold + this.parameter.register_reward_gold
        });
    };
    City.prototype.addRandomCampGold = function () {
        console.log('addUserScoresAndExtraGeneral addRandomCampGold:', { gold: this.state.gold, choose_random_camp_reward: this.parameter.choose_random_camp_reward });
        this.state.update({
            gold: this.state.gold + this.parameter.choose_random_camp_reward
        });
    };
    City.prototype.useGold = function (amount) {
        if (amount <= this.state.gold) {
            this.state.update({
                gold: this.state.gold - amount
            });
            return true;
        }
        return false;
    };
    City.prototype.addTestResource = function () {
        var seasonState = this.map.getSeasonState();
        var seasonId = seasonState.seasonId;
        if (seasonId && seasonId.indexOf('test-') !== 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.AddTestResource,
                error: 'illeagel-opration'
            };
        }
        var coolDown = this.getTestResourceCoolDownTime();
        if (coolDown != 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.AddTestResource,
                error: 'cool-down-have-not-end'
            };
        }
        var time = (0, Utils_1.getTimeStamp)();
        this.useSilver(-100000000);
        this.useTroop(-100000);
        this.useGold(-50000);
        this.state.update({
            lastAddTestTime: time
        });
        return {
            txType: Const_1.StateTransition.AddTestResource,
            result: true
        };
    };
    City.prototype.getOfferList = function () {
        return this.offers.config;
    };
    City.prototype.buyOffer = function (offerId) {
        var offerData = this.offers.get(offerId);
        console.log('onBuyOffer 2:', offerId, ', offerData: ', offerData);
        var buyOfferRecords = this.state.buyOfferRecords || {};
        console.log('onBuyOffer buyOfferRecords:', buyOfferRecords);
        if (buyOfferRecords[offerId]) {
            return {
                txType: Const_1.StateTransition.BuyOffer,
                result: false,
                error: 'not allow by again',
                data: offerData
            };
        }
        if (!offerData['offer_id']) {
            return {
                txType: Const_1.StateTransition.BuyOffer,
                result: false,
                error: 'Offer id err',
                data: offerData
            };
        }
        var goldCount = offerData.offer_gold;
        if (!this.useGold(goldCount)) {
            return {
                txType: Const_1.StateTransition.BuyOffer,
                result: false,
                error: 'Gold is not enough',
                data: offerData
            };
        }
        var isCanbuy = this.isOfferCanBuy(offerData);
        if (!isCanbuy) {
            return {
                txType: Const_1.StateTransition.BuyOffer,
                result: false,
                error: 'not allow buy this offer',
                data: offerData
            };
        }
        var silverCount = 0 - offerData.offer_reward_sliver;
        var troopsCount = 0 - offerData.offer_reward_troops;
        this.useSilver(silverCount);
        this.useTroop(troopsCount);
        buyOfferRecords[offerId] = (0, Utils_1.getTimeStamp)();
        this.state.update({
            buyOfferRecords: buyOfferRecords
        });
        return {
            txType: Const_1.StateTransition.BuyOffer,
            result: true,
            data: offerData
        };
    };
    City.prototype.isOfferCanBuy = function (offerData) {
        var offer_trigger_1 = offerData.offer_trigger_1, _a = offerData.offer_trigger_2, offer_trigger_2 = _a === void 0 ? 0 : _a, offer_trigger_value = offerData.offer_trigger_value;
        var silverProduction = this.boost.getProduction(Const_1.ResouceType.Silver);
        var troopProduction = this.boost.getProduction(Const_1.ResouceType.Troop);
        var maxGeneralLevel = this.general.getMaxGeneralLevel();
        var compareVars = {
            '1': silverProduction,
            '2': maxGeneralLevel,
            '3': troopProduction
        };
        var seasonState = this.map.getSeasonState();
        var season_open = seasonState.season_open;
        var timeNow = (0, Utils_1.getTimeStamp)();
        var dayLong = 24 * 60 * 60;
        var dayCount = Math.ceil(timeNow / dayLong) - Math.ceil(season_open / dayLong) + 1;
        console.log('onBuyOffer canbuy 1:', offerData);
        console.log('onBuyOffer canbuy 2:', compareVars, { season_open: season_open, dayCount: dayCount });
        var isCanbuy = dayCount >= offer_trigger_1;
        if (offer_trigger_2) {
            isCanbuy = isCanbuy && compareVars[offer_trigger_2] < offer_trigger_value;
        }
        return isCanbuy;
    };
    City.prototype.getTestResourceCoolDownTime = function () {
        var time = (0, Utils_1.getTimeStamp)();
        var maxCoolDown = 24 * 3600;
        if (time - this.state.lastAddTestTime >= maxCoolDown) {
            return 0;
        }
        else {
            return maxCoolDown - (time - this.state.lastAddTestTime);
        }
    };
    City.prototype.getActivityData = function (activityId) {
        for (var _i = 0, _a = this.state.userActivity; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.id == activityId) {
                return item.value;
            }
        }
        return -1;
    };
    City.prototype.setActivityData = function (activityId, value) {
        var haveSet = false;
        var list = this.state.userActivity;
        for (var i in list) {
            if (list[i].id == activityId) {
                list[i].value = value;
                haveSet = true;
                break;
            }
        }
        if (!haveSet) {
            list.push({
                id: activityId,
                value: value
            });
        }
        this.state.update({
            userActivity: list
        });
    };
    City.prototype.showAll = function () {
        var _a, _b;
        //facilities
        console.log('@@@Dump all facilities');
        for (var key in this.state.facilities) {
            console.log('facilitie: ', key, ' ', this.state.facilities[key]);
        }
        console.log('allTroops ', (_a = this.state.resources[Const_1.ResouceType.Troop]) !== null && _a !== void 0 ? _a : 0);
        console.log('allSilver ', (_b = this.state.resources[Const_1.ResouceType.Silver]) !== null && _b !== void 0 ? _b : 0);
        console.log('@@@Dump all facilities end\n');
    };
    City.prototype.getGuideStep = function (type) {
        var list = this.state.guideStep;
        for (var i in list) {
            if (list[i].type == type) {
                return list[i].step;
            }
        }
        return 0;
    };
    City.prototype.setGuideStep = function (type, step) {
        var list = this.state.guideStep;
        var haveSet = false;
        for (var i in list) {
            if (list[i].type == type) {
                list[i].step = step;
                haveSet = true;
                break;
            }
        }
        if (!haveSet) {
            var item = {
                type: type,
                step: step
            };
            list.push(item);
        }
        this.state.update({
            guideStep: list
        });
        return {
            txType: Const_1.StateTransition.SetGuideStep,
            result: true
        };
    };
    return City;
}());
exports.City = City;
