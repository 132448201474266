import { subs } from './utils';
import AppGame from '../const/app.json';

let rootDir = process.env.PUBLIC_URL;
// let rootDir = '//cdn.leagueofthrones.com';

const cityImagePath = (city) => {
    let type = city.type.toLowerCase();
    return rootDir + '/builds/' + type + '.png';
}

const generalImagePath = (general) => {
    let index = general.index || general.generalId;
    return rootDir + '/general/gnr_' + index + '.png';
}
const generalBigImagePath = (general) => {
    let index = general.index;
    return rootDir + '/general/' + index + '.png';
}
const generalTypeImagePath = (type) => {
    return rootDir + '/general/t' + type + '.png';
}
const generalSkillImagePath = (type) => {
    return rootDir + '/general/' + type + '.png';
}
const unionImagePath = (id) => {
    return rootDir + '/unions/' + id + '.png';
} 
const chainIcon = (id) => {
    return rootDir + '/chains/' + id + '.png';
} 
const actImagePath = (id) => {
    return {
        banner: rootDir + '/acts/' + id + '.png',
        icon: rootDir + '/acts/i' + id + '.png'
    };
} 
const getPolicyIcon = (name) => {
    return rootDir + '/strategy/' + name + '.png';
}

const getLastTime = function(endtime){
    //endtime, timestramp as seconds;
    return endtime - (new Date().getTime())/1000;
}

const getNickName = function(names, address){
    names = names || {};
    address = address.toLowerCase();
    return names[address] || address;
}

const shortString = function(str, len){
    if(str.length > len){
        return str.substring(0, len) + '…';
    }
    return str;
}

const shortHash = function(str, number) {
    let arrL = str.substring(0, number);    
    let arrR = str.substring(str.length - number, str.length);
    return arrL + '....' + arrR;    
}   

const getNftImageUrl = function(nftType, nftId){
    const urls = {
        "airose": "https://airose.mypinata.cloud/ipfs/QmaWPWcxiETd9BwemjYkr6gQAakx5NuJtzS1qDKqY6PWos/%{nftId}.png",
        "apex": "https://airose.mypinata.cloud/ipfs/QmQbbX9ZcA8Dfay94vJZrmvohub1oQfLtZ791DzD8DLmMx/%{nftId}.png",
        "seibaraclub": "https://airose.mypinata.cloud/ipfs/QmQyQGEmJ57H2bb8ktNHpyge6DsKP4oUvbBfgAvzpPP3Y7/IMG-%{nftId}.png"
    };
    nftType = (nftType || '').toLowerCase();
    const url = urls[nftType] || '';
    return subs(url, { nftId });
}

let host = window.location.host;
const idDev = host.indexOf('localhost:') === 0 || host.indexOf('192.168.') === 0;
const isProd = window.location.host.indexOf(AppGame.host) === 0;

export {
    idDev,
    isProd,
    cityImagePath,
    generalImagePath,
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath,
    getPolicyIcon,
    actImagePath,
    getLastTime,
    getNickName,
    shortString,
    shortHash,
    getNftImageUrl,
    chainIcon
}