/*
1: JSON.stringfy --> set --> get --> JSON.parse
2: data format well return as set`s
3: undefined in array will be null after stringfy+parse
4: NS --> namespace
*/
var keyNS = 'lot-default-';

function get(key) {
    /*
    legal data: "" [] {} null flase true

    illegal: undefined
        1: key not set
        2: key is cleared
        3: key removed
        4: wrong data format
    */
    var tempKey = keyNS + key;
    if (!isKeyExist(tempKey)) {
        return null;
    }
    // maybe keyNS could avoid conflict
    var val = localStorage.getItem(tempKey) || sessionStorage.getItem(tempKey);
    val = JSON.parse(val);
    // val format check
    if (val !== null
        && Object.prototype.hasOwnProperty.call(val, 'type')
        && Object.prototype.hasOwnProperty.call(val, 'data')) {
        return val.data;
    }
    return null;
}
// isPersistent
function set(key, val, isTemp) {
    var store;
    if (isTemp) {
        store = sessionStorage;
    } else {
        store = localStorage;
    }

    var data = JSON.stringify({
        data: val,
        type: (typeof val)
    });
    try{
        store.setItem(keyNS + key, data);
        return true;
    }catch(e){
        if(e.name.toUpperCase().indexOf('QUOTA')>=0){
            localStorage.clear();
            store.setItem(keyNS + key, data);
        }
    }
}

function remove(key) {
    var tempKey = keyNS + key;
    localStorage.removeItem(tempKey);
    sessionStorage.removeItem(tempKey);
}

function removeOtherSeason(pramas){
    let { seasonId, account } = pramas;
    let preStr = 'L-';
    for(var i=0; i<localStorage.length; i++){
        let key = localStorage.key(i);
        // console.log('removeOtherSeason', {key, seasonId, account, preStr});
        let isClear = key.indexOf(seasonId) === -1 || key.indexOf(account) === -1; 
        if(key.indexOf(preStr) === 0 && isClear){
            localStorage.removeItem(key);
        }
    }
    //
}

function isKeyExist(key) {
    // do not depend on value cause of "",0
    return Object.prototype.hasOwnProperty.call(localStorage, key)
        || Object.prototype.hasOwnProperty.call(sessionStorage, key);
}

function setKeyNS(NS) {
    var isString = typeof NS === 'string';
    if (isString && NS !== '') {
        keyNS = NS;
    }
}

export {
    setKeyNS,
    get,
    set,
    remove,
    removeOtherSeason
};