"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseStateManager = exports.GenerateMemoryLoadStateFunction = void 0;
function GenerateMemoryLoadStateFunction(initStates) {
    var states = initStates || {};
    return function (sid) {
        return states[sid.id];
    };
}
exports.GenerateMemoryLoadStateFunction = GenerateMemoryLoadStateFunction;
var BaseStateManager = /** @class */ (function () {
    function BaseStateManager(initStates, loadStateFunc, cache) {
        this.states = initStates;
        this.loadStateFunc = loadStateFunc;
        this.cache = cache;
    }
    BaseStateManager.prototype.get = function (sid) {
        if (this.cache && this.states[sid.id]) {
            return this.states[sid.id];
        }
        if (this.loadStateFunc(sid)) {
            this.save(sid, this.loadStateFunc(sid));
        }
        return this.states[sid.id];
    };
    BaseStateManager.prototype.save = function (sid, state) {
        this.states[sid.id] = state;
    };
    return BaseStateManager;
}());
exports.BaseStateManager = BaseStateManager;
