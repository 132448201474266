import * as Sentry from "@sentry/react";
import { Integrations as ApmIntegrations } from '@sentry/apm';
import { BrowserTracing } from "@sentry/tracing";
import { idDev, isProd } from './ui-res';

export default function sentryInit(){
    if(idDev){
        // return;
    }
    let dnsList = {
        'app.leagueofthrones.com': 'https://45da4d632c2e4042b5a96da57efdf499@o4504529473372160.ingest.sentry.io/4504529619189760',
        'default': 'https://db817878f1e442ac976bc647e6392415@o4504529473372160.ingest.sentry.io/4504529476452352'
    };
    Sentry.init({
        // tracesSampleRate: 1.0,
        dsn: dnsList[window.location.host] || dnsList['default'],
        integrations: [
            // new BrowserTracing(),
            new ApmIntegrations.Tracing({
                tracingOrigins: [
                    'test.leagueofthrones.com',
                    'app.leagueofthrones.com'
                ]
            }),
            new Sentry.Integrations.Breadcrumbs({
              console: false,
            })
        ]
    });    
}
