"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.State = exports.copyObj = void 0;
var log = globalThis.log || function () { };
function copyObj(aObject) {
    // Prevent undefined objects
    // if (!aObject) return aObject;
    var bObject = Array.isArray(aObject) ? [] : {};
    var value;
    for (var key in aObject) {
        // Prevent self-references to parent object
        // if (Object.is(aObject[key], aObject)) continue;
        value = aObject[key];
        bObject[key] = (typeof value === "object") ? copyObj(value) : value;
    }
    return bObject;
}
exports.copyObj = copyObj;
function setObjectByPath(obj, path, val) {
    var segIndex = path.indexOf('.');
    if (segIndex == -1) {
        if (typeof (val) == "object") {
            obj[path] = copyObj(val);
        }
        else {
            obj[path] = val;
        }
    }
    else {
        var key = path.substr(0, segIndex);
        if (!obj[key]) {
            obj[key] = {};
        }
        setObjectByPath(obj[key], path.substr(segIndex + 1), val);
    }
}
var State = /** @class */ (function () {
    function State(initVal, watcher) {
        // deep clone ins ES%
        for (var key in initVal) {
            this[key] = initVal[key];
        }
        this._watcher = watcher;
    }
    State.prototype.update = function (obj) {
        //protect id
        delete obj['id'];
        delete obj['_watcher'];
        delete obj['update'];
        delete obj['getId'];
        delete obj['unsderlying'];
        delete obj['getWatcher'];
        for (var key in obj) {
            if (State.protectedFields.indexOf(key) == -1) {
                setObjectByPath(this, key, obj[key]);
            }
        }
        log("onStateChange ", obj);
        if (this._watcher) {
            this._watcher.onStateChange(obj, this);
        }
    };
    State.prototype.getId = function () {
        return this.id;
    };
    State.prototype.unsderlying = function () {
        return this;
    };
    State.prototype.stateObj = function () {
        var res = {};
        for (var key in this) {
            if (State.protectedFields.indexOf(key) == -1) {
                setObjectByPath(res, key, this[key]);
            }
        }
        return res;
    };
    State.prototype.getWatcher = function () {
        return this._watcher;
    };
    State.protectedFields = [
        '_watcher',
        'update',
        'getId',
        'unsderlying',
        'stateObj',
        'getWatcher',
    ];
    return State;
}());
exports.State = State;
