"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatMessage = exports.ProfileTransId = exports.ChatTransId = exports.ChatChannel = exports.ChatType = exports.OutChainUserActivityArgs = exports.GuideStepArgs = exports.DonateSilverArgs = exports.InitUserStatesArgs = exports.BuyStrategyPointArgs = exports.RecoverMoraleArgs = exports.RecoverMoraleType = exports.RechargeArgs = exports.SetIconIdArgs = exports.StartSeasonArgs = exports.SetSeasonEndArgs = exports.SetUnionWinArgs = exports.SetUnionIdArgs = exports.AttackBlockArgs = exports.BattleArgs = exports.ReceiveTroopArgs = exports.SetDefenseGeneralArgs = exports.UpgradeGeneralSkillArgs = exports.UpgradeGeneralArgs = exports.DisableGeneralArgs = exports.AbleGeneralArgs = exports.RecruitArgs = exports.UpgradeFacilityArgs = exports.StateTransitionArgs = exports.StateTransition = exports.ResouceType = exports.CityFacility = exports.StateName = void 0;
/**
 * This module was automatically generated by `ts-interface-builder`
 */
var t = __importStar(require("ts-interface-checker"));
// tslint:disable:object-literal-key-quotes
exports.StateName = t.enumtype({
    "City": "city",
    "General": "general",
    "DefenderInfo": "defenderinfo",
    "MapGlobalInfo": "mapglobalinfo",
    "BlockInfo": "blockinfo",
    "SeasonConfig": "seasonconfig",
    "RewardGloablState": "rewardglobalstate",
    "Strategy": "strategy",
    "Activity": "activity",
});
exports.CityFacility = t.enumtype({
    "Fortress": "fortress",
    "MilitaryCenter": "militarycenter",
    "Wall": "wall",
    "Store": "store",
    "InfantryCamp": "infantrycamp",
    "CavalryCamp": "cavalrycamp",
    "ArcherCamp": "archercamp",
    "TrainingCenter": "trainingcenter",
    "Home": "home",
    "Hospital": "hospital",
    "Assembly": "assembly",
});
exports.ResouceType = t.enumtype({
    "Silver": "silver",
    "Troop": "troop",
});
exports.StateTransition = t.enumtype({
    "UpgradeFacility": 1,
    "Recruit": 2,
    "ExcuteStrategy": 3,
    "HireGeneral": 4,
    "EnhanceGeneral": 5,
    "AttackEnemy": 6,
    "MarchToPos": 7,
    "AbleGeneral": 8,
    "DisableGeneral": 9,
    "UpgradeGeneral": 10,
    "UpgradeGeneralSkill": 11,
    "SetDefenseGeneral": 12,
    "ReceiveTroop": 13,
    "Battle": 14,
    "DefenseBlock": 15,
    "AttackBlock": 16,
    "CancelDefenseBlock": 17,
    "SetUnionId": 18,
    "SetUnionWin": 19,
    "SetSeasonEnd": 20,
    "StartSeason": 21,
    "SetIconId": 22,
    "Recharge": 23,
    "AddTestResource": 24,
    "RecoverMorale": 25,
    "BuyStrategyPoint": 26,
    "StrategyBuySilver": 27,
    "StrategyBuyTroop": 28,
    "StrategyBuyMorale": 29,
    "StrategyBuyProtect": 30,
    "StrategyBuyStore": 31,
    "MiningBlock": 32,
    "InitUserStates": 33,
    "InitGlobalStates": 34,
    "DonateSilver": 35,
    "RegularTask": 36,
    "SetGuideStep": 37,
    "FirstLogin": 38,
    "StrategyBuyProtect1": 39,
    "FinishOutChainUserActivity": 40,
    "HealTroops": 41,
});
exports.StateTransitionArgs = t.iface([], {
    "from": "string",
});
exports.UpgradeFacilityArgs = t.iface(["StateTransitionArgs"], {
    "typ": "CityFacility",
    "index": "number",
});
exports.RecruitArgs = t.iface(["StateTransitionArgs"], {
    "amount": "number",
});
exports.AbleGeneralArgs = t.iface(["StateTransitionArgs"], {
    "id": "number",
});
exports.DisableGeneralArgs = t.iface(["StateTransitionArgs"], {
    "id": "number",
});
exports.UpgradeGeneralArgs = t.iface(["StateTransitionArgs"], {
    "id": "number",
});
exports.UpgradeGeneralSkillArgs = t.iface(["StateTransitionArgs"], {
    "generalId": "number",
    "skillIndex": "number",
});
exports.SetDefenseGeneralArgs = t.iface(["StateTransitionArgs"], {
    "generalId": "number",
});
exports.ReceiveTroopArgs = t.iface(["StateTransitionArgs"], {});
exports.BattleArgs = t.iface(["StateTransitionArgs"], {
    "generalId": "number",
    "name": "string",
});
exports.AttackBlockArgs = t.iface(["StateTransitionArgs"], {
    "x_id": "number",
    "y_id": "number",
    "generalId": "number",
});
exports.SetUnionIdArgs = t.iface(["StateTransitionArgs"], {
    "union_id": "number",
    "force": "boolean",
    "random_union": "boolean",
    "general_ids": t.array("number"),
});
exports.SetUnionWinArgs = t.iface(["StateTransitionArgs"], {
    "unionId": "number",
});
exports.SetSeasonEndArgs = t.iface(["StateTransitionArgs"], {});
exports.StartSeasonArgs = t.iface(["StateTransitionArgs"], {
    "applies": t.iface([], {}),
    "season": t.iface([], {
        "apply_ts": "number",
        "prepare_ts": "number",
        "start_ts": "number",
        "end_ts": "number",
        "reward_amount_1": "number",
        "reward_amount_2": "number",
        "rank_config_fromto": t.array("number"),
        "rank_config_value": t.array("number"),
    }),
});
exports.SetIconIdArgs = t.iface(["StateTransitionArgs"], {
    "iconId": "number",
});
exports.RechargeArgs = t.iface(["StateTransitionArgs"], {
    "username": "string",
    "rechargeId": "number",
    "amount": "number",
});
exports.RecoverMoraleType = t.enumtype({
    "Silver": "silver",
    "Gold": "gold",
});
exports.RecoverMoraleArgs = t.iface(["StateTransitionArgs"], {
    "resourceType": "RecoverMoraleType",
});
exports.BuyStrategyPointArgs = t.iface(["StateTransitionArgs"], {
    "amount": "number",
});
exports.InitUserStatesArgs = t.iface(["StateTransitionArgs"], {
    "username": "string",
});
exports.DonateSilverArgs = t.iface(["StateTransitionArgs"], {
    "activityId": "number",
    "amount": "number",
});
exports.GuideStepArgs = t.iface(["StateTransitionArgs"], {
    "type": "string",
    "step": "number",
});
exports.OutChainUserActivityArgs = t.iface(["StateTransitionArgs"], {
    "username": "string",
    "type": "string",
    "action": "string",
});
exports.ChatType = t.enumtype({
    "ChatTypeText": 1,
    "ChatTypePos": 2,
    "ChatTypeSystem_OccupiedTile": 3,
    "ChatTypeSystem_OccupiedCenter": 4,
});
exports.ChatChannel = t.enumtype({
    "ChatChannel_WORLD": 1,
    "ChatChannel_Camp": 2,
});
exports.ChatTransId = t.enumtype({
    "SendChat": "send",
    "HistoryData": "query",
});
exports.ProfileTransId = t.enumtype({
    "Save": "save",
    "Query": "query",
});
exports.ChatMessage = t.iface([], {
    "id": "string",
    "type": "ChatType",
    "channel": "ChatChannel",
    "content": "string",
    "sender": "string",
    "senderCamp": "number",
    "iconId": "number",
    "ts": "number",
});
var exportedTypeSuite = {
    StateName: exports.StateName,
    CityFacility: exports.CityFacility,
    ResouceType: exports.ResouceType,
    StateTransition: exports.StateTransition,
    StateTransitionArgs: exports.StateTransitionArgs,
    UpgradeFacilityArgs: exports.UpgradeFacilityArgs,
    RecruitArgs: exports.RecruitArgs,
    AbleGeneralArgs: exports.AbleGeneralArgs,
    DisableGeneralArgs: exports.DisableGeneralArgs,
    UpgradeGeneralArgs: exports.UpgradeGeneralArgs,
    UpgradeGeneralSkillArgs: exports.UpgradeGeneralSkillArgs,
    SetDefenseGeneralArgs: exports.SetDefenseGeneralArgs,
    ReceiveTroopArgs: exports.ReceiveTroopArgs,
    BattleArgs: exports.BattleArgs,
    AttackBlockArgs: exports.AttackBlockArgs,
    SetUnionIdArgs: exports.SetUnionIdArgs,
    SetUnionWinArgs: exports.SetUnionWinArgs,
    SetSeasonEndArgs: exports.SetSeasonEndArgs,
    StartSeasonArgs: exports.StartSeasonArgs,
    SetIconIdArgs: exports.SetIconIdArgs,
    RechargeArgs: exports.RechargeArgs,
    RecoverMoraleType: exports.RecoverMoraleType,
    RecoverMoraleArgs: exports.RecoverMoraleArgs,
    BuyStrategyPointArgs: exports.BuyStrategyPointArgs,
    InitUserStatesArgs: exports.InitUserStatesArgs,
    DonateSilverArgs: exports.DonateSilverArgs,
    GuideStepArgs: exports.GuideStepArgs,
    OutChainUserActivityArgs: exports.OutChainUserActivityArgs,
    ChatType: exports.ChatType,
    ChatChannel: exports.ChatChannel,
    ChatTransId: exports.ChatTransId,
    ProfileTransId: exports.ProfileTransId,
    ChatMessage: exports.ChatMessage,
};
exports.default = exportedTypeSuite;
