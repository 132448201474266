"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkNaNInObj = exports.isNumber = exports.decodeChatProfile = exports.encodeChatProfile = exports.getTxHash = exports.getRandom = exports.addToNormalSortedList = exports.addToSortList = exports.indexOfSortedList = exports.getTimeStamp = exports.setTimeOffset = exports.transDateToTimeStamp = exports.parseStateId = void 0;
var Const_1 = require("../Const");
function parseStateId(stateId) {
    var re = {
        type: Const_1.StateName.City,
        username: ''
    };
    var list = stateId.split(':');
    if (list.length == 2) {
        re.type = list[0];
        re.username = list[1];
    }
    else {
        re.type = list[0];
    }
    return re;
}
exports.parseStateId = parseStateId;
function transDateToTimeStamp(date) {
    //new Date('2019-05-28T09:00:20.000Z')
    //"2022_8_14_22"
    date = date || '';
    var list = date.split('_');
    if (list.length != 4) {
        return 0;
    }
    for (var index in list) {
        if (list[index].length == 1) {
            list[index] = '0' + list[index];
        }
    }
    var dataString = list[0] + '-' + list[1] + '-' + list[2] + 'T' + list[3] + ':00:00.000Z';
    return parseInt((new Date(dataString)).valueOf() / 1000 + '');
}
exports.transDateToTimeStamp = transDateToTimeStamp;
var timeOffset = 0;
function setTimeOffset(offset) {
    timeOffset = offset;
}
exports.setTimeOffset = setTimeOffset;
function getTimeStamp(offset) {
    if (offset === void 0) { offset = timeOffset; }
    var time;
    var isBrowser = function () { return typeof window !== "undefined"; };
    if (!isBrowser() && ctx) {
        time = ctx.now(); // + offset
    }
    else {
        time = parseInt(new Date().getTime() / 1000 + '');
    }
    return time; //+ offset
}
exports.getTimeStamp = getTimeStamp;
function getGloryPos(list, username, glory) {
    var oldRank = -1;
    var newRank = -1;
    for (var i = 0; i < list.length; i++) {
        if (list[i].username === username) {
            oldRank = i;
        }
        if (list[i].glory <= glory && newRank === -1) {
            newRank = i;
        }
    }
    return { oldRank: oldRank, newRank: newRank };
}
function indexOfGloryList(list, username, glory) {
    var beginIndex = 0, endIndex = list.length - 1;
    var mid = 0;
    var exist = false;
    var equal = false;
    var index = 0;
    if (list.length == 0) {
        return { exist: exist, index: index };
    }
    while (beginIndex <= endIndex) {
        mid = Math.floor((beginIndex + endIndex) / 2);
        if (list[mid].glory > glory) {
            beginIndex = mid + 1;
            index = mid + 1;
        }
        else if (list[mid].glory < glory) {
            endIndex = mid - 1;
            index = mid;
        }
        else {
            equal = true;
            index = mid;
            break;
        }
    }
    if (equal) {
        //to 0
        do {
            for (var i = mid; i >= 0; i--) {
                if (list[i].glory == glory) {
                    if (list[i].username == username) {
                        index = i;
                        exist = true;
                        break;
                    }
                }
                else {
                    break;
                }
            }
            if (exist) {
                break;
            }
            for (var i = mid; i < list.length; i++) {
                if (list[i].glory == glory) {
                    if (list[i].username == username) {
                        index = i;
                        exist = true;
                        break;
                    }
                }
                else {
                    break;
                }
            }
        } while (false);
    }
    return { exist: exist, index: index };
}
function indexOfSortedList(list, username, value, valueKey) {
    var beginIndex = 0, endIndex = list.length - 1;
    var mid = 0;
    var exist = false;
    var equal = false;
    var index = 0;
    if (list.length == 0) {
        return { exist: exist, index: index };
    }
    while (beginIndex <= endIndex) {
        mid = Math.floor((beginIndex + endIndex) / 2);
        if (list[mid][valueKey] > value) {
            beginIndex = mid + 1;
            index = mid + 1;
        }
        else if (list[mid][valueKey] < value) {
            endIndex = mid - 1;
            index = mid;
        }
        else {
            equal = true;
            index = mid;
            break;
        }
    }
    if (equal) {
        //to 0
        do {
            for (var i = mid; i >= 0; i--) {
                if (list[i][valueKey] == value) {
                    if (list[i]['username'] == username) {
                        index = i;
                        exist = true;
                        break;
                    }
                }
                else {
                    break;
                }
            }
            if (exist) {
                break;
            }
            for (var i = mid; i < list.length; i++) {
                if (list[i][valueKey] == value) {
                    if (list[i]['username'] == username) {
                        index = i;
                        exist = true;
                        break;
                    }
                }
                else {
                    break;
                }
            }
        } while (false);
    }
    return { exist: exist, index: index };
}
exports.indexOfSortedList = indexOfSortedList;
function addToSortList(list, username, originGlory, newGlory, unionId) {
    var insert = {
        username: username,
        glory: newGlory,
        unionId: unionId
    };
    console.log("before add sort list, length:" + list.length + " list:" + JSON.stringify(list));
    if (list.length == 0) {
        list.push(insert);
        console.log("add new item to list", JSON.stringify(list));
        return;
    }
    // let origin = indexOfGloryList(list, username, originGlory)
    // if(origin.exist){
    //     list.splice(origin.index, 1)
    // }
    // let newInfo = indexOfGloryList(list, username, newGlory)
    // list.splice(newInfo.index, 0, insert)
    var posData = getGloryPos(list, username, newGlory);
    console.log("before add sort list:", posData, "new rank data", { username: username, newGlory: newGlory }, " list:" + JSON.stringify(list));
    var oldRank = posData.oldRank, newRank = posData.newRank;
    if (newRank === -1) {
        newRank = list.length;
    }
    if (oldRank > -1) {
        if (oldRank < newRank) {
            newRank = newRank - 1;
        }
        list.splice(oldRank, 1);
    }
    list.splice(newRank, 0, insert);
}
exports.addToSortList = addToSortList;
function addToNormalSortedList(list, username, originValue, newValue, valueKey) {
    var insert = {
        username: username,
    };
    insert[valueKey] = newValue;
    if (list.length == 0) {
        list.push(insert);
        return;
    }
    var origin = indexOfSortedList(list, username, originValue, valueKey);
    if (origin.exist) {
        list.splice(origin.index, 1);
    }
    var newInfo = indexOfSortedList(list, username, newValue, valueKey);
    list.splice(newInfo.index, 0, insert);
}
exports.addToNormalSortedList = addToNormalSortedList;
function getRandom() {
    var isBrowser = function () { return typeof window !== "undefined"; };
    if (!isBrowser() && ctx) {
        return ctx.random();
    }
    else {
        return Math.random();
    }
}
exports.getRandom = getRandom;
function getTxHash() {
    var isBrowser = function () { return typeof window !== "undefined"; };
    if (!isBrowser() && ctx) {
        return ctx.getTxHash();
    }
    else {
        return "";
    }
}
exports.getTxHash = getTxHash;
function encodeChatProfile(id, ts) {
    return id + ":" + ts;
}
exports.encodeChatProfile = encodeChatProfile;
function decodeChatProfile(data) {
    var list = data.split(':');
    if (list.length != 2) {
        throw "chat profile error";
    }
    return {
        id: list[0],
        ts: parseInt(list[1])
    };
}
exports.decodeChatProfile = decodeChatProfile;
function isNumber(value) {
    return Number.isFinite(value);
}
exports.isNumber = isNumber;
function checkNaNInObj(aObject) {
    var value;
    for (var key in aObject) {
        value = aObject[key];
        if (typeof value == "number" && !isNumber(value)) {
            throw "obj have illegal number";
        }
        if (typeof value === "object") {
            checkNaNInObj(value);
        }
    }
}
exports.checkNaNInObj = checkNaNInObj;
