import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { Throne, ComponentType } from 'throne-underlying';
import { logTrace } from '../utils/log'
import * as cache from '../utils/cache'

let chainInstance = null;
const cacheKey = 'gameTransactions';
const cacheNewKey = 'gameNewTransactions';
const cacheZetaKey = 'gameZetaTransactions';

export const chainInit = createAsyncThunk("chain/init", async () => {
    if(chainInstance){
        return;
    }
    const response = await new Promise((resolve, reject) => {
        Throne.instance().initComponent(ComponentType.Chain, (_chain) => {
            chainInstance = _chain;


            //{"type":"sync_blockchain","data":{"hash":"" ,"chain":"zeta"} }
            chainInstance.onReceiveChainBlockInfo((transaction) => {
                // console.log('transaction 21', transaction);
                console.log('new event', transaction);
                if(transaction.Type === 'sync_blockchain'){
                    cacheZetaTransactions(transaction);
                }else{
                    cacheTransactions(transaction);
                    cacheNewTransactions(transaction);
                }
                resolve(transaction);
            });
            resolve(true);
        });
    });
    return response;
});

export const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    chainReady: false,
    newTransactions: [],
    zetaTransactions: [],
    transactions: cache.get(cacheKey) || []
});

export const chainSlice = createSlice({
    name: 'chain',
    initialState,
    reducers: {
        refreshTransactions: function(state, action){
            if(!chainInstance){
                return;
            }
            state['newTransactions'] = cache.get(cacheNewKey) || [];
            state['transactions'] = cache.get(cacheKey) || [];
            state['zetaTransactions'] = cache.get(cacheZetaKey) || [];
        },
        clearNewTransactions: function(state, action){
            if(!chainInstance){
                return;
            }
            cache.set(cacheNewKey, []);
            state['newTransactions'] = [];
        }
    },
    extraReducers: builder => {
        builder.addCase(chainInit.fulfilled, (state, action) => {
            if(!chainInstance){
                return;
            }
            state['chainReady'] = true;
        });
    }
});

export const { 
    refreshTransactions,
    clearNewTransactions,
} = chainSlice.actions;
export default chainSlice.reducer;

function cacheTransactions(transaction){
    let maxLen = 20;
    let gameTransactions = cache.get(cacheKey) || [];
        gameTransactions = [transaction].concat(gameTransactions);
        gameTransactions = gameTransactions.splice(0, maxLen);
    cache.set(cacheKey, gameTransactions);
    // console.log('cacheTransactions', {transaction, gameTransactions} );
    // return gameTransactions;
}

function cacheNewTransactions(transaction){
    let list = cache.get(cacheNewKey) || [];
        list = [transaction].concat(list);
        list = list.splice(0, 6);
    cache.set(cacheNewKey, list);
    // console.log('cacheNewTransactions', {transaction, list} );
}

function cacheZetaTransactions(transaction){
    let list = cache.get(cacheZetaKey) || [];
        list = [transaction].concat(list);
        list = list.splice(0, 20);
    cache.set(cacheZetaKey, list);
    // console.log('cacheNewTransactions', {transaction, list} );
}
