"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketMediator = void 0;
var websocket_1 = require("websocket");
var mediator_1 = require("../../Core/mediator");
var state_1 = require("../../Core/state");
var Const_1 = require("../Const");
var protocol_1 = require("./Websocket/protocol");
var WebSocketMediator = /** @class */ (function (_super) {
    __extends(WebSocketMediator, _super);
    function WebSocketMediator(url, data) {
        var _this_1 = _super.call(this) || this;
        _this_1.stateCaches = {};
        _this_1.seqNum = 1;
        _this_1.url = url;
        _this_1.client = new websocket_1.w3cwebsocket(url);
        _this_1.respCallbacks = {};
        _this_1.respContext = {};
        _this_1.stateCaches = {};
        _this_1.notifyStateChange = true;
        _this_1.hasCallClose = 0;
        _this_1.seasonId = data.seasonId;
        return _this_1;
    }
    WebSocketMediator.prototype.reconnect = function () {
        var _this = this;
        var url = _this.url;
        var t1 = setTimeout(function () {
            console.log('reconnect init:', url);
            _this.client = new websocket_1.w3cwebsocket(url);
            _this.init();
            if (_this.hasCallClose === 0) {
                clearTimeout(t1);
                _this.closeCallback({ setInterval: 1, hasCallClose: this.hasCallClose }, 1, 'reconnect');
            }
        }, 1000);
    };
    WebSocketMediator.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this_1 = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var _this = _this_1;
                        _this_1.client.onopen = function () {
                            resolve('');
                        };
                        _this_1.hasCallClose = 0;
                        _this_1.client.onmessage = function (message) {
                            var msg = JSON.parse(message.data);
                            _this_1.ctx = msg;
                            if ((msg.Type == protocol_1.MessageType.Transition || msg.Type == protocol_1.MessageType.SyncBlockchain) && _this_1.chainBlockCallback) {
                                _this_1.chainBlockCallback(msg);
                            }
                            console.log('client receive msg is ', JSON.stringify(msg));
                            if (msg.TransID === 'kickout') {
                                _this_1.hasCallClose = 1;
                                if (_this.closeCallback != undefined) {
                                    _this.closeCallback(msg, 0, 'kickout');
                                }
                            }
                            if (msg.SeqNum) {
                                //context call
                                if (_this_1.respCallbacks[msg.SeqNum]) {
                                    if (msg.Type === protocol_1.MessageType.Transition) {
                                        _this_1.respCallbacks[msg.SeqNum](__assign(__assign({}, _this_1.respContext[msg.SeqNum]), { result: msg.Data }));
                                        for (var sid in msg.States) {
                                            var stateObj = msg.States[sid];
                                            _this_1._updateState(sid, stateObj, true);
                                        }
                                    }
                                    else if (msg.Type == protocol_1.MessageType.StateQuery) {
                                        for (var sid in msg.States) {
                                            var stateObj = msg.States[sid];
                                            _this_1._updateState(sid, stateObj, false);
                                        }
                                        _this_1.respCallbacks[msg.SeqNum](_this_1._getState(sid));
                                    }
                                    else if (msg.Type == protocol_1.MessageType.Query || msg.Type == protocol_1.MessageType.Chat || msg.Type == protocol_1.MessageType.QueryCount || msg.Type == protocol_1.MessageType.Profile) {
                                        _this_1.respCallbacks[msg.SeqNum](msg.Data);
                                    }
                                    delete _this_1.respCallbacks[msg.SeqNum];
                                    delete _this_1.respContext[msg.SeqNum];
                                }
                            }
                            else {
                                if (msg.Type === protocol_1.MessageType.Transition) {
                                    // state notify
                                    for (var sid in msg.States) {
                                        var stateObj = msg.States[sid];
                                        _this_1._updateState(sid, stateObj, true);
                                    }
                                }
                                else if (msg.Type === protocol_1.MessageType.Chat) {
                                    var msgList = msg.Data;
                                    for (var _i = 0, msgList_1 = msgList; _i < msgList_1.length; _i++) {
                                        var chatMsg = msgList_1[_i];
                                        //TODO:handle chant msg
                                        _this_1.onReceiveChat(chatMsg);
                                    }
                                }
                            }
                        };
                        _this_1.client.onerror = function (err) {
                            if (_this.closeCallback != undefined && this.hasCallClose === 0) {
                                _this.closeCallback(err, 0, 'error');
                            }
                            this.hasCallClose = 1;
                            this.reconnect();
                            console.log('reconnect onerror', err);
                        };
                        // this.client.onclose = function (err: Error) {
                        //   if(_this.closeCallback != undefined){
                        //     _this.closeCallback(err)
                        //   }
                        //   this.hasCallClose = 1
                        //   console.log('onclose', err);
                        // };
                        setInterval(function () {
                            //ping
                            _this_1.client.send('{}');
                            if (_this_1.closeCallback != undefined && _this_1.client.readyState == websocket_1.w3cwebsocket.CLOSED && _this_1.hasCallClose == 0) {
                                _this_1.closeCallback({ setInterval: 1, hasCallClose: _this_1.hasCallClose }, 0, 'close');
                                _this_1.hasCallClose = 1;
                            }
                            if (_this_1.hasCallClose == 1) {
                                _this_1.reconnect();
                                console.log('reconnect close', _this_1.client.readyState);
                            }
                        }, 10000);
                    })];
            });
        });
    };
    WebSocketMediator.prototype.onStateChange = function (modify, state) {
        state &&
            this.notifyStateChange &&
            this.notifyState({ id: state.getId() }, __assign(__assign({}, state), { context: this.ctx }));
    };
    WebSocketMediator.prototype.query = function (typ, args) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        args.seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Query,
            TransID: typ,
            Data: args,
        };
        console.log('send msg is ', JSON.stringify(msg));
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.defaultQuery = function (type, transID, args) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        args.seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: type,
            TransID: transID,
            Data: args,
        };
        console.log('send msg is ', JSON.stringify(msg));
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.chat = function (data) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        data.seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Chat,
            TransID: Const_1.ChatTransId.SendChat,
            Data: data,
        };
        console.log('chat msg is ', JSON.stringify(msg));
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.chatHistory = function (data) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        data.seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Chat,
            TransID: Const_1.ChatTransId.HistoryData,
            Data: data,
        };
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.profileQuery = function (key) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        var seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Profile,
            TransID: Const_1.ProfileTransId.Query,
            Data: {
                seasonId: seasonId,
                key: key
            },
        };
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.profileSave = function (key, value) {
        var _this_1 = this;
        var seqNum = this.seqNum++;
        var seasonId = this.seasonId;
        var msg = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Profile,
            TransID: Const_1.ProfileTransId.Save,
            Data: {
                seasonId: seasonId,
                key: key,
                value: value
            },
        };
        this.client.send(JSON.stringify(msg));
        return new Promise(function (res, rej) {
            _this_1.respCallbacks[seqNum] = res;
        });
    };
    WebSocketMediator.prototype.queryState = function (sid, args, callback) {
        var _this_1 = this;
        //state is in memory
        if (this._getState(sid.id)) {
            var state_2 = this._getState(sid.id);
            if (callback) {
                callback(state_2);
            }
            else {
                return new Promise(function (resolve, reject) {
                    if (state_2) {
                        resolve(state_2);
                    }
                    else {
                        reject({});
                    }
                });
            }
        }
        else {
            //async load state from server
            var seqNum_1 = this.seqNum++;
            var seasonId = this.seasonId;
            var msg = {
                SeqNum: seqNum_1,
                Type: protocol_1.MessageType.StateQuery,
                TransID: sid.id,
                Data: {
                    seasonId: seasonId
                }
            };
            console.log('send msg is ', JSON.stringify(msg));
            this.client.send(JSON.stringify(msg));
            if (callback) {
                this.respCallbacks[seqNum_1] = callback;
            }
            else {
                return new Promise(function (res, rej) {
                    _this_1.respCallbacks[seqNum_1] = res;
                });
            }
        }
    };
    WebSocketMediator.prototype.sendTransaction = function (tid, args, callback) {
        var seqNum = this.seqNum++;
        args.seasonId = this.seasonId;
        var ctx = {
            SeqNum: seqNum,
            Type: protocol_1.MessageType.Transition,
            TransID: tid.toString()
        };
        var msg = __assign(__assign({}, ctx), { Data: __assign(__assign({}, args), { ts: new Date().getTime() }) });
        console.log('send msg is ', JSON.stringify(msg));
        this.client.send(JSON.stringify(msg));
        this.respContext[seqNum] = ctx;
        this.respCallbacks[seqNum] = callback;
        return ctx;
    };
    WebSocketMediator.prototype.setWsCloseCallback = function (callback) {
        this.closeCallback = callback;
    };
    WebSocketMediator.prototype.setChainBlockCallback = function (callback) {
        this.chainBlockCallback = callback;
    };
    WebSocketMediator.prototype._updateState = function (sid, obj, notify) {
        console.log('update state', sid, obj, notify);
        if (this.stateCaches[sid]) {
            this.notifyStateChange = notify;
            this.stateCaches[sid].update(obj);
            this.notifyStateChange = true;
        }
        else {
            this.stateCaches[sid] = new state_1.State(obj, this);
        }
    };
    WebSocketMediator.prototype._getState = function (sid) {
        return this.stateCaches[sid];
    };
    return WebSocketMediator;
}(mediator_1.BaseMediator));
exports.WebSocketMediator = WebSocketMediator;
