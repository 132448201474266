"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyComponent = void 0;
var _1 = require(".");
var strategy_1 = require("../Logic/strategy");
var Const_1 = require("../Const");
var StrategyComponent = /** @class */ (function () {
    function StrategyComponent(mediator) {
        this.type = _1.ComponentType.Strategy;
        this.mediator = mediator;
    }
    StrategyComponent.prototype.setStrategy = function (strategy) {
        var _this = this;
        this.strategy = strategy;
        this.mediator.onReceiveState({ id: this.strategy.state.id }, function () {
            _this.strategy.updateBoost();
        });
    };
    StrategyComponent.prototype.onStateUpdate = function (callback) {
        this.mediator.onReceiveState({ id: this.strategy.state.id }, callback);
    };
    StrategyComponent.prototype.getStrategyPointInfo = function () {
        var re = {};
        re['strategyPoint'] = this.strategy.getStrategyPonit();
        re['maxStrategyPoint'] = Const_1.MaxStrategyPoint;
        re['buyTimes'] = this.strategy.getBuyStrategyTimes();
        re['buyTimesLastUpdate'] = this.strategy.getBuyStrategyTimesLastUpdate();
        re['maxBuyTimes'] = this.strategy.strategyBuyConfig.getMaxTimes();
        return re;
    };
    StrategyComponent.prototype.getStrategiesInfo = function () {
        var re = {};
        re['buyTroopCount'] = this.strategy.getOpenDayCount() * 210;
        re['buySilverCount'] = this.strategy.getOpenDayCount() * 30000;
        re['buyMoraleCount'] = 3;
        re['protect1'] = {
            able: this.strategy.getStrategyStatus(strategy_1.StrategyType.Protect1).able,
            remainTime: this.strategy.getStrategyStatusRemainTime(strategy_1.StrategyType.Protect1),
            maxTime: this.strategy.parameter.order_protect_1hour_times
        };
        re['protect'] = {
            able: this.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able,
            remainTime: this.strategy.getStrategyStatusRemainTime(strategy_1.StrategyType.Protect),
            maxTime: this.strategy.parameter.order_protect_times
        };
        re['store'] = {
            able: this.strategy.getStrategyStatus(strategy_1.StrategyType.Store).able,
            remainTime: this.strategy.getStrategyStatusRemainTime(strategy_1.StrategyType.Store),
            maxTime: this.strategy.parameter.order_hoard_times
        };
        return re;
    };
    StrategyComponent.prototype.getBuyStrategyPointNeed = function (amount) {
        return this.strategy.getBuyStrategyNeed(amount);
    };
    StrategyComponent.prototype.getRecoverStrategyRemainTime = function () {
        return this.strategy.getRecoverRemainTime();
    };
    StrategyComponent.prototype.getStrategyNeed = function () {
        return {
            buySilver: 1,
            buyTroop: 1,
            buyMorale: 1,
            protect1: this.strategy.parameter.order_protect_1hour_need,
            protect: this.strategy.parameter.order_protect_need,
            store: this.strategy.parameter.order_hoard_need
        };
    };
    StrategyComponent.prototype.buyStrategyPoint = function (amount, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.BuyStrategyPoint, {
            from: _1.Throne.instance().username,
            amount: amount
        }, callback);
    };
    StrategyComponent.prototype.buySilver = function (times, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuySilver, {
            from: _1.Throne.instance().username,
            times: times
        }, callback);
    };
    StrategyComponent.prototype.buyTroop = function (times, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuyTroop, {
            from: _1.Throne.instance().username,
            times: times
        }, callback);
    };
    StrategyComponent.prototype.buyMorale = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuyMorale, {
            from: _1.Throne.instance().username,
        }, callback);
    };
    StrategyComponent.prototype.buyProtect = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuyProtect, {
            from: _1.Throne.instance().username,
        }, callback);
    };
    StrategyComponent.prototype.buyProtect1 = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuyProtect1, {
            from: _1.Throne.instance().username,
        }, callback);
    };
    StrategyComponent.prototype.buyStore = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.StrategyBuyStore, {
            from: _1.Throne.instance().username,
        }, callback);
    };
    return StrategyComponent;
}());
exports.StrategyComponent = StrategyComponent;
