"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Strategy = exports.StrategyType = void 0;
var Const_1 = require("../Const");
var DataConfig_1 = require("../DataConfig");
var Utils_1 = require("../Utils");
var StrategyType;
(function (StrategyType) {
    StrategyType["Store"] = "store";
    StrategyType["Protect"] = "protect";
    StrategyType["Protect1"] = "protect1";
})(StrategyType = exports.StrategyType || (exports.StrategyType = {}));
var Strategy = /** @class */ (function () {
    function Strategy(state) {
        this.state = state;
        this.parameter = DataConfig_1.parameterConfig;
        this.strategyBuyConfig = DataConfig_1.StrategyBuyConfigFromGDS;
    }
    Strategy.prototype.setBoost = function (boost) {
        this.boost = boost;
    };
    Strategy.prototype.setLogic = function (city, map, general) {
        this.city = city;
        this.map = map;
        this.general = general;
    };
    Strategy.prototype.getStrategyPonit = function () {
        var time = (0, Utils_1.getTimeStamp)();
        var recover = Math.floor((time - this.state.strategyPoint.lastUpdate) / this.parameter.order_recovery_need_times);
        if (this.state.strategyPoint.value + recover > Const_1.MaxStrategyPoint) {
            return Const_1.MaxStrategyPoint;
        }
        else {
            var point = recover + this.state.strategyPoint.value;
            return (0, Utils_1.isNumber)(point) ? point : 0;
        }
    };
    Strategy.prototype.getRecoverRemainTime = function () {
        var time = (0, Utils_1.getTimeStamp)();
        var strategyPoint = this.getStrategyPonit();
        if (strategyPoint == Const_1.MaxStrategyPoint) {
            return 0;
        }
        else {
            var recoverTime = time - this.state.strategyPoint.lastUpdate;
            var recoverCost = Math.floor((time - this.state.strategyPoint.lastUpdate) / this.parameter.order_recovery_need_times) * this.parameter.order_recovery_need_times;
            return this.parameter.order_recovery_need_times - (recoverTime - recoverCost);
        }
    };
    Strategy.prototype.offsetStrategyPoint = function (amount, overload) {
        if (overload === void 0) { overload = false; }
        if (!(0, Utils_1.isNumber)(amount)) {
            return false;
        }
        var strategyPoint = this.getStrategyPonit();
        var time = (0, Utils_1.getTimeStamp)();
        if (strategyPoint + amount >= Const_1.MaxStrategyPoint) {
            var value = Const_1.MaxStrategyPoint;
            if (overload) {
                value = strategyPoint + amount;
            }
            this.state.update({
                strategyPoint: {
                    lastUpdate: time,
                    value: value,
                }
            });
            return true;
        }
        else if (strategyPoint == Const_1.MaxStrategyPoint) {
            this.state.update({
                strategyPoint: {
                    lastUpdate: time,
                    value: strategyPoint + amount,
                }
            });
            return true;
        }
        else if (strategyPoint + amount < 0) {
            return false;
        }
        else {
            var updateTime = time - (this.parameter.order_recovery_need_times - this.getRecoverRemainTime());
            this.state.update({
                strategyPoint: {
                    lastUpdate: updateTime,
                    value: strategyPoint + amount,
                }
            });
            return true;
        }
    };
    Strategy.prototype.getBuyStrategyTimes = function () {
        var aDaySeconds = 60 * 60 * 24;
        var time = (0, Utils_1.getTimeStamp)();
        var nowDay = Math.floor(time / aDaySeconds);
        var lastDay = Math.floor(this.state.buyTimes.lastUpdate / aDaySeconds);
        if (nowDay != lastDay) {
            return 0;
        }
        else {
            return (0, Utils_1.isNumber)(this.state.buyTimes.value) ? this.state.buyTimes.value : 0;
        }
    };
    Strategy.prototype.getBuyStrategyTimesLastUpdate = function () {
        return this.state.buyTimes.lastUpdate;
    };
    Strategy.prototype.getBuyStrategyNeed = function (amount) {
        var times = this.getBuyStrategyTimes();
        var re = 0;
        if (times + amount > this.strategyBuyConfig.getMaxTimes()) {
            return re;
        }
        else {
            for (var i = times; i < times + amount; i++) {
                re += this.strategyBuyConfig.config[i];
            }
            return re;
        }
    };
    Strategy.prototype.buyStrategyPoint = function (amount) {
        if (!(0, Utils_1.isNumber)(amount)) {
            return {
                result: false,
                txType: Const_1.StateTransition.BuyStrategyPoint,
                error: "amount-illegal"
            };
        }
        if (amount <= 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.BuyStrategyPoint,
                error: "amount-illegal"
            };
        }
        var times = this.getBuyStrategyTimes();
        var time = (0, Utils_1.getTimeStamp)();
        if (times + amount > this.strategyBuyConfig.getMaxTimes()) {
            return {
                result: false,
                txType: Const_1.StateTransition.BuyStrategyPoint,
                error: "have-reach-to-max-buy-times"
            };
        }
        else {
            var goldNeed = this.getBuyStrategyNeed(amount);
            if (!this.city.useGold(goldNeed)) {
                return {
                    result: false,
                    txType: Const_1.StateTransition.BuyStrategyPoint,
                    error: "gold-is-not-enough"
                };
            }
            this.offsetStrategyPoint(amount);
            this.state.update({
                buyTimes: {
                    lastUpdate: time,
                    value: times + amount,
                }
            });
            return {
                result: true,
                txType: Const_1.StateTransition.BuyStrategyPoint
            };
        }
    };
    Strategy.prototype.getOpenDayCount = function () {
        var time = (0, Utils_1.getTimeStamp)();
        var openTime = this.map.seasonState.season_open;
        var dayCount = Math.ceil((time - openTime) / (60 * 60 * 24));
        return dayCount >= 0 ? dayCount : 1;
    };
    Strategy.prototype.buyTroop = function (strategyUse) {
        strategyUse = strategyUse || 1;
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuyTroop,
                error: "strategy-point-error"
            };
        }
        var count = this.getOpenDayCount() * 210 * strategyUse;
        this.city.useTroop(-count);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuyTroop
        };
    };
    Strategy.prototype.buySilver = function (strategyUse) {
        strategyUse = strategyUse || 1;
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuySilver,
                error: "strategy-point-error"
            };
        }
        var count = this.getOpenDayCount() * 30000 * strategyUse;
        this.city.useSilver(-count);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuySilver
        };
    };
    Strategy.prototype.buyProtect = function () {
        var strategyUse = this.parameter.order_protect_need;
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuyProtect,
                error: "strategy-point-error"
            };
        }
        this.setStrategyStatus(StrategyType.Protect, true);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuyProtect
        };
    };
    Strategy.prototype.buyProtect1 = function () {
        var strategyUse = this.parameter.order_protect_1hour_need;
        console.log('buyProtect1.1', strategyUse, StrategyType.Protect1);
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuyProtect1,
                error: "strategy-point-error"
            };
        }
        console.log('buyProtect1', strategyUse, StrategyType.Protect1);
        this.setStrategyStatus(StrategyType.Protect1, true);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuyProtect1
        };
    };
    Strategy.prototype.buyStore = function () {
        var strategyUse = this.parameter.order_hoard_need;
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuyStore,
                error: "strategy-point-error"
            };
        }
        this.setStrategyStatus(StrategyType.Store, true);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuyStore
        };
    };
    Strategy.prototype.buyMorale = function () {
        var strategyUse = 1;
        if (!this.offsetStrategyPoint(-strategyUse)) {
            return {
                result: false,
                txType: Const_1.StateTransition.StrategyBuyMorale,
                error: "strategy-point-error"
            };
        }
        this.general.offsetMorale(3);
        return {
            result: true,
            txType: Const_1.StateTransition.StrategyBuyMorale
        };
    };
    Strategy.prototype.getStrategyStatus = function (type) {
        var time = (0, Utils_1.getTimeStamp)();
        var info;
        var lastTime;
        if (type == StrategyType.Protect) {
            info = this.state.protect;
            lastTime = this.parameter.order_protect_times;
        }
        if (type == StrategyType.Protect1) {
            info = this.state.protect1 || { able: false, beginTime: 0 };
            lastTime = this.parameter.order_protect_1hour_times;
        }
        if (type == StrategyType.Store) {
            info = this.state.store;
            lastTime = this.parameter.order_hoard_times;
        }
        var re = {
            able: false,
            beginTime: 0
        };
        if (!info.able) {
            return re;
        }
        else {
            if (time - info.beginTime > lastTime) {
                return re;
            }
            else {
                re.able = true;
                re.beginTime = info.beginTime;
                return re;
            }
        }
    };
    Strategy.prototype.setStrategyStatus = function (type, able) {
        var time = (0, Utils_1.getTimeStamp)();
        var item = {
            able: able,
            beginTime: 0
        };
        if (able) {
            item.beginTime = time;
        }
        if (type == StrategyType.Protect) {
            this.state.update({
                protect: item
            });
        }
        if (type == StrategyType.Protect1) {
            this.state.update({
                protect1: item
            });
        }
        if (type == StrategyType.Store) {
            this.state.update({
                store: item
            });
        }
    };
    Strategy.prototype.getStrategyStatusRemainTime = function (type) {
        var time = (0, Utils_1.getTimeStamp)();
        var info = this.getStrategyStatus(type);
        var lastTime;
        if (type == StrategyType.Protect) {
            lastTime = this.parameter.order_protect_times;
        }
        else if (type == StrategyType.Protect1) {
            lastTime = this.parameter.order_protect_1hour_times;
        }
        else {
            lastTime = this.parameter.order_hoard_times;
        }
        var remainTime = 0;
        if (info.able) {
            remainTime = lastTime - (time - info.beginTime);
        }
        if (remainTime < 0) {
            throw "strategy status calculate error";
        }
        return remainTime;
    };
    Strategy.prototype.updateBoost = function () {
        this.boost.setStrategyStatus(StrategyType.Protect, this.getStrategyStatus(StrategyType.Protect).able);
        this.boost.setStrategyStatus(StrategyType.Protect1, this.getStrategyStatus(StrategyType.Protect1).able);
        this.boost.setStrategyStatus(StrategyType.Store, this.getStrategyStatus(StrategyType.Store).able);
    };
    return Strategy;
}());
exports.Strategy = Strategy;
