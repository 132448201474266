"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapComponent = exports.SeasonStatus = void 0;
var _1 = require(".");
var Const_1 = require("../Const");
var state_1 = require("../../Core/state");
var State_1 = require("../State");
var protocol_1 = require("../Controler/Websocket/protocol");
var SeasonStatus;
(function (SeasonStatus) {
    SeasonStatus["Reservation"] = "reservation";
    SeasonStatus["Ready"] = "ready";
    SeasonStatus["Open"] = "open";
    SeasonStatus["End"] = "end";
})(SeasonStatus = exports.SeasonStatus || (exports.SeasonStatus = {}));
var MapComponent = /** @class */ (function () {
    function MapComponent(mediator) {
        this.type = _1.ComponentType.General;
        this.mediator = mediator;
    }
    MapComponent.prototype.setMap = function (map) {
        var _this = this;
        this.map = map;
        this.mediator.onReceiveState({ id: Const_1.StateName.MapGlobalInfo }, function () {
            _this.map.boost.setMapBuff(_this.map.getBuffList(_1.Throne.instance().unionId));
        });
    };
    MapComponent.prototype.onStateUpdate = function (callback) {
        this.mediator.onReceiveState({ id: Const_1.StateName.MapGlobalInfo }, callback);
    };
    MapComponent.prototype.getTokenPriceInfo = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var tokenPriceInfo, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.TokenPriceInfo) }, {}, null)];
                    case 1:
                        tokenPriceInfo = (_a.sent());
                        console.log('updateTokenPriceInfo in map:', tokenPriceInfo);
                        result = this.createTokenPriceFormat(tokenPriceInfo);
                        callback(result);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.createTokenPriceFormat = function (tokenPriceInfo) {
        var unions = {
            1: "BTC",
            2: "ETH",
            3: "USDT",
            4: "BNB"
        };
        var current = tokenPriceInfo.current, initial = tokenPriceInfo.initial;
        var result = [];
        for (var id = 1; id < 5; id++) {
            var name_1 = unions[id];
            var v1 = initial[name_1] / 1 || current[name_1] / 1;
            var v2 = current[name_1] / 1;
            var changeValue = Math.min((v2 - v1) / v1, 5);
            result.push({ id: id, name: name_1, changeValue: changeValue, v1: v1, v2: v2 });
        }
        return result;
    };
    MapComponent.prototype.genBlockIds = function (x_id, y_id) {
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var re = [];
        var seasonState = this.map.getSeasonState();
        var mapId = seasonState.mapId;
        // let mapId = Throne.instance().mapId;
        var centerid = "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(x_id, "^").concat(y_id);
        console.log('queryBlockStates genBlockIds', { centerid: centerid, mapId: mapId, validBlockIds: State_1.validBlockIds, seasonState: seasonState });
        if (State_1.validBlockIds.length == 0) {
            (0, State_1.GetInitState)('map.genBlockIds');
        }
        if (State_1.validBlockIds.indexOf(centerid) != -1) {
            re.push(centerid);
        }
        for (var i = 0; i < 6; i++) {
            var tempX = x_id + xOffset[i];
            var tempY = y_id + yOffset[i];
            var id = tempX + "^" + tempY;
            var stateId = "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(id);
            if (State_1.validBlockIds.indexOf(stateId) != -1) {
                re.push(stateId);
            }
        }
        var capitals = this.map.getCapitalsBlocks();
        for (var blockId in capitals) {
            var stateId = "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(blockId);
            if (State_1.validBlockIds.indexOf(stateId) != -1) {
                re.push(stateId);
            }
        }
        console.log('genBlockIds genBlockIds', re);
        return re;
    };
    MapComponent.prototype.queryBlockStates = function (x_id, y_id) {
        return __awaiter(this, void 0, void 0, function () {
            var idLists, blockStats;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        idLists = this.genBlockIds(x_id, y_id);
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.BlockInfo, { 'id': { "$in": idLists } })];
                    case 1:
                        blockStats = (_a.sent()) || [];
                        // console.log('queryBlockStates 2', blockStats);
                        this.map.loadBlockStates(blockStats);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.getBlocksBelongInfo = function () {
        return this.map.getBlocksBelongInfo();
    };
    MapComponent.prototype.getDefenseList = function (xId, yId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re, re1, re2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.queryBlockStates(xId, yId)];
                    case 1:
                        _a.sent();
                        re = this.map.getDefenseList(xId, yId, true);
                        re1 = this.map.getDefenseList(xId, yId, false);
                        re2 = re.concat(re1);
                        callback(re2);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.attackBlock = function (xId, yId, generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.AttackBlock, {
            from: _1.Throne.instance().username,
            x_id: xId,
            y_id: yId,
            generalId: generalId
        }, callback);
    };
    MapComponent.prototype.defenseBlock = function (xId, yId, generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.DefenseBlock, {
            from: _1.Throne.instance().username,
            x_id: xId,
            y_id: yId,
            generalId: generalId
        }, callback);
    };
    MapComponent.prototype.cancelDefenseBlock = function (xId, yId, generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.CancelDefenseBlock, {
            from: _1.Throne.instance().username,
            x_id: xId,
            y_id: yId,
            generalId: generalId
        }, callback);
    };
    MapComponent.prototype.miningBlock = function (xId, yId, generalId, times, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.MiningBlock, {
            from: _1.Throne.instance().username,
            x_id: xId,
            y_id: yId,
            generalId: generalId,
            times: times
        }, callback);
    };
    MapComponent.prototype.getBlockInfo = function (xId, yId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var row, xOffset, yOffset, defenseListLength, i, tempX, tempY, blockState;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.queryBlockStates(xId, yId)];
                    case 1:
                        _a.sent();
                        row = (0, state_1.copyObj)(this.map.mapConfig.get(xId, yId));
                        row['now_durability'] = this.map.getDurability(xId, yId);
                        xOffset = [0, 1, 1, 0, -1, -1];
                        yOffset = [2, 1, -1, -2, -1, 1];
                        defenseListLength = 0;
                        defenseListLength += this.map.getDefenseList(xId, yId, true).length;
                        defenseListLength += this.map.getDefenseList(xId, yId, false).length;
                        for (i = 0; i < 6; i++) {
                            tempX = xId + xOffset[i];
                            tempY = yId + yOffset[i];
                            defenseListLength += this.map.getDefenseList(tempX, tempY, false).length;
                        }
                        row['defense_list_len'] = defenseListLength;
                        row['protect_time'] = this.map.getProtectRemainTime(xId, yId);
                        blockState = this.map.getBlockState(xId, yId);
                        row['belong'] = (0, state_1.copyObj)(blockState.belong);
                        row['remainSilver'] = blockState.remainSilver;
                        callback(row);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.getInitBlockInfo = function (xId, yId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var row, blockState;
            return __generator(this, function (_a) {
                row = (0, state_1.copyObj)(this.map.mapConfig.get(xId, yId));
                blockState = (0, state_1.copyObj)(this.map.getBlockInitState(xId, yId));
                row['now_durability'] = blockState.durability;
                row['defense_list_len'] = blockState.defaultDefense.length;
                row['protect_time'] = 0;
                row['belong'] = (0, state_1.copyObj)(blockState.belong);
                row['remainSilver'] = blockState.remainSilver;
                callback(row);
                return [2 /*return*/];
            });
        });
    };
    MapComponent.prototype.getSeasonRankResult = function (callback) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var defenseList, re, rankReward, rewardIndex, reward, i, temp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { '$orderBy': '-glory' })];
                    case 1:
                        defenseList = (_a = (_b.sent())) !== null && _a !== void 0 ? _a : [];
                        re = [];
                        rankReward = this.map.genRankResultList();
                        rewardIndex = 0;
                        reward = rankReward[rewardIndex];
                        for (i in defenseList) {
                            if (parseInt(i) + 1 > reward.end) {
                                rewardIndex++;
                                if (rewardIndex >= rankReward.length) {
                                    break;
                                }
                                reward = rankReward[rewardIndex];
                            }
                            temp = {
                                username: defenseList[i]['username'],
                                unionId: defenseList[i]['unionId'],
                                glory: defenseList[i]['glory'],
                                reward: {
                                    type: reward.type,
                                    name: reward.name,
                                    count: reward.count
                                }
                            };
                            re.push(temp);
                        }
                        callback(re);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.getSeasonStatus = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re;
            return __generator(this, function (_a) {
                re = this.map.getSeasonStatus();
                callback(re);
                return [2 /*return*/];
            });
        });
    };
    MapComponent.prototype.getSeasonConfig = function () {
        var seasonState = this.map.getSeasonState();
        return seasonState;
    };
    MapComponent.prototype.getUnionWinInfo = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.queryBlockStates(0, 0)];
                    case 1:
                        _a.sent();
                        re = this.map.checkUnionWin();
                        delete re.unionWin;
                        callback(re);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.getEndSeasonParameters = function (winUnion) {
        return __awaiter(this, void 0, void 0, function () {
            var defenseList, unionList, addressList, gloryList, unionSumGlory, _i, defenseList_1, item, _a, unionList_1, item, re;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { '$orderBy': '-glory', "$limit": 20 })];
                    case 1:
                        defenseList = (_b.sent());
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": winUnion, '$orderBy': '-glory' })];
                    case 2:
                        unionList = (_b.sent());
                        addressList = [];
                        gloryList = [];
                        unionSumGlory = 0;
                        for (_i = 0, defenseList_1 = defenseList; _i < defenseList_1.length; _i++) {
                            item = defenseList_1[_i];
                            addressList.push(item.username);
                            gloryList.push(item.glory);
                        }
                        for (_a = 0, unionList_1 = unionList; _a < unionList_1.length; _a++) {
                            item = unionList_1[_a];
                            unionSumGlory += item.glory;
                            if (addressList.indexOf(item.username) == -1) {
                                addressList.push(item.username);
                                gloryList.push(item.glory);
                            }
                        }
                        re = {
                            addressList: addressList,
                            gloryList: gloryList,
                            unionSumGlory: unionSumGlory
                        };
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                resolve(re);
                            })];
                }
            });
        });
    };
    MapComponent.prototype.getExpectUnionReward = function (chainName, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var unionId, unionSum, seasonState, rewardSum, re, unionList, rank, i, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        unionId = _1.Throne.instance().unionId;
                        unionSum = this.map.rewardGlobalState.unionGlorySumRuntime[unionId - 1];
                        seasonState = this.map.getSeasonState();
                        rewardSum = seasonState.rankRewardValue;
                        re = {
                            topInfo: [],
                            myInfo: {}
                        };
                        if (!(unionSum == 0)) return [3 /*break*/, 1];
                        callback(re);
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": unionId, '$orderBy': '-glory', "$limit": 20 })];
                    case 2:
                        unionList = (_a.sent());
                        rank = -1;
                        return [4 /*yield*/, this.mediator.defaultQuery(protocol_1.MessageType.QueryCount, Const_1.StateName.DefenderInfo, { "unionId": unionId, "glory": { "$gt": this.map.general.state.glory } })];
                    case 3:
                        rank = _a.sent();
                        for (i in unionList) {
                            item = {
                                username: unionList[i].username,
                                unionId: unionId,
                                glory: unionList[i].glory,
                                rank: parseInt(i) + 1,
                                reward: rewardSum * unionList[i].glory / unionSum
                            };
                            re.topInfo.push(item);
                        }
                        console.log('conquest rank: ', { unionSum: unionSum, rewardSum: rewardSum }, unionList);
                        re.myInfo = {
                            username: _1.Throne.instance().username,
                            unionId: unionId,
                            glory: this.map.general.state.glory,
                            rank: rank + 1,
                            reward: this.map.general.state.glory / unionSum * rewardSum
                        };
                        callback(re);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.getUnionOverView = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re, _a, _b, _c, _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        re = [[], [], [], []];
                        _a = re;
                        _b = 0;
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": 1, '$orderBy': '-glory' })];
                    case 1:
                        _a[_b] = (_j.sent());
                        _c = re;
                        _d = 1;
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": 2, '$orderBy': '-glory' })];
                    case 2:
                        _c[_d] = (_j.sent());
                        _e = re;
                        _f = 2;
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": 3, '$orderBy': '-glory' })];
                    case 3:
                        _e[_f] = (_j.sent());
                        _g = re;
                        _h = 3;
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { "unionId": 4, '$orderBy': '-glory' })];
                    case 4:
                        _g[_h] = (_j.sent());
                        callback(re);
                        return [2 /*return*/];
                }
            });
        });
    };
    return MapComponent;
}());
exports.MapComponent = MapComponent;
