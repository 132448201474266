"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.run = exports.GameName = exports.getVipSilverBuff = exports.staminaTimes = exports.rewardConfig = exports.randomCampReward = void 0;
var Const_1 = require("./Game/Const");
// import mapGDS = require('./gds/map_config_0.json')
var vipGDS = require("./gds/vip.json");
var Throne_1 = require("./Game/Throne");
var Utils_1 = require("./Game/Utils");
var parameterGDS = require("./gds/parameter.json");
exports.randomCampReward = parameterGDS.choose_random_camp_reward;
exports.rewardConfig = {
    randomCamp: parameterGDS.choose_random_camp_reward || {},
    upgrade_fortress: parameterGDS.upgrade_fortress_share_activity_reward || {},
    attack: parameterGDS.attack_territory_share_activity_reward || {},
    stamina_share: parameterGDS.stamina_share_activity_reward || {}
};
exports.staminaTimes = {
    attackPlayer: parameterGDS.attack_player_need_stamina.value,
    attackPlots: parameterGDS.attack_plots_need_stamina.value,
    defensePlots: parameterGDS.defense_plots_need_stamina.value,
    gather: parameterGDS.gather_need_stamina.value,
    spy: parameterGDS.spy_need_stamina.value,
    assembly: parameterGDS.assembly_need_stamina.value
};
function getVipSilverBuff(userScore) {
    var scores = vipGDS['Config'];
    var minScore = scores[0].score;
    var maxScore = scores[scores.length - 1].score;
    if (userScore >= maxScore) {
        return scores[scores.length - 1];
    }
    var buffs = {};
    for (var i = 0; i < scores.length - 1; i++) {
        if (userScore >= scores[i].score && userScore < scores[i + 1].score) {
            buffs = scores[i];
        }
    }
    return buffs;
}
exports.getVipSilverBuff = getVipSilverBuff;
exports.GameName = 'league of thrones';
__exportStar(require("./Game/Controler/mediator"), exports);
__exportStar(require("./Game/Controler/transition"), exports);
__exportStar(require("./Game/State"), exports);
__exportStar(require("./Core/state"), exports);
__exportStar(require("./Game/Throne"), exports);
__exportStar(require("./Game/Const"), exports);
var run = function () {
    /*
    const mediator = new LocalMediator();
  
    const myCityStateId = `${StateName.City}:${TestWallet}`;
  
    //async accuire state
    const defaultState = {
      id: myCityStateId,
      facilities: {},
      resources: {}
    };
    const city: City = new City(
      new State<ICityState>(defaultState).unsderlying(),CityConfigFromGDS
    );
    let cityInitd: boolean = false;
    mediator.onReceiveState({ id: myCityStateId }, (state: IState) => {
      //first init
      if (!cityInitd) {
        cityInitd = true;
        console.log('city initd');
        city.loadState(state.stateObj());
        city.showAll();
        //city update
      } else {
        console.log('city updated');
        city.loadState(state.stateObj());
        city.showAll();
      }
    });
    //trigger aysnc query
    mediator.queryState({ id: myCityStateId });
  
    //trigger upgrade
    mediator.sendTransaction(StateTransition.UpgradeFacility, {
      from: TestWallet,
      typ: CityFacility.Fortress,
      index: 0,
      targetLevel: 1
    });
    mediator.sendTransaction(StateTransition.UpgradeFacility, {
      from: TestWallet,
      typ: CityFacility.Fortress,
      index: 0,
      targetLevel: 2
    });
    */
    // mediator.sendTransaction(StateTransition.UpgradeFacility, { from: TestWallet, typ: CityFacility.Logistics, index: 0, targetLevel: 1 })
};
exports.run = run;
//run();
var count = 0;
function example() {
    global.ctx = undefined;
    console.log("hello world");
    Throne_1.Throne.instance().init({
        username: "test",
        unionId: 2
    }, function (result) {
        console.log(Throne_1.Throne.instance().mediator.getTransaction().checkUnionWin());
        console.log(Throne_1.Throne.instance().mediator.getTransaction().getSeasonStatus());
        console.log("set union", result);
        Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.StartSeason, {
            from: Throne_1.Throne.instance().username,
            applies: {
            // "1" :{
            //   "test1" : [ 12, 13 ]
            // }
            },
            season: {
                apply_ts: 0,
                prepare_ts: 0,
                start_ts: 0,
                end_ts: 0,
                reward_amount_1: 5000,
                reward_amount_2: 5000,
                rank_config_fromto: [1, 1, 2, 3],
                rank_config_value: [1100, 800],
            }
        }, function () { });
        Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.SetUnionId, {
            from: 'test1',
            unionId: 0,
            force: true
        }, function () { });
        Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.RegularTask, {}, function () { });
        Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.AbleGeneral, {
            from: 'test1',
            id: 1
        }, function () { });
        Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.DefenseBlock, {
            from: 'test1',
            x_id: 9,
            y_id: 9,
            generalId: 1
        }, function () { });
    });
    setTimeout(function () {
        Throne_1.Throne.instance().initComponent(Throne_1.ComponentType.Chain, function (chainCom) {
            chainCom.onReceiveChainBlockInfo(function (msg) {
                console.log("mmmmmsg", msg);
            });
        });
    }, 500);
    setTimeout(function () {
        Throne_1.Throne.instance().initComponent(Throne_1.ComponentType.City, function (city) {
            console.log('City init');
            // bind button with action
            // button.onClick = () =>{
            for (var i = 0; i < 12; i++) {
                city.doUpgradeFacility(Const_1.CityFacility.Fortress, 0, function () { });
                city.doUpgradeFacility(Const_1.CityFacility.Home, 0, function () { });
            }
            console.log('test error', city.getUpgradeInfo(Const_1.CityFacility.Store, 15));
            // watch action response
            console.log(city.getFacilityOrder());
            var resource = city.getResource();
            // watch state update
            city.onStateUpdate(function () {
                // regenerate  ui state
                var facilities = city.getFacilityList();
                var resource = city.getResource();
                var uiState = { facilities: facilities, resource: resource };
                console.log("receive state", uiState);
                count += 1;
                //console.log("============", count)
                // rerender by new state
            });
            console.log("cool down", city.getTestResourceCoolDownTime());
            city.addTestResource(function (result) {
                console.log(result);
            });
            console.log("cool down", city.getTestResourceCoolDownTime());
            city.addTestResource(function (result) {
                console.log(result);
            });
            city.updateResource();
            city.doRecruit(5, function (re) {
                console.log(re);
            });
            console.log(city.getRecruitState());
            console.log(JSON.stringify(city.getRechargeConfigs('oasis')));
            Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.Recharge, {
                from: 'test',
                username: 'test',
                rechargeId: 1,
                amount: 1
            }, function () { });
            Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.Recharge, {
                from: 'test',
                username: 'test',
                rechargeId: 1,
                amount: 10001
            }, function () { });
            Throne_1.Throne.instance().mediator.sendTransaction(Const_1.StateTransition.Recharge, {
                from: 'test',
                username: 'test',
                rechargeId: 1,
                amount: 1000
            }, function () { });
            // Throne.instance().mediator.sendTransaction(StateTransition.InitUserStates,
            //   {
            //     from: 'test',
            //     username: 'test',
            //   },
            //   ()=>{})
            //update
            console.log(city.getAbleActivityInfo(1));
            city.donateSilver(1, 100, function (result) {
                console.log(result);
            });
            console.log(city.getGuideStep("test"));
            city.setGuideStep("test", 2, function () {
                console.log(city.getGuideStep("test"));
            });
            city.setGuideStep("test", 5, function () {
                console.log(city.getGuideStep("test"));
            });
        });
    }, 1000);
    setTimeout(function () {
        Throne_1.Throne.instance().initComponent(Throne_1.ComponentType.General, (function (general) {
            general.getRecentWorldBattleRecords(function (re) {
                console.log(re);
            });
            general.onStateUpdate(function (state) {
                console.log("general", state);
            });
            general.ableGeneral(1, function (result) {
                console.log("general able ", result);
            });
            general.ableGeneral(2, function (result) {
                console.log("general able ", result);
            });
            general.ableGeneral(3, function (result) {
                console.log("general able ", result);
            });
            for (var i = 0; i < 100; i++) {
                general.upgradeGeneral(1, 2, function (result) {
                    console.log("general upgrade ", result);
                });
            }
            var num = general.getUpgradeGeneralNeed(2, 1, 2);
            var num1 = general.getSkillUpgradeNeed(2, 1, 1);
            console.log("value", general.getGeneralQuaValue(1, 1));
            console.log("general status ", general.getAbleStatus());
            for (var i = 0; i < 100; i++) {
                general.upgradeGeneralSkill(1, 0, function (result) {
                    console.log("upgrade skill", result);
                });
            }
            console.log("general", general.getGeneralList());
            console.log("const ", general.getConstData());
            general.setDefenseGeneral(1, function (re) {
                console.log(re);
            });
            console.log('defenseGeneral', general.getGeneralBattleInfo(1));
            general.battle(1, 'test1', function (re) {
                console.log(re);
            });
            general.getBattleRecords(function (result) {
                console.log('+++++++++++++++++++++++++', result);
            });
            general.battle(2, 'test1', function (re) {
                console.log("general-----battle", re);
            });
            general.battle(2, 'test1', function (re) {
                console.log("general-----battle", re);
            });
            general.battle(2, 'test1', function (re) {
                console.log("general-----battle", re);
            });
            general.getBattleStatuses('', function (result) {
                console.log('general-----getbbb', result);
            });
            general.getBattleStatuses('test', function (result) {
                console.log('general----======-getbbb', result);
            });
            console.log(general.getIconId());
            general.setIconId(4, function (result) {
                console.log('general----======setIcon', result);
            });
            console.log(general.getIconId());
            console.log(general.getMorale());
            console.log(general.getMoraleBuff());
            console.log(general.getRecoverMoraleInfo());
            general.recoverMorale(Const_1.RecoverMoraleType.Gold, function (re) {
                console.log(re);
            });
        }));
    }, 1500);
    setTimeout(function () {
        Throne_1.Throne.instance().initComponent(Throne_1.ComponentType.Map, function (map) {
            map.onStateUpdate(function () {
                console.log(map.getBlocksBelongInfo());
            });
            map.getBlockInfo(1, 1, function (result) {
                console.log('map----getBlockInfo', result);
            });
            //console.log(map.getBlocksBelongInfo())
            map.attackBlock(9, 9, 1, function (result) {
                console.log('map----attackBlock', result);
                map.getBlockInfo(9, 9, function (result) {
                    console.log(result);
                });
            });
            map.attackBlock(9, 9, 1, function (result) {
                console.log('map----attackBlock', result);
                map.getBlockInfo(9, 9, function (result) {
                    console.log(result);
                });
            });
            map.attackBlock(9, 9, 1, function (result) {
                console.log('map----attackBlock', result);
                map.getBlockInfo(9, 9, function (result) {
                    console.log(result);
                });
            });
            map.attackBlock(9, 9, 1, function () { });
            map.attackBlock(9, 9, 1, function () { });
            map.attackBlock(9, 9, 1, function (result) {
                console.log('map----attackBlock', result);
                map.miningBlock(9, 9, 1, 1, function () { });
                map.getBlockInfo(9, 9, function (result) {
                    console.log(result);
                });
                map.getInitBlockInfo(9, 9, function (result) {
                    console.log(result);
                });
                map.attackBlock(8, 10, 2, function (result) {
                    console.log('map----attackBlock', result);
                    console.log(map.getBlocksBelongInfo());
                });
            });
            console.log(map.getBlocksBelongInfo());
            map.defenseBlock(10, 10, 3, function (result) {
                console.log('map----defenseBlock', result);
            });
            map.defenseBlock(10, 10, 2, function (result) {
                console.log('map----defenseBlock', result);
            });
            map.cancelDefenseBlock(10, 10, 3, function (result) {
                console.log('map----defenseBlock', result);
            });
            map.getDefenseList(10, 10, function (result) {
                console.log('map----getDefenseList', result);
            });
            map.getSeasonStatus(function (result) {
                console.log('map----getSeasonStatus', result);
            });
            map.getSeasonRankResult(function (result) {
                console.log('map----getSeasonRankResult', result);
            });
            map.getUnionWinInfo(function (result) {
                console.log('map----getUnionWinInfo', JSON.stringify(result));
            });
            console.log(map.getSeasonConfig());
        });
    }, 2000);
    setTimeout(function () {
        Throne_1.Throne.instance().initComponent(Throne_1.ComponentType.Strategy, function (strategy) {
            strategy.onStateUpdate(function () {
                console.log(strategy.getStrategyPointInfo());
            });
            strategy.buySilver(1, function (re) { return console.log(re); });
            strategy.buyMorale(function (re) { return console.log(re); });
            strategy.buyTroop(1, function (re) { return console.log(re); });
            console.log(strategy.getStrategyNeed());
            console.log(strategy.getBuyStrategyPointNeed(2));
            console.log(strategy.getRecoverStrategyRemainTime());
            console.log(strategy.getStrategyPointInfo());
            strategy.buyStrategyPoint(2, function () { });
            strategy.buyStore(function (re) { return console.log(re); });
            strategy.buyProtect(function (re) { return console.log(re); });
            strategy.buyProtect1(function (re) { return console.log(re); });
            console.log(strategy.getStrategiesInfo());
        });
    }, 2500);
}
function test() {
    var TTT;
    (function (TTT) {
        TTT["Test1"] = "test1";
        TTT["Test2"] = "test2";
    })(TTT || (TTT = {}));
    for (var key in TTT) {
        console.log(key);
    }
    for (var key in TTT) {
        var type = TTT[key];
        var typeT = type;
        console.log(typeT);
    }
    var t1 = {
        tt: 123,
        tt12: {
            tt: 231,
            t3: 123,
        },
        t3: [1, 3]
    };
    (0, Utils_1.checkNaNInObj)(t1);
}
function testSort() {
    var list = [];
    (0, Utils_1.addToSortList)(list, "aaa", 0, 2, 0);
    (0, Utils_1.addToSortList)(list, "bbb", 0, 3, 0);
    (0, Utils_1.addToSortList)(list, "aaa", 2, 4, 0);
    (0, Utils_1.addToSortList)(list, "ccc", 0, 3, 0);
    (0, Utils_1.addToSortList)(list, "ddd", 0, 1, 0);
    (0, Utils_1.addToSortList)(list, "eee", 0, 2.5, 0);
    (0, Utils_1.addToSortList)(list, "eee", 2.5, 3.5, 0);
    console.log(list);
    var newList = [];
    (0, Utils_1.addToNormalSortedList)(newList, "aaa", 0, 2, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "bbb", 0, 3, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "aaa", 2, 4, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "ccc", 0, 3, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "ddd", 0, 1, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "eee", 0, 2.5, 'value');
    (0, Utils_1.addToNormalSortedList)(newList, "eee", 2.5, 3.5, 'value');
    console.log(newList);
}
//test()
//example()
//testSort()
