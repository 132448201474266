"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigContainer = void 0;
var ConfigContainer = /** @class */ (function () {
    function ConfigContainer(containers) {
        this.configs = {};
        //standlize container
        for (var key in containers) {
            this.configs[key] = containers[key];
        }
    }
    ConfigContainer.prototype.get = function (key) {
        return this.configs[key];
    };
    return ConfigContainer;
}());
exports.ConfigContainer = ConfigContainer;
