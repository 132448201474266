import { idDev, isProd } from '../utils/ui-res';


var browserVersions = (function () {
    var u = window.navigator.userAgent;
    var app = window.navigator.appVersion;
    return {
        trident: u.indexOf('Trident') > -1, //IE
        presto: u.indexOf('Presto') > -1, //opera
        webKit: u.indexOf('AppleWebKit') > -1, // apple, chorome
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,    //firefox
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //is mobile
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android/uc
        iPhone: u.indexOf('iPhone') > -1, //iPhone
        iPad: u.indexOf('iPad') > -1, //iPad
        webApp: u.indexOf('Safari') == -1, //web
        weixin: u.indexOf('MicroMessenger') > -1, 
        qq: u.match(/\sQQ/i) == " qq" //QQ
    };
})();

// console.log(browserVersions, isMobile);

function init(){
    var isInTG = function(){
        let { href } = window.location;
        let isTG = href.indexOf('#tgWebAppData-query') > -1;
        return isTG || !!window.TelegramWebviewProxy;
    }
    var isMobile = isInTG() || (function(){
        if(browserVersions.iPad){
            return false;
        }
        return browserVersions.mobile || browserVersions.android || browserVersions.ios || browserVersions.iPhone;
    })();
    window.LeagueOfThronesConfig.isMobile = isMobile;

    if(!isMobile){
        return;
    }
    const screenW = document.documentElement.clientWidth;
    const screenH = document.documentElement.clientHeight;
    var rootNode = document.getElementsByTagName('body')[0];
        rootNode.classList.add('App-mobile');

    let offset = screenW/2/screenH;
    document.querySelector('.App-mobile #root').style.transformOrigin = (offset * 100) + '%';
    var rootNode2 = document.getElementById('root');
    // var orientation = window.screen.orientation;
    // var isRotate = orientation.type.indexOf('landscape') > -1;
    var isRotate = window.orientation === 0;
    setRotate(isRotate);
    
    window.addEventListener("orientationchange",function(){
        let isRotate = window.orientation === 0; //-90,0,90
        setRotate(isRotate);
        window.location.reload(true);
    });

    function setRotate(isRotate){
        if(isRotate){
            window.LeagueOfThronesConfig.isLandscape = true;
            rootNode.style.width = screenH + 'px';
            rootNode.style.height = screenW + 'px';
            rootNode2.style.width = screenH + 'px';
            rootNode2.style.height = screenW + 'px';
        }else{
            window.LeagueOfThronesConfig.isLandscape = false;
            // rootNode.classList.remove('App-mobile');
            rootNode.style.width = screenW + 'px';
            rootNode.style.height = screenH + 'px';
            rootNode2.style.width = screenW + 'px';
            rootNode2.style.height = screenH + 'px';
        }
    }
}

function isMobile(){
    if(browserVersions.iPad){
        return false;
    }
    return browserVersions.mobile || browserVersions.android || browserVersions.ios || browserVersions.iPhone;
}

export {
    browserVersions,
    init,
    isMobile
};