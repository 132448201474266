/*
steps:
1. new login btn for okx
2. new connect wallet function
3. new getEvmProvider
4. contract.js init both for okx + window.ethereum
*/ 

import { OKXUniversalProvider } from "@okxconnect/universal-provider";

const okxUniversalProvider = await OKXUniversalProvider.init({
    dappMetaData: {
        name: "League of thrones",
        icon: "https://cdn.leagueofthrones.com/favicon.png"
    },
})

const isInTG = () => {
    let { userAgent } = window.navigator;
    let { href } = window.location;
    let isTG = href.indexOf('#tgWebAppData-query') > -1;
    return (isTG || !!window.TelegramWebviewProxy);
}

const isWebTG = () => {
    let url = window.location.href;
    return url.indexOf('#tgWebAppData=user') > -1 && url.indexOf('language_code') > -1;
}

const connect = async(chain) => {
    console.log('okxUniversalProvider', okxUniversalProvider);
    let res = await okxUniversalProvider.connect({
        namespaces: {
            eip155: {
                chains: ["eip155:7000"],
                rpcMap: {
                    "7000": "https://zetachain-evm.blockpi.network/v1/rpc/public" // set your own rpc url
                },
                defaultChain: "7000"
            }
        },
        optionalNamespaces: {
            eip155: {
                chains: ["eip155:7001"]
            }
        },
        sessionConfig: {
            redirect: "tg://resolve"
        }
    });
    /*
    var res = {
        "topic": "589e6e34c8f38efbbaa1eac647c14cfb521e31b8d758240e982263cb15d406b8",
        "sessionConfig": {
            "dapplnfo": {
                "url":"test.leaqueofthrones.com", 
                "name":"League of thrones",
                "icon":"https://cdn.leaqueofthrones.com/favicon.png",
                "openUniversalUrl": true,
                "redirect": "tg://resolve"
            }
        },
        "namespaces": {
            "eip155": {
                "chains":["eip155:7000"],
                "accounts": ["eip155:7000:0x888d11b72d10ca32ef951b56c1fafcaec7665577"],
                "methods": [
                    "wallet addEthereumChain",
                    "wallet switchEthereymChain",
                    "wallet watchAsset",
                    "personalsign",
                    "eth signTypedData_v4",
                    "ethsendTransaction"
                ]
            }
        }
    }
    */
    let { sessionConfig = {}, namespaces = {} } = res || {};
    let { accounts = [] } = namespaces['eip155'] || {};
    let account = accounts[0] || '';
    account = account.toLowerCase();
    account = account.replace('eip155:7000:', '');
    console.log('connectOKX', res);

    return { account };
    //https://t.me/pvplotgame_bot/pvpgame
}

const getProvider = () => {
    return okxUniversalProvider;
}

export {
    isInTG,
    isWebTG,
    connect,
    getProvider
}