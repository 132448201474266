import { combineReducers } from 'redux'

import app from './throne.init'
import map from './slice-map'
import city from './slice-city'
import generals from './slice-general'
import user from './slice-user'
import strategies from './slice-strategies'
import chain from './slice-chain'
import signup from './slice-signup'
import dauction from '../da/slice'

export default combineReducers({
    app,
    map,
    city,
    generals,
    user,
    strategies,
    chain,
    signup,
    dauction
})