import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { Throne, ComponentType } from 'throne-underlying';
import { logTrace } from '../utils/log'
import * as cache from '../utils/cache'

let isInited = false;
let generalInstance = null;
let generalList = [];
let activeStats = [];
let config = {};
const cacheKey = 'recentWorldBattleRecords';
const cacheNewKey = 'recentNewWorldBattleRecords';

export const generalInit = createAsyncThunk("general/init", async () => {
    if(isInited){
        return;
    }
    const response = await new Promise((resolve, reject) => {
        isInited = true;
        Throne.instance().initComponent(ComponentType.General, (general) => {
            generalInstance = general;
            // console.log('generalInstance', generalInstance)
            resolve(generalInstance);
        });
    });
    return response;
});

export const getUserVipBuffs = createAsyncThunk("user/vip/buff", async (data) => {
    if(!generalInstance){ 
        return; 
    }
    const { username } = data;
    const response = await new Promise((resolve, reject) => {
        let res = generalInstance.getUserVipBuffs(username);
        resolve(res);
    });
    return response;
});

export const generalListUpdated = createAsyncThunk("generalList/updated", async () => {
    if(!generalInstance){ 
        return; 
    }
    // console.log("general.list.back 0", generalInstance)
    const response = await new Promise((resolve, reject) => {
        // generalInstance.onStateUpdate(()=>{
            // console.log("general.list.back 1")
            let generalList = generalInstance.getGeneralList();
            resolve(generalList);
            // console.log("general.list.back 2", generalList)
        // })
    });
    return response;
});

export const generalStatusChange = createAsyncThunk("general/statusChange", async (data) => {
    if(!generalInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        //fn = ableGeneral, disableGeneral
        let fn = data.fn;
        generalInstance[fn](data.id, (res) => {
            let { result } = res;
            result.back = true;
            logTrace({res, data}, 'general.StatusChange');
            if(result.result){
                resolve(result);                
            }else{
                reject(result);
            }
        });
    });
    return response;
});

export const generalUpdated = createAsyncThunk("general/updated", async (data) => {
    if(!generalInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        //index
        let { id, levelTo } = data;
        generalInstance.upgradeGeneral(id, levelTo, (res) => {
            // console.log('generalUpdated', id, res);
            let { result } = res;
            result.id = id;
            resolve(result);
        });
    });
    return response;
});

export const generalSkillUpdate = createAsyncThunk("generalSkill/update", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        let { generalId, skillIndex } = data;
        generalInstance.upgradeGeneralSkill(generalId, skillIndex, (args) => {
            let result = args.result.result;
            if(result){
                data.result = result;
                data.r = Math.random();
                resolve(data);                
            }else{
                reject(args);
            }
        });
    });
    return response;
});

export const enemiesSearch = createAsyncThunk("enemies/search", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        //getAllBattleStatuses
        generalInstance.getBattleStatuses(data, function(players){
            logTrace({ getAllBattleStatus: players }, 'general.getBattleStatuses');
            resolve(players); 
        });
    });
    return response;
});

export const spyEnamy = createAsyncThunk("enemies/spy", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        //getAllBattleStatuses
        generalInstance.spyEnamy(data.username, data.generalId, function(result){
            resolve(result); 
        });
    });
    return response;
});

export const getBattleRecords = createAsyncThunk("battle/records", async (data) => {
    if(!generalInstance){ 
        return;
    }                    
    const response = await new Promise((resolve, reject) => {
        generalInstance.getBattleRecords(function(battleRecords){
            logTrace(battleRecords, 'battle.records');
            resolve(battleRecords);
        });
    });
    return response;
});

export const battleLasting = createAsyncThunk("battle/lasting", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        let { generalId, name } = data;
        logTrace(data, 'general.battleLasting');
        generalInstance.battle(generalId, name, function(result){
            logTrace({ battle: result, generalId, name }, 'general.battleLasting');
            if(result.result){
                result.over = true;
                resolve(result); 
            }else{
                reject(result);
            }
        });
    });
    return response;
});

export const getDefenseCityGeneralId = createAsyncThunk("genereal/defenseCity/get", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        let result = generalInstance.getDefenseGeneralId();
        logTrace(result, 'general.defense.CityList');
        resolve(result); 
    });
    return response;
});

export const setDefenseCityGeneral = createAsyncThunk("genereal/defenseCity/set", async (data) => {
    // console.log(generalInstance, 'generalInstance')
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        generalInstance.setDefenseGeneral(data.generalId, function(res){
            logTrace(res, 'general.defense.set');
            resolve(res);  
        });
    });
    return response;
});

export const setUserAvatar = createAsyncThunk("userAvatar/set", async (user) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        generalInstance.setIconId(user.avatar, function(res){
            logTrace(res, 'general.userAvatar.set');
            res.userAvatar = user.avatar;
            resolve(res);  
        });
    });
    return response;
});

export const recoverMorale = createAsyncThunk("morale/recover", async (data) => {
    if(!generalInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        generalInstance.recoverMorale(data.resourceType, function(res){
            logTrace(res, 'general.recoverMorale');
            resolve(res.result);  
        });
    });
    return response;
});

export const getGloryAndRank = createAsyncThunk("glory/rank", async () => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.getGloryAndRank(function(res){
            // console.log(res, 'season.getGloryAndRank');
            resolve(res);  
        });
    });
});

export const getRecentWorldBattleRecords = createAsyncThunk("world/battle/records/recent", async () => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.getRecentWorldBattleRecords(function(res){
            // console.log(res, 'battle.recentWorldBattleRecords');
            let list = cache.get(cacheNewKey) || [];
                list = res.concat(list);
                list = list.splice(0, 20);
            cache.set(cacheNewKey, list);
            resolve(res);  
        });
    });
});

export const getBattleStatuses = createAsyncThunk("world/battle/status", async (data) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.getBattleStatuses(data.username, function(res){
            // console.log(res, 'battle.getBattleStatuses');
            resolve(res);  
        });
    });
});

export const getCodList = createAsyncThunk("cod/list", async (data) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        // generalInstance.getCodList(function(res){
        //     console.log('cod list:', res);
        //     resolve(res);  
        // });
        let res = generalInstance.getCodList();
        resolve(res);
    });
});

export const createCod = createAsyncThunk("cod/create", async (blockInfo) => {
    if(!generalInstance){ 
        return;
    }
    let { x_id, y_id, generalId } = blockInfo;
    return await new Promise((resolve, reject) => {
        generalInstance.createCod({ x_id, y_id }, generalId, function(res){
            resolve(res);  
        });
    });
});

export const cancelCod = createAsyncThunk("cod/cancel", async (data) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.cancelCod(data.codId, function(res){
            resolve(res);  
        });
    });
});

export const joinCod = createAsyncThunk("cod/join", async (data) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.joinCod(data.codId, data.generalId, function(res){
            resolve(res);  
        });
    });
});

export const quitCod = createAsyncThunk("cod/quit", async (data) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.quitCod(data.codId, function(res){
            console.log('codCreatorDetail', res)
            resolve(res);  
        });
    });
});

export const getCodCreatorDetail = createAsyncThunk("cod/creatorInfo", async (codId) => {
    if(!generalInstance){ 
        return;
    }
    return await new Promise((resolve, reject) => {
        generalInstance.getCodCreatorDetail(codId, function(res){
            resolve(res.result);  
        });
    });
});

export const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    generalReady: false,
    all: {},
    list: getGeralListDetail(generalList),
    generalSkillUpdateResult: {},
    config: config,
    activeStats: activeStats,
    players: [],
    spyResult: {},
    battleInfo: { base: {}, sum: {}},
    battleOverInfo: { over: false },
    battleRecords: [],
    defenseBlockGenerals: [],
    defenseCityGeneralId: -1,
    userAvatar: -1,
    userAvatarTime: 0,
    skillInfo: {},
    detail: { currentStrength: { qualification: {} }, nextStrength: {}, skillList: {}, upgradeData: {} },
    upgradeGeneralCost: {},
    morale: {},
    recoverMoraleResult: {},
    generalUpdatedResult: {},
    generalStatusChangeResult: { back: false },
    troopsCanUse: {
        attack: 0,
        defense: 0
    },
    seasonGlory: {},
    newRecords: false,
    recentWorldBattleRecords: cache.get(cacheKey) || [],
    newWorldRecords: [],
    battleStatuses: {},
    userVipBuffs: {},
    codCreatorDetail: {},
    codList: [],
    codsAll: {},
    codGeneralIds: {},
    codResult: { back: false }
});

export const generalSlice = createSlice({
    name: 'generals',
    initialState,
    reducers: {
        getUserAvatar: function(state, action){
            if(!generalInstance){
                return;
            }
            state['userAvatar'] = generalInstance.getIconId();
        },
        getUseTroops: function(state, action){
            if(!generalInstance){
                return;
            }
            state['troopsCanUse'] = {
                attack: generalInstance.getAttackTroop(),
                defense: generalInstance.getDefenseTroop()
            }
        },
        getMorale: function(state, action){
            if(!generalInstance){
                return;
            }
            let morale = {
                value: generalInstance.getMorale(),
                buff: generalInstance.getMoraleBuff(),
                recoverInfo: generalInstance.getRecoverMoraleInfo()
            };
            state['morale'] = morale;
        },
        // update: _adapter.update
        getGeneralBattleInfo: function(state, action){
            if(!generalInstance){
                return;
            }
            let { general_id } = action.payload || {};
            if(!general_id){
                return;
            }
            let battleInfo = generalInstance.getGeneralBattleInfo(general_id);
            state['battleInfo'] = battleInfo;
        },
        getAllGenerals: function(state, action){
            let data = {};
            for(var i= -1;i<25;i++){
                data[i] = generalInstance.getGeneralQualification(i) || {};
            }
            state['all'] = data;
        },
        resetGeneralStatusChangeResult: function(state, action){
            state['generalStatusChangeResult'] = { back: false};
        },
        resetSpyResult: function(state, action){
            state['spyResult'] = {};
        },
        getDefenseBlockGenerals: function(state, action){
            if(!generalInstance){
                return;
            }
            let defenseBlockGenerals = generalInstance.getDefenseBlockGenerals();
            let list = [];
            defenseBlockGenerals.forEach(function(item){
                item.data = generalInstance.getGeneralQualification(item.generalId);
                list.push(item);
            });
            state['defenseBlockGenerals'] = list;
            logTrace(list, 'general.defense');
        },
        getSkillInfo: function(state, action){
            if(!generalInstance){
                return;
            }
            let { skill_id } = action.payload;
            let skillInfo = generalInstance.getSkillInfo(skill_id);
            state.skillInfo = skillInfo || {};
            logTrace({skillInfo, action}, 'general.skillInfo');
        },
        getUpgradeGeneralCost: function(state, action){
            if(!generalInstance){
                return;
            }
            let { id, level, levelTo } = action.payload;
            console.log('getGeneralUpgradeNeed slice:', { id, level, levelTo});
            let { silvers } = generalInstance.getUpgradeGeneralCost(id, level, levelTo);
            state.upgradeGeneralCost[id + '-' + levelTo] = silvers;
        },
        getGeneralQuaValue: function(state, action){
            if(!generalInstance){
                return;
            }
            let { id, level } = action.payload;
            if(!id || !level){
                return;
            }
            let currentStrength = generalInstance.getGeneralQuaValue(id, level);
                currentStrength.level = level;
            let nextStrength = generalInstance.getGeneralQuaValue(id, level + 1);
                nextStrength.level = level + 1;

            let skillList = generalInstance.getGeneralSkillList(id);
            let skillInfos = [];
            skillList.skill_id.forEach(function(skill_id){
                let skillInfo = generalInstance.getSkillInfo(skill_id);
                skillInfos.push(skillInfo);
            });
            skillList.skillInfos = skillInfos;

            let canUpgrade = generalInstance.checkUpgradeGeneral(id);
            // console.log('canUpgrade', canUpgrade);
            let upgradeSilver = generalInstance.getUpgradeGeneralNeed(id, level, level+ 1);
            let upgradeData = { canUpgrade, upgradeSilver};
            state['detail'] = { currentStrength, nextStrength, skillList, upgradeData, id, level };
        },
        checkNewRecords: function(state, action){
            if(!generalInstance){
                return;
            }
            let result = generalInstance.getBattleRecordRedPoint();
            // console.log('newRecords checkNewRecords', result);
            state['newRecords'] = result;
        },
        removeNewRecords: function(state, action){
            if(!generalInstance){
                return;
            }
            let { timestamp } = action.payload;
            generalInstance.updateBattleRedPoint(timestamp);
            state['newRecords'] = false;
            // console.log('newRecords removeNewRecords', timestamp);
        },
        getNewWorldRecords: function(state, action){
            state['newWorldRecords'] = cache.get(cacheNewKey) || [];
        },
        removeNewWorldRecords: function(state, action){
            cache.set(cacheNewKey, []);
        },
        getCodGeneralIds: function(state, action){
            if(!generalInstance){
                return;
            }
            let codGeneralIds = generalInstance.getCodGeneralIds();
            state['codGeneralIds'] = codGeneralIds;
        },
        resetCodResult: function(state, action){
            state.codResult = { back: false };
        }
    },
    extraReducers: builder => {
        builder.addCase(getGloryAndRank.fulfilled, (state, action) => {
            let seasonGlory = action.payload;
            state['seasonGlory'] = seasonGlory;
        });
        builder.addCase(setUserAvatar.fulfilled, (state, action) => {
            let { userAvatar } = action.payload;
            state['userAvatar'] = userAvatar;
            state['userAvatarTime'] = new Date();
        });
        builder.addCase(generalInit.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let generalList = generalInstance.getGeneralList();
            let list = getGeralListDetail(generalList);
            list.forEach(function(general){
                let generalId = general.qualification.general_id;
                let battleInfo = generalInstance.getGeneralBattleInfo(generalId);
                general.battleInfo = battleInfo;
            });
            state.list = list;
            state.config = generalInstance.getConstData();
            state.activeStats = generalInstance.getAbleStatus();

            state.generalReady = true;
        });
        builder.addCase(generalListUpdated.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let generalList = action.payload;
            let activeStats = generalInstance.getAbleStatus();
            let list = getGeralListDetail(generalList);
            list.forEach(function(general){
                let generalId = general.qualification.general_id;
                let battleInfo = generalInstance.getGeneralBattleInfo(generalId);
                general.battleInfo = battleInfo;
            });
            state.list = list;
            state.activeStats = activeStats;
            logTrace({ state, action, generalList }, 'general.list.Updated.fulfilled');
        });
        builder.addCase(generalUpdated.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let generalList = generalInstance.getGeneralList();
            let activeStats = generalInstance.getAbleStatus();
            let list = getGeralListDetail(generalList);
            list.forEach(function(general){
                let generalId = general.qualification.general_id;
                let battleInfo = generalInstance.getGeneralBattleInfo(generalId);
                general.battleInfo = battleInfo;
            });
            state.list = list;
            state.activeStats = activeStats;
            state.generalUpdatedResult = action.payload || {};
            logTrace({ 'generalUpdated.fulfilled': { state, action, generalList }}, 'general');
        });
        builder.addCase(generalStatusChange.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let generalList = generalInstance.getGeneralList();
            let activeStats = generalInstance.getAbleStatus();
            let list = getGeralListDetail(generalList);
            list.forEach(function(general){
                let generalId = general.qualification.general_id;
                let battleInfo = generalInstance.getGeneralBattleInfo(generalId);
                general.battleInfo = battleInfo;
            });
            state.list = list;
            state.activeStats = activeStats;
            state.generalStatusChangeResult = action.payload || {};
            logTrace({action, generalList}, 'general.StatusChange.fulfilled');
        });
        builder.addCase(generalSkillUpdate.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let data = action.payload;
            state.generalSkillUpdateResult = data;
            logTrace(action, 'general.skills.update');
        });
        builder.addCase(enemiesSearch.fulfilled, (state, action) => {
            let players = action.payload || [];
            state.players = players;
            logTrace({ 'enemiesSearch.fulfilled': { state, action }}, 'general');
        });
        builder.addCase(spyEnamy.fulfilled, (state, action) => {
            let res = action.payload || {};
            state.spyResult = res;
            let spyRecords = cache.get('spyRecords2') || [];
            if(res.result){
                let item = res.data[0] || {};
                    item.store = res.result.store;
                spyRecords = spyRecords.concat(item);
                cache.set('spyRecords2', spyRecords);
            }
            logTrace({ 'spyEnamy.fulfilled': { state, action }}, 'general');
        });
        builder.addCase(getBattleRecords.fulfilled, (state, action) => {
            let battleRecords = action.payload || [];
            state.battleRecords = battleRecords;
            logTrace({ 'battleRecords.fulfilled': { state, action }}, 'general');
        });
        builder.addCase(battleLasting.fulfilled, (state, action) => {
            let info = action.payload;
            // console.log(info);
            state.battleOverInfo = info;
            logTrace(action, 'general.battleOverInfo');
        });
        builder.addCase(getDefenseCityGeneralId.fulfilled, (state, action) => {
            // let id = generalInstance.getDefenseGeneralId();
            let info = action.payload;
            state.defenseCityGeneralId = info;
            logTrace(action, 'general.defense.city.id');
        });
        builder.addCase(setDefenseCityGeneral.fulfilled, (state, action) => {
            if(!generalInstance){
                return;
            }
            let id = generalInstance.getDefenseGeneralId();
            state.defenseCityGeneralId = id;
            logTrace(action, 'general.defense.city.id');
        });
        builder.addCase(recoverMorale.fulfilled, (state, action) => {
            state.recoverMoraleResult = action.payload;
            logTrace(action, 'recoverMorale.fulfilled');
        });
        builder.addCase(getRecentWorldBattleRecords.fulfilled, (state, action) => {
            let records = action.payload || [];
            let recordsBefore = cache.get(cacheKey) || [];
                records = records.concat(recordsBefore);
            let maxLen = 20;
            if(records.length > maxLen){
                records = records.splice(0, maxLen);
            }
            cache.set(cacheKey, records);
            logTrace({records, recordsBefore}, 'battle.worldBattleRecords');
            state.recentWorldBattleRecords = records;
        });
        builder.addCase(getBattleStatuses.fulfilled, (state, action) => {
            state.battleStatuses = action.payload || [];
            logTrace(action, 'battle.getBattleStatuses');
        });
        builder.addCase(getUserVipBuffs.fulfilled, (state, action) => {
            state.userVipBuffs = action.payload || {};
            logTrace(action, 'getUserVipBuffs.fulfilled');
        });
        builder.addCase(getCodList.fulfilled, (state, action) => {
            let { codList, cods } = action.payload || {};
            state.codList = codList || [];
            state.codsAll = cods || {};
            logTrace(action, 'getCodList.fulfilled');
        });
        builder.addCase(createCod.fulfilled, (state, action) => {
            let payload = action.payload || {};
            let codResult= payload.result || {};
                codResult.back = true;
                codResult.op = 'create assembly';
            state.codResult = codResult;
            logTrace(action, 'createCod.fulfilled');
        });
        builder.addCase(cancelCod.fulfilled, (state, action) => {
            let payload = action.payload || {};
            let codResult= payload.result || {};
                codResult.back = true;
                codResult.op = 'dismiss assembly';
            state.codResult = codResult;
            logTrace(action, 'cancelCod.fulfilled');
        });
        builder.addCase(joinCod.fulfilled, (state, action) => {
            let payload = action.payload || {};
            let codResult= payload.result || {};
                codResult.back = true;
                codResult.op = 'join assembly';
            state.codResult = codResult;
            logTrace(action, 'joinCod.fulfilled');
        });
        builder.addCase(quitCod.fulfilled, (state, action) => {
            let payload = action.payload || {};
            let codResult= payload.result || {};
                codResult.result = true;
                codResult.back = true;
                codResult.op = 'quit assembly';
            state.codResult = codResult;
            logTrace(action, 'quitCod.fulfilled');
        });
        builder.addCase(getCodCreatorDetail.fulfilled, (state, action) => {
            let payload = action.payload || {};
            state.codCreatorDetail = payload;
            logTrace(action, 'getCodCreatorDetail.fulfilled');
        });
    }
});

export const { 
    getUserAvatar, getAllGenerals, getGeneralQuaValue, getGeneralBattleInfo, 
    getUpgradeGeneralCost,
    getDefenseBlockGenerals, getSkillInfo, getMorale,
    getUseTroops,
    checkNewRecords, removeNewRecords,
    resetGeneralStatusChangeResult,
    getNewWorldRecords,
    removeNewWorldRecords,
    resetSpyResult,
    getCodGeneralIds, resetCodResult
} = generalSlice.actions;

export default generalSlice.reducer;

function getGeralListDetail(allGeneral){
    //todo sth with list before render
    let list = [];
    for(var id in allGeneral){
        list.push(allGeneral[id]);
    }
    return list;
}
