import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import * as API from './api';
import * as Contract from '../wallets/contract';
import { logTrace } from '../utils/log'

export const getUserSignupInfo = createAsyncThunk("signup/info", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getUserSignupInfo(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const getAllPlayersGeneralPortraits = createAsyncThunk("signup/generalPortraits/allPlayer", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getAllPlayersGeneralPortraits(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const generateGeneralPortraits = createAsyncThunk("signup/generalPortrait/generate", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.generateGeneralPortraits(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const generateGeneralPortraitsTest = createAsyncThunk("signup/generalPortrait/generateTest", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.generateGeneralPortraitsTest(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const generalPortraitConfirm = createAsyncThunk("signup/generalPortrait/confirm", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.generalPortraitConfirm(data, function(res){
            res = res || {};
            resolve(res);
        });
    });
    return response;
});

export const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    userSignupInfo: {},
    allPlayersGeneralPortraits: {},
    generalPortraitsResult: {},
    generalPortraitsTestResult: {},
    generalPortraitConfirmResult: {},
});

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        resetGPTResult: function(state, action){
            state.generalPortraitsResult = {};
            state.generalPortraitsTestResult = {};
            state.generalPortraitConfirmResult = {};
        }
    },
    extraReducers: builder => {
        builder.addCase(getUserSignupInfo.fulfilled, (state, action) => {
            let result = action.payload;
            state.userSignupInfo = result;
            logTrace(result, 'signup.info.fulfilled');
        });
        builder.addCase(getAllPlayersGeneralPortraits.fulfilled, (state, action) => {
            let result = action.payload;
            state.allPlayersGeneralPortraits = result;
            logTrace(result, 'signup/generalPortraits/allPlayer.fulfilled');
        });
        builder.addCase(generateGeneralPortraits.fulfilled, (state, action) => {
            let result = action.payload;
            state.generalPortraitsResult = {
                result: result.code === 0,
                back: true,
                images: result.images || {}
            };
            logTrace(result, 'signup/generalPortrait/generate.fulfilled');
        });
        builder.addCase(generateGeneralPortraitsTest.fulfilled, (state, action) => {
            let result = action.payload;
            state.generalPortraitsTestResult = {
                result: result.code === 0,
                back: true,
                images: result.images || {}
            };
            logTrace(result, 'signup/generalPortrait/generateTest.fulfilled');
        });
        builder.addCase(generalPortraitConfirm.fulfilled, (state, action) => {
            let result = action.payload;
            state.generalPortraitConfirmResult = {
                result: result.code === 0,
                back: true,
                data: result
            };
            logTrace(result, 'signup/generalPortrait/confirm.fulfilled');
        });
    }
});

export const { 
    resetGPTResult
} = signupSlice.actions

export default signupSlice.reducer;