import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { Throne, ComponentType } from 'throne-underlying';

import { logTrace } from '../utils/log'

//init map component
let mapInstance;
let mapInited = false;

export const mapInit = createAsyncThunk("map/init", async () => {
    if(mapInited){
        return;
    }
    const response = await new Promise((resolve, reject) => {
        mapInited = true;
        Throne.instance().initComponent(ComponentType.Map, (_map) => {
            mapInstance = _map;
            resolve(mapInstance);
            logTrace(mapInstance, 'map.init')
        });
    });
    return response;
});

export const getTileInfo = createAsyncThunk("tile/info", async (data) => {
    logTrace(mapInstance, 'map.tile.request');
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getBlockInfo(data.x/1, data.y/1, (res)=>{
            logTrace({data, res}, 'map.tile.detail.res');
            if(res.x_id === data.x){
                resolve(res);           
            }else{
                reject(res);
            }
        });
    });
    return response;
});

export const getInitBlockInfo = createAsyncThunk("tile/initInfo", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getInitBlockInfo(data.x/1, data.y/1, (res)=>{
            logTrace({data, res}, 'map.tile.detail.initInfo');
            if(res.x_id === data.x){
                resolve(res);           
            }else{
                reject(res);
            }
        });
    });
    return response;
});


export const attackTile = createAsyncThunk("tile/attack", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        logTrace(data, 'tile.attack.start');

        let x_id = data.x/1;
        let y_id = data.y/1;
        mapInstance.attackBlock(x_id, y_id, data.generalId, (res)=>{
            logTrace({res, data}, 'tile.attack.res');
            mapInstance.getBlockInfo(x_id, y_id, (detail)=>{
                res.result = res.result || {};
                res.result.detail = detail;
                resolve(res.result);
            });
        });
    });
    return response;
});

export const getDefenseList = createAsyncThunk("defenseList/load", async (data) => {
    logTrace(data, 'tile.defense.list.start');
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getDefenseList(data.x, data.y, (res)=>{
            logTrace({res, data}, 'tile.defense.list.res');
            resolve(res);
        });
    });
    return response;
});

export const defenseTile = createAsyncThunk("tile/defense", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        logTrace(data, 'tile.defense.start');
        mapInstance.defenseBlock(data.x/1, data.y/1, data.generalId, (res)=>{
            logTrace({res, data}, 'tile.defense.res');
            resolve(res.result);
        });
    });
    return response;
});

export const defenseTileCancel = createAsyncThunk("tile/defenseCancel", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.cancelDefenseBlock(data.x/1, data.y/1, data.generalId, (res)=>{
            logTrace({res, data}, 'tile.defenseCancel.res');
            let { result } = res;
            // res.data = data;
            resolve(result);
        });
    });
    return response;
});

export const getSeasonInfo = createAsyncThunk("season/info", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getSeasonStatus((res)=>{
            logTrace(res, 'season.info.res');
            resolve(res);
        });
    });
    return response;
});

export const getSeasonConfig = createAsyncThunk("season/config", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        let res = mapInstance.getSeasonConfig();
        logTrace(res, 'season.config res');
        resolve(res);
    });
    return response;
});

export const getSeasonRankResult = createAsyncThunk("season/rank", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getSeasonRankResult((res)=>{
            logTrace(res, 'season.rank.res');
            resolve(res);
        });
    });
    return response;
});

export const getUnionWinInfo = createAsyncThunk("season/win", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getUnionWinInfo((res)=>{
            logTrace(res, 'season.win.res');
            resolve(res);
        });
    });
    return response;
});

export const miningSilvers = createAsyncThunk("silvers/mining", async (data) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        let { xId, yId, generalId, times } = data;
        mapInstance.miningBlock(xId, yId, generalId, times, (res)=>{
            resolve(res.result);
        });
    });
    return response;
});

export const getExpectUnionReward = createAsyncThunk("reward/expect", async (chainName) => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getExpectUnionReward(chainName, (res)=>{
            resolve(res);
        });
    });
    return response;
});

export const getUnionOverView = createAsyncThunk("union/overview", async () => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getUnionOverView((res)=>{
            resolve(res);
        });
    });
    return response;
});

export const getTokenPriceInfo = createAsyncThunk("tokenPriceInfo/get", async () => {
    if(!mapInstance){ 
        return; 
    }
    const response = await new Promise((resolve, reject) => {
        mapInstance.getTokenPriceInfo((res)=>{
            resolve(res);
        });
    });
    return response;
});

export const _adapter = createEntityAdapter();

const initialState = _adapter.getInitialState({
    unionWinInfo: {},
    mapReady: false,
    occupieds: {},
    detail: { area: -1 },
    detailInit: { area: -1 },
    attackResult: {
        back: false
    },
    defenseList: [],
    defenseResult: {
        back: false
    },
    defenseCancelResult: {
        back: false
    },
    seasonInfo: {
        status: 'end',
        remaintime: 0
    },
    seasonConfig: {
        show_occupy_reward: [{}], 
        show_rank_reward: [{}], 
        show_season_victory_reward: [{}]
    },
    seasonRank: [],
    miningResult: {},
    rewardExpect: {},
    unionPlayersWithGlory: [],
    tokenPriceInfos: []
});

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        getOccupiedTiles(state, action) {
            if(!mapInstance){
                return;
            }
            state.occupieds = mapInstance.getBlocksBelongInfo();
            logTrace(state.occupieds, 'tile.detail');
        },
        resetBattleResult(state, action){
            state.attackResult = {
                back: false
            };
            state.defenseResult = {
                back: false
            };
        },
        resetBlockInfo(state, action){
            state.detail = {
                area: -1
            };
        },
        resetMiningResult(state, action){
            state.miningResult = {
                back: false
            };
        },
        resetDefenseCancelResult(state, action){
            state.defenseCancelResult = {
                back: false
            };
        }
    },
    extraReducers: builder => {
        builder.addCase(mapInit.fulfilled, (state, action) => {
            let occupieds = mapInstance.getBlocksBelongInfo();
            state.occupieds = occupieds;
            state.mapReady = true;
            logTrace(occupieds, 'map.init.fulfilled');
        });
        builder.addCase(getTileInfo.fulfilled, (state, action) => {
            state.detail = action.payload;
            logTrace(action, 'tile.detail.fulfilled');
        });
        builder.addCase(getInitBlockInfo.fulfilled, (state, action) => {
            state.detailInit = action.payload;
            logTrace(action, 'tile.detailed.fulfilled');
        });
        builder.addCase(attackTile.fulfilled, (state, action) => {
            state.attackResult = action.payload;
            state.attackResult.back = true;
            state.attackResult.time = new Date();
            logTrace(action, 'tile.attack.fulfilled');
        });
        builder.addCase(getDefenseList.fulfilled, (state, action) => {
            state.defenseList = action.payload;
            logTrace(action, 'tile.defense.list.fulfilled');
        });
        builder.addCase(defenseTile.fulfilled, (state, action) => {
            state.defenseResult = action.payload || {};
            state.defenseResult.back = true;
            state.defenseResult.time = new Date();
            logTrace(action, 'tile.defense.fulfilled');
        });
        builder.addCase(defenseTileCancel.fulfilled, (state, action) => {
            state.defenseCancelResult = action.payload || { result: false };
            state.defenseCancelResult.back = true;
            logTrace(action, 'tile.defense.cancel.fulfilled');
        });
        builder.addCase(getSeasonConfig.fulfilled, (state, action) => {
            state.seasonConfig = action.payload;
            logTrace(action, 'season.config.fulfilled');
        });
        builder.addCase(getSeasonInfo.fulfilled, (state, action) => {
            state.seasonInfo = action.payload;
            logTrace(action, 'season.info.fulfilled');
        });
        builder.addCase(getSeasonRankResult.fulfilled, (state, action) => {
            state.seasonRank = action.payload;
            logTrace(action, 'season.rank.fulfilled');
        });
        builder.addCase(getUnionWinInfo.fulfilled, (state, action) => {
            state.unionWinInfo = action.payload || {};
            logTrace(action, 'season.win.fulfilled');
        });
        builder.addCase(miningSilvers.fulfilled, (state, action) => {
            logTrace(action, 'season.miningSilvers.fulfilled');
            let miningResult = action.payload || {};
                miningResult.text = miningResult.error;
                miningResult.back = true;
                miningResult.time = new Date().getTime();
            state.miningResult = miningResult; 
        });
        builder.addCase(getExpectUnionReward.fulfilled, (state, action) => {
            let rewardExpect = action.payload || {};
            logTrace(rewardExpect, 'season.reward.expect');
            state.rewardExpect = rewardExpect;
        });
        builder.addCase(getUnionOverView.fulfilled, (state, action) => {
            let data = action.payload || {};
            logTrace(data, 'getUnionOverView.fulfilled');
            state.unionPlayersWithGlory = data;
        });
        builder.addCase(getTokenPriceInfo.fulfilled, (state, action) => {
            let data = action.payload || {};
            logTrace(data, 'getTokenPriceInfo.fulfilled');
            state.tokenPriceInfos = data;
        });
    }
});

export const { getOccupiedTiles, resetBattleResult, resetBlockInfo, resetMiningResult, resetDefenseCancelResult } = mapSlice.actions
export default mapSlice.reducer;