"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageType = void 0;
var MessageType;
(function (MessageType) {
    MessageType["Transition"] = "transition";
    MessageType["StateQuery"] = "state_query";
    MessageType["Query"] = "query";
    MessageType["Chat"] = "chat";
    MessageType["QueryCount"] = "query_count";
    MessageType["Profile"] = "profile";
    MessageType["SyncBlockchain"] = "sync_blockchain";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
