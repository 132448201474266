window.logConfig = {
    open: true,
    features: {
        // 'app.init': true,
        // 'map.init': true,
        // 'map.scene': true,
        // 'tile.attack': true,
        // 'tile.detail': true,
        // 'map.tile': true,
        // 'map.tile.occupieds': true,
        // 'map.defenseTile': true,
        // 'general': true,
        // 'general.detail': true,
        // 'general.skill': true,
        // 'training': true,
        // 'battle': true,
        // 'season': true,
        // 'user': true,
        // 'user.login': true,
        // 'user.charge': true,
        // 'strategy': true
    }
};

function logTrace(data, _feature){
    if(!window.logConfig.open){
        return;
    }
    for(var name in window.logConfig.features){
        if(_feature.indexOf(name) > -1){
            console.log(_feature, data);
        }
    }
}

export {
    logTrace
}