"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseMediator = void 0;
var Const_1 = require("../Game/Const");
var BaseMediator = /** @class */ (function () {
    function BaseMediator() {
        var _a;
        this.listeners = {};
        this.chatListener = (_a = {},
            _a[Const_1.ChatChannel.ChatChannel_Camp] = [],
            _a[Const_1.ChatChannel.ChatChannel_WORLD] = [],
            _a);
    }
    BaseMediator.prototype.onReceiveState = function (sid, callback) {
        if (!this.listeners[sid.id]) {
            this.listeners[sid.id] = [];
        }
        this.listeners[sid.id].push(callback);
    };
    BaseMediator.prototype.onReceiveChat = function (data) {
        var callbacklist = this.chatListener[data.channel];
        for (var _i = 0, callbacklist_1 = callbacklist; _i < callbacklist_1.length; _i++) {
            var callback = callbacklist_1[_i];
            callback(data);
        }
    };
    BaseMediator.prototype.listenChat = function (channel, callback) {
        this.chatListener[channel].push(callback);
    };
    BaseMediator.prototype.queryState = function (sid, args, callback) {
        throw "not emplement";
    };
    BaseMediator.prototype.query = function (typ, args) {
        throw "not emplement";
    };
    BaseMediator.prototype.defaultQuery = function (type, transID, args) {
        throw "not emplement";
    };
    BaseMediator.prototype.chat = function (data) {
        throw "not emplement";
    };
    BaseMediator.prototype.chatHistory = function (data) {
        throw "not emplement";
    };
    BaseMediator.prototype.sendTransaction = function (tid, args, callback) {
        throw "not emplement";
    };
    BaseMediator.prototype.profileQuery = function (key) {
        throw "not emplement";
    };
    BaseMediator.prototype.profileSave = function (key, value) {
        throw "not emplement";
    };
    BaseMediator.prototype.setWsCloseCallbacl = function (callback) {
    };
    BaseMediator.prototype.setChainBlockCallback = function (callback) {
        throw "not emplement";
    };
    BaseMediator.prototype.notifyState = function (sid, state) {
        var listeners = this.listeners[sid.id];
        for (var index in listeners) {
            listeners[index](state);
        }
    };
    return BaseMediator;
}());
exports.BaseMediator = BaseMediator;
