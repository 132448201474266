export default function() {
    //https://www.zetachain.com/docs/reference/api/
    //https://chainlist.org/chain/80001
    return {
        'zetatest': {
            name: 'zetatest',
            icon: 'aZETA',
            chainId: '0x1b59',
            chainName: 'ZetaChain Testnet',
            priceUnit: 1000000,
            isCrossChain: true,
            nativeCurrency: {
                name: 'ZetaChain Testnet',
                symbol: 'aZETA',
                decimals: 18
            },
            rpcUrls: ['https://rpc.ankr.com/zetachain_evm_athens_testnet'],
            nfts: ['','',''],
            reward: 'usdt'
        },
        'zeta': {
            name: 'zeta',
            icon: 'ZETA',
            chainId: '0x1b58',
            chainName: 'ZetaChain',
            priceUnit: 1000000,
            isCrossChain: true,
            nativeCurrency: {
                name: 'ZetaChain',
                symbol: 'ZETA',
                decimals: 18
            },
            rpcUrls: ['https://zetachain-evm.blockpi.network/v1/rpc/public'],
            nfts: ['','',''],
            reward: 'usdt'
        },
        'emerald': {
            name: 'emerald',
            icon: 'rose',
            chainId: '0xa516',
            chainName: 'Emerald',
            priceUnit: 1000000,
            nativeCurrency: {
                name: 'Emerald',
                symbol: 'ROSE',
                decimals: 18
            },
            rpcUrls: ['https://emerald.oasis.dev'],
            nfts: ['airose', 'apex', 'seibaraclub'],
            reward: 'yuzu'
        },
        'bsc': {
            name: 'bsc',
            icon: 'bnb',
            chainId: '0x38',
            chainName: 'BNBChain',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org'],
            nfts: ['binanceregularnft', 'thecr7nftcollection', 'spaceid'],
            reward: 'usdt'
        },
        'bsctest': {
            name: 'bsctest',
            icon: 'bnb',
            chainId: '0x61',
            chainName: 'BSC Testnet',
            priceUnit: 1000000,
            nativeCurrency: {
                name: 'TBNB',
                symbol: 'TBNB',
                decimals: 18
            },
            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
            nfts: ['binanceregularnft', 'thecr7nftcollection', 'spaceid'],
            reward: 'usdt'
        },
        'mumbaitest': {
            name: 'mumbaitest',
            icon: 'Polygon',
            chainId: '0x13881',
            chainName: 'Mumbai Testnet',
            priceUnit: 1000000,
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
            nfts: [],
            reward: 'zetatest'
        },
        'goerlitest': {
            name: 'goerlitest',
            icon: 'ETH',
            chainId: '0x5',
            chainName: 'Goerli Test',
            priceUnit: 1000000,
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18
            },
            rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
            nfts: [],
            reward: 'zetatest'
        }
    }
}