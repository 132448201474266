"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGlobalEsential = exports.createLogicEsential = void 0;
var general_1 = require("./general");
var game_1 = require("./game");
var boost_1 = require("./boost");
var Const_1 = require("../Const");
var map_1 = require("./map");
var strategy_1 = require("./strategy");
var activity_1 = require("./activity");
function createLogicEsential(states) {
    console.log('createLogicEsential', states);
    var boost = new boost_1.Boost();
    var city = new game_1.City(states.city);
    var general = new general_1.General(states.general, city, states.codsGlobal, states.seasonState);
    var map = new map_1.Map(states.mapGlobal, states.seasonState, states.rewardGlobalState, states.tokenPriceInfo);
    var strategy = new strategy_1.Strategy(states.strategy);
    var activity = new activity_1.Activity(states.activityState);
    city.setBoost(boost);
    city.setMap(map);
    city.setGeneral(general);
    general.setBoost(boost);
    general.setMap(map);
    map.setBoost(boost);
    map.setGeneral(general);
    map.setCity(city);
    map.loadBlockStates(states.blocks);
    strategy.setBoost(boost);
    strategy.setLogic(city, map, general);
    boost.setTroop(city.getResource(Const_1.ResouceType.Troop), city.getMaintainNeedTroop());
    boost.setMapBuff(map.getBuffList(states.general.unionId));
    boost.setProduction(Const_1.StateName.City, Const_1.ResouceType.Silver, city.calculatePoduction(Const_1.ResouceType.Silver));
    boost.setProduction(Const_1.StateName.City, Const_1.ResouceType.Troop, city.calculatePoduction(Const_1.ResouceType.Troop));
    boost.setProduction(Const_1.StateName.General, Const_1.ResouceType.Silver, general.getGeneralProduction(Const_1.ResouceType.Silver));
    boost.setProduction(Const_1.StateName.General, Const_1.ResouceType.Troop, general.getGeneralProduction(Const_1.ResouceType.Troop));
    strategy.updateBoost();
    activity.setCity(city);
    activity.setMap(map);
    activity.setBoost(boost);
    //city.SetBoost(boost)
    //general.SetBoost(boost)
    //boost.recalulate() 
    return { city: city, general: general, map: map, strategy: strategy, boost: boost, activity: activity };
}
exports.createLogicEsential = createLogicEsential;
function createGlobalEsential(gStates) {
    var map = new map_1.Map(gStates.mapGlobal, gStates.seasonState, gStates.rewardGlobalState, gStates.tokenPriceInfo);
    map.loadBlockStates(gStates.blocks);
    var activity = new activity_1.Activity(gStates.activityState);
    return { map: map, activity: activity };
}
exports.createGlobalEsential = createGlobalEsential;
