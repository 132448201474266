"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Throne = exports.InstanceStatus = exports.ComponentType = exports.GeneralComponent = exports.CityComponent = void 0;
var general_1 = require("../Logic/general");
var game_1 = require("../Logic/game");
var mediator_1 = require("../Controler/mediator");
var Const_1 = require("../Const");
var state_1 = require("../../Core/state");
var State_1 = require("../State");
var creator_1 = require("../Logic/creator");
var websocket_1 = require("../Controler/websocket");
var map_1 = require("./map");
var transition_1 = require("../Controler/transition");
var Utils_1 = require("../Utils");
var strategy_1 = require("./strategy");
var protocol_1 = require("../Controler/Websocket/protocol");
var chain_1 = require("./chain");
var CityComponent = /** @class */ (function () {
    function CityComponent(myStateId, mediator) {
        var _a;
        this.cityStateId = {
            id: myStateId
        };
        this.type = ComponentType.City;
        this.mediator = mediator;
        this.listener = [];
        this.chatReadInfo = {};
        this.chatRedPointInfo = {};
        this.chatProfileKey = (_a = {},
            _a[Const_1.ChatChannel.ChatChannel_Camp] = "profile-chatCamp",
            _a[Const_1.ChatChannel.ChatChannel_WORLD] = "profile-chatWorld",
            _a);
        this.activityHaveRead = [];
    }
    CityComponent.prototype.setCity = function (city) {
        var _this_1 = this;
        this.city = city;
        this.mediator.onReceiveState(this.cityStateId, function () {
            _this_1.city.updateBoost();
        });
    };
    CityComponent.prototype.setActivity = function (activity) {
        this.activity = activity;
        var len = this.activity.state.activityData.length;
        for (var i = 0; i < len; i++) {
            this.activityHaveRead.push(false);
        }
    };
    CityComponent.prototype.getActivityProfileKey = function (activityId) {
        return "profile-activity-".concat(activityId);
    };
    CityComponent.prototype.getUpgradeInfo = function (typ, targetLevel) {
        return this.city.getUpgradeInfo(typ, targetLevel);
    };
    CityComponent.prototype.getAllUpgradeInfo = function (typ) {
        return this.city.getAllUpgradeInfo(typ);
    };
    CityComponent.prototype.getFacilityOrder = function () {
        return this.city.getFacilityOrder();
    };
    CityComponent.prototype.getInjuredTroops = function () {
        return this.city.getInjuredTroops();
    };
    CityComponent.prototype.healEstimate = function (amount) {
        return this.city.healEstimate(amount);
    };
    CityComponent.prototype.healTroopsStart = function (typ, amount, callback) {
        var supportTypes = {
            silver: true,
            gold: true
        };
        // if(!supportTypes[typ]){
        //   callback({
        //     err: 'support type should be silver/gold.'
        //   })
        // }
        // if(amount <= 0){
        //   callback({
        //     err: 'amount must > 0.'
        //   })
        // }
        // if(typ === 'silver'){
        //   return this.city.healTroopsBySilver(amount);
        // }
        // if(typ === 'gold'){
        //   return this.city.healTroopsByGold(amount);
        // }
        this.mediator.sendTransaction(Const_1.StateTransition.HealTroops, {
            from: Throne.instance().username,
            typ: typ,
            amount: amount,
        }, callback);
    };
    CityComponent.prototype.updateResource = function (inter) {
        var _this_1 = this;
        if (inter === void 0) { inter = 5000; }
        setInterval(function () {
            for (var _i = 0, _a = _this_1.listener; _i < _a.length; _i++) {
                var callbak = _a[_i];
                callbak({});
            }
        }, inter);
    };
    CityComponent.prototype.getFacilityList = function () {
        return (0, state_1.copyObj)(this.city.state.facilities);
    };
    CityComponent.prototype.getResource = function () {
        var _a;
        var silverStatus = this.city.boost.getProductionStatus(Const_1.ResouceType.Silver);
        var troopStatus = this.city.boost.getProductionStatus(Const_1.ResouceType.Troop);
        var re = (_a = {},
            _a[Const_1.ResouceType.Silver] = {
                value: this.city.getResource(Const_1.ResouceType.Silver),
                production: this.city.boost.getProduction(Const_1.ResouceType.Silver),
                maintain: silverStatus.maintain,
                normal: silverStatus.normalProduction
            },
            _a[Const_1.ResouceType.Troop] = {
                value: this.city.getResource(Const_1.ResouceType.Troop),
                production: this.city.boost.getProduction(Const_1.ResouceType.Troop),
                maintain: troopStatus.maintain,
                normal: troopStatus.normalProduction
            },
            _a);
        re['maintainNeedTroop'] = this.city.getMaintainNeedTroop();
        re['protectSilver'] = this.city.getSaveSilverAmount();
        re['troopUseSilver'] = re[Const_1.ResouceType.Troop].value * 1;
        return re;
    };
    CityComponent.prototype.getFacilityUpgradeRequirement = function (typ, targetLevel) {
        return this.city.getUpgradeInfo(typ, targetLevel);
    };
    CityComponent.prototype.checkUpgradeFacility = function (typ, index) {
        return this.city.checkUpgradeFacility(typ, index);
    };
    CityComponent.prototype.doUpgradeFacility = function (typ, index, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.UpgradeFacility, {
            from: Throne.instance().username,
            typ: typ,
            index: index,
        }, callback);
    };
    CityComponent.prototype.recruitEstimate = function (amount) {
        return this.city.recruitEstimate(amount);
    };
    CityComponent.prototype.doRecruit = function (amount, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.Recruit, {
            from: Throne.instance().username,
            amount: amount
        }, callback);
    };
    CityComponent.prototype.getRecruitNeed = function (amount) {
        return this.city.getRecruitNeed(amount);
    };
    CityComponent.prototype.getRecruitState = function () {
        var time = parseInt(new Date().getTime() / 1000 + '');
        var re = {
            status: game_1.RecruitStatus.None,
            endtime: 0,
            amount: 0
        };
        if (this.city.state.recruit.length != 0) {
            re.endtime = this.city.state.recruit[0].endtime;
            re.amount = this.city.state.recruit[0].amount;
            if (re.endtime >= time) {
                re.status = game_1.RecruitStatus.Going;
            }
            else {
                re.status = game_1.RecruitStatus.Ready;
            }
        }
        return re;
    };
    CityComponent.prototype.receiveTroop = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.ReceiveTroop, {
            from: Throne.instance().username
        }, callback);
    };
    CityComponent.prototype.onStateUpdate = function (callback) {
        this.mediator.onReceiveState(this.cityStateId, callback);
        this.listener.push(callback);
    };
    CityComponent.prototype.getRechargeConfigs = function (chainName) {
        return this.city.getRechargeConfigs(chainName);
    };
    CityComponent.prototype.getGold = function () {
        return this.city.state.gold;
    };
    CityComponent.prototype.getTestResourceCoolDownTime = function () {
        return this.city.getTestResourceCoolDownTime();
    };
    CityComponent.prototype.addTestResource = function (callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.AddTestResource, {
            from: Throne.instance().username
        }, callback);
    };
    CityComponent.prototype.getOfferList = function () {
        var buyOfferRecords = this.city.state.buyOfferRecords;
        var all = this.city.getOfferList();
        console.log('onBuyOffer getOfferList:', buyOfferRecords, all);
        return { buyOfferRecords: buyOfferRecords, all: all };
    };
    CityComponent.prototype.buyOffer = function (offerId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.BuyOffer, {
            from: Throne.instance().username,
            offerId: offerId
        }, callback);
    };
    CityComponent.prototype.onReceiveChat = function (channel, callback) {
        this.mediator.listenChat(channel, callback);
    };
    CityComponent.prototype.chat = function (data, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var msg, re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = {
                            id: '',
                            type: Const_1.ChatType.ChatTypeText,
                            channel: data.channel,
                            content: data.content,
                            sender: Throne.instance().username,
                            seasonId: Throne.instance().seasonId,
                            senderCamp: Throne.instance().logicEssential.general.state.unionId,
                            iconId: Throne.instance().logicEssential.general.state.iconId,
                            ts: (0, Utils_1.getTimeStamp)()
                        };
                        return [4 /*yield*/, this.mediator.chat(msg)];
                    case 1:
                        re = _a.sent();
                        callback(re);
                        return [2 /*return*/];
                }
            });
        });
    };
    CityComponent.prototype.getHistoryChatData = function (data, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var queryData, re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryData = {};
                        if (!data.unionId) {
                            queryData['camp'] = data.unionId = 1;
                        }
                        else {
                            queryData['camp'] = data.unionId;
                        }
                        return [4 /*yield*/, this.mediator.chatHistory(queryData)];
                    case 1:
                        re = _a.sent();
                        callback(re || []);
                        return [2 /*return*/];
                }
            });
        });
    };
    CityComponent.prototype.getAbleActivityInfo = function (index) {
        this.activity.setChainIndex(index);
        var infolist = this.activity.getBeforeActivities();
        var re = [];
        for (var _i = 0, infolist_1 = infolist; _i < infolist_1.length; _i++) {
            var item = infolist_1[_i];
            var rank = this.activity.getActivityRank(item.activityId, (0, Utils_1.parseStateId)(this.cityStateId.id).username, this.city.getActivityData(item.activityId));
            var singleInfo = Object.assign(item, rank);
            singleInfo['redPoint'] = !this.activityHaveRead[item.activityId];
            re.push(singleInfo);
        }
        return re;
    };
    CityComponent.prototype.donateSilver = function (activityId, amount, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.DonateSilver, {
            from: Throne.instance().username,
            activityId: activityId,
            amount: amount
        }, callback);
    };
    CityComponent.prototype.getGuideStep = function (type) {
        return this.city.getGuideStep(type);
    };
    CityComponent.prototype.setGuideStep = function (type, step, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.SetGuideStep, {
            from: Throne.instance().username,
            type: type,
            step: step,
        }, callback);
    };
    CityComponent.prototype.getChatRedPoint = function (channel) {
        if (!this.chatRedPointInfo[channel] ||
            JSON.stringify(this.chatRedPointInfo[channel]) === '{}') {
            return false;
        }
        else if (this.chatReadInfo[channel]
            && JSON.stringify(this.chatReadInfo[channel]) !== '{}'
            && this.chatReadInfo[channel].ts == this.chatRedPointInfo[channel].ts
            && this.chatReadInfo[channel].id == this.chatRedPointInfo[channel].id) {
            return false;
        }
        return true;
    };
    CityComponent.prototype.updateChatRedPoint = function (channel, msg) {
        var info = {
            id: msg.id,
            ts: msg.ts
        };
        this.chatReadInfo[channel] = info;
        this.mediator.profileSave(this.chatProfileKey[channel], (0, Utils_1.encodeChatProfile)(msg.id, msg.ts));
    };
    CityComponent.prototype.initRedPoint = function () {
        return __awaiter(this, void 0, void 0, function () {
            var campProfile, campInfo, worldProfile, worldInfo, i, activityInfo;
            var _this_1 = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Throne.instance()', Throne.instance());
                        return [4 /*yield*/, this.getHistoryChatData({ unionId: Throne.instance().unionId }, function (result) {
                                if (result.length != 0) {
                                    var worldInfo = {
                                        id: result[result.length - 1].id,
                                        ts: result[result.length - 1].ts
                                    };
                                    _this_1.chatRedPointInfo[Const_1.ChatChannel.ChatChannel_WORLD] = worldInfo;
                                    for (var i = result.length - 1; i >= 0; i--) {
                                        if (result[i].senderCamp == Throne.instance().unionId) {
                                            var unionInfo = {
                                                id: result[i].id,
                                                ts: result[i].ts
                                            };
                                            _this_1.chatRedPointInfo[Const_1.ChatChannel.ChatChannel_Camp] = unionInfo;
                                        }
                                        break;
                                    }
                                }
                            })
                            //read red point info from backend
                            //for(let key in ChatChannel)
                        ];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.mediator.profileQuery(this.chatProfileKey[Const_1.ChatChannel.ChatChannel_Camp])];
                    case 2:
                        campProfile = _a.sent();
                        if (campProfile['code'] == 0) {
                            campInfo = (0, Utils_1.decodeChatProfile)(campProfile['data']);
                            this.chatReadInfo[Const_1.ChatChannel.ChatChannel_Camp] = {
                                id: campInfo.id,
                                ts: campInfo.ts
                            };
                        }
                        return [4 /*yield*/, this.mediator.profileQuery(this.chatProfileKey[Const_1.ChatChannel.ChatChannel_WORLD])];
                    case 3:
                        worldProfile = _a.sent();
                        if (worldProfile['code'] == 0) {
                            worldInfo = (0, Utils_1.decodeChatProfile)(worldProfile['data']);
                            this.chatReadInfo[Const_1.ChatChannel.ChatChannel_WORLD] = {
                                id: worldInfo.id,
                                ts: worldInfo.ts
                            };
                        }
                        this.mediator.listenChat(Const_1.ChatChannel.ChatChannel_Camp, function (msg) {
                            _this_1.chatRedPointInfo[Const_1.ChatChannel.ChatChannel_Camp] = {
                                id: msg.id,
                                ts: msg.ts
                            };
                        });
                        this.mediator.listenChat(Const_1.ChatChannel.ChatChannel_WORLD, function (msg) {
                            _this_1.chatRedPointInfo[Const_1.ChatChannel.ChatChannel_WORLD] = {
                                id: msg.id,
                                ts: msg.ts
                            };
                        });
                        i = 0;
                        _a.label = 4;
                    case 4:
                        if (!(i < this.activityHaveRead.length)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.mediator.profileQuery(this.getActivityProfileKey(i))];
                    case 5:
                        activityInfo = _a.sent();
                        if (activityInfo['code'] == 0) {
                            this.activityHaveRead[i] = true;
                        }
                        _a.label = 6;
                    case 6:
                        i++;
                        return [3 /*break*/, 4];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CityComponent.prototype.readActivity = function (activityId) {
        this.activityHaveRead[activityId] = true;
        this.mediator.profileSave(this.getActivityProfileKey(activityId), "1");
    };
    return CityComponent;
}());
exports.CityComponent = CityComponent;
var GeneralComponent = /** @class */ (function () {
    function GeneralComponent(myStateId, mediator) {
        this.generalStateId = {
            id: myStateId
        };
        this.type = ComponentType.General;
        this.mediator = mediator;
        this.battleRecordProfileKey = "profile-battleRecord";
        this.battleRecordLocalTs = 0;
        this.battleRecordGobalTs = 0;
        this.recentWorldRecordTs = 0;
    }
    GeneralComponent.prototype.setGeneral = function (general) {
        var _this_1 = this;
        this.general = general;
        this.mediator.onReceiveState(this.generalStateId, function () {
            _this_1.general.updateBoost();
        });
    };
    GeneralComponent.prototype.initRedPoint = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loaclInfo;
            var _this_1 = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mediator.profileQuery(this.battleRecordProfileKey)];
                    case 1:
                        loaclInfo = _a.sent();
                        if (loaclInfo['code'] == 0) {
                            this.battleRecordLocalTs = parseInt(loaclInfo['data']);
                        }
                        setInterval(function () {
                            _this_1.getBattleRecords(function () { });
                        }, 10000);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.getConstData = function () {
        var re = {
            general_max_level: this.general.config.parameter.general_max_level,
            skill_max_level: this.general.config.parameter.general_skill_max_level
        };
        return re;
    };
    GeneralComponent.prototype.getUserVipBuffs = function (username) {
        var userScore = this.general.getUserScore(username);
        var vipBuffs = this.general.getVipBuffs(userScore);
        return {
            address: username,
            accountRating: Math.floor(userScore * 100) / 100,
            vipBuffs: vipBuffs
        };
    };
    GeneralComponent.prototype.getGeneralQualification = function (id) {
        return this.general.getGeneralQualification(id);
    };
    GeneralComponent.prototype.getAbleStatus = function () {
        var re = {
            able_count: 0,
            max_able_count: 0
        };
        re.max_able_count = this.general.city.getGeneralMaxAble();
        re.able_count = this.general.getAbleCount();
        return re;
    };
    GeneralComponent.prototype.getGeneralList = function () {
        var re = {};
        for (var idstring in this.general.state.generalList) {
            var generalInfo = this.general.state.generalList[idstring];
            var stamina = generalInfo.stamina;
            var id = parseInt(idstring);
            var temp = {
                id: id,
                qualification: {},
                level: 0,
                able: false,
                skilllevel: [1, 1, 1],
                stamina: 0,
                staminaTime: 0
            };
            temp.qualification = JSON.parse(JSON.stringify(this.getGeneralQualification(id)));
            temp.level = generalInfo.level;
            temp.able = generalInfo.able;
            temp.skilllevel = generalInfo.skill_levels.concat();
            temp.stamina = this.general.getGeneralStamina(id);
            temp.staminaTime = stamina.lastUpdate;
            re[idstring] = temp;
        }
        return re;
    };
    GeneralComponent.prototype.ableGeneral = function (id, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.AbleGeneral, {
            from: Throne.instance().username,
            id: id
        }, callback);
    };
    GeneralComponent.prototype.disableGeneral = function (id, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.DisableGeneral, {
            from: Throne.instance().username,
            id: id
        }, callback);
    };
    GeneralComponent.prototype.upgradeGeneral = function (id, levelTo, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.UpgradeGeneral, {
            from: Throne.instance().username,
            id: id,
            levelTo: levelTo
        }, callback);
    };
    GeneralComponent.prototype.getUpgradeGeneralCost = function (id, level, levelTo) {
        var silvers = this.general.getGeneralUpgradeNeed(id, level, levelTo);
        return { id: id, level: level, levelTo: levelTo, silvers: silvers };
    };
    GeneralComponent.prototype.onStateUpdate = function (callback) {
        this.mediator.onReceiveState(this.generalStateId, callback);
    };
    GeneralComponent.prototype.getSkillInfo = function (skillId) {
        return this.general.getSkillInfo(skillId);
    };
    GeneralComponent.prototype.checkUpgradeGeneral = function (id) {
        return this.general.checkUpgradeGeneral(id);
    };
    GeneralComponent.prototype.getUpgradeGeneralNeed = function (id, level, levelTo) {
        return this.general.getGeneralUpgradeNeed(id, level, levelTo);
    };
    GeneralComponent.prototype.getGeneralQuaValue = function (id, level) {
        var re = {};
        re['attack'] = this.general.getGeneralAbility(id, level, general_1.GeneralAbility.Attack);
        re['defense'] = this.general.getGeneralAbility(id, level, general_1.GeneralAbility.Defense);
        re['load'] = this.general.getGeneralAbility(id, level, general_1.GeneralAbility.Load);
        re['silver_product'] = this.general.getGeneralAbility(id, level, general_1.GeneralAbility.Silver);
        re['troop_product'] = this.general.getGeneralAbility(id, level, general_1.GeneralAbility.Troop);
        return re;
    };
    GeneralComponent.prototype.getSkillUpgradeNeed = function (generalId, skillIndex, level) {
        return this.general.getSkillUpdateNeed(generalId, skillIndex, level);
    };
    GeneralComponent.prototype.checkGeneralSkillUpgrade = function (generalId, skillIndex) {
        return this.general.checkGeneralSkillUpgrade(generalId, skillIndex);
    };
    GeneralComponent.prototype.getGeneralSkillList = function (generalId) {
        //skill_id: number[],  skill_level : number[],  upgrade_need: number[] , value_type: number[], buff_value: number[], check_upgrade: boolean[]
        var re = {
            skill_id: [],
            skill_level: [],
            upgrade_need: [],
            value_type: [],
            value: [],
            check_upgrade: []
        };
        var generalInfo = this.general.getGeneralState(generalId);
        var qualification = JSON.parse(JSON.stringify(this.getGeneralQualification(generalId)));
        re.skill_id = qualification.general_skill.concat();
        re.skill_level = generalInfo.skill_levels.concat();
        var upgrade_need = new Array(re.skill_id.length).fill(0);
        var value_type = new Array(re.skill_id.length).fill(0);
        var value = new Array(re.skill_id.length).fill(0);
        var check_upgrade = new Array(re.skill_id.length).fill(false);
        for (var i = 0; i < re.skill_id.length; i++) {
            upgrade_need[i] = this.general.getSkillUpdateNeed(generalId, i, re.skill_level[i]);
            var temp = this.general.getSkillValue(generalId, i, re.skill_level[i]);
            value_type[i] = temp['value_type'];
            value[i] = temp['value'];
            check_upgrade[i] = this.general.checkGeneralSkillUpgrade(generalId, i);
        }
        re.upgrade_need = upgrade_need;
        re.value_type = value_type;
        re.value = value;
        re.check_upgrade = check_upgrade;
        return re;
    };
    GeneralComponent.prototype.upgradeGeneralSkill = function (generalId, skillIndex, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.UpgradeGeneralSkill, {
            from: Throne.instance().username,
            generalId: generalId,
            skillIndex: skillIndex
        }, callback);
    };
    GeneralComponent.prototype.setDefenseGeneral = function (generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.SetDefenseGeneral, {
            from: Throne.instance().username,
            generalId: generalId
        }, callback);
    };
    GeneralComponent.prototype.getDefenseGeneralId = function () {
        return this.general.state.defense_general;
    };
    GeneralComponent.prototype.getGeneralBattleInfo = function (generalId) {
        return this.general.getGeneralBattleStatus(generalId);
    };
    GeneralComponent.prototype.getAllBattleStatuses = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { '$orderBy': '-silver' })];
                    case 1:
                        re = _a.sent();
                        callback(re !== null && re !== void 0 ? re : []);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.getBattleStatuses = function (username, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re, list;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        re = [];
                        if (!(username == '')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { '$orderBy': '-silver' })];
                    case 1:
                        re = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { username: username })];
                    case 3:
                        re = _a.sent();
                        _a.label = 4;
                    case 4:
                        re = re !== null && re !== void 0 ? re : [];
                        list = [];
                        re.forEach(function (item) {
                            list.push({
                                id: item.id,
                                username: item.username,
                                unionId: item.unionId,
                                player: item.player,
                                isProtected: item.isProtected,
                                statuses: item
                            });
                        });
                        callback(list);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.spyEnamy = function (username, generalId, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var _this, res, _enamy;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _this = this;
                        return [4 /*yield*/, this.mediator.query(Const_1.StateName.DefenderInfo, { username: username })];
                    case 1:
                        res = _a.sent();
                        _enamy = (res || [])[0] || {};
                        if (_enamy.isProtected) {
                            callback({
                                result: false,
                                err: 'Player not exist or under protect',
                                data: res || []
                            });
                            return [2 /*return*/];
                        }
                        this.mediator.sendTransaction(Const_1.StateTransition.SpyEnamy, {
                            from: Throne.instance().username,
                            generalId: generalId,
                            username: username
                        }, function (res) {
                            return __awaiter(this, void 0, void 0, function () {
                                var data;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!res.result) return [3 /*break*/, 2];
                                            return [4 /*yield*/, _this.mediator.query(Const_1.StateName.DefenderInfo, { username: username })];
                                        case 1:
                                            data = _a.sent();
                                            res.data = data || [];
                                            callback(res);
                                            return [3 /*break*/, 3];
                                        case 2:
                                            callback(res);
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.getCodCreatorDetail = function (codId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.CodCreatorDetail, {
            from: Throne.instance().username,
            codId: codId,
        }, function (res) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    callback(res);
                    return [2 /*return*/];
                });
            });
        });
    };
    GeneralComponent.prototype.getCodGeneralIds = function () {
        return this.general.getCodGeneralIds('');
    };
    GeneralComponent.prototype.clearCodGeneralIds = function () {
        for (var i = 0; i < 20; i++) {
            console.log('clearCodGeneralIds', i);
            this.general.opCodGeneralId(i, 'release', {});
        }
    };
    GeneralComponent.prototype.getCodList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var codsGlobal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.GlobalCod) }, {}, null)];
                    case 1:
                        codsGlobal = (_a.sent());
                        console.log('cod codsGlobal getCodList:', codsGlobal);
                        return [2 /*return*/, this.general.getCodList()];
                }
            });
        });
    };
    GeneralComponent.prototype.createCod = function (blockInfo, generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.CreateCod, {
            from: Throne.instance().username,
            blockInfo: blockInfo,
            generalId: generalId
        }, callback);
    };
    GeneralComponent.prototype.cancelCod = function (codId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.CancelCod, {
            from: Throne.instance().username,
            codId: codId
        }, callback);
    };
    GeneralComponent.prototype.joinCod = function (codId, generalId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.JoinCod, {
            from: Throne.instance().username,
            codId: codId,
            generalId: generalId
        }, callback);
    };
    GeneralComponent.prototype.quitCod = function (codId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.QuitCod, {
            from: Throne.instance().username,
            codId: codId
        }, callback);
    };
    GeneralComponent.prototype.getGloryAndRank = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var rank, re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        rank = -1;
                        return [4 /*yield*/, this.mediator.defaultQuery(protocol_1.MessageType.QueryCount, Const_1.StateName.DefenderInfo, { "glory": { "$gt": this.general.state.glory } })];
                    case 1:
                        rank = _a.sent();
                        re = {
                            glory: this.general.state.glory,
                            rank: rank + 1
                        };
                        callback(re);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.battle = function (generalId, name, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.Battle, {
            from: Throne.instance().username,
            generalId: generalId,
            name: name
        }, callback);
    };
    GeneralComponent.prototype.getBattleRecords = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var re, trans, _i, _a, record;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.mediator.query(transition_1.TransitionEventType.Battles, {
                            "$or": [{ "attackInfo.username": Throne.instance().username }, { "defenseInfo.username": Throne.instance().username }],
                            '$orderBy': '-timestamp'
                        })];
                    case 1:
                        re = (_b.sent());
                        console.log('getBattleRecords', re);
                        trans = [];
                        for (_i = 0, _a = re !== null && re !== void 0 ? re : []; _i < _a.length; _i++) {
                            record = _a[_i];
                            trans.push(this.general.transferTransRecord(record));
                        }
                        callback(trans);
                        if (!(trans.length != 0)) return [3 /*break*/, 3];
                        this.battleRecordGobalTs = trans[0].timestamp;
                        return [4 /*yield*/, this.mediator.profileSave(this.battleRecordProfileKey, this.battleRecordGobalTs + '')];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.getRecentWorldBattleRecords = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var lastTime, re;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lastTime = this.recentWorldRecordTs == 0 ? (0, Utils_1.getTimeStamp)() - 180 : this.recentWorldRecordTs;
                        return [4 /*yield*/, this.mediator.query(transition_1.TransitionEventType.Battles, {
                                "timestamp": { "$gt": lastTime },
                                '$orderBy': '-timestamp'
                            })];
                    case 1:
                        re = (_a.sent());
                        if (re) {
                            this.recentWorldRecordTs = re[0].timestamp;
                        }
                        callback(re !== null && re !== void 0 ? re : []);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeneralComponent.prototype.getDefenseBlockGenerals = function () {
        return (0, state_1.copyObj)(this.general.state.defenseBlockList);
    };
    GeneralComponent.prototype.getIconId = function () {
        return this.general.getIconId();
    };
    GeneralComponent.prototype.setIconId = function (iconId, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.SetIconId, {
            from: Throne.instance().username,
            iconId: iconId
        }, callback);
    };
    GeneralComponent.prototype.getMorale = function () {
        return this.general.getMorale();
    };
    GeneralComponent.prototype.getRecoverMoraleInfo = function () {
        return this.general.getRecoverMoraleInfo();
    };
    GeneralComponent.prototype.getMoraleBuff = function () {
        var percent = this.general.getMoralePercent();
        var re = {};
        re['attack'] = 1 + percent;
        re['defense'] = 1 + percent;
        re['load'] = 1 + percent;
        re['silver_product'] = 1 + percent;
        re['troop_product'] = 1 + percent;
        return re;
    };
    GeneralComponent.prototype.recoverMorale = function (resourceType, callback) {
        this.mediator.sendTransaction(Const_1.StateTransition.RecoverMorale, {
            from: Throne.instance().username,
            resourceType: resourceType
        }, callback);
    };
    GeneralComponent.prototype.getAttackTroop = function () {
        return this.general.getMaxAttackTroop();
    };
    GeneralComponent.prototype.getDefenseTroop = function () {
        return this.general.getMaxDefenseTroop();
    };
    GeneralComponent.prototype.getBattleRecordRedPoint = function () {
        if (this.battleRecordLocalTs != this.battleRecordGobalTs) {
            return true;
        }
        return false;
    };
    GeneralComponent.prototype.updateBattleRedPoint = function (timestamp) {
        this.mediator.profileSave(this.battleRecordProfileKey, timestamp + '');
        this.battleRecordLocalTs = timestamp;
    };
    return GeneralComponent;
}());
exports.GeneralComponent = GeneralComponent;
var ComponentType;
(function (ComponentType) {
    ComponentType[ComponentType["City"] = 1] = "City";
    ComponentType[ComponentType["General"] = 2] = "General";
    ComponentType[ComponentType["Map"] = 3] = "Map";
    ComponentType[ComponentType["Strategy"] = 4] = "Strategy";
    ComponentType[ComponentType["User"] = 5] = "User";
    ComponentType[ComponentType["Chain"] = 6] = "Chain";
})(ComponentType = exports.ComponentType || (exports.ComponentType = {}));
var InstanceStatus;
(function (InstanceStatus) {
    InstanceStatus[InstanceStatus["Null"] = 0] = "Null";
    InstanceStatus[InstanceStatus["Loading"] = 1] = "Loading";
    InstanceStatus[InstanceStatus["Ready"] = 2] = "Ready";
})(InstanceStatus = exports.InstanceStatus || (exports.InstanceStatus = {}));
var Throne = /** @class */ (function () {
    function Throne() {
        this.components = {};
        this.inited = false;
        this.instanceState = InstanceStatus.Null;
        this.version = "u516";
    }
    Throne.instance = function () {
        if (!Throne.throne) {
            Throne.throne = new Throne();
        }
        return this.throne;
    };
    Throne.prototype.init = function (obj, callback) {
        if (callback === void 0) { callback = function () { }; }
        return __awaiter(this, void 0, void 0, function () {
            var states, statesTest, wsmediator, mapId, serverTimeStamp, _a, _b, _c, _d, _e, _f, _g, _h, _j, mapId;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        if (this.instanceState == InstanceStatus.Null) {
                            this.instanceState = InstanceStatus.Loading;
                        }
                        else if (this.instanceState == InstanceStatus.Loading) {
                            return [2 /*return*/, {
                                    result: false,
                                    error: "throne-have-not-finish-init"
                                }];
                        }
                        else {
                            return [2 /*return*/, {
                                    result: true
                                }];
                        }
                        states = {};
                        statesTest = {};
                        this.username = obj['username'] ? obj['username'] : 'test';
                        this.seasonId = obj['seasonId'];
                        this.unionId = obj['unionId'] ? obj['unionId'] : 0;
                        this.wsUrl = obj["wsurl"] ? obj["wsurl"] : "ws://test.leagueofthrones.com/ws/".concat(this.username);
                        if (!(this.wsUrl && this.username != 'test')) return [3 /*break*/, 2];
                        wsmediator = new websocket_1.WebSocketMediator(this.wsUrl, obj);
                        if (obj['wsCloseCallback']) {
                            wsmediator.setWsCloseCallback(obj['wsCloseCallback']);
                        }
                        return [4 /*yield*/, wsmediator.init()];
                    case 1:
                        _k.sent();
                        this.mediator = wsmediator;
                        return [3 /*break*/, 3];
                    case 2:
                        mapId = State_1.InitState[Const_1.StateName.SeasonConfig].mapId;
                        this.mediator = new mediator_1.LocalMediator([this.username, 'test1'], mapId);
                        if (obj['unionId']) {
                            State_1.InitState[Const_1.StateName.General].unionId = obj['unionId'];
                        }
                        _k.label = 3;
                    case 3: return [4 /*yield*/, this.mediator.query(transition_1.TransitionEventType.TimeStamp, {})];
                    case 4:
                        serverTimeStamp = (_k.sent());
                        (0, Utils_1.setTimeOffset)(serverTimeStamp - (0, Utils_1.getTimeStamp)(0));
                        // init essensial states
                        states.username = 'index:' + this.username;
                        _a = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.City, ":").concat(this.username) }, {}, null)];
                    case 5:
                        _a.city = (_k.sent());
                        _b = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.General, ":").concat(this.username) }, {}, null)];
                    case 6:
                        _b.general = (_k.sent());
                        _c = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.Strategy, ":").concat(this.username) }, {}, null)];
                    case 7:
                        _c.strategy = (_k.sent());
                        // await Promise.all([
                        //   async () => {
                        //     states.city = (await this.mediator.queryState({ id: `${StateName.City}:${this.username}` }, {}, null)) as ICityState
                        //   },
                        //   async () => {
                        //     states.general = (await this.mediator.queryState({ id: `${StateName.General}:${this.username}` }, {}, null)) as IGeneralState
                        //   },
                        //   async () => {
                        //     states.strategy = (await this.mediator.queryState({ id: `${StateName.Strategy}:${this.username}` }, {}, null)) as IStrategyState
                        //   },
                        // ])
                        states.blocks = [];
                        _d = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.Activity) }, {}, null)];
                    case 8:
                        _d.activityState = (_k.sent());
                        _e = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.MapGlobalInfo) }, {}, null)];
                    case 9:
                        _e.mapGlobal = (_k.sent());
                        _f = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.SeasonConfig) }, {}, null)];
                    case 10:
                        _f.seasonState = (_k.sent());
                        _g = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.RewardGloablState) }, {}, null)];
                    case 11:
                        _g.rewardGlobalState = (_k.sent());
                        _h = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.TokenPriceInfo) }, {}, null)];
                    case 12:
                        _h.tokenPriceInfo = (_k.sent());
                        _j = states;
                        return [4 /*yield*/, this.mediator.queryState({ id: "".concat(Const_1.StateName.GlobalCod) }, {}, null)];
                    case 13:
                        _j.codsGlobal = (_k.sent());
                        console.log('createLogicEsential', states);
                        console.log('createLogicEsential city', states.city);
                        this.logicEssential = (0, creator_1.createLogicEsential)(states);
                        this.inited = true;
                        this.instanceState = InstanceStatus.Ready;
                        if (this.unionId != 0) {
                            this.mediator.sendTransaction(Const_1.StateTransition.SetUnionId, {
                                from: this.username,
                                unionId: this.unionId,
                                force: false
                            }, callback);
                        }
                        else {
                            mapId = State_1.InitState[Const_1.StateName.SeasonConfig].mapId;
                            callback({
                                result: {
                                    result: true,
                                    unionId: states.general.unionId,
                                    mapId: mapId,
                                    username: this.username
                                }
                            });
                            this.unionId = states.general.unionId;
                        }
                        if (this.logicEssential.city.state.firstLogin == -1) {
                            this.mediator.sendTransaction(Const_1.StateTransition.FirstLogin, {
                                from: this.username,
                            }, function () { });
                        }
                        this.components[ComponentType.Chain] = new chain_1.ChainComponent(this.mediator);
                        return [2 /*return*/];
                }
            });
        });
    };
    Throne.prototype.initComponent = function (typ, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var initRe, cityCom, generalCom, mapCom, strategyCom;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.init({})];
                    case 1:
                        initRe = _a.sent();
                        this.inited = true;
                        if (!initRe['result']) {
                            throw initRe['error'];
                        }
                        if (!(typ == ComponentType.City)) return [3 /*break*/, 3];
                        this.components[ComponentType.City] = new CityComponent("".concat(Const_1.StateName.City, ":").concat(this.username), this.mediator);
                        cityCom = this.components[ComponentType.City];
                        cityCom.setCity(this.logicEssential.city);
                        cityCom.setActivity(this.logicEssential.activity);
                        return [4 /*yield*/, cityCom.initRedPoint()];
                    case 2:
                        _a.sent();
                        callback(cityCom);
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(typ == ComponentType.General)) return [3 /*break*/, 5];
                        this.components[ComponentType.General] = new GeneralComponent("".concat(Const_1.StateName.General, ":").concat(this.username), this.mediator);
                        generalCom = this.components[ComponentType.General];
                        generalCom.setGeneral(this.logicEssential.general);
                        return [4 /*yield*/, generalCom.initRedPoint()];
                    case 4:
                        _a.sent();
                        callback(generalCom);
                        return [3 /*break*/, 6];
                    case 5:
                        if (typ == ComponentType.Map) {
                            this.components[ComponentType.Map] = new map_1.MapComponent(this.mediator);
                            mapCom = this.components[ComponentType.Map];
                            mapCom.setMap(this.logicEssential.map);
                            callback(mapCom);
                        }
                        else if (typ == ComponentType.Strategy) {
                            this.components[ComponentType.Strategy] = new strategy_1.StrategyComponent(this.mediator);
                            strategyCom = this.components[ComponentType.Strategy];
                            strategyCom.setStrategy(this.logicEssential.strategy);
                            callback(strategyCom);
                        }
                        else if (typ == ComponentType.Chain) {
                            callback(this.components[ComponentType.Chain]);
                        }
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return Throne;
}());
exports.Throne = Throne;
function example() {
    Throne.instance().initComponent(ComponentType.City, function (city) {
        console.log('City init');
        // bind button with action
        // button.onClick = () =>{
        //city.doUpgradeFacility()
        //      console.log(city.getAllUpgradeInfo(CityFacility.Home))
        // watch state update
        city.onStateUpdate(function (state) {
            // regenerate  ui state
            var facilities = city.getFacilityList();
            var resource = city.getResource();
            var uiState = { facilities: facilities, resource: resource };
            console.log("receive state", uiState);
            // rerender by new state
        });
        //update
    });
    Throne.instance().initComponent(ComponentType.General, (function (general) {
        general.onStateUpdate(function (state) {
            console.log("general", state);
        });
    }));
    Throne.instance()[ComponentType.City].doUpgradeFacility();
}
