import { subs } from '../utils/utils';
import { unionImagePath } from '../utils/ui-res';
import AppGame from '../const/app.json';
import mapList from './map_list.json';

import mapGDS from './7/gds.json';
import distancesConfig from './7/distances.json';

let rootDir = process.env.PUBLIC_URL;
// let rootDir = '//cdn.leagueofthrones.com';
let mapDir = rootDir + '/map-config/';

const getMapId = (seasonInfo)=> {
    let map_info = (seasonInfo || {})['map_info'] || {};
    return map_info['map_result'] || 1;
}

const getMapGDS = (mapId)=>{
    return {
        mapGDS,
        distancesConfig
    }
}

const getMapConfig =(mapId, isRotate)=>{
     let mapInfo = {};
    for(let item of mapList){
        if(item['map_id'] === mapId){
            mapInfo = item;
        }
    }

    const rows = mapInfo.rows;  //21
    const cols = mapInfo.cols;  //11
    let xAxis = cols;
    let yAxis = rows + 1;

    let topLeft = -50;
    const tileWidth = 204;
    const tileHeight = 116;
    
    let width = tileWidth*(cols - 0.2) - topLeft*2;
    let height = tileHeight*(rows + 1)/2 - topLeft*2;

    const screenW = Math.max(document.documentElement.clientWidth, 8);
    const screenH = Math.max(document.documentElement.clientHeight, 5);

    let mapWidth = screenW;
    let mapHeight = screenH;

    let mobileStr = '';
    let degNumber = 0;
    let graphicsSkew = 0;

    if(isRotate){
        xAxis = (rows + 1)/2;
        yAxis = cols*2;

        mapWidth = screenW;
        mapHeight = screenH;

        mobileStr = '.mobile';
        degNumber = 90;
        graphicsSkew = 22;

        width = tileHeight*(rows + 1)/2 - topLeft*2;
        height = tileWidth*(cols - 0.2);
    }

    let keys = ["mountains", "bgIndexs", "mapIndexs", "bgIndexsMobile", "mapIndexsMobile", "inits", "capitals", "ports"];
    for(let key of keys){
        if(typeof mapInfo[key] == 'string'){
            mapInfo[key] = JSON.parse(mapInfo[key]);
        }
    }

    return {
        mapId: mapId,
        mapCover: mapDir + mapId + '-cover.png?v=' + window.LeagueOfThronesConfig.version,
        bgIndexs: mapInfo['bgIndexs'],
        mapIndexs: mapInfo['mapIndexs'],
        mountains: mapInfo['mountains'],
        capitals: mapInfo['capitals'],
        capitalType: mapInfo['capitalType'],
        inits: mapInfo['inits'],
        ports: mapInfo['ports'],
        width: width,
        height: height,
        topLeft: topLeft,
        mapWidth: mapWidth,
        mapHeight: mapHeight,
        tileWidth: tileWidth,
        tileHeight: tileHeight,
        degNumber: degNumber,
        rows: rows,
        cols: cols,
        xAxis: xAxis,
        yAxis: yAxis,
        graphicsSkew: graphicsSkew,
        bg: {
            imagesNormal: mapDir + mapId + '-bg.png?v=' + window.LeagueOfThronesConfig.version,
            images: mapDir + mapId + '-bg' + mobileStr + '.png?v=' + window.LeagueOfThronesConfig.version,
            config: mapDir + mapId + '-bg' + mobileStr + '.json?v=' + window.LeagueOfThronesConfig.version
        },
        map: {
            imagesNormal: mapDir + mapId + '-map.png?v=' + window.LeagueOfThronesConfig.version,
            images: mapDir + mapId + '-map' + mobileStr + '.png?v=' + window.LeagueOfThronesConfig.version,
            config: mapDir + mapId + '-map' + mobileStr + '.json?v=' + window.LeagueOfThronesConfig.version
        },
        unions: {
            1: unionImagePath(1),
            2: unionImagePath(2),
            3: unionImagePath(3),
            4: unionImagePath(4),
            5: unionImagePath(5),
            6: unionImagePath(6)
        },
        rexdragplugin: mapDir + 'rexdragplugin.js'
    };
}

const getIdIndex = function(x, y, mapConfig, isRotate){
    if(isRotate){
        return getIdIndexMobile(x, y, mapConfig);
    }
    let { rows, cols } = mapConfig;
    return {
        x_id: (x - 5) * 2 + y % 2,
        y_id:  (rows - 1)/2 - y
    }
    // return {
    //     x_id: (x - 5)*2 + y%2,
    //     y_id: 10 - y
    // }
}

function getIdIndexMobile(x, y, mapConfig) {
    let { rows, cols } = mapConfig;
    let xMobile = (rows + 1)/2;
    let yMobile = cols*2 - 1;
    return {
        x_id: y - Math.floor(yMobile/2),
        y_id: x*2 - (xMobile - 1) + y%2
    }
}

const getOriginIndex = function(x_id, y_id, mapConfig){
    let { rows, cols } = mapConfig;
    let y = (rows - 1)/2 - y_id;
    return {
        x: (cols - 1 + x_id - Math.abs(x_id%2))/2,
        y: y
    }
}

const colors = {
    0: '0x000000',
    1: '0x39cd0c', 
    2: '0x23afff', //northwest
    3: '0xffa200',
    4: '0xff2424' //
};

const createOccupieds = function(){
    let cols = 11, rows = 41;
    let xAxis = (rows + 1)/2;
    let yAxis = cols*2;

    let data = {};
    for(var y = 0; y < yAxis; y++ ){
        for(var x = 0; x < xAxis; x++ ){
            let ids = getIdIndex(x, y, { cols, rows }, false);
            let xyIndex = ids.x_id + '^' + ids.y_id;
            data[xyIndex] = {
                unionId: Math.ceil(Math.random()*789999)%5,
                protectEndTime: new Date().getTime()/1000 + 1000000,
                attackEndTime: new Date().getTime()/1000 + 10000000
            };
        }
    }
    return data;
}

export {
    getMapId,
    getMapConfig,
    getMapGDS,
    colors,
    getIdIndex,
    getOriginIndex,
    createOccupieds
}