"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Boost = void 0;
var Const_1 = require("../Const");
var Const_2 = require("../Const");
var strategy_1 = require("./strategy");
var Boost = /** @class */ (function () {
    function Boost() {
        var _a, _b;
        this.city = {
            product: (_a = {},
                _a[Const_1.ResouceType.Silver] = 0,
                _a[Const_1.ResouceType.Troop] = 0,
                _a)
        };
        this.general = {
            product: (_b = {},
                _b[Const_1.ResouceType.Silver] = 0,
                _b[Const_1.ResouceType.Troop] = 0,
                _b)
        };
        this.map = {
            buff: []
        };
        this.troop = 0;
        this.maintainNeedTroop = -1;
        this.strategy = {
            store: false,
            protect: false,
            protect1: false
        };
    }
    Boost.prototype.setProduction = function (stateType, typ, value) {
        switch (stateType) {
            case Const_2.StateName.City:
                this.city.product[typ] = value;
                break;
            case Const_2.StateName.General:
                this.general.product[typ] = value;
                break;
        }
    };
    Boost.prototype.getProduction = function (typ) {
        var weight = 1;
        if (this.maintainNeedTroop > this.troop) {
            weight = Math.max(this.troop / this.maintainNeedTroop, 0.8);
        }
        return (this.city.product[typ] + this.general.product[typ]) * weight;
    };
    Boost.prototype.getSilverPosProduction = function () {
        var weight = 1;
        if (this.maintainNeedTroop > this.troop) {
            weight = Math.max(this.troop / this.maintainNeedTroop, 0.8);
        }
        return (this.city.product[Const_1.ResouceType.Silver] + this.general.product[Const_1.ResouceType.Silver] + this.troop) * weight;
    };
    Boost.prototype.getProductionStatus = function (typ) {
        var re = {
            maintain: true,
            normalProduction: this.city.product[typ] + this.general.product[typ]
        };
        if (this.maintainNeedTroop > this.troop) {
            re.maintain = false;
        }
        return re;
    };
    Boost.prototype.setTroop = function (troop, needTroop) {
        this.troop = troop;
        this.maintainNeedTroop = needTroop;
    };
    Boost.prototype.setMapBuff = function (list) {
        this.map.buff = list.concat();
    };
    Boost.prototype.getMapBuff = function () {
        return this.map.buff.concat();
    };
    Boost.prototype.setStrategyStatus = function (type, able) {
        if (type == strategy_1.StrategyType.Protect) {
            this.strategy.protect = able;
        }
        else if (type == strategy_1.StrategyType.Protect1) {
            this.strategy.protect1 = able;
        }
        else {
            this.strategy.store = able;
        }
    };
    Boost.prototype.getStrategyStatus = function (type) {
        if (type == strategy_1.StrategyType.Protect) {
            return this.strategy.protect;
        }
        else if (type == strategy_1.StrategyType.Protect1) {
            return this.strategy.protect1;
        }
        else {
            return this.strategy.store;
        }
    };
    return Boost;
}());
exports.Boost = Boost;
