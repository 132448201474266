import axios from 'axios';
import { subs } from '../utils/utils';

import { apiHost, wsHost, transactionLink } from '../const';

const timeout = 3*1000;
const getShortUrl = (data, callback) => {
    let api = apiHost + '/web/utils/shorturl';
    axios.post(api, data).then(function(res){
        res = res.data;
        res.code = 0;
        callback(res);
    }).catch(function (error) {
        callback({code:1});
    });
}

const getServerStatus = (callback) => {
    let api = apiHost + '/web/login';
    axios.get(api, { timeout }).then(function(res){
        res = res || {};
        let data = res.data || {};
        let isMaintenancing = !!data.MaintainMessage;
        callback({
            isMaintenancing: isMaintenancing,
            reason: data.MaintainMessage || ''
        });
    }).catch(function(err){
        callback({
            isMaintenancing: true,
            reason: ''
        });
    });
}

const getSeasonList = (callback) => {
    let api = apiHost + '/web/season/actives?r=' + Math.random();
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.list || [];
        // res = res.concat(res);
        callback(res);
    });
}

const getHisSeasonList = (callback) => {
    //limit = 20, page = 0, 1,,,,, 9
    let data = { page: 0 };
    data.r = Math.random();
    let api = apiHost + '/web/season/histories?r=%{r}&page=%{page}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.list || [];
        res.forEach(function(season){
            season.end = true;
        });
        callback(res);
    });
}


const getHisSeasonListAll = (callback) => {
    //limit = 20, page = 0, 1,,,,, 9
    let list = [];
    let times = 0;
    for(var i=0;i<10;i++){
        getHisSeasonListPage({page: i}, function(res){
            list = list.concat(res);
            times += 1;
            if(times >= 10){
                list = list.concat(list);
                callback(list);
            }
        });
    }
}

const setSeasonVote = (data, callback) => {
    let api = apiHost + '/web/season/map/vote';
    axios.post(api, data).then(function(res){
        res = res.data || {};
        callback(res);
    });
}

const getSeasonInfo = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/login?seasonId=%{seasonId}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data;
        // res.SeasonState = 'applying'; //preparation, applying, running, 'over'
        // res.SeasonState = 'running'; //mock to remove
        // res.SeasonId = 1;
        // res.StartTs = 19837783;
        res.seasonId = res.SeasonId;
        callback(res);
    });
}

const getUserScore = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/account/portfolio?address=%{address}&r=%{r}';
        api = subs(api, data);

    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        callback({});
    });;
}

const getSignMessage = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/login?seasonId=%{seasonId}&address=%{address}&r=%{r}';
        api = subs(api, data);

    axios.get(api).then(function(res){
        res = res.data;
        res.address = data.address;
        res.seasonId = data.seasonId;
        res.login = true;
        callback(res);
    });
}

const getNFTs = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/query_nft?seasonId=%{seasonId}&address=%{address}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        callback(res.data);
    }).catch(function(err){
        callback(err)
    });
}

const getUserSignupInfo = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/season/player_apply?address=%{address}&seasonId=%{seasonId}&r=%{r}';
        api = subs(api, data);

    axios.get(api).then(function(res){
        res = res.data;
        // res = {"Id":"64f323a985ddb144843fe7c0","sid":"prod-bsc-2023-08-31-1","owner":"0x04c535c9f175cb8980b43617fb480412c7e341e4","general_ids":[7,11],"camp":"3","nick_name":"leagueofthrones.bnb","nft_infos":{"11":{"nft_type":"SPACE ID","nft_id":"39470177479186642185478963052336703344955864321176576001998087291129693415646"},"7":{"nft_type":"The CR7 NFT Collection","nft_id":"1019300867622"}},"chain":"bsc"};
        callback(res);
    });
}

const getAllPlayersGeneralPortraits = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/aigc/general-portrait/all?seasonId=%{seasonId}&r=%{r}';
        api = subs(api, data);

    axios.get(api).then(function(res){
        res = res.data || {};
        res = res.Data || {};
        for(var prop in res){
            res[prop.toLowerCase()] = res[prop];
        }
        callback(res);
    }).catch(function(err){
        callback({})
    });;
}

const generateGeneralPortraits = (data, callback) => {
    /*
    data = { address, general_id }
    */ 
    let api = apiHost + '/web/aigc/general-portrait/generate';
    axios.post(api, data).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        callback({code:1});
        // console.log('generateGeneralPortraits', error);
    });
}

const generateGeneralPortraitsTest = (data, callback) => {
    /*
    data = { nft_type: Airose/Apex/SeibaraClub, nft_id }
    */ 
    let api = apiHost + '/web/aigc/general-portrait/test_generate';
    axios.post(api, data).then(function(res){
        res = res.data || {};
        callback(res);
    }).catch(function (error) {
        callback({code:1});
        // console.log('generateGeneralPortraitsTest', error);
    });
}

const generalPortraitConfirm = (data, callback) => {
    /*
    data = { address, general_id, image_key }
    */ 
    let api = apiHost + '/web/aigc/general-portrait/confirm';
    axios.post(api, data).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        callback({code:1});
        // console.log('generalPortraitConfirm', error);
    });
}

const getAllPlayers = (data, callback) => {
    data.r = Math.random();
    var api = apiHost + '/web/nameservice/mapping?seasonId=%{seasonId}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data;
        let list = res.List || res.list || [];
        callback(list);
    });
}

const getUnionPlayers = (data, callback) => {
    let api = apiHost + '/web/season/players?unionId=' + data.unionId +  '&seasonId=' + data.seasonId + '&r=' + Math.random();
    axios.get(api).then(function(res){
        res = res.data;
        let list = res.List || [];
        callback(list);
    });
}

const setShareRecord = (data, callback) => {
    /*
    data = {
        address
        action: upgrade_fortress/attack_territory
        platform: twitter/facebook
    }
    */ 
    let api = apiHost + '/web/account/activity/share';
    axios.post(api, data).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        // console.log('setShareRecord', error);
    });
}

const getShareRecords = (data, callback) => {
    data.r = Math.random();
    let api = apiHost + '/web/account/activity/share?seasonId=%{seasonId}&season_id=%{season_id}&address=%{address}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        // console.log('getShareRecords', error);
    });
}

const getMySeasons = (data, callback) => {
    data.r = Math.random();
    let api = apiHost + '/web/season/actives?chain=%{chainName}&address=%{address}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data;
        let applied_info = res.applied_info || [];
        let seasons = {};
        applied_info.forEach(function(item){
            seasons[item.sid] = item;
        });
        callback(seasons);
    }).catch(function (error) {
        callback({});
    });
}

const isUserSigned = (data, callback) => {
    data.r = Math.random();
    let api = apiHost + '/web/season/actives?chain=%{chainName}&address=%{address}&r=%{r}';
        api = subs(api, data);
    axios.get(api).then(function(res){
        res = res.data;
        let wallInfo = {};
        let applied_info = res.applied_info || [];
        let flag = false;
        applied_info.forEach(function(item){
            if(item.sid === data.seasonId){
                wallInfo = item;
                flag = true;
            }
        });
        wallInfo.flag = flag;
        callback(wallInfo);
    }).catch(function (error) {
        callback({});
        // console.log('getShareRecords', error);
    });
}

const privateKeyExport = (data, callback) => {
    let api = apiHost + '/web/dump_wallet';
        // api = subs(api, data);
    axios.post(api, data).then(function(res){
        res = res.data;
        callback(res);
    }).catch(function (error) {
        callback(null);
        // console.log('getShareRecords', error);
    });
}

export {
    getServerStatus,
    getSeasonList,
    getMySeasons,
    getHisSeasonList,
    getSeasonInfo,
    setSeasonVote,
    getUnionPlayers,
    getUserScore,
    getSignMessage,
    getNFTs,
    getAllPlayers,
    setShareRecord,
    getShareRecords,
    getUserSignupInfo,
    getAllPlayersGeneralPortraits,
    generateGeneralPortraits,
    generateGeneralPortraitsTest,
    generalPortraitConfirm,
    getShortUrl,
    isUserSigned,
    privateKeyExport
};