"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.General = exports.BattleType = exports.SkillType = exports.GeneralAbility = void 0;
var DataConfig_1 = require("../DataConfig");
var Const_1 = require("../Const");
var DataConfig_2 = require("../DataConfig");
var state_1 = require("../../Core/state");
var Utils_1 = require("../Utils");
var strategy_1 = require("./strategy");
var GeneralAbility;
(function (GeneralAbility) {
    GeneralAbility["Attack"] = "qualification_attack";
    GeneralAbility["Defense"] = "qualification_defense";
    GeneralAbility["Load"] = "qualification_load";
    GeneralAbility["Silver"] = "qualification_silver_product";
    GeneralAbility["Troop"] = "qualification_troop_recruit";
})(GeneralAbility = exports.GeneralAbility || (exports.GeneralAbility = {}));
var SkillType;
(function (SkillType) {
    SkillType["Attack"] = "attack";
    SkillType["Defense"] = "defense";
    SkillType["Load"] = "load";
    SkillType["Silver"] = "product";
    SkillType["Troop"] = "recruit";
})(SkillType = exports.SkillType || (exports.SkillType = {}));
var BattleType;
(function (BattleType) {
    BattleType["Attack"] = "attack";
    BattleType["Defense"] = "defense";
})(BattleType = exports.BattleType || (exports.BattleType = {}));
var General = /** @class */ (function () {
    function General(state, city, codsGlobal, seasonState) {
        this.state = state;
        this.codsGlobal = codsGlobal;
        this.config = DataConfig_2.GeneralConfigFromGDS;
        this.cityConfig = DataConfig_2.CityConfigFromGDS;
        this.vipConfig = DataConfig_2.vipConfigFromGDS;
        var mapId = seasonState.mapId;
        this.mapConfig = (0, DataConfig_1.getMapConfigFromGDS)(mapId);
        this.seasonState = seasonState;
        this.city = city;
    }
    General.prototype.setMap = function (map) {
        this.map = map;
    };
    General.prototype.setBoost = function (boost) {
        this.boost = boost;
    };
    /**
     * get the qualification of the general
     * @param id the id of the general
    */
    General.prototype.getGeneralQualification = function (id) {
        return this.config.qualification.get((id - 1).toString());
    };
    General.prototype.getSkillInfo = function (id) {
        return this.config.buff.get(id.toString());
    };
    General.prototype.getGeneralState = function (id) {
        return (0, state_1.copyObj)(this.state.generalList[id + ""]);
    };
    General.prototype.getAbleCount = function () {
        var count = 0;
        for (var id in this.state.generalList) {
            if (this.state.generalList[id].able) {
                count++;
            }
        }
        return count;
    };
    General.prototype.getMaxAbleCount = function () {
        return this.city.getGeneralMaxAble();
    };
    General.prototype.checkIdAble = function (id) {
        var len = Object.keys(this.config.qualification.configs).length;
        if (id > len || id <= 0) {
            return false;
        }
        if (!this.state.generalList[id + ""]) {
            return false;
        }
        return true;
    };
    General.prototype.getGeneralInfo = function (id) {
        var generalInfos = this.state.generalList;
        return generalInfos[id];
    };
    General.prototype.ableGeneral = function (id) {
        var _a;
        this.city.updateResource(Const_1.ResouceType.Silver);
        if (!this.checkIdAble(id)) {
            return {
                result: false,
                generalId: id,
                txType: Const_1.StateTransition.AbleGeneral,
                error: 'index-error'
            };
        }
        var count = this.getAbleCount();
        var generalInfo = this.getGeneralState(id);
        if (count < this.getMaxAbleCount()) {
            generalInfo.able = true;
        }
        this.state.update((_a = {},
            _a["generalList.".concat(id)] = generalInfo,
            _a));
        return {
            txType: Const_1.StateTransition.AbleGeneral,
            generalId: id,
            result: true
        };
    };
    General.prototype.disableGeneral = function (id) {
        var _a, _b;
        this.city.updateResource(Const_1.ResouceType.Silver);
        if (!this.checkIdAble(id)) {
            return {
                result: false,
                generalId: id,
                txType: Const_1.StateTransition.DisableGeneral,
                error: 'index-error'
            };
        }
        var generalInfo = this.getGeneralState(id);
        generalInfo.able = false;
        if (this.state.defense_general == id) {
            this.state.update((_a = {},
                _a["generalList.".concat(id)] = generalInfo,
                _a.defense_general = -1,
                _a));
        }
        else {
            this.state.update((_b = {},
                _b["generalList.".concat(id)] = generalInfo,
                _b));
        }
        return {
            generalId: id,
            txType: Const_1.StateTransition.DisableGeneral,
            result: true
        };
    };
    General.prototype.setDefenseGeneral = function (id) {
        if (!this.checkIdAble(id)) {
            return {
                result: false,
                generalId: id,
                txType: Const_1.StateTransition.SetDefenseGeneral,
                error: 'id-error'
            };
        }
        var generalInfo = this.getGeneralState(id);
        if (!generalInfo.able) {
            return {
                result: false,
                generalId: id,
                txType: Const_1.StateTransition.SetDefenseGeneral,
                error: 'general-not-able'
            };
        }
        if (!this.checkDefenseBlock(id)) {
            return {
                result: false,
                generalId: id,
                txType: Const_1.StateTransition.SetDefenseGeneral,
                error: 'id-error'
            };
        }
        this.state.update({
            defense_general: id
        });
        return {
            txType: Const_1.StateTransition.SetDefenseGeneral,
            generalId: id,
            result: true
        };
    };
    General.prototype.getGeneralUpgradeNeed = function (id, currentLevel, levelTo) {
        if (!this.checkIdAble(id)) {
            return 0;
        }
        var row = this.getGeneralQualification(id);
        var sumq = row.qualification_attack + row.qualification_load + row.qualification_silver_product + row.qualification_troop_recruit + row.qualification_defense;
        var re = 0;
        console.log('getGeneralUpgradeNeed', { sumq: sumq, currentLevel: currentLevel, levelTo: levelTo });
        for (var i = currentLevel; i < levelTo + 1; i++) {
            re += Math.ceil(20 * sumq * i);
            console.log('getGeneralUpgradeNeed for', { sumq: sumq, i: i, re: re });
        }
        console.log('getGeneralUpgradeNeed result', re);
        return re;
    };
    General.prototype.checkUpgradeGeneral = function (id) {
        if (!this.checkIdAble(id)) {
            return false;
        }
        var generalInfo = this.getGeneralState(id);
        var level = generalInfo.level;
        if (level == this.config.parameter.general_max_level) {
            return false;
        }
        var cost = this.getGeneralUpgradeNeed(id, level, level + 1);
        if (this.city.getResource(Const_1.ResouceType.Silver) >= cost) {
            return true;
        }
        return false;
    };
    General.prototype.getGeneralLevel = function (id) {
        if (this.checkIdAble(id)) {
            var generalInfo = this.getGeneralState(id);
            var level = generalInfo.level;
            return level;
        }
        return 1;
    };
    General.prototype.upgradeGeneral = function (id, levelTo) {
        var _a;
        this.city.updateResource(Const_1.ResouceType.Silver);
        var generalInfo = this.getGeneralState(id);
        var level = generalInfo.level;
        if (!this.checkIdAble(id)) {
            return {
                txType: Const_1.StateTransition.UpgradeGeneral,
                generalId: id,
                levelTo: levelTo,
                result: false,
                error: 'index-error'
            };
        }
        if (level == this.config.parameter.general_max_level) {
            return {
                txType: Const_1.StateTransition.UpgradeGeneral,
                generalId: id,
                levelTo: levelTo,
                result: false,
                error: 'general-level-is-max'
            };
        }
        if (levelTo <= level || levelTo > this.config.parameter.general_max_level) {
            return {
                txType: Const_1.StateTransition.UpgradeGeneral,
                generalId: id,
                levelTo: levelTo,
                result: false,
                error: 'levelTo is illeagel'
            };
        }
        var cost = this.getGeneralUpgradeNeed(id, level, levelTo);
        console.log('getGeneralUpgradeNeed general ', { id: id, level: level, levelTo: levelTo, cost: cost });
        generalInfo.level = levelTo;
        if (this.city.useSilver(cost)) {
            this.state.update((_a = {},
                _a["generalList.".concat(id)] = generalInfo,
                _a));
            return {
                result: true,
                txType: Const_1.StateTransition.UpgradeGeneral,
                generalId: id,
                levelTo: generalInfo.level
            };
        }
        return {
            txType: Const_1.StateTransition.UpgradeGeneral,
            generalId: id,
            levelTo: levelTo,
            result: false,
            error: 'silver-not-enough-error'
        };
    };
    General.prototype.getGeneralAbility = function (id, level, typ) {
        var row = this.getGeneralQualification(id);
        switch (typ) {
            case GeneralAbility.Attack:
            case GeneralAbility.Defense:
                return row[typ] * 10 * level;
            case GeneralAbility.Load:
                //qualification_load
                return row[typ] * 100 * level;
            case GeneralAbility.Silver:
                return parseFloat((40 * row[typ] * level).toFixed(2));
            case GeneralAbility.Troop:
                return parseFloat((0.4 * row[typ] * level).toFixed(2));
        }
    };
    General.prototype.getSkillUpdateNeed = function (generalId, skillIndex, level) {
        var row = this.getGeneralQualification(generalId);
        var skillId = row.general_skill[skillIndex];
        var buff = this.getSkillInfo(skillId);
        var cost = 0;
        switch (buff.buff_type) {
            case SkillType.Attack:
                cost = row.qualification_attack * level;
                break;
            case SkillType.Defense:
                cost = row.qualification_defense * level;
                break;
            case SkillType.Load:
                cost = row.qualification_load * level;
                break;
            case SkillType.Silver:
                cost = row.qualification_silver_product * level;
                break;
            case SkillType.Troop:
                cost = row.qualification_troop_recruit * level;
                break;
        }
        return cost;
    };
    General.prototype.getSkillValue = function (generalId, skillIndex, level) {
        var re = {
            'value_type': 0,
            'value': 0
        };
        var row = this.getGeneralQualification(generalId);
        var skillId = row.general_skill[skillIndex];
        var buff = this.getSkillInfo(skillId);
        re['value_type'] = buff.value_type;
        if (buff.value_type == 1) {
            //percent
            re['value'] = buff.buff_value * level;
        }
        else {
            //value
            switch (buff.buff_type) {
                case SkillType.Silver:
                    re['value'] = row.qualification_silver_product * 40 * level;
                    break;
                case SkillType.Troop:
                    re['value'] = row.qualification_troop_recruit * 0.4 * level;
                    break;
                case SkillType.Attack:
                    re['value'] = 10 * row.qualification_attack * level;
                    break;
                case SkillType.Defense:
                    re['value'] = 10 * row.qualification_defense * level;
                    break;
                case SkillType.Load:
                    re['value'] = 100 * row.qualification_load * level;
                    break;
            }
        }
        return re;
    };
    General.prototype.checkGeneralSkillUpgrade = function (generalId, skillIndex) {
        var generalInfo = this.getGeneralState(generalId);
        var generalLevel = generalInfo.level;
        var skillLevelLimit = Math.floor(generalLevel / 5) + 1;
        var level = generalInfo.skill_levels[skillIndex];
        if (level == this.config.parameter.general_skill_max_level) {
            return false;
        }
        var need = this.getSkillUpdateNeed(generalId, skillIndex, level);
        if (this.city.state.gold >= need && level < skillLevelLimit) {
            return true;
        }
        return false;
    };
    General.prototype.upgradeGeneralSkill = function (generalId, skillIndex) {
        var _a;
        this.city.updateResource(Const_1.ResouceType.Silver);
        var generalInfo = this.getGeneralState(generalId);
        var level = generalInfo.skill_levels[skillIndex];
        if (!this.checkGeneralSkillUpgrade(generalId, skillIndex)) {
            return {
                txType: Const_1.StateTransition.UpgradeGeneralSkill,
                generalId: generalId,
                skillIndex: skillIndex,
                levelTo: generalInfo.skill_levels[skillIndex],
                result: false,
                error: 'silver-not-enough-error'
            };
        }
        if (level == this.config.parameter.general_skill_max_level) {
            return {
                txType: Const_1.StateTransition.UpgradeGeneralSkill,
                generalId: generalId,
                skillIndex: skillIndex,
                levelTo: generalInfo.skill_levels[skillIndex],
                result: false,
                error: 'skill-is-max-level'
            };
        }
        var need = this.getSkillUpdateNeed(generalId, skillIndex, level);
        if (this.city.useGold(need)) {
            generalInfo.skill_levels[skillIndex] = level + 1;
            this.state.update((_a = {},
                _a["generalList.".concat(generalId)] = generalInfo,
                _a));
            return {
                result: true,
                txType: Const_1.StateTransition.UpgradeGeneralSkill,
                generalId: generalId,
                skillIndex: skillIndex,
                levelTo: generalInfo.skill_levels[skillIndex]
            };
        }
        return {
            txType: Const_1.StateTransition.UpgradeGeneralSkill,
            generalId: generalId,
            skillIndex: skillIndex,
            levelTo: generalInfo.skill_levels[skillIndex],
            result: false,
            error: 'silver-not-enough-error'
        };
    };
    General.prototype.getGeneralProduction = function (typ) {
        var mapBase = 0;
        var mapPercent = 0;
        var mapBuffList = this.boost.getMapBuff();
        var moralePercent = this.getMoralePercent();
        var tokenBuff = this.getTokenBuff();
        var username = (0, Utils_1.parseStateId)(this.state.getId()).username;
        var userScore = this.getUserScore(username);
        var vipBuffs = this.getVipBuffs(userScore);
        var productBuff = {
            'silver': vipBuffs['product'] || 0,
            'troop': vipBuffs['recruit'] || 0
        };
        for (var _i = 0, mapBuffList_1 = mapBuffList; _i < mapBuffList_1.length; _i++) {
            var mapBuff = mapBuffList_1[_i];
            var skillRow = this.getSkillInfo(mapBuff);
            if ((skillRow.buff_type == SkillType.Silver && typ == Const_1.ResouceType.Silver) ||
                (skillRow.buff_type == SkillType.Troop && typ == Const_1.ResouceType.Troop)) {
                if (skillRow['value_type'] == 1) {
                    mapPercent += skillRow.buff_value;
                }
                else {
                    mapBase += skillRow.buff_value;
                }
            }
        }
        var product = 0;
        for (var idstring in this.state.generalList) {
            var generalInfo = this.state.generalList[idstring];
            var id = parseInt(idstring);
            if (!generalInfo.able) {
                continue;
            }
            var row = this.getGeneralQualification(id);
            var baseProduct = 0;
            var percentProduct = 1;
            if (typ == Const_1.ResouceType.Silver) {
                baseProduct += this.getGeneralAbility(id, generalInfo.level, GeneralAbility.Silver);
            }
            else {
                baseProduct += this.getGeneralAbility(id, generalInfo.level, GeneralAbility.Troop);
            }
            for (var bi = 0; bi < row.general_skill.length; bi++) {
                var buff = this.getSkillInfo(row.general_skill[bi]);
                if ((typ == Const_1.ResouceType.Silver && buff.buff_type == SkillType.Silver) ||
                    (typ == Const_1.ResouceType.Troop && buff.buff_type == SkillType.Troop)) {
                    var skillValue = this.getSkillValue(id, bi, generalInfo.skill_levels[bi]);
                    if (skillValue['value_type'] == 1) {
                        percentProduct += skillValue['value'];
                    }
                    else {
                        baseProduct += skillValue['value'];
                    }
                }
            }
            product += (baseProduct + mapBase) * (percentProduct + mapPercent + moralePercent + tokenBuff + (productBuff[typ] || 0));
            console.log('product buff:', typ, productBuff[typ], { percentProduct: percentProduct, mapPercent: mapPercent, moralePercent: moralePercent, tokenBuff: tokenBuff });
        }
        return product;
    };
    General.prototype.getGeneralStamina = function (generalId) {
        var time = parseInt(new Date().getTime() / 1000 + '');
        var generalInfo = this.getGeneralState(generalId);
        var stamina = generalInfo.stamina;
        var maxStamina = this.config.qualification.get((generalId - 1).toString()).stamina;
        var realStamina = Math.floor((time - stamina.lastUpdate) / this.config.parameter.general_stamina_recovery) + stamina.value;
        if (realStamina >= maxStamina) {
            return maxStamina;
        }
        return realStamina;
    };
    General.prototype.updateGeneralStamina = function (generalId) {
        var _a, _b;
        var time = parseInt(new Date().getTime() / 1000 + '');
        var generalInfo = this.getGeneralState(generalId);
        var stamina = generalInfo.stamina;
        var maxStamina = this.config.qualification.get((generalId - 1).toString()).stamina;
        var realStamina = Math.floor((time - stamina.lastUpdate) / this.config.parameter.general_stamina_recovery) + stamina.value;
        if (realStamina > stamina.value && realStamina < maxStamina) {
            generalInfo.stamina = {
                lastUpdate: stamina.lastUpdate + (realStamina - stamina.value) * this.config.parameter.general_stamina_recovery,
                value: realStamina
            };
            this.state.update((_a = {},
                _a["generalList.".concat(generalId)] = generalInfo,
                _a));
        }
        else if (realStamina >= maxStamina) {
            generalInfo.stamina = {
                lastUpdate: time,
                value: maxStamina
            };
            this.state.update((_b = {},
                _b["generalList.".concat(generalId)] = generalInfo,
                _b));
        }
    };
    General.prototype.useGeneralStamina = function (generalId, amount) {
        var _a;
        this.updateGeneralStamina(generalId);
        var generalInfo = this.getGeneralState(generalId);
        if (this.getGeneralStamina(generalId) >= amount) {
            generalInfo.stamina.value -= amount;
            this.state.update((_a = {},
                _a["generalList.".concat(generalId)] = generalInfo,
                _a));
            return true;
        }
        return false;
    };
    General.prototype.updateBoost = function () {
        this.boost.setProduction(Const_1.StateName.General, Const_1.ResouceType.Silver, this.getGeneralProduction(Const_1.ResouceType.Silver));
        this.boost.setProduction(Const_1.StateName.General, Const_1.ResouceType.Troop, this.getGeneralProduction(Const_1.ResouceType.Troop));
    };
    General.prototype.getTokenBuff = function () {
        var tokenPriceInfo = this.map.tokenPriceInfo;
        var unions = {
            1: "BTC",
            2: "ETH",
            3: "USDT",
            4: "BNB"
        };
        var unionId = this.state.unionId;
        var token = unions[unionId] || '';
        if (token === '') {
            return 0;
        }
        var initial = tokenPriceInfo.initial || {};
        var current = tokenPriceInfo.current || {};
        var v1 = (initial[token] || 0) / 1 || 0;
        var v2 = (current[token] || 0) / 1 || 0;
        // console.log('getGeneralBattleStatus tokenBuff 1: ', {v1, v2, unionId, token, tokenPriceInfo});
        if (v1 === 0 || v2 === 0) {
            return 0;
        }
        var tokenBuff = (v2 - v1) / v1;
        tokenBuff = Math.min(tokenBuff, 5);
        console.log('token buff: ', { unionId: unionId, tokenPriceInfo: tokenPriceInfo, tokenBuff: tokenBuff });
        return tokenBuff;
    };
    General.prototype.getGeneralBattleStatus = function (generalId) {
        var _a, _b, _c, _d, _e, _f;
        var tokenBuff = this.getTokenBuff();
        var username = (0, Utils_1.parseStateId)(this.state.getId()).username;
        var userScore = this.getUserScore(username);
        var vipBuffs = this.getVipBuffs(userScore);
        var generalInfo = this.getGeneralState(generalId);
        if (generalId == -1) {
            var base_1 = (_a = {},
                _a[SkillType.Attack] = this.config.parameter.default_defense_general[0],
                _a[SkillType.Defense] = this.config.parameter.default_defense_general[1],
                _a[SkillType.Load] = this.config.parameter.default_defense_general[2],
                _a);
            var cityStatus_1 = this.city.getBattleStatus(1);
            var sum_1 = (_b = {},
                _b[SkillType.Attack] = base_1[SkillType.Attack] + cityStatus_1.attack,
                _b[SkillType.Defense] = base_1[SkillType.Defense] + cityStatus_1.defense,
                _b[SkillType.Load] = base_1[SkillType.Load],
                _b);
            return {
                sum: sum_1,
                base: base_1
            };
        }
        var generalLevel = generalInfo.level;
        var base = (_c = {},
            _c[SkillType.Attack] = this.getGeneralAbility(generalId, generalLevel, GeneralAbility.Attack),
            _c[SkillType.Defense] = this.getGeneralAbility(generalId, generalLevel, GeneralAbility.Defense),
            _c[SkillType.Load] = this.getGeneralAbility(generalId, generalLevel, GeneralAbility.Load),
            _c);
        var extraValue = (_d = {},
            _d[SkillType.Attack] = 0,
            _d[SkillType.Defense] = 0,
            _d[SkillType.Load] = 0,
            _d);
        var extraPercent = (_e = {},
            _e[SkillType.Attack] = 1 + tokenBuff + vipBuffs[SkillType.Attack],
            _e[SkillType.Defense] = 1 + tokenBuff + vipBuffs[SkillType.Defense],
            _e[SkillType.Load] = 1 + tokenBuff + vipBuffs[SkillType.Load],
            _e);
        console.log('extraPercent buff:', { extraPercent: extraPercent, tokenBuff: tokenBuff, vipBuffs: vipBuffs });
        var row = this.getGeneralQualification(generalId);
        var cityStatus = this.city.getBattleStatus(row.general_type);
        extraValue[SkillType.Attack] = cityStatus.attack;
        extraValue[SkillType.Defense] = cityStatus.defense;
        for (var i = 0; i < row.general_skill.length; i++) {
            var skillRow = this.getSkillInfo(row.general_skill[i]);
            var skillLevel = generalInfo.skill_levels[i];
            var value = this.getSkillValue(generalId, i, skillLevel);
            if (skillRow.buff_type == SkillType.Attack || skillRow.buff_type == SkillType.Defense || skillRow.buff_type == SkillType.Load) {
                if (value['value_type'] == 1) {
                    extraPercent[skillRow.buff_type] += value['value'];
                }
                else {
                    extraValue[skillRow.buff_type] += value['value'];
                }
            }
        }
        //  buff table miss
        var mapBuffList = this.boost.getMapBuff();
        for (var _i = 0, mapBuffList_2 = mapBuffList; _i < mapBuffList_2.length; _i++) {
            var mapBuff = mapBuffList_2[_i];
            var skillRow = this.getSkillInfo(mapBuff);
            if (skillRow.buff_type == SkillType.Attack || skillRow.buff_type == SkillType.Defense || skillRow.buff_type == SkillType.Load) {
                if (skillRow['value_type'] == 1) {
                    extraPercent[skillRow.buff_type] += skillRow.buff_value;
                }
                else {
                    extraValue[skillRow.buff_type] += skillRow.buff_value;
                }
            }
        }
        var moralePercent = this.getMoralePercent();
        for (var key in extraPercent) {
            extraPercent[key] += moralePercent;
        }
        var sum = (_f = {},
            _f[SkillType.Attack] = 0,
            _f[SkillType.Defense] = 0,
            _f[SkillType.Load] = 0,
            _f);
        for (var type in sum) {
            sum[type] = (extraValue[type] + base[type]) * extraPercent[type];
        }
        return {
            sum: sum,
            base: base
        };
    };
    General.prototype.getDefenseInfo = function () {
        var re = {
            generalType: 1,
            generalId: -1,
            generalLevel: 1,
            attack: 0,
            defense: 0,
            silver: 0,
            troop: 0,
            defenseMaxTroop: 0
        };
        re.silver = this.city.getResource(Const_1.ResouceType.Silver);
        re.troop = this.city.getResource(Const_1.ResouceType.Troop);
        re.defenseMaxTroop = this.getMaxDefenseTroop();
        var defenseGeneralId = -1;
        if (this.state.defense_general != -1) {
            defenseGeneralId = this.state.defense_general;
        }
        else {
            var maxValue = 1;
            for (var idstring in this.state.generalList) {
                var id = parseInt(idstring);
                var generalInfo = this.state.generalList[idstring];
                if (generalInfo.able && this.checkDefenseBlock(id)) {
                    var generalLevel = generalInfo.level;
                    var tempValue = this.getGeneralAbility(id, generalLevel, GeneralAbility.Attack) + this.getGeneralAbility(id, generalLevel, GeneralAbility.Defense);
                    if (tempValue > maxValue) {
                        maxValue = tempValue;
                        defenseGeneralId = id;
                    }
                }
            }
        }
        var status = this.getGeneralBattleStatus(defenseGeneralId);
        re.attack = status.sum[SkillType.Attack];
        re.defense = status.sum[SkillType.Defense];
        if (defenseGeneralId != -1) {
            var row = this.getGeneralQualification(defenseGeneralId);
            re.generalType = row.general_type;
            re.generalLevel = this.getGeneralLevel(defenseGeneralId);
        }
        else {
            re.generalType = this.config.parameter.default_defense_general[3];
        }
        re.generalId = defenseGeneralId;
        return re;
    };
    //1= infantry ；2=cavalry ；3=archer
    General.prototype.getGeneralTypeCoe = function (generalType1, generalType2) {
        var re = 1;
        if ((generalType1 - generalType2 + 3) % 3 == 1) {
            re = 1.2;
        }
        return re;
    };
    General.prototype.getMaxAttackTroop = function () {
        return Math.min(this.city.getResource(Const_1.ResouceType.Troop), this.city.getMaxAttackTroop());
    };
    General.prototype.getMaxGeneralLevel = function () {
        var generalInfos = this.state.generalList;
        var level = 1;
        for (var idstring in generalInfos) {
            var generalInfo = generalInfos[idstring];
            if (generalInfo.able) {
                level = Math.max(level, generalInfo.level);
            }
        }
        return level;
    };
    General.prototype.getMaxDefenseTroop = function () {
        return Math.min(this.city.getResource(Const_1.ResouceType.Troop), this.city.getMaxDefenseTroop());
    };
    General.prototype.battle = function (generalId, unionIds, defenseInfo, remainTroop, useStamina) {
        if (remainTroop === void 0) { remainTroop = -1; }
        if (useStamina === void 0) { useStamina = true; }
        var generalInfo = this.getGeneralState(generalId);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                txType: Const_1.StateTransition.Battle,
                error: 'generalid-error'
            };
        }
        if (useStamina) {
            var stamina = this.config.parameter.attack_player_need_stamina;
            if (!(this.useGeneralStamina(generalId, stamina))) {
                return {
                    result: false,
                    txType: Const_1.StateTransition.Battle,
                    error: 'general-stamina-error'
                };
            }
        }
        var status = this.getGeneralBattleStatus(generalId);
        var generalRow = this.getGeneralQualification(generalId);
        var generalType = generalRow.general_type;
        var ableTroop = this.getMaxAttackTroop();
        if (remainTroop === -1 && ableTroop === 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.Battle,
                error: 'do-not-have-troop'
            };
        }
        var attackInfo = {
            attack: status.sum[SkillType.Attack],
            defense: status.sum[SkillType.Defense],
            load: status.sum[SkillType.Load],
            generalType: generalType,
            ableTroop: remainTroop != -1 ? remainTroop : ableTroop
        };
        var remainTroopA = attackInfo.ableTroop;
        var coeA = this.getGeneralTypeCoe(generalType, defenseInfo.generalType);
        var randomA = 0.9 + (0, Utils_1.getRandom)() * 0.2;
        var remainTroopD = defenseInfo.defenseMaxTroop;
        var coeD = this.getGeneralTypeCoe(defenseInfo.generalType, generalType);
        var randomD = 0.9 + (0, Utils_1.getRandom)() * 0.2;
        var loopTime = 0;
        var attackUnionId = unionIds.attackUnionId, defenseUnionId = unionIds.defenseUnionId;
        console.log('updateInjuredTroops battle battlecity:', attackUnionId, defenseUnionId);
        console.log('updateInjuredTroops battle info:', { attackInfo: attackInfo, defenseInfo: defenseInfo });
        while (true) {
            loopTime++;
            if (loopTime > 10000) {
                throw "battle data error";
            }
            remainTroopD -= ((attackInfo.attack * randomA / defenseInfo.defense / randomD) * coeA * remainTroopA / 10);
            if (remainTroopD <= 0) {
                remainTroopD = 0;
                break;
            }
            remainTroopA -= ((defenseInfo.attack * randomD / attackInfo.defense / randomA) * coeD * remainTroopD / 10);
            if (remainTroopA <= 0) {
                remainTroopA = 0;
                break;
            }
        }
        var re = {
            result: true,
            win: false,
            attackTroopReduce: 0,
            defenseTroopReduce: 0,
            silverGet: 0,
            attackGloryGet: 0,
            defenseGloryGet: 0,
            records: [],
            txType: Const_1.StateTransition.Battle
        };
        console.log('updateInjuredTroops battle ids:', { attackUnionId: attackUnionId, defenseUnionId: defenseUnionId, remainTroopA: remainTroopA, remainTroopD: remainTroopD });
        re.attackTroopReduce = Math.floor(attackInfo.ableTroop - remainTroopA);
        var realDefenseTroop = defenseInfo.defenseMaxTroop > defenseInfo.troop ? defenseInfo.troop : defenseInfo.defenseMaxTroop;
        re.defenseTroopReduce = Math.floor(realDefenseTroop - remainTroopD);
        // re.attackGloryGet = Math.floor(Math.sqrt((attackInfo.attack + attackInfo.defense) *  re.defenseTroopReduce / 100 ))
        if (attackUnionId !== defenseUnionId) {
            re.attackGloryGet = Math.floor(Math.sqrt((defenseInfo.attack + defenseInfo.defense) * re.defenseTroopReduce) / 100);
        }
        // re.defenseGloryGet = Math.floor(Math.sqrt((defenseInfo.attack + defenseInfo.defense) * re.attackTroopReduce / 100 ))
        re.defenseGloryGet = Math.floor(Math.sqrt((attackInfo.attack + attackInfo.defense) * re.attackTroopReduce) / 100);
        if (remainTroopA > 0) {
            re.win = true;
            re.silverGet = attackInfo.load + Math.floor(remainTroopA) * this.config.parameter.troops_base_load;
        }
        else {
            re.win = false;
        }
        if (re.win) {
            if (attackUnionId !== defenseUnionId) {
                re.attackGloryGet += this.config.parameter.battle_victory_get_glory;
            }
        }
        else {
            re.defenseGloryGet += this.config.parameter.battle_victory_get_glory;
        }
        return re;
    };
    General.prototype._battleCod = function (generalId, unionIds, defenseInfo, remainTroop, useStamina) {
        if (remainTroop === void 0) { remainTroop = -1; }
        if (useStamina === void 0) { useStamina = true; }
        console.log('attackBlocksAroundCod battleCod 1:', remainTroop, unionIds);
        var generalInfo = this.getGeneralState(generalId);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                txType: Const_1.StateTransition.Battle,
                error: 'generalid-error'
            };
        }
        if (useStamina) {
            var stamina = this.config.parameter.attack_player_need_stamina;
            if (!(this.useGeneralStamina(generalId, stamina))) {
                return {
                    result: false,
                    txType: Const_1.StateTransition.Battle,
                    error: 'general-stamina-error'
                };
            }
        }
        var status = this.getGeneralBattleStatus(generalId);
        var generalRow = this.getGeneralQualification(generalId);
        var generalType = generalRow.general_type;
        // let ableTroop = this.getMaxAttackTroop()
        // if(remainTroop <= 0){
        //     return{
        //         result: false,
        //         txType: StateTransition.Battle,
        //         error: 'do-not-have-troop'
        //     }
        // }
        var attackInfo = {
            attack: status.sum[SkillType.Attack],
            defense: status.sum[SkillType.Defense],
            load: status.sum[SkillType.Load],
            generalType: generalType,
            ableTroop: remainTroop
        };
        var remainTroopA = attackInfo.ableTroop;
        var coeA = this.getGeneralTypeCoe(generalType, defenseInfo.generalType);
        var randomA = 0.9 + (0, Utils_1.getRandom)() * 0.2;
        var remainTroopD = defenseInfo.defenseMaxTroop;
        var coeD = this.getGeneralTypeCoe(defenseInfo.generalType, generalType);
        var randomD = 0.9 + (0, Utils_1.getRandom)() * 0.2;
        var loopTime = 0;
        var attackUnionId = unionIds.attackUnionId, defenseUnionId = unionIds.defenseUnionId;
        console.log('attackBlocksAroundCod battleCod battlecity:', attackUnionId, defenseUnionId);
        while (true) {
            loopTime++;
            if (loopTime > 10000) {
                throw "battle data error";
            }
            remainTroopD -= ((attackInfo.attack * randomA / defenseInfo.defense / randomD) * coeA * remainTroopA / 10);
            if (remainTroopD <= 0) {
                remainTroopD = 0;
                break;
            }
            remainTroopA -= ((defenseInfo.attack * randomD / attackInfo.defense / randomA) * coeD * remainTroopD / 10);
            if (remainTroopA <= 0) {
                remainTroopA = 0;
                break;
            }
        }
        var re = {
            result: true,
            win: false,
            attackTroopReduce: 0,
            defenseTroopReduce: 0,
            silverGet: 0,
            attackGloryGet: 0,
            defenseGloryGet: 0,
            records: [],
            txType: Const_1.StateTransition.Battle
        };
        re.attackTroopReduce = Math.floor(attackInfo.ableTroop - remainTroopA);
        var realDefenseTroop = defenseInfo.defenseMaxTroop > defenseInfo.troop ? defenseInfo.troop : defenseInfo.defenseMaxTroop;
        re.defenseTroopReduce = Math.floor(realDefenseTroop - remainTroopD);
        // re.attackGloryGet = Math.floor(Math.sqrt((attackInfo.attack + attackInfo.defense) *  re.defenseTroopReduce / 100 ))
        if (attackUnionId !== defenseUnionId) {
            re.attackGloryGet = Math.floor(Math.sqrt((defenseInfo.attack + defenseInfo.defense) * re.defenseTroopReduce) / 100);
        }
        // re.defenseGloryGet = Math.floor(Math.sqrt((defenseInfo.attack + defenseInfo.defense) * re.attackTroopReduce / 100 ))
        re.defenseGloryGet = Math.floor(Math.sqrt((attackInfo.attack + attackInfo.defense) * re.attackTroopReduce) / 100);
        if (remainTroopA > 0) {
            re.win = true;
            re.silverGet = attackInfo.load + Math.floor(remainTroopA) * this.config.parameter.troops_base_load;
        }
        else {
            re.win = false;
        }
        if (re.win) {
            if (attackUnionId !== defenseUnionId) {
                re.attackGloryGet += this.config.parameter.battle_victory_get_glory;
            }
        }
        else {
            re.defenseGloryGet += this.config.parameter.battle_victory_get_glory;
        }
        // this.city.useTroop(re.attackTroopReduce)
        // this.city.updateInjuredTroops(re.attackTroopReduce, 'battle')
        // console.log('updateInjuredTroops battle.attackTroopReduce', re);
        console.log('attackBlocksAroundCod battleCod 2 result:', remainTroop, re);
        return re;
    };
    General.prototype.healTroops = function (typ, amount) {
        console.log('healTroops:', typ, amount);
        if (typ === 'silver') {
            return this.city.healTroopsBySilver(amount);
        }
        if (typ === 'gold') {
            return this.city.healTroopsByGold(amount);
        }
    };
    //username === enamy
    General.prototype.spyForEnamy = function (username, generalId) {
        if (username == '') {
            return {
                result: false,
                txType: Const_1.StateTransition.SpyEnamy,
                err: 'username require'
            };
        }
        var stamina = this.config.parameter.spy_need_stamina;
        if (!(this.useGeneralStamina(generalId, stamina))) {
            return {
                result: false,
                txType: Const_1.StateTransition.SpyEnamy,
                error: 'general-stamina-error'
            };
        }
        //beacuse of no mediator, spy data in callback by index.ts(async spyEnamy)
        return {
            result: true,
            txType: Const_1.StateTransition.SpyEnamy
        };
    };
    General.prototype.createCod = function (blockInfo, userInfo) {
        var cods = this.codsGlobal.cods;
        var unionId = this.state.unionId;
        var username = userInfo.username, generalId = userInfo.generalId;
        username = username.toLowerCase();
        var x_id = blockInfo.x_id, y_id = blockInfo.y_id;
        var codId = 'block_' + x_id + '_' + y_id;
        var codItem = cods[codId] || {};
        console.log('cod create:', unionId, blockInfo, userInfo);
        console.log('cod create list:', codId, ':', codItem['creator'], codItem);
        if (codItem['creator']) {
            return {
                result: false,
                data: blockInfo,
                error: 'only one assembly in each block',
                txType: Const_1.StateTransition.CreateCod
            };
        }
        var generalInfo = this.getGeneralState(generalId);
        console.log('cod create generalInfo:', generalInfo);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                txType: Const_1.StateTransition.CreateCod,
                error: 'generalid-error'
            };
        }
        var ifCanAttack = this.map.checkIfCanAttack(x_id, y_id);
        console.log('cod create checkIfCanAttack:', x_id, y_id, ', result: ', ifCanAttack);
        if (!ifCanAttack) {
            return {
                result: false,
                data: blockInfo,
                error: 'block cannot be attack',
                txType: Const_1.StateTransition.CreateCod
            };
        }
        var stamina = this.config.parameter.assembly_need_stamina;
        var useGeneralStamina = this.useGeneralStamina(generalId, stamina);
        console.log('cod create stamina:', stamina, useGeneralStamina);
        if (!useGeneralStamina) {
            return {
                result: false,
                data: { stamina: stamina },
                error: 'not enough stamina',
                txType: Const_1.StateTransition.CreateCod
            };
        }
        var assemble_last_times = this.config.parameter.assemble_last_times;
        // const assemblyTroops = 23000;
        var assemblyLevel = this.city.state.facilities[Const_1.CityFacility.Assembly][0];
        var assemblyTroops = this.cityConfig.facilityConfig[Const_1.CityFacility.Assembly].get(assemblyLevel - 1 + '').assemble_troops;
        var attackTroops = this.getMaxAttackTroop();
        console.log('cod create assembly gds:', { assemble_last_times: assemble_last_times, assemblyLevel: assemblyLevel, assemblyTroops: assemblyTroops, attackTroops: attackTroops });
        var time = (0, Utils_1.getTimeStamp)();
        var codData = {
            codId: codId,
            creator: username,
            createTime: time,
            unionId: unionId,
            troopTotal: assemblyTroops + attackTroops,
            troopNow: 0,
            lastTime: assemble_last_times,
            generalId: generalId,
            members: [],
            membersMap: {},
            updateTime: -1,
            blockInfo: blockInfo
        };
        cods[codId] = codData;
        this.codsGlobal.update({
            cods: cods
        });
        console.log('cod create codsGlobal finish 1:', this.codsGlobal.cods, codData);
        this.joinCod(codId, { username: username, generalId: generalId });
        console.log('cod create codsGlobal finish 2:', this.codsGlobal.cods);
        return {
            result: true,
            data: codData,
            txType: Const_1.StateTransition.CreateCod
        };
    };
    General.prototype.isCodCanCanel = function (codId, username) {
        username = username.toLowerCase();
        var cods = this.codsGlobal.cods;
        var codItem = cods[codId] || {};
        if (codItem.creator === username) {
            return true;
        }
        return false;
    };
    General.prototype.cancelCod = function (codId, username) {
        username = username.toLowerCase();
        var cods = this.codsGlobal.cods;
        var codItem = cods[codId] || {};
        console.log('cod cancel:', codId, username);
        console.log('cod cancel list:', cods);
        var isCodCanCanel = this.isCodCanCanel(codId, username);
        if (!isCodCanCanel) {
            return {
                result: false,
                data: codItem,
                error: 'assembly not exist or current user not creator',
                txType: Const_1.StateTransition.CancelCod
            };
        }
        var res = this.endCod(codId);
        console.log('cod cancel codsGlobal finish 2:', res, this.codsGlobal.cods);
        return {
            result: true,
            data: res,
            txType: Const_1.StateTransition.CancelCod
        };
    };
    General.prototype.endCod = function (codId) {
        var cods = this.codsGlobal.cods;
        cods[codId] = {};
        delete cods[codId];
        this.codsGlobal.update({
            cods: cods
        });
        console.log('cod endCod:', codId, this.codsGlobal.cods);
        return {
            codId: codId
        };
    };
    General.prototype.checkUserCanJoinedCod = function (codId, userInfo) {
        var unionId = this.state.unionId;
        var cods = this.codsGlobal.cods;
        var codItem = cods[codId] || {};
        var username = userInfo.username;
        username = username.toLowerCase();
        console.log('cod checkUserJoinedCod:', unionId, codId, userInfo);
        console.log('cod checkUserJoinedCod:', codItem);
        if (!codItem['creator']) {
            return {
                result: false,
                joined: false,
                canJoin: false,
                data: codItem,
                error: 'assembly not exist',
            };
        }
        if (codItem.unionId !== unionId) {
            return {
                result: false,
                joined: false,
                canJoin: false,
                data: codItem,
                error: 'not in same camp',
            };
        }
        var members = codItem.members || [];
        var membersObj = {};
        var index = -1;
        members.forEach(function (member, i, arr) {
            membersObj[member.username] = member;
            if (member.username === username) {
                index = i;
            }
        });
        if (membersObj[username]) {
            return {
                result: false,
                joined: true,
                canJoin: false,
                error: 'just allow join once',
                joinInfo: membersObj[username],
                data: codItem,
                index: index
            };
        }
        return {
            result: true,
            joined: false,
            canJoin: true,
            data: codItem
        };
    };
    General.prototype.joinCod = function (codId, userInfo) {
        var unionId = this.state.unionId;
        var cods = this.codsGlobal.cods;
        var codItem = cods[codId];
        var username = userInfo.username, generalId = userInfo.generalId;
        username = username.toLowerCase();
        console.log('cod join:', unionId, codId, userInfo);
        console.log('cod join list:', cods);
        var isCanJoined = this.checkUserCanJoinedCod(codId, userInfo);
        console.log('cod join isJoined:', isCanJoined);
        if (!isCanJoined.canJoin) {
            return {
                result: false,
                data: isCanJoined.data,
                error: isCanJoined.error,
                txType: Const_1.StateTransition.JoinCod
            };
        }
        var troopTotal = codItem.troopTotal, troopNow = codItem.troopNow, _a = codItem.members, members = _a === void 0 ? [] : _a, _b = codItem.membersMap, membersMap = _b === void 0 ? {} : _b, blockInfo = codItem.blockInfo;
        var x_id = blockInfo.x_id, y_id = blockInfo.y_id;
        if (troopNow >= troopTotal) {
            return {
                result: false,
                data: codItem,
                error: 'troops is full',
                txType: Const_1.StateTransition.JoinCod
            };
        }
        var generalInfo = this.getGeneralState(generalId);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                data: { generalInfo: generalInfo },
                txType: Const_1.StateTransition.JoinCod,
                error: 'generalid-error'
            };
        }
        if (!this.checkDefenseBlock(generalId)) {
            return {
                result: false,
                data: { generalInfo: generalInfo },
                txType: Const_1.StateTransition.JoinCod,
                error: 'general is undering defense or assembly'
            };
        }
        var attackTroops = this.getMaxAttackTroop();
        console.log('cod join attackTroops:', attackTroops);
        // let troops = 87;
        attackTroops = Math.min(attackTroops, troopTotal - troopNow);
        if (attackTroops <= 0) {
            return {
                result: false,
                data: { attackTroops: attackTroops, generalInfo: generalInfo },
                txType: Const_1.StateTransition.JoinCod,
                error: 'troop-not-enough'
            };
        }
        this.city.useTroop(attackTroops);
        this.opCodGeneralId(generalId, 'lock', codItem);
        var time = (0, Utils_1.getTimeStamp)();
        var joinData = {
            username: username,
            generalId: generalId,
            troops: attackTroops,
            joinTime: time
        };
        members.push(joinData);
        membersMap[username] = joinData;
        codItem.members = members;
        codItem.membersMap = membersMap;
        codItem.troopNow = codItem.troopNow + attackTroops;
        codItem.updateTime = time;
        cods[codId] = codItem;
        this.codsGlobal.update({
            cods: cods
        });
        console.log('cod join finish:', this.codsGlobal.cods);
        return {
            result: true,
            data: codItem,
            txType: Const_1.StateTransition.JoinCod
        };
    };
    General.prototype.quitCod = function (codId, userInfo) {
        var unionId = this.state.unionId;
        var username = userInfo.username;
        username = username.toLowerCase();
        var cods = this.codsGlobal.cods;
        var codItem = cods[codId];
        console.log('cod quit:', unionId, codId, username);
        console.log('cod quit list:', cods);
        var isCanJoined = this.checkUserCanJoinedCod(codId, userInfo);
        console.log('cod quit isJoined:', isCanJoined, codItem);
        if (!isCanJoined.joined) {
            return {
                result: false,
                data: isCanJoined.data,
                error: isCanJoined.error || 'assembly error or not in assembly',
                txType: Const_1.StateTransition.QuitCod
            };
        }
        var type = userInfo.type || '';
        if (type !== 'byCancel' && codItem.creator === username) {
            return {
                result: false,
                data: codItem,
                error: 'creator not allow quit',
                txType: Const_1.StateTransition.QuitCod
            };
        }
        var joinInfo = isCanJoined.joinInfo;
        var index = isCanJoined.index;
        var generalId = joinInfo.generalId;
        var troops = joinInfo.troops;
        this.opCodGeneralId(generalId, 'release', {});
        var membersMap = codItem.membersMap;
        delete membersMap[username];
        codItem.membersMap = membersMap;
        var members = codItem.members;
        var members2 = members.splice(index, 1);
        console.log('cod quit members:', members, ' members2: ', members2, ' index: ', index);
        codItem.members = members;
        codItem.troopNow = codItem.troopNow - troops;
        this.city.useTroop(-1 * troops);
        var time = (0, Utils_1.getTimeStamp)();
        codItem.updateTime = time;
        cods[codId] = codItem;
        this.codsGlobal.update({
            cods: cods
        });
        console.log('cod quit codItem:', codItem);
        console.log('cod quit finish:', cods);
        return {
            result: true,
            data: codItem,
            txType: Const_1.StateTransition.QuitCod
        };
    };
    General.prototype.getCodGeneralIds = function (id) {
        var codGeneralIdsMap = this.state.codGeneralIdsMap || {};
        if (id) {
            return codGeneralIdsMap[id];
        }
        return codGeneralIdsMap;
    };
    General.prototype.opCodGeneralId = function (generalId, typ, codItem) {
        var codGeneralIdsMap = this.state.codGeneralIdsMap || {};
        if (typ === 'lock') {
            codGeneralIdsMap[generalId] = codItem;
        }
        if (typ === 'release') {
            delete codGeneralIdsMap[generalId];
        }
        this.state.update({
            codGeneralIdsMap: codGeneralIdsMap
        });
    };
    General.prototype.getCodList = function () {
        var cods = this.codsGlobal.cods;
        var codList = [];
        var unionId = this.state.unionId;
        for (var id in cods) {
            var _unionId = cods[id]['unionId'];
            if (_unionId === unionId) {
                codList.push(cods[id]);
            }
        }
        console.log('cod getCodList:', { unionId: unionId }, cods, codList);
        return { codList: codList, cods: cods };
        // callback(codList);
    };
    General.prototype.getCodDetail = function (codId) {
        var cods = this.codsGlobal.cods;
        return cods[codId] || {};
    };
    //should trigger when defense general change
    General.prototype.updateDefenseInfo = function () {
        var defenseInfoId = this.state.id.replace(Const_1.StateName.General, Const_1.StateName.DefenderInfo);
        var defenderInfo = this.getDefenseInfo();
        defenderInfo['unionId'] = this.state.unionId;
        defenderInfo['iconId'] = this.state.iconId;
        defenderInfo['glory'] = this.state.glory;
        defenderInfo['username'] = (0, Utils_1.parseStateId)(defenseInfoId).username;
        defenderInfo['fortressLevel'] = this.city.state.facilities[Const_1.CityFacility.Fortress][0];
        defenderInfo['isProtected'] = this.boost.getStrategyStatus(strategy_1.StrategyType.Protect) || this.boost.getStrategyStatus(strategy_1.StrategyType.Protect1) || this.isNewPlayerProtect();
        new state_1.State({ id: defenseInfoId }, this.state.getWatcher()).update(defenderInfo);
    };
    General.prototype.getDefenseBlockInfo = function (generalId, troops) {
        var attackinfo = this.getGeneralBattleStatus(generalId);
        var row = this.getGeneralQualification(generalId);
        var username = (0, Utils_1.parseStateId)(this.state.getId()).username;
        var re = {
            username: username,
            generalId: generalId,
            generalLevel: this.getGeneralLevel(generalId),
            generalType: row.general_type,
            attack: attackinfo.sum[SkillType.Attack],
            defense: attackinfo.sum[SkillType.Defense],
            troops: troops ? troops : this.getMaxDefenseTroop(),
            unionId: this.state.unionId,
            iconId: this.state.iconId
        };
        return re;
    };
    General.prototype.checkDefenseBlock = function (generalId) {
        if (this.state.defense_general == generalId) {
            return false;
        }
        for (var _i = 0, _a = this.state.defenseBlockList; _i < _a.length; _i++) {
            var info = _a[_i];
            if (info.generalId == generalId) {
                return false;
            }
        }
        var generalCod = this.getCodGeneralIds(generalId);
        if (generalCod) {
            return false;
        }
        return true;
    };
    General.prototype.defenseBlock = function (generalId, x_id, y_id) {
        var generalInfo = this.getGeneralState(generalId);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                txType: Const_1.StateTransition.DefenseBlock,
                error: 'generalid-error'
            };
        }
        if (!this.checkDefenseBlock(generalId)) {
            return {
                result: false,
                txType: Const_1.StateTransition.DefenseBlock,
                error: 'one-block-can-only-defense-once'
            };
        }
        var stamina = this.config.parameter.defense_plots_need_stamina;
        if (!(this.useGeneralStamina(generalId, stamina))) {
            return {
                result: false,
                txType: Const_1.StateTransition.DefenseBlock,
                error: 'general-stamina-error'
            };
        }
        var troop = this.getMaxDefenseTroop();
        if (troop <= 0) {
            return {
                result: false,
                txType: Const_1.StateTransition.DefenseBlock,
                error: 'troop-not-enough'
            };
        }
        this.city.useTroop(troop);
        // this.city.updateInjuredTroops(troop, 'battle')
        // console.log('updateInjuredTroops defenseBlock', troop);
        var defenseList = this.state.defenseBlockList;
        defenseList.push({
            generalId: generalId,
            x_id: x_id,
            y_id: y_id
        });
        this.state.update({
            'defenseBlockList': defenseList
        });
        return {
            result: true,
            txType: Const_1.StateTransition.DefenseBlock,
            troops: troop
        };
    };
    General.prototype.miningBlock = function (generalId, times) {
        var generalInfo = this.getGeneralState(generalId);
        if (!(this.checkIdAble(generalId) && generalInfo.able)) {
            return {
                result: false,
                txType: Const_1.StateTransition.MiningBlock,
                error: 'generalid-error'
            };
        }
        var stamina = this.config.parameter.gather_need_stamina * times;
        if (!(this.useGeneralStamina(generalId, stamina))) {
            return {
                result: false,
                txType: Const_1.StateTransition.MiningBlock,
                error: 'general-stamina-error'
            };
        }
        return {
            txType: Const_1.StateTransition.MiningBlock,
            result: true
        };
    };
    General.prototype.cancelDefenseBlock = function (generalId, remainTroop) {
        var defenseList = this.state.defenseBlockList;
        for (var i = 0; i < defenseList.length; i++) {
            var info = defenseList[i];
            if (info.generalId == generalId) {
                defenseList.splice(i, 1);
                break;
            }
        }
        this.state.update({
            'defenseBlockList': defenseList
        });
        this.city.useTroop(-remainTroop);
    };
    General.prototype.transferTransRecord = function (record) {
        var _a;
        var username = (0, Utils_1.parseStateId)(this.state.getId()).username;
        username = username.toLowerCase();
        var type = BattleType.Attack;
        var result = true;
        var myInfo;
        var enemyInfo;
        if (record.attackInfo.username.toLowerCase() == username) {
            type = BattleType.Attack;
            result = record.result;
            myInfo = record.attackInfo;
            enemyInfo = record.defenseInfo;
        }
        else {
            type = BattleType.Defense;
            result = !record.result;
            myInfo = record.defenseInfo;
            enemyInfo = record.attackInfo;
        }
        var row = this.mapConfig.get(record.blockInfo.x_id, record.blockInfo.y_id);
        // || { x_id: -1, y_id: -1, type: -1, parameter: -1 };
        var newBlockInfo = {
            x_id: row.x_id,
            y_id: row.y_id,
            type: row.type,
            parameter: row.parameter,
            durabilityReduce: (_a = record.blockInfo.durabilityReduce) !== null && _a !== void 0 ? _a : 0
        };
        var re = {
            myInfo: myInfo,
            enemyInfo: enemyInfo,
            blockInfo: newBlockInfo,
            leader: record.leader,
            result: result,
            type: type,
            timestamp: record.timestamp,
            recordType: record.recordType
        };
        return re;
    };
    General.prototype.addGlory = function (count) {
        var nowCount = this.state.glory;
        this.state.update({
            'glory': nowCount + count
        });
    };
    General.prototype.addUserScores = function (scores) {
        var userScores = this.state.userScores;
        for (var username in scores) {
            userScores[username.toLowerCase()] = scores[username];
        }
        this.state.update({
            'userScores': userScores
        });
        console.log('userScores 2:', this.state.userScores);
    };
    General.prototype.getUserScore = function (username) {
        if (!username) {
            return 0;
        }
        var userScores = this.state.userScores || {};
        var address = username.toLowerCase();
        var score = userScores[address] || 0;
        console.log('getUserScore: ', address, score);
        return score;
    };
    General.prototype.getVipBuffs = function (userScore) {
        var scores = this.vipConfig['config'];
        var minScore = scores[0].score;
        var maxScore = scores[scores.length - 1].score;
        if (userScore >= maxScore) {
            var _buffs_1 = scores[scores.length - 1];
            var buffs_1 = __assign({}, _buffs_1);
            buffs_1.add_general_id = buffs_1.add_general_id || [];
            console.log('vip buff 1: ', { userScore: userScore, buffs: buffs_1, scores: scores });
            return buffs_1;
        }
        var _buffs;
        for (var i = 0; i < scores.length - 1; i++) {
            if (userScore >= scores[i].score && userScore < scores[i + 1].score) {
                _buffs = scores[i];
            }
        }
        var buffs = __assign({}, _buffs);
        buffs.add_general_id = buffs.add_general_id || [];
        console.log('vip buff 2: ', { userScore: userScore, buffs: buffs, scores: scores });
        return buffs;
    };
    General.prototype.addextraGeneral = function (ids) {
        console.log('addextraGeneral ', ids);
        ids = ids || [];
        if (ids.length === 0) {
            return;
        }
        var generalInfos = this.state.generalList;
        var time = (0, Utils_1.getTimeStamp)();
        for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
            var id = ids_1[_i];
            if (generalInfos[id + ''] != undefined) {
                continue;
            }
            var row = this.getGeneralQualification(id);
            if (row == undefined) {
                continue;
            }
            var generalInfo = {
                id: row.general_id,
                level: 1,
                able: false,
                skill_levels: new Array(3).fill(1),
                stamina: {
                    value: row.stamina,
                    lastUpdate: time
                }
            };
            console.log('addextraGeneral id:', id, ' generalInfo:', generalInfo);
            generalInfos[id + ""] = generalInfo;
        }
        this.state.update({ 'generalList': generalInfos });
        console.log('addextraGeneral result ok:', this.state.generalList, generalInfos);
    };
    General.prototype.getIconId = function () {
        return this.state.iconId;
    };
    General.prototype.setIconId = function (id) {
        if (!this.checkIdAble(id)) {
            return {
                result: false,
                txType: Const_1.StateTransition.SetIconId,
                error: 'does-not-have-this-icon'
            };
        }
        this.state.update({ 'iconId': id });
        return {
            result: true,
            txType: Const_1.StateTransition.SetIconId
        };
    };
    General.prototype.getMorale = function () {
        var morale = this.state.morale.value;
        if (morale <= DataConfig_1.normalMorale) {
            return Math.max(morale, DataConfig_1.minMorale);
        }
        else {
            var time = (0, Utils_1.getTimeStamp)();
            var reduceNumber = Math.floor((time - this.state.morale.lastUpdate) / DataConfig_1.moraleReduceGap);
            if (reduceNumber < 0) {
                throw "time error when reduce morale";
            }
            return Math.max(DataConfig_1.normalMorale, morale - reduceNumber);
        }
    };
    General.prototype.offsetMorale = function (amount) {
        var time = (0, Utils_1.getTimeStamp)();
        var morale = this.getMorale() + amount;
        morale = Math.max(morale, DataConfig_1.minMorale);
        morale = Math.min(morale, DataConfig_1.maxMorale);
        this.state.update({
            morale: {
                value: morale,
                lastUpdate: time
            }
        });
    };
    General.prototype.getMoralePercent = function () {
        var morale = this.getMorale();
        return morale / 100 - 1;
    };
    General.prototype.getRecoverMoraleInfo = function () {
        var morale = this.getMorale();
        var re = {
            silverUse: 0,
            silverAble: true,
            goldUse: 0,
            goldAble: true
        };
        if (morale >= DataConfig_1.normalMorale) {
            return re;
        }
        re.silverUse = this.boost.getSilverPosProduction() * (DataConfig_1.normalMorale - morale) * 0.15;
        re.silverAble = this.city.getResource(Const_1.ResouceType.Silver) >= re.silverUse ? true : false;
        re.goldUse = this.config.parameter.recovery_one_morale_need_gold * (DataConfig_1.normalMorale - morale);
        re.goldAble = this.city.state.gold >= re.goldUse ? true : false;
        return re;
    };
    General.prototype.recoverMorale = function (type) {
        var morale = this.getMorale();
        if (morale >= DataConfig_1.normalMorale) {
            return {
                result: false,
                txType: Const_1.StateTransition.RecoverMorale,
                error: 'no-need-to-recover-morale'
            };
        }
        var info = this.getRecoverMoraleInfo();
        if (type == Const_1.RecoverMoraleType.Gold) {
            if (info.goldAble) {
                this.city.useGold(info.goldUse);
                this.offsetMorale(DataConfig_1.normalMorale - morale);
                return {
                    txType: Const_1.StateTransition.RecoverMorale,
                    result: true
                };
            }
            else {
                return {
                    result: false,
                    txType: Const_1.StateTransition.RecoverMorale,
                    error: 'gold-is-not-enough'
                };
            }
        }
        else if (type == Const_1.RecoverMoraleType.Silver) {
            if (info.silverAble) {
                this.city.useSilver(info.silverUse);
                this.offsetMorale(DataConfig_1.normalMorale - morale);
                return {
                    txType: Const_1.StateTransition.RecoverMorale,
                    result: true
                };
            }
            else {
                return {
                    result: false,
                    txType: Const_1.StateTransition.RecoverMorale,
                    error: 'silver-is-not-enough'
                };
            }
        }
        return {
            result: false,
            txType: Const_1.StateTransition.RecoverMorale,
            error: 'undefined-type'
        };
    };
    General.prototype.isNewPlayerProtect = function () {
        var time = (0, Utils_1.getTimeStamp)();
        if ((this.city.state.firstLogin == -1 || time - this.city.state.firstLogin < this.config.parameter.new_player_protect_times)
            && this.state.lastBattle == -1) {
            return true;
        }
        return false;
    };
    General.prototype.setLastBattle = function () {
        var time = (0, Utils_1.getTimeStamp)();
        this.state.update({ lastBattle: time });
    };
    return General;
}());
exports.General = General;
