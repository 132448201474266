import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
// import { prepareData } from "../toolkit/helpers";

import { getVipSilverBuff } from 'throne-underlying';

import { apiHost, wsHost } from '../const';
import * as API from './api';
import * as Contract from '../wallets/contract';
import * as EthFuns from '../wallets/ethereum';
import { logTrace } from '../utils/log'
import { NFTConfig } from '../const/';

export const getSeasonList = createAsyncThunk("season/list", async () => {
    const response = await new Promise((resolve, reject) => {
        API.getSeasonList(function(res){
            resolve(res);
        });
    });
    return response;
});

export const getHisSeasonList = createAsyncThunk("season/his/list", async () => {
    const response = await new Promise((resolve, reject) => {
        API.getHisSeasonList(function(res){
            resolve(res);
        });
    });
    return response;
});

export const getSeasonInfo = createAsyncThunk("season/info", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getSeasonInfo(data, function(res){
            // res.SeasonState = 'applying'; //preparation, applying, running, 'over'
            // res.SeasonState = 'running'; //mock to remove
            // res.SeasonId = 1;
            // res.StartTs = 19837783;
            res.seasonBasicInfo = data.seasonBasicInfo;
            res.seasonId = data.seasonId;
            resolve(res);
        });
    });
    return response;
});

export const getSeasonDetail = createAsyncThunk("season/detail", async (data) => {
    let address = data.address;
    let seasonId = data.seasonId;
    let seasonBasicInfo = data.seasonBasicInfo || {};
    let count = 0;

    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        API.getSignMessage(data, function(res){
            res.seasonBasicInfo = seasonBasicInfo;
            res.seasonId = seasonId;

            // res.seasonStatus = {};
            // res.signUpInfo = {};
            // resolve(res);

            if(seasonBasicInfo['cross_chain_protocol'] === 'zeta'){
                API.isUserSigned({
                    chainName: seasonBasicInfo.chain,
                    seasonId,
                    address
                }, function(signInfo){
                    signInfo.unionId = signInfo.flag ? -1 : 0;
                    res.signUpInfo = signInfo;
                    count += 1;
                    if(count === 2){
                        resolve(res);
                    }
                });
                Contract.getZetaSeasonStatus({
                    seasonId: seasonId,
                    contract_addr: seasonBasicInfo.contract_addr,
                    chain: seasonBasicInfo.chain
                }, function(result){
                    res.seasonStatus = result;
                    count += 1;
                    if(count >= 2){
                        resolve(res);
                    }
                });
            }else{
                Contract.getSignUpInfo({
                    seasonId: seasonId,
                    address: address
                }, function(result){
                    res.signUpInfo = result;
                    count += 1;
                    if(count === 2){
                        resolve(res);
                    }
                });
                Contract.getSeasonStatus({
                    seasonId: seasonId
                }, function(result){
                    res.seasonStatus = result;
                    count += 1;
                    if(count >= 2){
                        resolve(res);
                    }
                });
            }
        });
    });
    return response;
});

export const enterGame = createAsyncThunk("user/connect", async (data, crossInfo) => {
    let { address, seasonId, message } = data;
    // console.log({data, message, address});
    let chain = crossInfo.chain_name || '';

    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        EthFuns.personalSign(data, function(res){
            if(res.code !== 'ok'){
                resolve({
                    seasonId: seasonId,
                    wsurl: '',
                    message: data.Message,
                    address: address,
                    chain: chain,
                    ready: false,
                    data: res,
                    back: true,
                    code: res.code
                });
                return;
            }
            let sign = res.data;
            let wsurl = wsHost + '/ws/' + address + '/' + seasonId + '?sign=' + sign + '&message=' + message;
            if(chain){
                wsurl += '&chain=' + chain;
            }
            wsurl += '&r=' + Math.random();
            console.log('personalSign', {sign, data, wsurl});
            resolve({
                seasonId: seasonId,
                wsurl: wsurl,
                message: data.Message,
                address: address,
                chain: chain,
                data: res,
                back: true,
                ready: true
            });
        });
    });
    return response;
});

export const signUpGame = createAsyncThunk("game/signup", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        var { userInfo, NFTs, unionId, seasonId, chain, isCrossChain, registeryFee } = data;
        var nft1TokenId = (NFTs[0] || {}).id || 0;
        var nft2TokenId = (NFTs[1] || {}).id || 0;
        let { crossInfo = {}, seasonBasicInfo = {} } = data;

        let cross_chain_protocol = seasonBasicInfo.cross_chain_protocol || ''; 
        let tss_address = crossInfo.tss_address;
        let contract_addr = seasonBasicInfo.contract_addr;
        let value = crossInfo.signup_fee - 0 + registeryFee.value;

        if(isCrossChain){
            const txData = EthFuns.prepareData(
                contract_addr,
                ["uint8","string","uint256","uint256","uint256"],  // signupaction=1,seasonId,unionId,nft1,nft2
                ["1", seasonId , unionId, nft1TokenId, nft2TokenId]
            );
            EthFuns.sendTransaction({
                wallet: data.wallet,
                from: data.from,
                to: tss_address, 
                data: txData, 
                value
            }, function(result){
                // console.log('cross_chain_protocol result:', result);
                resolve({seasonId, result});    
            })
            return;
        }

        var address = userInfo.address;
        
        // console.log('Contract.signUp', { NFTs, unionId}, { chain, address, seasonId, nft1TokenId, nft2TokenId, unionId })
        Contract.signUp({ address, seasonId, NFTs, nft1TokenId, nft2TokenId, unionId, chain, registeryFee }, function(result){
            resolve({seasonId, result});    
        });
    });
    return response;
});

export const getUserNFTs = createAsyncThunk("nft/list", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        const { address, seasonId } = data;
        API.getNFTs(data, function(res){
            let NFTs = {};
            let nftList = res.List || [];

            nftList.forEach(function(item){
                let type = item.NftType;
                    type = type.split(' ').join('');
                    type = type.toLowerCase();
                let image = (item.ImageUrl || [])[0] || '';
                let ids = item.ItemIds || [];
                let id = ids[0] || 0;
                let name = (NFTConfig.nfts[type] || {}).name || '';
                let address = item.Address.toLowerCase();

                NFTs[address] = { id, type, name, image, address };
            });

            Contract.getNFTAddresses({ seasonId }, function(res){
                // console.log('getNFTAddresses', seasonId, res);
                let result = [];
                res = res || [];
                res.forEach(function(address){
                    address = address.toLowerCase();
                    result.push(NFTs[address] || {});
                });
                console.log('getNFTAddresses:', {res, seasonId, NFTs, result});
                resolve({
                    seasonId: seasonId, 
                    NFTs: result
                });
            });
        });
    });
    return response;
});

export const getUserLevel = createAsyncThunk("user/level", async (data) => {
    const response = await new Promise((resolve, reject) => {
        const { address, seasonId } = data;
        API.getUserScore({ address }, function(res){
            res = res || {};
            let data = res.data || {};
            let attributes = data.attributes || {};
            let total = attributes.total || {};
            var accountRatingToken = total.positions || 0;
                accountRatingToken = Math.floor(accountRatingToken*100)/100;

            var accountRatingNFT = 0;
            let nftInfo = res.nft_info || {};
            let nftList = nftInfo.data || [];
            nftList.forEach(function(nftItem){
                let attributes = nftItem.attributes || {};
                accountRatingNFT += attributes.value || 0;
            });
                accountRatingNFT = Math.floor(accountRatingNFT*100)/100;

            var accountRating = accountRatingToken + accountRatingNFT;

            let testUsers = {
            };
            if(testUsers[address.toLowerCase()]){
                accountRating = testUsers[address.toLowerCase()];
                accountRatingToken = Math.ceil(Math.random()*9999)%99 + 10;
                accountRatingNFT = accountRating - accountRatingToken;
            }

            let vipBuffs = getVipSilverBuff(accountRating);
            
            resolve({
                address,
                accountRating,
                accountRatingToken,
                accountRatingNFT,
                vipBuffs
            });
        });
    });
    return response;
});

export const getUnionPlayers = createAsyncThunk("union/players/list", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getUnionPlayers(data, function(res){
            resolve({
                seasonId: data.seasonId,
                players: res
            });
        });
    });
    return response;
});

export const getAllUnionPlayers = createAsyncThunk("union/all/players/list", async (data) => {
    const response = await new Promise((resolve, reject) => {
        var times = 0;
        var count = 4;
        var result = {
            1: [],
            2: [],
            3: [],
            4: [],
            ready: true
        };
        for(var i=0;i< count;i++){
            (function(i){
                API.getUnionPlayers({
                    seasonId: data.seasonId,
                    unionId: i + 1
                }, function(res){
                    result[i + 1] = res;
                    times += 1;
                    if(times === count){
                        resolve({
                            seasonId: data.seasonId,
                            players: result
                        });
                    }
                });
            })(i);
        }
    });
    return response;
});

export const getAllPlayers = createAsyncThunk("players/all", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getAllPlayers(data, function(res){
            resolve({
                seasonId: data.seasonId,
                players: res
            });
        });
    });
    return response;
});

export const setShareRecord = createAsyncThunk("share/record/set", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.setShareRecord(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const getShareRecords = createAsyncThunk("share/records/get", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getShareRecords(data, function(res){
            res = res || {};
            resolve(res);
        });
    });
    return response;
});

export const getRewardBalance = createAsyncThunk("reaward/amount/get", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        Contract.getRewardBalance(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const withdrawMyBalance = createAsyncThunk("reaward/claim", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        Contract.withdrawMyBalance(data, function(res){
            res.back = true;
            resolve(res);
        });
    });
    return response;
});

export const _adapter = createEntityAdapter();

const initialState = _adapter.getInitialState({
    userLevel: {},
    seasonList: [],
    seasonHisList: [],
    currentSeasonId: '',
    seasonDetail: { login: false, signUpInfo: {}, seasonStatus: {} },
    seasonInfo: {},
    sign: { ready: false },
    signUpSuccess: {},
    userCount: { total: 200 },
    userNFTs: {},
    unionPlayers: {},
    allUnionPlayers: {
        1: [],
        2: [],
        3: [],
        4: [],
        ready: false
    },
    allPlayers: {},
    shareRecords: {},
    rewardBalance: 0,
    lotBalance: 0,
    withdrawMyBalanceResult: {},
    walletSelectedInfo: {}
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUserResult: function(state, action){
            state.withdrawMyBalanceResult = {};
            state.signUpSuccess = {};
            state.sign.back = false;
        },
        setWalletSelectedInfo: function(state, action){
            let info = action.payload;
            state.walletSelectedInfo = info || {};
        }
    },
    extraReducers: builder => {
        builder.addCase(getSeasonList.fulfilled, (state, action) => {
            let seasonList = action.payload;
            state.seasonList = seasonList;
            logTrace(seasonList, 'season.list.fulfilled');
        });
        builder.addCase(getHisSeasonList.fulfilled, (state, action) => {
            let seasonList = action.payload;
            state.seasonHisList = seasonList;
            logTrace(seasonList, 'season.his.list.fulfilled');
        });
        builder.addCase(getSeasonInfo.fulfilled, (state, action) => {
            let seasonInfo = action.payload;
            state.currentSeasonId = seasonInfo.seasonId;
            state.seasonInfo[seasonInfo.seasonId] = seasonInfo;
            logTrace(seasonInfo, 'season.info.fulfilled');
        });
        builder.addCase(getSeasonDetail.fulfilled, (state, action) => {
            let seasonDetail = action.payload;
                seasonDetail.ready = true;
            state.currentSeasonId = seasonDetail.seasonId;
            state.seasonDetail[seasonDetail.seasonId] = seasonDetail;
            logTrace(seasonDetail, 'season.detail.fulfilled');
        });
        builder.addCase(enterGame.fulfilled, (state, action) => {
            let sign = action.payload;
            state.sign = sign;
            state.currentSeasonId = sign.seasonId;
            logTrace(sign, 'user.enterGame.fulfilled');
        });
        builder.addCase(signUpGame.fulfilled, (state, action) => {
            let { result, seasonId } = action.payload;
                result.back = true;
            state.signUpSuccess[seasonId] = result;
            logTrace(result, 'user.signup.fulfilled');
        });
        builder.addCase(getUserNFTs.fulfilled, (state, action) => {
            let { seasonId, NFTs } = action.payload;
            state.userNFTs[seasonId] = NFTs;
            logTrace(NFTs, 'user.NFTs.fulfilled');
        });
        builder.addCase(getUnionPlayers.fulfilled, (state, action) => {
            let { seasonId, players } = action.payload;
            state.unionPlayers[seasonId] = players;
            logTrace(players, 'getUnionPlayers.fulfilled');
        });
        builder.addCase(getAllUnionPlayers.fulfilled, (state, action) => {
            let { seasonId, players } = action.payload;
            state.allUnionPlayers[seasonId] = players;
            logTrace(players, 'allUnionPlayers.fulfilled');
        });
        builder.addCase(getAllPlayers.fulfilled, (state, action) => {
            let { seasonId, players } = action.payload;
            let allPlayers = {};
            players.forEach(function(player){
                let address = player.username || player.address || player.account;
                    address = address.toLowerCase();
                if(address){
                    allPlayers[address] = player.name || '';
                }
            });
            state.allPlayers[seasonId] = allPlayers;
            logTrace({players, allPlayers}, 'getAllPlayers.fulfilled');
        });
        builder.addCase(setShareRecord.fulfilled, (state, action) => {
            let data = action.payload;
        });
        builder.addCase(getShareRecords.fulfilled, (state, action) => {
            let records = action.payload;
            state.shareRecords = records;
        });
        builder.addCase(getUserLevel.fulfilled, (state, action) => {
            let userLevel = action.payload;
            state.userLevel = userLevel;
        });
        builder.addCase(getRewardBalance.fulfilled, (state, action) => {
            let { rewardBalance = 0, lotBalance = 0 } = action.payload;
            // rewardBalance = '12643720665623666619';
            rewardBalance = Math.floor(rewardBalance/Math.pow(10, 16))/Math.pow(10,2);
            lotBalance = Math.floor(lotBalance/Math.pow(10, 16))/Math.pow(10,2);
            state.rewardBalance = rewardBalance;
            state.lotBalance = lotBalance;
        });
        builder.addCase(withdrawMyBalance.fulfilled, (state, action) => {
            let withdrawMyBalanceResult = action.payload;
            state.withdrawMyBalanceResult = withdrawMyBalanceResult;
        });
    }
});

export const { 
    resetUserResult, setWalletSelectedInfo
} = userSlice.actions

export default userSlice.reducer;