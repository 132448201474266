"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTestBattleTransRecord = exports.LocalMediator = void 0;
var Const_1 = require("../Const");
var mediator_1 = require("../../Core/mediator");
var state_1 = require("../../Core/state");
var transition_1 = require("./transition");
var State_1 = require("../State");
var statemanger_1 = require("./statemanger");
var protocol_1 = require("./Websocket/protocol");
var Utils_1 = require("../Utils");
function getInitState(username, mapId, wather) {
    var _a;
    var cityStateId = "".concat(Const_1.StateName.City, ":").concat(username);
    var generalStateId = "".concat(Const_1.StateName.General, ":").concat(username);
    var strategyStateId = "".concat(Const_1.StateName.Strategy, ":").concat(username);
    var InitState = (0, State_1.GetInitState)('mediator.getInitState');
    return _a = {},
        _a[cityStateId] = new state_1.State(__assign({ id: cityStateId }, InitState[Const_1.StateName.City]), wather).unsderlying(),
        _a[generalStateId] = new state_1.State(__assign({ id: generalStateId }, InitState[Const_1.StateName.General]), wather).unsderlying(),
        _a[strategyStateId] = new state_1.State(__assign({ id: strategyStateId }, InitState[Const_1.StateName.Strategy]), wather).unsderlying(),
        _a;
}
function getGlobleState(mapId, wather) {
    var _a;
    var re = {};
    var InitState = (0, State_1.GetInitState)('mediator.getGlobleState');
    re = Object.assign(re, (_a = {},
        _a[Const_1.StateName.MapGlobalInfo] = new state_1.State(__assign({ id: [Const_1.StateName.MapGlobalInfo] }, InitState[Const_1.StateName.MapGlobalInfo]), wather).unsderlying(),
        _a[Const_1.StateName.SeasonConfig] = new state_1.State(__assign({ id: [Const_1.StateName.SeasonConfig] }, InitState[Const_1.StateName.SeasonConfig]), wather).unsderlying(),
        _a[Const_1.StateName.RewardGloablState] = new state_1.State(__assign({ id: [Const_1.StateName.RewardGloablState] }, InitState[Const_1.StateName.RewardGloablState]), wather).unsderlying(),
        _a[Const_1.StateName.Activity] = new state_1.State(__assign({ id: [Const_1.StateName.Activity] }, InitState[Const_1.StateName.Activity]), wather).unsderlying(),
        _a));
    var mapState = (0, State_1.GetMapState)(mapId);
    for (var id in mapState) {
        re[id] = new state_1.State(mapState[id], wather).unsderlying();
    }
    return re;
}
var LocalMediator = /** @class */ (function (_super) {
    __extends(LocalMediator, _super);
    function LocalMediator(username, mapId) {
        var _this = _super.call(this) || this;
        var obj = {};
        for (var _i = 0, username_1 = username; _i < username_1.length; _i++) {
            var name_1 = username_1[_i];
            obj = Object.assign(obj, getInitState(name_1, mapId, _this));
        }
        obj = Object.assign(obj, getGlobleState(mapId, _this));
        _this.transitionHandler = new transition_1.TransitionHandler(_this, (0, statemanger_1.GenerateMemoryLoadStateFunction)(obj));
        _this.seqNum = 0;
        return _this;
    }
    LocalMediator.prototype.onStateChange = function (modify, state) {
        state &&
            this.notifyState({ id: state.getId() }, __assign(__assign({}, state), { context: this.ctx }));
    };
    LocalMediator.prototype.query = function (typ, args) {
        var re = [];
        var record1 = {
            attackInfo: {
                generalId: 1,
                generalLevel: 2,
                generalType: 1,
                username: 'test',
                troopReduce: 1000,
                silverGet: 100,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            defenseInfo: {
                generalId: -1,
                generalLevel: 2,
                generalType: 1,
                username: 'test1',
                troopReduce: 1500,
                silverGet: -100,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            leader: '',
            recordType: transition_1.BattleRecordType.City,
            blockInfo: {
                x_id: 2,
                y_id: 2,
                durabilityReduce: 0
            },
            timestamp: 0,
            txHash: (0, Utils_1.getTxHash)(),
            result: true,
        };
        var record2 = {
            attackInfo: {
                generalId: 1,
                generalLevel: 2,
                generalType: 1,
                username: 'test',
                troopReduce: 1500,
                silverGet: 0,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            defenseInfo: {
                generalId: 2,
                generalLevel: 2,
                generalType: 2,
                username: 'test1',
                troopReduce: 1000,
                silverGet: 0,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            leader: '',
            recordType: transition_1.BattleRecordType.City,
            blockInfo: {
                x_id: 2,
                y_id: 2,
                durabilityReduce: 0
            },
            timestamp: 1,
            txHash: (0, Utils_1.getTxHash)(),
            result: false,
        };
        var record3 = {
            attackInfo: {
                generalId: 1,
                generalLevel: 2,
                generalType: 1,
                username: 'test',
                troopReduce: 1000,
                silverGet: 0,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            leader: '',
            recordType: transition_1.BattleRecordType.City,
            defenseInfo: {
                generalId: 2,
                generalLevel: 2,
                generalType: 2,
                username: 'test1',
                troopReduce: 1500,
                silverGet: 0,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            blockInfo: {
                x_id: -1,
                y_id: -1,
                durabilityReduce: 0
            },
            timestamp: 2,
            txHash: (0, Utils_1.getTxHash)(),
            result: true,
        };
        var record4 = {
            attackInfo: {
                generalId: 1,
                generalLevel: 2,
                generalType: 1,
                username: 'test',
                troopReduce: 1500,
                silverGet: -100,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            leader: '',
            defenseInfo: {
                generalId: 2,
                generalLevel: 2,
                generalType: 2,
                username: 'test1',
                troopReduce: 1000,
                silverGet: 100,
                gloryGet: 100,
                unionId: 1,
                iconId: -1
            },
            recordType: transition_1.BattleRecordType.City,
            timestamp: 3,
            blockInfo: {
                x_id: -1,
                y_id: -1,
                durabilityReduce: 0
            },
            txHash: (0, Utils_1.getTxHash)(),
            result: false,
        };
        if (typ == Const_1.StateName.DefenderInfo) {
            if (args['username'] == undefined) {
                re = [
                    {
                        username: 'test',
                        generalId: 1,
                        generalLevel: 2,
                        generalType: 3,
                        attack: 1000,
                        defense: 200,
                        silver: 100000,
                        troop: 10000,
                        unionId: 1,
                        glory: 500,
                        defenseMaxTroop: 100,
                        fortressLevel: 1
                    },
                    {
                        username: 'test1',
                        generalId: -1,
                        generalLevel: 1,
                        generalType: 1,
                        attack: 1000,
                        defense: 200,
                        silver: 9899,
                        troop: 10000,
                        unionId: 1,
                        glory: 500,
                        defenseMaxTroop: 100,
                        fortressLevel: 1
                    }
                ];
            }
            else {
                re = [
                    {
                        username: 'test1',
                        generalId: -1,
                        generalLevel: 1,
                        generalType: 1,
                        attack: 1000,
                        defense: 200,
                        silver: 9899,
                        troop: 10000,
                        unionId: 1,
                        glory: 500,
                        defenseMaxTroop: 100,
                        fortressLevel: 1
                    }
                ];
            }
        }
        else if (typ == transition_1.TransitionEventType.Battles) {
            re = [record1, record2, record3, record4];
        }
        else if (typ == Const_1.StateName.BlockInfo) {
            var blocks = args['id']['$in'];
            for (var _i = 0, blocks_1 = blocks; _i < blocks_1.length; _i++) {
                var key = blocks_1[_i];
                var sid = {
                    id: key
                };
                var blockstate = this.transitionHandler.stateManger.get(sid);
                if (blockstate) {
                    re.push(blockstate);
                }
            }
        }
        else if (typ == transition_1.TransitionEventType.TimeStamp) {
            return new Promise(function (resolve, reject) {
                resolve((0, Utils_1.getTimeStamp)(3));
            });
        }
        //TODO:mock result here
        return new Promise(function (resolve, reject) {
            resolve(re);
        });
    };
    LocalMediator.prototype.queryState = function (sid, args, callback) {
        var state = this.transitionHandler.stateManger.get(sid);
        if (callback) {
            callback(state);
        }
        else {
            return new Promise(function (resolve, reject) {
                if (state) {
                    resolve(state);
                }
                else {
                    reject({});
                }
            });
        }
    };
    LocalMediator.prototype.sendTransaction = function (tid, args, callback) {
        //set context
        var ctx = {
            SeqNum: this.seqNum++,
            Type: protocol_1.MessageType.Transition,
            TransID: tid.toString()
        };
        //record ctx
        this.ctx = ctx;
        var result = this.transitionHandler.onTransition(tid, args);
        //clean ctx
        console.log('sendTransaction', { tid: tid, args: args, result: result, ctx: ctx });
        console.log('sendTransaction StateTransition', Const_1.StateTransition);
        this.ctx = null;
        if (this.chainBlockCallback) {
            var msg = {
                SeqNum: ctx.SeqNum,
                Type: protocol_1.MessageType.Transition,
                TransID: ctx.TransID,
                States: {},
                Data: result
            };
            this.chainBlockCallback(msg);
        }
        if (callback) {
            callback(__assign(__assign({}, ctx), { result: result }));
        }
        return ctx;
    };
    LocalMediator.prototype.getTransaction = function () {
        return this.transitionHandler;
    };
    LocalMediator.prototype.profileQuery = function (key) {
        return new Promise(function (resolve, reject) {
            resolve({});
        });
    };
    LocalMediator.prototype.profileSave = function (key, value) {
        return new Promise(function (resolve, reject) {
            resolve({});
        });
    };
    LocalMediator.prototype.defaultQuery = function (type, transID, args) {
        return new Promise(function (resolve, reject) {
            resolve({});
        });
    };
    LocalMediator.prototype.chatHistory = function (data) {
        return new Promise(function (resolve, reject) {
            resolve([]);
        });
    };
    LocalMediator.prototype.setChainBlockCallback = function (callback) {
        this.chainBlockCallback = callback;
    };
    return LocalMediator;
}(mediator_1.BaseMediator));
exports.LocalMediator = LocalMediator;
function GetTestBattleTransRecord() {
    var re = [];
    var record1 = {
        attackInfo: {
            generalId: 1,
            generalLevel: 2,
            generalType: 1,
            username: 'test',
            troopReduce: 1000,
            silverGet: 100,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        defenseInfo: {
            generalId: -1,
            generalLevel: 2,
            generalType: 1,
            username: 'test1',
            troopReduce: 1500,
            silverGet: -100,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        leader: '',
        recordType: transition_1.BattleRecordType.Block,
        blockInfo: {
            x_id: 2,
            y_id: 2,
            durabilityReduce: 0
        },
        timestamp: (0, Utils_1.getTimeStamp)(),
        txHash: (0, Utils_1.getTxHash)(),
        result: true,
    };
    re.push(record1);
    var record2 = {
        attackInfo: {
            generalId: 1,
            generalLevel: 2,
            generalType: 1,
            username: 'test',
            troopReduce: 1500,
            silverGet: 0,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        defenseInfo: {
            generalId: 2,
            generalLevel: 2,
            generalType: 2,
            username: 'test1',
            troopReduce: 1000,
            silverGet: 0,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        leader: '',
        recordType: transition_1.BattleRecordType.Block,
        blockInfo: {
            x_id: 2,
            y_id: 2,
            durabilityReduce: 0
        },
        timestamp: (0, Utils_1.getTimeStamp)(),
        txHash: (0, Utils_1.getTxHash)(),
        result: false,
    };
    re.push(record2);
    var record3 = {
        attackInfo: {
            generalId: 1,
            generalLevel: 2,
            generalType: 1,
            username: 'test',
            troopReduce: 1000,
            silverGet: 0,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        leader: '',
        recordType: transition_1.BattleRecordType.City,
        defenseInfo: {
            generalId: 2,
            generalLevel: 2,
            generalType: 2,
            username: 'test1',
            troopReduce: 1500,
            silverGet: 0,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        blockInfo: {
            x_id: -1,
            y_id: -1,
            durabilityReduce: 0
        },
        timestamp: (0, Utils_1.getTimeStamp)(),
        txHash: (0, Utils_1.getTxHash)(),
        result: true,
    };
    re.push(record3);
    var record4 = {
        attackInfo: {
            generalId: 1,
            generalLevel: 2,
            generalType: 1,
            username: 'test',
            troopReduce: 1500,
            silverGet: -100,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        defenseInfo: {
            generalId: 2,
            generalLevel: 2,
            generalType: 2,
            username: 'test1',
            troopReduce: 1000,
            silverGet: 100,
            gloryGet: 100,
            unionId: 1,
            iconId: -1
        },
        leader: '',
        recordType: transition_1.BattleRecordType.City,
        timestamp: (0, Utils_1.getTimeStamp)(),
        blockInfo: {
            x_id: -1,
            y_id: -1,
            durabilityReduce: 0
        },
        txHash: (0, Utils_1.getTxHash)(),
        result: false,
    };
    re.push(record4);
    return re;
}
exports.GetTestBattleTransRecord = GetTestBattleTransRecord;
