import { idDev, isProd } from './ui-res';
import * as EthFuns from '../wallets/ethereum';

var userId = '';
export default async function gaInit(){
    return;
    // if(idDev){
    //     return;
    // }

    // window.dataLayer = window.dataLayer || [];
    // window.gtag = function(){ 
    //     window.dataLayer.push(arguments);
    // }
    // window.gtag('js', new Date());
    // window.gtag('config', 'G-GRYS3389C2');

    window.dataLayer = window.dataLayer || [];
    window.gtag = function(){window.dataLayer.push(arguments);}

    var gId = 'G-GRYS3389C2'
    var gaLib = 'https://www.googletagmanager.com/gtag/js?id=' + gId;
    var gaJs = document.createElement("script");
        gaJs.type = "text/javascript";
        gaJs.src = gaLib;
    document.body.appendChild(gaJs);

    await EthFuns.connect();
    gaJs.onload = function(){
        EthFuns.getUserAddress(function(res){
            userId = res.account + '';
            window.gtag('js', new Date());
            window.gtag('config', gId);
            window.gtag('set', {'user_id': userId});
            window.addEventListener("click", function(event){
                let target = event.target;
                if(target){
                    let eventName = getGaEvent(target);
                    gaClickEvent({ eventName, userId });   
                }
            }, true);
        })
    }
}

export function gaClickEvent(data){
    return;
    // _ga_event="login.play"
    let { eventName, userId, value=0 } = data;
    console.log(eventName, userId);
    if(!eventName){
        return;
    }
    window.gtag("event", eventName, {
        userId: 's-' + userId,
        value: value
    });
}

function getGaEvent(nodeDom) {
    let eventName = nodeDom.getAttribute('_ga_event') || '';
    if(nodeDom.tagName.toLowerCase() === 'body'){
        return eventName;
    }
    if (eventName === '') {
        nodeDom = nodeDom.parentNode;
        return getGaEvent(nodeDom);
    } else {
        return eventName;
    }
}