"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransitionHandler = exports.BattleRecordType = exports.TransitionEventType = void 0;
var state_1 = require("../../Core/state");
var Const_1 = require("../Const");
var State_1 = require("../State");
var statemanger_1 = require("./statemanger");
var creator_1 = require("../Logic/creator");
var mapGDS = require("../../gds/map_config.json");
var mapListGDS = require("../../gds/map_list.json");
var DataConfig_1 = require("../DataConfig");
var Utils_1 = require("../Utils");
var strategy_1 = require("../Logic/strategy");
var log = globalThis.log || function () { };
var TransitionEventType;
(function (TransitionEventType) {
    TransitionEventType["Battles"] = "battles";
    TransitionEventType["TimeStamp"] = "timeStamp";
})(TransitionEventType = exports.TransitionEventType || (exports.TransitionEventType = {}));
var BattleRecordType;
(function (BattleRecordType) {
    BattleRecordType["Block"] = "block";
    BattleRecordType["City"] = "city";
    BattleRecordType["Spy"] = "spy";
    BattleRecordType["Assembly"] = "assembly";
})(BattleRecordType = exports.BattleRecordType || (exports.BattleRecordType = {}));
var TransitionHandler = /** @class */ (function () {
    function TransitionHandler(stateWatcher, loadLoadStateFunc) {
        //init state
        this.stateManger = new statemanger_1.BaseStateManager({}, loadLoadStateFunc);
    }
    TransitionHandler.prototype.onTransition = function (sid, arg, eventRecorderFunc) {
        console.log("underlying_transition: sid: ", sid, ' : ', Const_1.StateTransition[sid], " args:", arg);
        var re = {};
        this.eventRecorderFunc = eventRecorderFunc;
        this.runCodList('onTransition ' + sid);
        try {
            if (Const_1.checkerMapForTxArgsTypeMap[sid]) {
                Const_1.checkerMapForTxArgsTypeMap[sid].check(arg);
                (0, Utils_1.checkNaNInObj)(arg);
            }
            var witnessName = "witness";
            var onlyWitnessTransitions = [
                Const_1.StateTransition.StartSeason,
                Const_1.StateTransition.Recharge,
                Const_1.StateTransition.FinishOutChainUserActivity,
            ];
            // if sid in onlyWitness transition type
            if (onlyWitnessTransitions.includes(sid)) {
                if (arg["from"] != witnessName) {
                    console.log(" only witness can do this transition sid: ", sid, " from: ", arg["from"]);
                    throw new Error(" only witness can do this transition sid: " + sid + " from: " + arg["from"]);
                }
            }
            switch (sid) {
                case Const_1.StateTransition.UpgradeFacility:
                    re = this.onUpdateFacility(arg);
                    break;
                case Const_1.StateTransition.Recruit:
                    re = this.onRecruit(arg);
                    break;
                case Const_1.StateTransition.AbleGeneral:
                    re = this.onAbleGeneral(arg);
                    break;
                case Const_1.StateTransition.DisableGeneral:
                    re = this.onDisableGeneral(arg);
                    break;
                case Const_1.StateTransition.UpgradeGeneral:
                    re = this.onUpgradeGeneral(arg);
                    break;
                case Const_1.StateTransition.UpgradeGeneralSkill:
                    re = this.onUpgradeGeneralSkill(arg);
                    break;
                case Const_1.StateTransition.SetDefenseGeneral:
                    re = this.onSetDefenseGeneral(arg);
                    break;
                case Const_1.StateTransition.ReceiveTroop:
                    re = this.onReceiveTroop(arg);
                    break;
                case Const_1.StateTransition.Battle:
                    re = this.onBattle(arg);
                    break;
                case Const_1.StateTransition.AttackBlock:
                    re = this.onAttackBlock(arg, -1);
                    break;
                case Const_1.StateTransition.DefenseBlock:
                    re = this.onDefenseBlock(arg);
                    break;
                case Const_1.StateTransition.CancelDefenseBlock:
                    re = this.onCancelDefenseBlock(arg);
                    break;
                case Const_1.StateTransition.SetUnionId:
                    re = this.onSetUnionId(arg);
                    break;
                case Const_1.StateTransition.SetIconId:
                    re = this.onSetIconId(arg);
                    break;
                case Const_1.StateTransition.AddTestResource:
                    re = this.onAddTestResource(arg);
                    break;
                case Const_1.StateTransition.RecoverMorale:
                    re = this.onRecoverMorale(arg);
                    break;
                case Const_1.StateTransition.BuyStrategyPoint:
                    re = this.onBuyStrategyPoint(arg);
                    break;
                case Const_1.StateTransition.StrategyBuySilver:
                    re = this.onStrategyBuySilver(arg);
                    break;
                case Const_1.StateTransition.StrategyBuyTroop:
                    re = this.onStrategyBuyTroop(arg);
                    break;
                case Const_1.StateTransition.StrategyBuyMorale:
                    re = this.onStrategyBuyMorale(arg);
                    break;
                case Const_1.StateTransition.StrategyBuyProtect:
                    re = this.onStrategyBuyProtect(arg);
                    break;
                case Const_1.StateTransition.StrategyBuyProtect1:
                    re = this.onStrategyBuyProtect1(arg);
                    break;
                case Const_1.StateTransition.StrategyBuyStore:
                    re = this.onStrategyBuyStore(arg);
                    break;
                case Const_1.StateTransition.MiningBlock:
                    re = this.onMiningBlock(arg);
                    break;
                case Const_1.StateTransition.SetGuideStep:
                    re = this.onSetGuideStep(arg);
                    break;
                case Const_1.StateTransition.InitUserStates:
                    re = this.onInitUserStates(arg);
                    return re;
                case Const_1.StateTransition.DonateSilver:
                    re = this.onDonateSilver(arg);
                    break;
                case Const_1.StateTransition.FirstLogin:
                    re = this.onFirstLogin(arg);
                    break;
                case Const_1.StateTransition.SetUnionWin:
                    re = this.onSetUnionWin(arg);
                    return re;
                case Const_1.StateTransition.SetSeasonEnd:
                    re = this.onSetSeasonEnd(arg);
                    return re;
                case Const_1.StateTransition.StartSeason:
                    re = this.onStartSeason(arg);
                    return re;
                case Const_1.StateTransition.Recharge:
                    re = this.onRecharge(arg);
                    return re;
                case Const_1.StateTransition.InitGlobalStates:
                    re = this.onInitGlobalStates(arg);
                    return re;
                case Const_1.StateTransition.RegularTask:
                    re = this.onRegularTask(arg);
                    return re;
                case Const_1.StateTransition.FinishOutChainUserActivity:
                    re = this.onUserFinsishOutChainActivity(arg);
                    return re;
                case Const_1.StateTransition.HealTroops:
                    re = this.onHealTroops(arg);
                    return re;
                case Const_1.StateTransition.SpyEnamy:
                    re = this.onSpyEnamy(arg);
                    return re;
                case Const_1.StateTransition.BuyOffer:
                    re = this.onBuyOffer(arg);
                    return re;
                case Const_1.StateTransition.CreateCod:
                    re = this.onCreateCod(arg);
                    return re;
                case Const_1.StateTransition.CancelCod:
                    re = this.onCancelCod(arg);
                    return re;
                case Const_1.StateTransition.JoinCod:
                    re = this.onJoinCod(arg);
                    return re;
                case Const_1.StateTransition.QuitCod:
                    re = this.onQuitCod(arg);
                case Const_1.StateTransition.CodCreatorDetail:
                    re = this.onCodCreatorDetail(arg);
                    return re;
            }
            var logic = this.genLogic(arg['from']);
            console.log("transition before update", logic.city.state);
            logic.general.updateDefenseInfo();
            logic.activity.updateAbleActivities();
            console.log("transition after update", logic.city.state);
            console.log("underlying_transition result: sid:", sid, ' : ', Const_1.StateTransition[sid], " result:", re);
            return re;
        }
        catch (err) {
            console.log("underlying_transition failed,  sid:", sid, ' : ', Const_1.StateTransition[sid], " args:", arg, " err ", err);
            throw err;
        }
    };
    TransitionHandler.prototype.getBlockStates = function (mapId, x_id, y_id) {
        if (!mapId) {
            return [];
        }
        var re = [];
        //get blocks for args
        var xOffset = [0, 1, 1, 0, -1, -1];
        var yOffset = [2, 1, -1, -2, -1, 1];
        var center = this.stateManger.get({ id: "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(x_id, "^").concat(y_id) });
        console.log("getBlockStates center:", { mapId: mapId, x_id: x_id, y_id: y_id }, center);
        if (!center) {
            return re;
        }
        re.push(center);
        for (var i = 0; i < 6; i++) {
            var newX = x_id + xOffset[i];
            var newY = y_id + yOffset[i];
            var stateId = { id: "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(newX, "^").concat(newY) };
            var newState = this.stateManger.get(stateId);
            if (newState) {
                re.push(newState);
                console.log("getBlockStates newState around:", { mapId: mapId, newX: newX, newY: newY }, newState);
            }
        }
        if (mapId >= 3) {
            var mapConfig = (0, DataConfig_1.getMapConfigFromGDS)(mapId);
            for (var blockId in mapConfig['config']) {
                var blockInfo = mapConfig['config'][blockId];
                if (blockInfo.type === 2) {
                    var newX = blockInfo.x_id;
                    var newY = blockInfo.y_id;
                    var stateId = { id: "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(newX, "^").concat(newY) };
                    var newState = this.stateManger.get(stateId);
                    if (newState) {
                        re.push(newState);
                        console.log("getBlockStates newState mapId >= 3:", newState);
                    }
                }
            }
        }
        console.log("getBlockStates return:", re);
        return re;
    };
    TransitionHandler.prototype.genLogic = function (id, x_id, y_id, gStatesIn) {
        if (x_id === void 0) { x_id = 0; }
        if (y_id === void 0) { y_id = 0; }
        if (gStatesIn === void 0) { gStatesIn = null; }
        var cityState = this.stateManger.get({
            id: "".concat(Const_1.StateName.City, ":").concat(id)
        });
        var generalState = this.stateManger.get({
            id: "".concat(Const_1.StateName.General, ":").concat(id)
        });
        var strategyState = this.stateManger.get({
            id: "".concat(Const_1.StateName.Strategy, ":").concat(id)
        });
        var gStates;
        if (gStatesIn == null) {
            gStates = this.genGlobalStateEssential(x_id, y_id);
        }
        else {
            gStates = gStatesIn;
        }
        var states = {
            username: 'transition:' + id,
            city: cityState,
            general: generalState,
            strategy: strategyState,
            mapGlobal: gStates.mapGlobal,
            seasonState: gStates.seasonState,
            tokenPriceInfo: gStates.tokenPriceInfo,
            rewardGlobalState: gStates.rewardGlobalState,
            blocks: gStates.blocks,
            activityState: gStates.activityState,
            codsGlobal: gStates.codsGlobal
        };
        return (0, creator_1.createLogicEsential)(states);
    };
    TransitionHandler.prototype.genGlobalStateEssential = function (x_id, y_id) {
        var mapGlobalState = this.stateManger.get({
            id: "".concat(Const_1.StateName.MapGlobalInfo)
        });
        var seasonState = this.stateManger.get({
            id: "".concat(Const_1.StateName.SeasonConfig)
        });
        var tokenPriceInfo = this.stateManger.get({
            id: "".concat(Const_1.StateName.TokenPriceInfo)
        });
        var rewardGlobalState = this.stateManger.get({
            id: "".concat(Const_1.StateName.RewardGloablState)
        });
        var activities = this.stateManger.get({
            id: "".concat(Const_1.StateName.Activity)
        });
        var codsGlobal = this.stateManger.get({
            id: "".concat(Const_1.StateName.GlobalCod)
        });
        var gStates = {
            mapGlobal: mapGlobalState,
            seasonState: seasonState,
            tokenPriceInfo: tokenPriceInfo,
            rewardGlobalState: rewardGlobalState,
            blocks: this.getBlockStates(seasonState.mapId, x_id, y_id),
            activityState: activities,
            codsGlobal: codsGlobal
        };
        return gStates;
    };
    TransitionHandler.prototype.genGlobalLogic = function (x_id, y_id) {
        if (x_id === void 0) { x_id = 0; }
        if (y_id === void 0) { y_id = 0; }
        var gStates = this.genGlobalStateEssential(x_id, y_id);
        return (0, creator_1.createGlobalEsential)(gStates);
    };
    TransitionHandler.prototype.onUpdateFacility = function (args) {
        var logic = this.genLogic(args.from);
        var city = logic.city;
        log('onUpdateFacility args ', args, ' cityState ', city.state);
        //Do Logic  here
        //Valdiate resource requirement first
        var re = city.upgradeFacility(args.typ, args.index);
        if (re.result && args.typ == Const_1.CityFacility.Fortress && city.state.facilities[Const_1.CityFacility.Fortress][0] == 7) {
            this.updateRewardState(logic.map.rewardGlobalState, (0, Utils_1.parseStateId)(logic.city.state.getId()).username, 0, logic.general.state.glory, logic.general.state.unionId);
        }
        return re;
    };
    TransitionHandler.prototype.onRecruit = function (args) {
        var logic = this.genLogic(args.from);
        var city = logic.city;
        return city.recruit(args.amount);
    };
    TransitionHandler.prototype.onAbleGeneral = function (args) {
        var logic = this.genLogic(args.from);
        var general = logic.general;
        return general.ableGeneral(args.id);
    };
    TransitionHandler.prototype.onDisableGeneral = function (args) {
        var logic = this.genLogic(args.from);
        var general = logic.general;
        return general.disableGeneral(args.id);
    };
    TransitionHandler.prototype.onUpgradeGeneral = function (args) {
        var logic = this.genLogic(args.from);
        var general = logic.general;
        return general.upgradeGeneral(args.id, args.levelTo);
    };
    TransitionHandler.prototype.onUpgradeGeneralSkill = function (args) {
        var logic = this.genLogic(args.from);
        var general = logic.general;
        return general.upgradeGeneralSkill(args.generalId, args.skillIndex);
    };
    TransitionHandler.prototype.onSetDefenseGeneral = function (args) {
        var logic = this.genLogic(args.from);
        var general = logic.general;
        return general.setDefenseGeneral(args.generalId);
    };
    TransitionHandler.prototype.onReceiveTroop = function (args) {
        var logic = this.genLogic(args.from);
        var city = logic.city;
        var beforeReceive = city.getResource(Const_1.ResouceType.Troop);
        city.updateResource(Const_1.ResouceType.Troop);
        var afterReceive = city.getResource(Const_1.ResouceType.Troop);
        return {
            result: true,
            txType: Const_1.StateTransition.ReceiveTroop,
            receive: afterReceive - beforeReceive
        };
    };
    TransitionHandler.prototype.onBattle = function (args) {
        var gStates = this.genGlobalStateEssential(0, 0);
        var logic1 = this.genLogic(args.from, 0, 0, gStates);
        if (logic1.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able) {
            logic1.strategy.setStrategyStatus(strategy_1.StrategyType.Protect, false);
        }
        logic1.general.setLastBattle();
        var logic2 = this.genLogic(args.name.replace("defenderinfo:", ""), 0, 0, gStates);
        if (logic2.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able || logic2.general.isNewPlayerProtect()) {
            return {
                result: false,
                txType: Const_1.StateTransition.Battle,
                error: 'cant-battle-player-be-protected'
            };
        }
        if (logic1.city.state.id == logic2.city.state.id) {
            return {
                result: false,
                txType: Const_1.StateTransition.Battle,
                error: 'cant-battle-self'
            };
        }
        console.log('updateInjuredTroops battle args:', args);
        var defenseInfo = logic2.general.getDefenseInfo();
        console.log('updateInjuredTroops defenseInfo:', defenseInfo);
        var unionIds = {
            attackUnionId: logic1.general.state.unionId,
            defenseUnionId: logic2.general.state.unionId
        };
        var re = logic1.general.battle(args.generalId, unionIds, defenseInfo);
        console.log('updateInjuredTroops battle result:', re);
        if (re['attackTroopReduce']) {
            logic1.city.useTroop(re['attackTroopReduce']);
            logic1.city.updateInjuredTroops(re['attackTroopReduce'], 'battle');
        }
        if (re['defenseTroopReduce']) {
            logic2.city.updateInjuredTroops(re['defenseTroopReduce'], 'battle');
        }
        if (re.result == true) {
            re.silverGet = logic2.city.robSilver(re.silverGet);
            var btr = {
                attackInfo: {
                    username: (0, Utils_1.parseStateId)(logic1.city.state.getId()).username,
                    generalId: args.generalId,
                    generalLevel: logic1.general.getGeneralLevel(args.generalId),
                    generalType: logic1.general.getGeneralQualification(args.generalId).general_type,
                    troopReduce: re['attackTroopReduce'],
                    silverGet: re['silverGet'],
                    gloryGet: re['attackGloryGet'],
                    unionId: logic1.general.state.unionId,
                    iconId: logic1.general.state.iconId
                },
                defenseInfo: {
                    username: (0, Utils_1.parseStateId)(logic2.city.state.getId()).username,
                    generalId: defenseInfo.generalId,
                    generalLevel: defenseInfo.generalLevel,
                    generalType: defenseInfo.generalType,
                    troopReduce: re['defenseTroopReduce'],
                    silverGet: -re['silverGet'],
                    gloryGet: re['defenseGloryGet'],
                    unionId: logic2.general.state.unionId,
                    iconId: logic2.general.state.iconId
                },
                leader: '',
                recordType: BattleRecordType.City,
                blockInfo: {
                    x_id: 0,
                    y_id: 0,
                    durabilityReduce: 0
                },
                timestamp: (0, Utils_1.getTimeStamp)(),
                txHash: (0, Utils_1.getTxHash)(),
                result: re['win']
            };
            var status1 = logic1.general.getGeneralBattleStatus(args.generalId);
            var total1 = status1.sum['attack'] + status1.sum['defense'];
            var status2 = logic2.general.getGeneralBattleStatus(defenseInfo.generalId);
            var total2 = status2.sum['attack'] + status2.sum['defense'];
            var powerCompare = total1 / total2;
            console.log('powerCompare:', status1, status2, total1, total2, powerCompare);
            var moraleAdd = 2;
            if (powerCompare > 2) {
                if (!re['win']) {
                    logic1.general.offsetMorale(moraleAdd * -1);
                    logic2.general.offsetMorale(moraleAdd);
                }
            }
            else if (powerCompare <= 2 && powerCompare > 1 / 2) {
                if (re['win']) {
                    logic1.general.offsetMorale(moraleAdd);
                    logic2.general.offsetMorale(moraleAdd * -1);
                }
                else {
                    logic1.general.offsetMorale(moraleAdd * -1);
                    logic2.general.offsetMorale(moraleAdd);
                }
            }
            else {
                if (re['win']) {
                    logic1.general.offsetMorale(moraleAdd);
                    logic2.general.offsetMorale(moraleAdd * -1);
                }
            }
            var oldGlory1 = logic1.general.state.glory;
            var oldGlory2 = logic2.general.state.glory;
            logic1.map.addGloryAndSum(btr.attackInfo.gloryGet);
            logic2.map.addGloryAndSum(btr.defenseInfo.gloryGet);
            logic2.city.useTroop(btr.defenseInfo.troopReduce);
            logic2.general.updateDefenseInfo();
            if (logic1.city.state.facilities[Const_1.CityFacility.Fortress][0] >= 7) {
                this.updateRewardState(logic1.map.rewardGlobalState, (0, Utils_1.parseStateId)(logic1.city.state.getId()).username, oldGlory1, logic1.general.state.glory, logic1.general.state.unionId);
            }
            if (logic2.city.state.facilities[Const_1.CityFacility.Fortress][0] >= 7) {
                this.updateRewardState(logic2.map.rewardGlobalState, (0, Utils_1.parseStateId)(logic2.city.state.getId()).username, oldGlory2, logic2.general.state.glory, logic2.general.state.unionId);
            }
            this.recordEvent(TransitionEventType.Battles, btr);
        }
        if (re.silverGet > 0) {
            logic1.city.useSilver(-re.silverGet);
        }
        return re;
    };
    TransitionHandler.prototype.onHealTroops = function (args) {
        var logic = this.genLogic(args.from);
        console.log('onHealTroops', args);
        var re = logic.general.healTroops(args.typ, args.amount);
        console.log('onHealTroops', re);
        return re;
    };
    TransitionHandler.prototype.onSpyEnamy = function (args) {
        var gStates = this.genGlobalStateEssential(0, 0);
        var logic1 = this.genLogic(args.from, 0, 0, gStates);
        var logic2 = this.genLogic(args.username, 0, 0, gStates);
        var logic = this.genLogic(args.from);
        var re = logic.general.spyForEnamy(args.from, args.generalId);
        var typeInfos = logic2.city.getAllUpgradeInfo(Const_1.CityFacility.Store);
        var cityLevel = logic2.city.state.facilities['store'][0];
        re['store'] = typeInfos[cityLevel - 1];
        var btr = {
            attackInfo: {
                username: args.from,
                generalId: args.generalId,
                generalLevel: logic1.general.getGeneralLevel(args.generalId),
                generalType: logic1.general.getGeneralQualification(args.generalId).general_type,
                troopReduce: 0,
                silverGet: 0,
                gloryGet: 0,
                unionId: logic1.general.state.unionId,
                iconId: logic1.general.state.iconId
            },
            defenseInfo: {
                username: args.username,
                generalId: -1,
                generalLevel: -1,
                generalType: -1,
                troopReduce: 0,
                silverGet: 0,
                gloryGet: 0,
                unionId: logic2.general.state.unionId,
                iconId: logic2.general.state.iconId
            },
            leader: '',
            recordType: BattleRecordType.Spy,
            blockInfo: {
                x_id: 0,
                y_id: 0,
                durabilityReduce: 0
            },
            timestamp: (0, Utils_1.getTimeStamp)(),
            txHash: (0, Utils_1.getTxHash)(),
            result: re['result']
        };
        this.recordEvent(TransitionEventType.Battles, btr);
        console.log('spyEnamy', args, re);
        return re;
    };
    TransitionHandler.prototype.onCreateCod = function (args) {
        var username = args.from;
        var blockInfo = args.blockInfo;
        var generalId = args.generalId;
        console.log('cod onCreateCod args:', args);
        var gStates = this.genGlobalStateEssential(blockInfo.x_id, blockInfo.y_id);
        console.log('cod onCreateCod gStates:', gStates);
        var logic = this.genLogic(username, blockInfo.x_id, blockInfo.y_id, gStates);
        var re = logic.general.createCod(blockInfo, { username: username, generalId: generalId });
        console.log('cod onCreateCod result:', re);
        return re;
    };
    TransitionHandler.prototype.onCancelCod = function (args) {
        var _this = this;
        var username = args.from;
        var codId = args.codId;
        var logic = this.genLogic(username);
        var isCodCanCanel = logic.general.isCodCanCanel(codId, username);
        if (!isCodCanCanel) {
            return {
                result: false,
                error: 'assembly not exist or current user not creator',
                txType: Const_1.StateTransition.CancelCod
            };
        }
        var codDetail = logic.general.getCodDetail(codId);
        this.membersQuitCod(codDetail);
        return logic.general.cancelCod(codId, username);
    };
    TransitionHandler.prototype.membersQuitCod = function (codDetail) {
        var _this = this;
        var codId = codDetail.codId;
        var members = codDetail.members || [];
        members.forEach(function (member) {
            var username = member['username'];
            var logicPlayer = _this.genLogic(username);
            var type = 'byCancel';
            logicPlayer.general.quitCod(codId, { username: username, type: type });
        });
    };
    TransitionHandler.prototype.onJoinCod = function (args) {
        var username = args.from;
        var generalId = args.generalId;
        var codId = args.codId;
        var logic = this.genLogic(username);
        var re = logic.general.joinCod(codId, { username: username, generalId: generalId });
        return re;
    };
    TransitionHandler.prototype.onQuitCod = function (args) {
        var username = args.from;
        var codId = args.codId;
        var logic = this.genLogic(username);
        var re = logic.general.quitCod(codId, { username: username });
        return re;
    };
    TransitionHandler.prototype.onCodCreatorDetail = function (args) {
        var username = args.from;
        var codId = args.codId;
        var logic = this.genLogic(username);
        var codDetail = logic.general.getCodDetail(codId);
        if (!codDetail.creator) {
            console.log('onCodCreatorDetail empty:', codId, ' not exist', codDetail);
            return {
                result: true,
                generalInfo: {},
                battleInfo: {},
                qualificationInfo: {},
                txType: Const_1.StateTransition.CodCreatorDetail
            };
        }
        var generalId = codDetail.generalId;
        var logicCreator = this.genLogic(codDetail.creator);
        var battleInfo = logicCreator.general.getGeneralBattleStatus(generalId);
        var generalInfo = logicCreator.general.getGeneralInfo(generalId);
        var qualificationInfo = logicCreator.general.getGeneralQualification(generalId);
        console.log('onCodCreatorDetail battleInfo:', codId, ' ', { generalInfo: generalInfo, battleInfo: battleInfo, qualificationInfo: qualificationInfo });
        return {
            result: true,
            generalInfo: generalInfo,
            battleInfo: battleInfo,
            qualificationInfo: qualificationInfo,
            txType: Const_1.StateTransition.CodCreatorDetail
        };
    };
    TransitionHandler.prototype.startAttackCod = function (codItem) {
        var _this = this;
        var username = codItem.creator;
        var codId = codItem.codId, blockInfo = codItem.blockInfo, troopTotal = codItem.troopTotal, troopNow = codItem.troopNow, members = codItem.members, generalId = codItem.generalId;
        var logic = _this.genLogic(username);
        console.log('cod runList attack start 1:', codId, ' ', codItem);
        var args = {
            from: username,
            generalId: generalId,
            x_id: blockInfo.x_id,
            y_id: blockInfo.y_id
        };
        var re = this.onAttackBlockCod(args, troopNow);
        re['txType'] = Const_1.StateTransition.AttackBlockCod;
        console.log('cod runList attack start 2:', codId, ', result:', re);
        //1. get troops reduce for creator, 0.01 for not zero.
        var records = re['records'] || [];
        var recordItem = records[records.length - 1] || {};
        var attackInfo = recordItem.attackInfo || {};
        var troopReduce = attackInfo.troopReduce || 0;
        troopNow += 0.00001;
        var generalDetail = this.onCodCreatorDetail({
            from: username,
            codId: codId
        });
        members.forEach(function (member) {
            _this.codRecords(args, member, re, troopReduce, troopNow, generalDetail);
        });
        logic.general.endCod(codId);
    };
    TransitionHandler.prototype.codRecords = function (args, member, re, troopReduce, troopNow, generalDetail) {
        var username = member['username'];
        var generalId = member['generalId'];
        var _troopReduce = member['troops'] * troopReduce / troopNow;
        _troopReduce = Math.round(_troopReduce);
        console.log('cod runList attack codRecords:', member, { troopReduce: troopReduce, troopNow: troopNow }, re);
        var records = re['records'] || [];
        var recordItem = records[records.length - 1] || {};
        var userCreator = args.from;
        var logicCreator = this.genLogic(userCreator);
        //1. release assembly generals
        var logic = this.genLogic(username);
        logic.general.opCodGeneralId(generalId, 'release', {});
        console.log('cod runList attack release generalId:', generalId);
        // 2. same glory get
        var attackInfo = recordItem['attackInfo'] || {};
        var gloryGet = re['gloryGet'] || attackInfo['gloryGet'] || 0;
        if (gloryGet > 0) {
            logic.map.addGloryAndSum(gloryGet);
        }
        console.log('cod runList attack gloryGet:', gloryGet, ' ', re['gloryGet'], ' attackInfo.gloryGet:', attackInfo['gloryGet'], ' ', username);
        ;
        //update glory rank
        console.log('cod runList city.state facilities:', logic.city.state);
        var fortressLevel = logic.city.state.facilities[Const_1.CityFacility.Fortress][0];
        if (fortressLevel >= 7) {
            this.updateRewardState(logic.map.rewardGlobalState, username, -1, logic.general.state.glory, logic.general.state.unionId);
        }
        console.log('cod runList attack glory rank: fortressLevel:', fortressLevel);
        console.log('cod runList attack glory rank: glory:', logic.general.state.glory);
        //3. remain troops to resource
        var remainTroops = _troopReduce - member['troops'];
        logic.city.useTroop(remainTroops);
        if (_troopReduce > 0) {
            //4. reduce trooops to hospital
            logic.city.updateInjuredTroops(_troopReduce, 'battle');
        }
        console.log('cod runList attack remain troops:', { remainTroops: remainTroops, _troopReduce: _troopReduce }, ' username:', username);
        var generalInfo = generalDetail.generalInfo, qualificationInfo = generalDetail.qualificationInfo;
        //battle record all as same
        if (records.length > 0) {
            var moraleAdd = recordItem.result ? 2 : -2;
            logic.general.offsetMorale(moraleAdd);
            console.log('cod runList attack moraleAdd:', moraleAdd);
        }
        var duraReduceRecord = 0;
        for (var _i = 0, _a = records; _i < _a.length; _i++) {
            var record = _a[_i];
            var blockInfo = record['blockInfo'] || {};
            duraReduceRecord = blockInfo['durabilityReduce'] || 0;
            record.recordType = BattleRecordType.Assembly;
            record.leader = userCreator;
            record.attackInfo.generalId = generalInfo.id;
            record.attackInfo.generalLevel = generalInfo.level;
            record.attackInfo.generalType = qualificationInfo.general_type;
            record.attackInfo.username = username;
            record.attackInfo.troopReduce = _troopReduce;
            console.log('cod runList attack record', record);
            this.recordEvent(TransitionEventType.Battles, record);
        }
        var duraReduce = re['durabilityReduce'] || 0;
        if (duraReduce > 0 && duraReduceRecord != duraReduce) {
            var victory_glory = logicCreator.general.config.parameter.battle_victory_get_glory;
            var get_glory = Math.floor(duraReduce / 50) + victory_glory;
            var durabilityRecord = logicCreator.map.genDurabilityRecord(args.x_id, args.y_id, args.generalId, get_glory, duraReduce);
            durabilityRecord = JSON.parse(JSON.stringify(durabilityRecord));
            durabilityRecord.attackInfo.username = username;
            durabilityRecord.recordType = BattleRecordType.Assembly;
            durabilityRecord.leader = userCreator;
            console.log('cod runList attack record durabilityReduce:', durabilityRecord);
            this.recordEvent(TransitionEventType.Battles, durabilityRecord);
        }
    };
    TransitionHandler.prototype.runCodList = function (from) {
        var _this = this;
        var gStates = _this.genGlobalStateEssential(0, 0);
        var cods = gStates.codsGlobal.cods;
        var codList = [];
        var codIds = [];
        for (var codId in cods) {
            if (cods[codId]['creator']) {
                codList.push(cods[codId]);
                codIds.push(codId);
            }
        }
        console.log('cod runList ids:', from, ' codList:', codList.length, codIds);
        console.log('cod runList:', codList);
        codList.forEach(function (codItem) {
            var username = codItem.creator;
            var logic = _this.genLogic(username);
            var codId = codItem.codId, createTime = codItem.createTime, lastTime = codItem.lastTime, troopTotal = codItem.troopTotal, troopNow = codItem.troopNow, members = codItem.members;
            console.log('cod runList item:', username, ', ', codId, ', members:', members.length, ', ', codItem);
            var timeNow = (0, Utils_1.getTimeStamp)();
            var isTimeout = timeNow >= createTime + lastTime;
            if (isTimeout && members.length === 1) {
                console.log('cod runList cancel:', codId, ' ', username);
                _this.membersQuitCod(codItem);
                logic.general.cancelCod(codId, username);
            }
            if ((isTimeout && members.length > 1) || troopNow >= troopTotal) {
                console.log('cod runList attack:', codId);
                _this.startAttackCod(codItem);
            }
        });
    };
    TransitionHandler.prototype.onAttackBlockCod = function (args, remainTroops) {
        console.log('attackBlocksAroundCod args cod 1:', args, remainTroops);
        var _this = this;
        var gStates = this.genGlobalStateEssential(args.x_id, args.y_id);
        var logic = this.genLogic(args.from, args.x_id, args.y_id, gStates);
        // console.log('attackBlocksAround args 2:', gStates, logic);
        if (logic.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able) {
            logic.strategy.setStrategyStatus(strategy_1.StrategyType.Protect, false);
        }
        var myUnionId = logic.general.state.unionId;
        var isCanAttack = logic.map.checkBetween(myUnionId, args.x_id, args.y_id);
        if (!isCanAttack) {
            return {
                result: false,
                error: 'block-is-too-far'
            };
        }
        var blockGds = logic.map.mapConfig.get(args.x_id, args.y_id);
        console.log('attackBlocksAroundCod args cod 3:', blockGds);
        if (blockGds.type == 3) {
            return {
                result: false,
                error: 'cant-attack-init-block'
            };
        }
        console.log('attackBlocksAroundCod args cod 4:', remainTroops);
        var isCod = true;
        var re = logic.map.attackBlocksAround(args.x_id, args.y_id, args.generalId, remainTroops, isCod, function onBelongChange() { });
        console.log('attackBlocksAroundCod result:', re);
        if (re['result'] == undefined) {
            for (var _i = 0, _a = re['cancelList']; _i < _a.length; _i++) {
                var cancelDefense = _a[_i];
                if (cancelDefense.username != '') {
                    var tempLogic = this.genLogic(cancelDefense.username, args.x_id, args.y_id, gStates);
                    tempLogic.general.cancelDefenseBlock(cancelDefense.generalId, 0);
                }
            }
            var oldGlory = logic.general.state.glory;
            for (var _b = 0, _c = re['records']; _b < _c.length; _b++) {
                var record = _c[_b];
                var moraleAdd = record.result ? 2 : -2;
                // logic.general.offsetMorale(moraleAdd)
                // logic.map.addGloryAndSum(record.attackInfo.gloryGet)
                if (record.defenseInfo.username != '') {
                    var tempLogic = this.genLogic(record.defenseInfo.username, args.x_id, args.y_id, gStates);
                    if (tempLogic) {
                        var oldTempGlory = tempLogic.general.state.glory;
                        tempLogic.map.addGloryAndSum(record.defenseInfo.gloryGet);
                        tempLogic.general.offsetMorale(-moraleAdd);
                        if (tempLogic.city.state.facilities[Const_1.CityFacility.Fortress][0] >= 7) {
                            this.updateRewardState(tempLogic.map.rewardGlobalState, (0, Utils_1.parseStateId)(tempLogic.city.state.getId()).username, oldTempGlory, tempLogic.general.state.glory, tempLogic.general.state.unionId);
                        }
                    }
                }
                // this.recordEvent(TransitionEventType.Battles, record)
            }
            var temp = re['records'];
            var transRe = {};
            if (temp.length != 0) {
                transRe = {
                    result: true,
                    record: temp[temp.length - 1],
                    records: [temp[temp.length - 1]],
                    durabilityReduce: re['durabilityReduce']
                };
                var defenseInfo = temp[temp.length - 1].defenseInfo || { troopReduce: 0, username: '' };
                var _d = defenseInfo.troopReduce, troopReduce = _d === void 0 ? 0 : _d, _e = defenseInfo.username, username = _e === void 0 ? '' : _e;
                if (username !== '') {
                    var logic2 = this.genLogic(username.replace("defenderinfo:", ""), args.x_id, args.y_id, gStates);
                    logic2.city.updateInjuredTroops(troopReduce, 'battle');
                    console.log('updateInjuredTroops attackBlocksAroundCod defenseInfo:', defenseInfo);
                }
            }
            else {
                var gloryGet = Math.floor(re['durabilityReduce'] / 50) + logic.general.config.parameter.battle_victory_get_glory;
                transRe = {
                    result: true,
                    gloryGet: gloryGet,
                    durabilityReduce: re['durabilityReduce']
                };
            }
            console.log('attackBlocksAroundCod result 2:', transRe);
            return transRe;
        }
        else {
            var records = re['records'] || [];
            var defenseInfo = (records[records.length - 1] || {}).defenseInfo || { troopReduce: 0, username: '' };
            var attackInfo = (records[records.length - 1] || {}).attackInfo || { troopReduce: 0, username: '' };
            var username = defenseInfo.username;
            var troopReduce = defenseInfo.troopReduce || 0;
            if (username !== '') {
                var logic2 = this.genLogic(username.replace("defenderinfo:", ""), args.x_id, args.y_id, gStates);
                logic2.city.updateInjuredTroops(troopReduce, 'battle');
                console.log('updateInjuredTroops attackBlocksAroundCod defenseInfo:', defenseInfo);
            }
            console.log('attackBlocksAroundCod result 1:', re);
            return re;
        }
    };
    TransitionHandler.prototype.onAttackBlock = function (args, remainTroops) {
        var re = this.onAttackBlockCommon(args, remainTroops);
        re['txType'] = Const_1.StateTransition.AttackBlock;
        return re;
    };
    TransitionHandler.prototype.onAttackBlockCommon = function (args, remainTroops) {
        console.log('attackBlocksAround args 1:', args, remainTroops);
        var _this = this;
        var gStates = this.genGlobalStateEssential(args.x_id, args.y_id);
        var logic = this.genLogic(args.from, args.x_id, args.y_id, gStates);
        // console.log('attackBlocksAround args 2:', gStates, logic);
        if (logic.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able) {
            logic.strategy.setStrategyStatus(strategy_1.StrategyType.Protect, false);
        }
        var myUnionId = logic.general.state.unionId;
        var isCanAttack = logic.map.checkBetween(myUnionId, args.x_id, args.y_id);
        console.log('attackBlocksAround args can attack:', { myUnionId: myUnionId, isCanAttack: isCanAttack });
        if (!isCanAttack) {
            return {
                result: false,
                error: 'block-is-too-far'
            };
        }
        var blockGds1 = logic.map.getMapGDS(args.x_id, args.y_id);
        console.log('attackBlocksAround args 2:', { blockGds1: blockGds1, args: args, remainTroops: remainTroops, mapConfig: logic.map.mapConfig });
        var blockGds = logic.map.mapConfig.get(args.x_id, args.y_id);
        console.log('attackBlocksAround args 3:', blockGds);
        if (blockGds.type == 3) {
            return {
                result: false,
                error: 'cant-attack-init-block'
            };
        }
        console.log('attackBlocksAround args 4:', remainTroops);
        var isCod = false;
        var re = logic.map.attackBlocksAround(args.x_id, args.y_id, args.generalId, remainTroops, isCod, function onBelongChange() {
            var codId = 'block_' + args.x_id + '_' + args.y_id;
            var codDetail = logic.general.getCodDetail(codId);
            var creator = codDetail.creator;
            if (!creator) {
                return;
            }
            var logicCreator = _this.genLogic(creator);
            _this.membersQuitCod(codDetail);
            logicCreator.general.cancelCod(codId, creator);
            console.log('cod cancel by blockbelong change:', codId, ', creator: ', creator);
        });
        this.attackBlockRecords(args, re, 'attack');
        console.log('attackBlocksAround result:', re);
        if (re['result'] == undefined) {
            for (var _i = 0, _a = re['cancelList']; _i < _a.length; _i++) {
                var cancelDefense = _a[_i];
                if (cancelDefense.username != '') {
                    var tempLogic = this.genLogic(cancelDefense.username, args.x_id, args.y_id, gStates);
                    tempLogic.general.cancelDefenseBlock(cancelDefense.generalId, 0);
                }
            }
            var oldGlory = logic.general.state.glory;
            for (var _b = 0, _c = re['records']; _b < _c.length; _b++) {
                var record = _c[_b];
                var moraleAdd = record.result ? 2 : -2;
                logic.general.offsetMorale(moraleAdd);
                logic.map.addGloryAndSum(record.attackInfo.gloryGet);
                if (record.defenseInfo.username != '') {
                    var tempLogic = this.genLogic(record.defenseInfo.username, args.x_id, args.y_id, gStates);
                    if (tempLogic) {
                        var oldTempGlory = tempLogic.general.state.glory;
                        tempLogic.map.addGloryAndSum(record.defenseInfo.gloryGet);
                        tempLogic.general.offsetMorale(-moraleAdd);
                        if (tempLogic.city.state.facilities[Const_1.CityFacility.Fortress][0] >= 7) {
                            this.updateRewardState(tempLogic.map.rewardGlobalState, (0, Utils_1.parseStateId)(tempLogic.city.state.getId()).username, oldTempGlory, tempLogic.general.state.glory, tempLogic.general.state.unionId);
                        }
                    }
                }
                // this.recordEvent(TransitionEventType.Battles, record)
            }
            var temp = re['records'];
            var transRe = {};
            if (temp.length != 0) {
                transRe = {
                    result: true,
                    record: temp[temp.length - 1],
                    durabilityReduce: re['durabilityReduce']
                };
                var defenseInfo = temp[temp.length - 1].defenseInfo || { troopReduce: 0, username: '' };
                var _d = defenseInfo.troopReduce, troopReduce = _d === void 0 ? 0 : _d, _e = defenseInfo.username, username = _e === void 0 ? '' : _e;
                if (username !== '') {
                    var logic2 = this.genLogic(username.replace("defenderinfo:", ""), args.x_id, args.y_id, gStates);
                    logic2.city.updateInjuredTroops(troopReduce, 'battle');
                    console.log('updateInjuredTroops attackBlock defenseInfo:', defenseInfo);
                }
            }
            else {
                var gloryGet = Math.floor(re['durabilityReduce'] / 50) + logic.general.config.parameter.battle_victory_get_glory;
                logic.map.addGloryAndSum(gloryGet);
                transRe = {
                    result: true,
                    gloryGet: gloryGet,
                    durabilityReduce: re['durabilityReduce']
                };
                // this.recordEvent(
                //   TransitionEventType.Battles,
                //   logic.map.genDurabilityRecord(
                //     args.x_id, args.y_id, args.generalId, Math.floor(re['durabilityReduce'] / 50) + logic.general.config.parameter.battle_victory_get_glory, re['durabilityReduce']
                //   )
                // )
            }
            if (logic.city.state.facilities[Const_1.CityFacility.Fortress][0] >= 7) {
                this.updateRewardState(logic.map.rewardGlobalState, (0, Utils_1.parseStateId)(logic.city.state.getId()).username, oldGlory, logic.general.state.glory, logic.general.state.unionId);
            }
            console.log('attackBlocksAround result2:', transRe);
            return transRe;
        }
        else {
            var records = re['records'] || [];
            var defenseInfo = (records[records.length - 1] || {}).defenseInfo || { troopReduce: 0, username: '' };
            var _f = defenseInfo.troopReduce, troopReduce = _f === void 0 ? 0 : _f, _g = defenseInfo.username, username = _g === void 0 ? '' : _g;
            if (username !== '') {
                var logic2 = this.genLogic(username.replace("defenderinfo:", ""), args.x_id, args.y_id, gStates);
                logic2.city.updateInjuredTroops(troopReduce, 'battle');
                console.log('updateInjuredTroops attackBlock defenseInfo:', defenseInfo);
            }
            console.log('attackBlocksAround result1:', re);
            return re;
        }
    };
    TransitionHandler.prototype.attackBlockRecords = function (args, battleResult, typ) {
        console.log('attackBlockRecords:', { typ: typ, battleResult: battleResult, args: args });
        var logic = this.genLogic(args.from, args.x_id, args.y_id);
        var records = battleResult['records'] || [];
        var duraReduceRecord = 0;
        for (var _i = 0, _a = records; _i < _a.length; _i++) {
            var record = _a[_i];
            this.recordEvent(TransitionEventType.Battles, record);
            var blockInfo = record['blockInfo'] || {};
            duraReduceRecord = blockInfo['durabilityReduce'] || 0;
        }
        var duraReduce = battleResult['durabilityReduce'] || 0;
        if (duraReduce > 0 && duraReduceRecord != duraReduce) {
            var get_glory = logic.general.config.parameter.battle_victory_get_glory;
            var record = logic.map.genDurabilityRecord(args.x_id, args.y_id, args.generalId, Math.floor(duraReduce / 50) + get_glory, duraReduce);
            this.recordEvent(TransitionEventType.Battles, record);
        }
    };
    TransitionHandler.prototype.onDefenseBlock = function (args) {
        var logic = this.genLogic(args.from, args.x_id, args.y_id);
        if (logic.strategy.getStrategyStatus(strategy_1.StrategyType.Protect).able) {
            logic.strategy.setStrategyStatus(strategy_1.StrategyType.Protect, false);
        }
        if (logic.general.state.unionId != logic.map.getBelongInfo(args.x_id, args.y_id)) {
            return {
                result: false,
                txType: Const_1.StateTransition.DefenseBlock,
                error: 'unionId-error'
            };
        }
        var re = logic.general.defenseBlock(args.generalId, args.x_id, args.y_id);
        if (re['result'] == false) {
            return re;
        }
        var info = logic.general.getDefenseBlockInfo(args.generalId, re.troops);
        var re1 = logic.map.defenseBlock(args.x_id, args.y_id, info);
        return {
            txType: Const_1.StateTransition.DefenseBlock,
            result: true
        };
    };
    TransitionHandler.prototype.onCancelDefenseBlock = function (args) {
        var logic = this.genLogic(args.from, args.x_id, args.y_id);
        var remainTroop = logic.map.cancelDefenseBlock(args.x_id, args.y_id, args.from, args.generalId);
        logic.general.cancelDefenseBlock(args.generalId, remainTroop);
        return {
            txType: Const_1.StateTransition.CancelDefenseBlock,
            result: true
        };
    };
    TransitionHandler.prototype.onSetUnionId = function (args) {
        var logic = this.genLogic(args.from);
        if (args.force == false && logic.general.state.unionInit == true) {
            return {
                result: false,
                txType: Const_1.StateTransition.SetUnionId,
                error: 'unionId-have-set'
            };
        }
        logic.general.state.update({
            'unionId': args.union_id,
            "unionInit": true
        });
        var username = args.from;
        console.log("onSetUnionId username ", username, " applyInfo is ", args);
        this.addUserScoresAndExtraGeneral('onSetUnionId : ', args);
        if (args.random_union) {
            logic.city.addRandomCampGold();
        }
        return {
            result: true,
            txType: Const_1.StateTransition.SetUnionId,
            username: args.from,
            unionId: args.union_id
        };
    };
    TransitionHandler.prototype.onCheckSeasonFinish = function (args) {
        var logic = this.genLogic(args.from);
        var time = (0, Utils_1.getTimeStamp)();
        // const config = logic.map.seasonConfig.get(1)
        var config = logic.map.seasonState;
        if (time > config.season_end) {
            return {
                result: false,
                error: 'season-end'
            };
        }
    };
    TransitionHandler.prototype.onSetUnionWin = function (args) {
        var logic = this.genLogic(args.from, 0, 0);
        var re = logic.map.setUnionWin(args.unionId);
        return re;
    };
    TransitionHandler.prototype.onSetSeasonEnd = function (args) {
        var logic = this.genLogic(args.from);
        logic.map.setSeasonEnd();
        return {
            txType: Const_1.StateTransition.SetSeasonEnd,
            result: true
        };
    };
    TransitionHandler.prototype.checkUnionWin = function () {
        var logic = this.genGlobalLogic(0, 0);
        var re = logic.map.checkUnionWin();
        re['unionHaveSet'] = logic.map.gState.unionWinId != 0;
        return re;
    };
    TransitionHandler.prototype.getSeasonStatus = function () {
        var logic = this.genGlobalLogic();
        var re = logic.map.getSeasonStatus();
        re['endHaveSet'] = logic.map.gState.seasonEnd;
        return re;
    };
    TransitionHandler.prototype.onStartSeason = function (args) {
        var logic = this.genLogic(args.from);
        var gLogic = this.genGlobalLogic();
        if (gLogic.map.seasonState.haveSet) {
            return {
                result: true,
                txType: Const_1.StateTransition.StartSeason,
                error: 'seasonHaveSet'
            };
        }
        console.log("onStartSeason seasonState value ", gLogic.map.seasonState, {
            seasonId: args.seasonId,
            chain: args.season.chain,
            mapConfigId: args.mapConfigId
        });
        console.log("onStartSeason args are ", args);
        var applies = args.applies || {};
        for (var unionIdString in applies) {
            var unionId = parseInt(unionIdString);
            if (unionId < 1 || unionId > 4) {
                continue;
            }
            var userInfos = applies[unionIdString];
            for (var username in userInfos) {
                var applyInfo = userInfos[username];
                applyInfo['username'] = username;
                console.log("onStartSeason addUserScoresAndExtraGeneral ", applyInfo);
                this.addUserScoresAndExtraGeneral('onStartSeason: ', applyInfo);
            }
        }
        console.log("onStartSeason addUserScoresAndExtraGeneral ", applies);
        for (var item in args.season) {
            console.log("onStartSeason season item ", args.season[item] == undefined, ' ', args.season[item]);
            if (args.season[item] == undefined) {
                throw "start season args error";
            }
        }
        console.log("onStartSeason seasonState set ", gLogic.map.seasonState, {
            seasonId: args.seasonId,
            chain: args.season.chain,
            mapConfigId: args.mapConfigId
        });
        var mapConfigId = args.mapConfigId;
        var mapList = mapListGDS['Config'];
        var mapId = mapConfigId;
        if (mapConfigId === 0) {
            var len = mapList.length;
            mapId = Math.ceil(Math.random() * 987654) % len + 1;
        }
        console.log("onStartSeason seasonState set mapId ", { mapList: mapList, mapConfigId: mapConfigId, mapId: mapId });
        gLogic.map.seasonState.update({
            'seasonId': args.seasonId,
            'chain': args.season.chain,
            'season_reservation': args.season.apply_ts,
            'season_ready': args.season.prepare_ts,
            'season_open': args.season.start_ts,
            'season_end': args.season.end_ts,
            'unionRewardValue': args.season.reward_amount_1,
            'rankRewardValue': args.season.reward_amount_2,
            'rankConfigFromTo': args.season.rank_config_fromto,
            'rankConfigValue': args.season.rank_config_value,
            'mapId': mapId
        });
        console.log("onStartSeason seasonState updated ", gLogic.map.seasonState);
        var priceInfo = args.priceInfo || {};
        this.updateTokenPriceInfo(gLogic, 'initial', priceInfo);
        console.log("onStartSeason seasonState return ", {
            txType: Const_1.StateTransition.StartSeason,
            result: true
        });
        return {
            txType: Const_1.StateTransition.StartSeason,
            result: true
        };
    };
    TransitionHandler.prototype.addUserScoresAndExtraGeneral = function (type, applyInfo) {
        console.log('addUserScoresAndExtraGeneral:', type, applyInfo);
        var username = applyInfo.username || applyInfo.from;
        var unionId = applyInfo.union_id;
        var logic = this.genLogic(username);
        logic.general.state.update({
            'unionId': unionId,
            "unionInit": true
        });
        console.log("addUserScoresAndExtraGeneral username ", username, " applyInfo is ", applyInfo);
        // applyInfo.wallet_value = applyInfo.wallet_token_value + applyInfo.wallet_nft_value
        var wallet_token_value = applyInfo.wallet_token_value || 0;
        var wallet_nft_value = applyInfo.wallet_nft_value || 0;
        var userScores = {};
        var userScore1 = wallet_token_value / 1 + wallet_nft_value / 1;
        userScores[username] = userScore1 || 0.01;
        logic.general.addUserScores(userScores);
        var userScore2 = logic.general.getUserScore(username);
        var vipBuffs = logic.general.getVipBuffs(userScore2);
        var general_ids = applyInfo.general_ids || [];
        var generalIds = general_ids.concat(vipBuffs.add_general_id || []);
        logic.general.addextraGeneral(generalIds);
        console.log('addUserScoresAndExtraGeneral getVipBuffs: ', username, ' userScore: ', { userScore1: userScore1, userScore2: userScore2 }, vipBuffs);
        logic.city.addPreRegisterGold();
        if (applyInfo.random_union) {
            logic.city.addRandomCampGold();
        }
        console.log('addUserScoresAndExtraGeneral end: ', username);
    };
    TransitionHandler.prototype.updateTokenPriceInfo = function (gLogic, typ, priceInfo) {
        var _a;
        console.log('updateTokenPriceInfo 1:', typ, priceInfo);
        var newTokenPriceInfo = (_a = {},
            _a[typ] = priceInfo,
            _a.lastUpdate = (0, Utils_1.getTimeStamp)(),
            _a);
        console.log('updateTokenPriceInfo 2:', newTokenPriceInfo);
        gLogic.map.tokenPriceInfo.update(newTokenPriceInfo);
        console.log('updateTokenPriceInfo 3:', gLogic.map.tokenPriceInfo);
    };
    TransitionHandler.prototype.recordEvent = function (typ, event) {
        console.log('recordEvent type:', typ, ', event:', event);
        if (this.eventRecorderFunc) {
            this.eventRecorderFunc(typ, event);
        }
    };
    TransitionHandler.prototype.updateRewardState = function (rewardState, username, oldGlory, newGlory, unionId) {
        var unionLists = rewardState.unionGloryRankInfo;
        var globalList = rewardState.globalGloryRankInfo;
        var unionList = (0, state_1.copyObj)(unionLists[unionId - 1]);
        (0, Utils_1.addToSortList)(unionList, username, oldGlory, newGlory, unionId);
        (0, Utils_1.addToSortList)(globalList, username, oldGlory, newGlory, unionId);
        unionLists[unionId - 1] = unionList;
        console.log("after reward update: union:" + JSON.stringify(unionList) + "global" + globalList + "unionId:" + unionId);
        rewardState.update({
            unionGloryRankInfo: unionLists,
            globalGloryRankInfo: globalList
        });
    };
    TransitionHandler.prototype.onSetIconId = function (args) {
        var logic = this.genLogic(args.from);
        return logic.general.setIconId(args.iconId);
    };
    TransitionHandler.prototype.onRecharge = function (args) {
        var logic = this.genLogic(args.username);
        return logic.city.recharge(args.rechargeId, args.amount);
    };
    TransitionHandler.prototype.onUserFinsishOutChainActivity = function (args) {
        console.log('FinishOutChainUserActivity args:', args);
        var logic = this.genLogic(args.username);
        var re = logic.city.finishOutChainUserActivity(args.type, args.action, logic.strategy);
        console.log('FinishOutChainUserActivity re:', re);
        return re;
    };
    TransitionHandler.prototype.onAddTestResource = function (args) {
        var logic = this.genLogic(args.from);
        return logic.city.addTestResource();
    };
    TransitionHandler.prototype.onBuyOffer = function (args) {
        var logic = this.genLogic(args.from);
        var offerId = args.offerId;
        console.log('onBuyOffer 1:', offerId);
        return logic.city.buyOffer(offerId);
    };
    TransitionHandler.prototype.onRecoverMorale = function (args) {
        var logic = this.genLogic(args.from);
        return logic.general.recoverMorale(args.resourceType);
    };
    TransitionHandler.prototype.onBuyStrategyPoint = function (args) {
        var logic = this.genLogic(args.from);
        return logic.strategy.buyStrategyPoint(args.amount);
    };
    TransitionHandler.prototype.onStrategyBuyTroop = function (args) {
        var logic = this.genLogic(args.from);
        return logic.strategy.buyTroop(args.times || 1);
    };
    TransitionHandler.prototype.onStrategyBuySilver = function (args) {
        var logic = this.genLogic(args.from);
        return logic.strategy.buySilver(args.times || 1);
    };
    TransitionHandler.prototype.onStrategyBuyMorale = function (args) {
        var logic = this.genLogic(args.from);
        return logic.strategy.buyMorale();
    };
    TransitionHandler.prototype.onStrategyBuyProtect = function (args) {
        var logic = this.genLogic(args.from);
        var re = logic.strategy.buyProtect();
        var defenseList = (0, state_1.copyObj)(logic.general.state.defenseBlockList);
        for (var _i = 0, defenseList_1 = defenseList; _i < defenseList_1.length; _i++) {
            var item = defenseList_1[_i];
            this.onCancelDefenseBlock({
                from: args.from,
                x_id: item.x_id,
                y_id: item.y_id,
                generalId: item.generalId
            });
        }
        return re;
    };
    TransitionHandler.prototype.onStrategyBuyProtect1 = function (args) {
        var logic = this.genLogic(args.from);
        var re = logic.strategy.buyProtect1();
        var defenseList = (0, state_1.copyObj)(logic.general.state.defenseBlockList);
        for (var _i = 0, defenseList_2 = defenseList; _i < defenseList_2.length; _i++) {
            var item = defenseList_2[_i];
            this.onCancelDefenseBlock({
                from: args.from,
                x_id: item.x_id,
                y_id: item.y_id,
                generalId: item.generalId
            });
        }
        return re;
    };
    TransitionHandler.prototype.onStrategyBuyStore = function (args) {
        var logic = this.genLogic(args.from);
        return logic.strategy.buyStore();
    };
    TransitionHandler.prototype.onMiningBlock = function (args) {
        var logic = this.genLogic(args.from, args.x_id, args.y_id);
        var times = args.times || 1;
        if (times < 1) {
            times = 1;
        }
        if (logic.general.state.unionId != logic.map.getBelongInfo(args.x_id, args.y_id)) {
            return {
                result: false,
                txType: Const_1.StateTransition.MiningBlock,
                error: 'unionId-error'
            };
        }
        if (!logic.map.miningable(args.x_id, args.y_id, times)) {
            return {
                result: false,
                txType: Const_1.StateTransition.MiningBlock,
                error: "remainSilver-too-less"
            };
        }
        var generalRe = logic.general.miningBlock(args.generalId, times);
        if (!generalRe.result) {
            return generalRe;
        }
        var num = logic.map.miningBlock(args.x_id, args.y_id, times);
        logic.city.useSilver(-num);
        return {
            result: true,
            txType: Const_1.StateTransition.MiningBlock,
            getSilver: num
        };
    };
    //not use, just for clear
    TransitionHandler.prototype.onInitUserStates = function (args) {
        var logic = this.genLogic(args.username);
        var seasonState = logic.map.seasonState;
        var initState = (0, State_1.GetInitState)('transition.onInitUserStates');
        console.log('state used to update', initState[Const_1.StateName.City], initState[Const_1.StateName.General], initState[Const_1.StateName.Strategy]);
        logic.city.state.update(initState[Const_1.StateName.City]);
        logic.general.state.update(initState[Const_1.StateName.General]);
        logic.strategy.state.update(initState[Const_1.StateName.Strategy]);
        console.log('state after update', logic.city.state, logic.general.state, logic.strategy.state);
        logic.general.updateDefenseInfo();
        return {
            txType: Const_1.StateTransition.InitUserStates,
            result: true
        };
    };
    // not use in prod, test.clear call onInitGlobalStates
    TransitionHandler.prototype.onInitGlobalStates = function (args) {
        var initState = (0, State_1.GetInitState)('transition.onInitGlobalStates');
        var mapGlobalState = this.stateManger.get({
            id: "".concat(Const_1.StateName.MapGlobalInfo)
        });
        var rewardGlobalState = this.stateManger.get({
            id: "".concat(Const_1.StateName.RewardGloablState)
        });
        var activityState = this.stateManger.get({
            id: "".concat(Const_1.StateName.Activity)
        });
        activityState['id'] = "".concat(Const_1.StateName.Activity);
        console.log('activityState detail', JSON.stringify(activityState));
        mapGlobalState.update(initState[Const_1.StateName.MapGlobalInfo]);
        rewardGlobalState.update(initState[Const_1.StateName.RewardGloablState]);
        console.log('activityState to set', initState[Const_1.StateName.Activity]);
        activityState.update(initState[Const_1.StateName.Activity]);
        for (var block in mapGDS) {
            var key = "".concat(Const_1.StateName.BlockInfo, ":1:").concat(block);
            var blockState = this.stateManger.get({ id: key });
            blockState.update(initState[key]);
        }
        return {
            txType: Const_1.StateTransition.InitGlobalStates,
            result: true
        };
    };
    TransitionHandler.prototype.onDonateSilver = function (args) {
        var logic = this.genLogic(args.from);
        return logic.activity.donateSilver(args.activityId, args.amount);
    };
    TransitionHandler.prototype.onRegularTask = function (args) {
        var logic = this.genLogic(args.from);
        var gLogic = this.genGlobalLogic();
        var seasonState = logic.map.seasonState;
        console.log('onRegularTask start:', seasonState, args);
        if (seasonState.season_open > 0) {
            this.onActivityReword(gLogic, seasonState);
        }
        var priceInfo = args.priceInfo || {};
        this.updateTokenPriceInfo(gLogic, 'current', priceInfo);
        this.runCodList('onRegularTask');
        return {
            txType: Const_1.StateTransition.RegularTask,
            result: true
        };
    };
    TransitionHandler.prototype.onActivityReword = function (gLogic, seasonState) {
        var activityList = gLogic.activity.getBeforeActivitiesForReward(seasonState);
        var time = (0, Utils_1.getTimeStamp)();
        console.log('onRegularTask activity list:', time, activityList);
        for (var _i = 0, activityList_1 = activityList; _i < activityList_1.length; _i++) {
            var activity = activityList_1[_i];
            console.log('onRegularTask activity item:', time, activity);
            if (activity.startTime > 1690000000 && time > activity.startTime + activity.lastTime && !gLogic.activity.state.haveSendReward[activity.activityId]) {
                //send activity reward
                console.log("onRegularTask activity reward id:", activity.activityId);
                var haveSendReward = gLogic.activity.state.haveSendReward;
                for (var _a = 0, _b = (gLogic.activity.state.activityData[activity.activityId] || []); _a < _b.length; _a++) {
                    var userdata = _b[_a];
                    var tempLogic = this.genLogic(userdata.username);
                    var rank = tempLogic.activity.getActivityRank(activity.activityId, userdata.username, userdata.value);
                    tempLogic.city.useGold(-rank.rankReward);
                }
                haveSendReward[activity.activityId] = true;
                gLogic.activity.state.update({
                    'haveSendReward': haveSendReward
                });
                console.log("onRegularTask activity reward over id:", activity.activityId, haveSendReward);
            }
        }
        console.log("onRegularTask activity reward over all:", gLogic.activity.state.haveSendReward);
    };
    TransitionHandler.prototype.onSetGuideStep = function (args) {
        var logic = this.genLogic(args.from);
        return logic.city.setGuideStep(args.type, args.step);
    };
    TransitionHandler.prototype.onFirstLogin = function (args) {
        var logic = this.genLogic(args.from);
        if (logic.city.state.firstLogin != -1) {
            return {
                result: false,
                txType: Const_1.StateTransition.FirstLogin,
                error: "it-is-not-first-login"
            };
        }
        var time = (0, Utils_1.getTimeStamp)();
        logic.city.state.update({
            firstLogin: time
        });
        return {
            txType: Const_1.StateTransition.FirstLogin,
            result: true
        };
    };
    return TransitionHandler;
}());
exports.TransitionHandler = TransitionHandler;
