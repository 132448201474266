import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { Throne, ComponentType } from 'throne-underlying';
import { logTrace } from '../utils/log'

let strategyInstance = null;

export const strategyInit = createAsyncThunk("strategy/init", async () => {
    if(strategyInstance){
        let { pointInfo, need, remainTime, info } = getDataByState();
        return { pointInfo, need, remainTime, info };
    }
    const response = await new Promise((resolve, reject) => {
        Throne.instance().initComponent(ComponentType.Strategy, (_policy) => {
            strategyInstance = _policy;
            // logTrace(strategyInstance, 'strategy.instance');
            let { pointInfo, need, remainTime, info } = getDataByState();
            resolve({ pointInfo, need, remainTime, info });
        });
    });
    return response;
});

export const pointUpdate = createAsyncThunk("strategy/update", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.onStateUpdate(() => {
            logTrace(strategyInstance, 'strategy.pointUpdate 2');
            let { pointInfo, need, remainTime, info } = getDataByState();
            logTrace({ pointInfo, need, remainTime, info }, 'strategy.pointUpdate');
            resolve({ pointInfo, need, remainTime, info });
        });
    });
    return response;
});

export const refreshPoints = createAsyncThunk("strategy/refresh", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        let { pointInfo, need, remainTime, info } = getDataByState();
        logTrace({ pointInfo, need, remainTime, info }, 'strategy.refreshPoints');
        resolve({ pointInfo, need, remainTime, info });
    });
    return response;
});

export const buyStrategyPoint = createAsyncThunk("point/buy", async (data) => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        // setTimeout(function(){
        //     resolve({result: true});
        // }, 4000);
        strategyInstance.buyStrategyPoint(data.amount, (res) => {
            logTrace(res, 'strategy.buyStrategyPoint');
            resolve(res.result);
        });
    });
    return response;
});

export const buySilver = createAsyncThunk("silver/buy", async (times) => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buySilver(times, (res) => {
            logTrace(res, 'strategy.buySilver');
            resolve(res.result);
        });
    });
    return response;
});

export const buyTroop = createAsyncThunk("troop/buy", async (times) => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buyTroop(times, (res) => {
            logTrace(res, 'strategy.buyTroop');
            resolve(res.result);
        });
    });
    return response;
});

export const buyMorale = createAsyncThunk("morale/buy", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buyMorale((res) => {
            logTrace(res, 'strategy.buyMorale');
            resolve(res.result);
        });
    });
    return response;
});

export const buyProtect = createAsyncThunk("protect/buy", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buyProtect((res) => {
            logTrace(res, 'strategy.buyProtect');
            resolve(res.result);
        });
    });
    return response;
});

export const buyProtect1 = createAsyncThunk("protect1/buy", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buyProtect1((res) => {
            // console.log(res, 'strategy.buyProtect1');
            resolve(res.result);
        });
    });
    return response;
});

export const buyStore = createAsyncThunk("store/buy", async () => {
    if(!strategyInstance){ 
        return;
    }
    const response = await new Promise((resolve, reject) => {
        strategyInstance.buyStore((res) => {
            logTrace(res, 'strategy.buyStore');
            resolve(res.result);
        });
    });
    return response;
});

export const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    strategyReady: false,
    pointInfo: {},
    need: {},
    info: {},
    remainTime: 0,
    pointNeed: 0,
    buyResult: {}
});

export const policySlice = createSlice({
    name: 'strategies',
    initialState,
    reducers: {
        getBuyStrategyPointNeed: function(state, action){
            if(!strategyInstance){
                return;
            }
            let { amount } = action.payload;
            let pointNeed = strategyInstance.getBuyStrategyPointNeed(amount);
            // console.log({amount, pointNeed}, 'strategy.pointNeed');
            logTrace(pointNeed, 'strategy.pointNeed');
            state['pointNeed'] = pointNeed;
        },
        resetBuyResult: function(state, action){
            // console.log('resetBuyResult function');
            state['buyResult'] = {};
        },
    },
    extraReducers: builder => {
        builder.addCase(strategyInit.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let { pointInfo, need, remainTime, info } = action.payload || {};
            state['pointInfo'] = pointInfo || {};
            state['need'] = need || {};
            state['remainTime'] = remainTime || 0;
            state['info'] = info || {};
            state['strategyReady'] = true;
        });
        builder.addCase(pointUpdate.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let { pointInfo, need, remainTime, info } = action.payload || {};
            // console.log({ pointInfo, need, remainTime, info }, 'pointUpdate')
            state['pointInfo'] = pointInfo || {};
            state['need'] = need || {};
            state['remainTime'] = remainTime || 0;
            state['info'] = info || {};
        });
        builder.addCase(refreshPoints.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let { pointInfo, need, remainTime, info } = action.payload;
            // console.log({ pointInfo, need, remainTime, info }, 'refreshPoints')
            state['pointInfo'] = pointInfo || {};
            state['need'] = need || {};
            state['remainTime'] = remainTime || 0;
            state['info'] = info || {};
        });
        builder.addCase(buyStrategyPoint.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyStrategyPoint';
            state['buyResult'] = result;
        });
        builder.addCase(buySilver.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buySilver';
            state['buyResult'] = result;
        });
        builder.addCase(buyTroop.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyTroop';
            state['buyResult'] = result;
        });
        builder.addCase(buyMorale.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyMorale';
            state['buyResult'] = result;
        });
        builder.addCase(buyProtect.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyProtect';
            state['buyResult'] = result;
        });
        builder.addCase(buyProtect1.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyProtect1';
            state['buyResult'] = result;
        });
        builder.addCase(buyStore.fulfilled, (state, action) => {
            if(!strategyInstance){
                return;
            }
            let result = action.payload;
                result.method = 'buyStore';
            state['buyResult'] = result;
        });
    }
});

export const { getBuyStrategyPointNeed, resetBuyResult } = policySlice.actions;
export default policySlice.reducer;

function getDataByState(){
    let pointInfo = strategyInstance.getStrategyPointInfo();
    let need = strategyInstance.getStrategyNeed();
    let remainTime = strategyInstance.getRecoverStrategyRemainTime();
    let info = strategyInstance.getStrategiesInfo();
    // console.log({ pointInfo, need, remainTime, info }, 'getDataByState')
    return { pointInfo, need, remainTime, info }
}
