"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainComponent = void 0;
var _1 = require(".");
var ChainComponent = /** @class */ (function () {
    function ChainComponent(mediator) {
        var _this = this;
        this.type = _1.ComponentType.Strategy;
        this.mediator = mediator;
        this.callbackList = [];
        this.mediator.setChainBlockCallback(function (msg) { return _this.HandleMsg(msg); });
    }
    ChainComponent.prototype.onStateUpdate = function (callback) {
    };
    ChainComponent.prototype.onReceiveChainBlockInfo = function (callback) {
        this.callbackList.push(callback);
    };
    ChainComponent.prototype.HandleMsg = function (msg) {
        //tofixed
        // if(msg.Data.txType != undefined){
        for (var i = 0; i < this.callbackList.length; i++) {
            this.callbackList[i](msg);
        }
        // }
    };
    return ChainComponent;
}());
exports.ChainComponent = ChainComponent;
