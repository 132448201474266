import { idDev, isProd } from '../utils/ui-res';

import apeImg from './nfts/ape.png';
import airoseImg from './nfts/airose.png';
import seibaraImg from './nfts/seibara.png';
import realboredapeImg from './nfts/realboredape.png';
import thecr7nftImg from './nfts/thecr7nft.png';
import spaceidImg from './nfts/spaceid.png';
import emptyImg from './nfts/empty.png';

const NFTypes = {
    "0x0f4c5a429166608f9225e094f7e66b0bf68a53b9": "airose",
    "0x99f43f11cc6b5c378ebc2cb4eed7cc4f5f0006c0": "apex",
    "0xa8c343905212449e079b191a83fe42bfeba024b3": "seibaraclub",
    "0x102a35917e9f2ff08ffc5dc4fe3e5a400e4f33a7": "thecr7nftcollection",
    "0x9dbc25e38099020e3d330239b7b4cffce7162dcf": "thecr7nftcollection", //mock
    "0xdf24ffad6bcd46dd3275a86fdade8a2ebf6bd110": "binanceregularnft",   //mock
    "0x1ddb2c0897daf18632662e71fdd2dbdc0eb3a9ec": "binanceregularnft"
};

var nfts = {
    '0x': {},
    airose: { 
    	name: 'Airose',
    	address: '0x0f4c5a429166608f9225e094f7e66b0bf68a53b9', 
    	avatar: apeImg,
        emptyImg: apeImg,
        gpt: true
    },
    apex: { 
    	name: 'Apex',
    	address: '0x99f43f11cc6b5c378ebc2cb4eed7cc4f5f0006c0', 
    	avatar: airoseImg,
        emptyImg: airoseImg,
        gpt: true
    },
    seibaraclub: { 
    	name: 'SeibaraClub',
    	address: '0xa8c343905212449e079b191a83fe42bfeba024b3', 
    	avatar: seibaraImg,
        emptyImg: seibaraImg,
        gpt: true
    },
    thecr7nftcollection: { 
    	name: 'The CR7 NFT collection',
    	address: '0x102a35917e9f2ff08ffc5dc4fe3e5a400e4f33a7', 
    	avatar: thecr7nftImg,
        emptyImg: thecr7nftImg,
        gpt: true
    },
    binanceregularnft: { 
        name: 'REAL BORED APE',
        address: '0x1ddb2c0897daf18632662e71fdd2dbdc0eb3a9ec', 
        avatar:  realboredapeImg,
        emptyImg:  realboredapeImg,
        gpt: true
    },
    realboredape: { 
        name: 'REAL BORED APE',
        address: '0x1ddb2c0897daf18632662e71fdd2dbdc0eb3a9ec', 
        avatar:  realboredapeImg,
        emptyImg:  realboredapeImg,
        gpt: true
    },
    spaceid: { 
        name: 'SPACE ID',
        address: '0xe3b1d32e43ce8d658368e2cbff95d57ef39be8a6', 
        avatar:  spaceidImg,
        emptyImg:  spaceidImg,
        gpt: false
    }
};

export {
	emptyImg,
	nfts,
	NFTypes
}