"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.offerConfigFromGDS = exports.OfferConfig = exports.vipConfigFromGDS = exports.VipConfig = exports.ActivityTypeConfigFromGDS = exports.ActivityTypeConfig = exports.StrategyBuyConfigFromGDS = exports.StrategyBuyConfig = exports.moraleReduceGap = exports.normalMorale = exports.maxMorale = exports.minMorale = exports.RechargeConfigFromGDS = exports.RechargeConfigs = exports.SeasonConfigFromGDS = exports.SeasonConfig = exports.GenBlockDefenseTroop = exports.getMapConfigFromGDS = exports.loadMapGDS = exports.getMapOffset = exports.GeneralConfigFromGDS = exports.parameterConfig = exports.BuffTable = exports.CityConfigFromGDS = exports.MapConfig = exports.Parameter = exports.FacilityLimit = void 0;
var fortressGDS = require("../../gds/fortress.json");
var militaryCenterGDS = require("../../gds/militarycenter.json");
var wallGDS = require("../../gds/wall.json");
var storeGDS = require("../../gds/store.json");
var infantryCampGDS = require("../../gds/infantrycamp.json");
var cavalryCampGDS = require("../../gds/cavalrycamp.json");
var archerCampGDS = require("../../gds/archercamp.json");
var trainingCenterGDS = require("../../gds/trainingcenter.json");
var homeGDS = require("../../gds/home.json");
var hospitalGDS = require("../../gds/hospital.json");
var assemblyGDS = require("../../gds/assembly.json");
var buildingCount = require("../../gds/building_count.json");
var qualificationGDS = require("../../gds/general.json");
var buffGDS = require("../../gds/buff_table.json");
var parameterGDS = require("../../gds/parameter.json");
var seasonGDS = require("../../gds/season.json");
var rechargeGDS = require("../../gds/payment.json");
var strategyBuyGDS = require("../../gds/buy_stamina_times.json");
var activityTypeGDS = require("../../gds/activity.json");
var vipGDS = require("../../gds/vip.json");
var offerGDS = require("../../gds/offer.json");
var mapListGDS = require("../../gds/map_list.json");
var mapGDS1 = require("../../gds/map_config_1.json");
var mapGDS2 = require("../../gds/map_config_2.json");
var mapGDS3 = require("../../gds/map_config_3.json");
var mapGDS4 = require("../../gds/map_config_4.json");
var mapGDS5 = require("../../gds/map_config_5.json");
var mapGDS6 = require("../../gds/map_config_6.json");
var Const_1 = require("../Const");
var config_1 = require("../../Core/config");
var FacilityLimit = /** @class */ (function () {
    function FacilityLimit(obj) {
        this.max_count = obj['max_count'] ? obj['max_count'] : 1;
        this.building_name = obj['building_name'] ? obj['building_name'] : 'error';
        this.order = obj['order'] ? obj['order'] : 1;
    }
    return FacilityLimit;
}());
exports.FacilityLimit = FacilityLimit;
var Parameter = /** @class */ (function () {
    function Parameter(obj) {
        this.default_defense_general = [];
        for (var key in obj) {
            if (obj[key]['value'].indexOf('|') != -1) {
                var tempList = obj[key]['value'].split('|');
                for (var _i = 0, tempList_1 = tempList; _i < tempList_1.length; _i++) {
                    var item = tempList_1[_i];
                    this[key].push(parseInt(item));
                }
            }
            else if (obj[key]['value'].indexOf('.') != -1) {
                this[key] = parseFloat(obj[key]['value']);
            }
            else {
                this[key] = parseInt(obj[key]['value']);
            }
        }
        //   this.general_troops_coefficient = obj['general_troops_coefficient'] ? parseFloat(obj['general_troops_coefficient']['value']) : 1;
        //   this.general_stamina_recovery = obj['general_stamina_recovery'] ?  parseInt(obj['general_stamina_recovery']['value']) : 3600;
        //   this.general_skill_max_level = obj['general_skill_max_level'] ? parseInt(obj['general_skill_max_level']['value']) : 20;
        //   this.general_max_level = obj['general_max_level'] ? parseInt(obj['general_max_level']['value']) : 100;
        //   this.troops_base_load = obj['troops_base_load']? parseInt(obj['troops_base_load']['value']): 100;
        //   this.victory_need_occupy_times = obj['victory_need_occupy_times']? parseInt(obj['victory_need_occupy_times']['value']): 28800
        //   this.occupy_block_protect_times = obj['occupy_block_protect_times']? parseInt(obj['occupy_block_protect_times']['value']): 7200
        //   this.battle_victory_get_glory = obj['battle_victory_get_glory']? parseInt(obj['battle_victory_get_glory']['value']): 100
        //   let tempList = (obj['default_defense_general']['value'] as string).split('|')
        //   this.recovery_one_morale_need_gold = obj['recovery_one_morale_need_gold']? parseInt(obj['recovery_one_morale_need_gold']['value']): 10
        //   for(let item of tempList){
        // 	this.default_defense_general.push(parseInt(item))
        //   }
    }
    return Parameter;
}());
exports.Parameter = Parameter;
var MapConfig = /** @class */ (function () {
    function MapConfig(obj) {
        this.config = {};
        for (var key in obj) {
            var row = obj[key];
            var temp = {
                x_id: row['x_id'],
                y_id: row['y_id'],
                victory_occupy_reward: [],
                type: row['type'],
                troops: [],
                silver_total_number: row['silver_total_number'],
                parameter: row['parameter'],
                gather_silver_speed: row['gather_silver_speed'],
                durability: row['durability'],
                buff_id: row['buff_id'],
                area: row['area']
            };
            for (var _i = 0, _a = row['victory_occupy_reward']; _i < _a.length; _i++) {
                var reward = _a[_i];
                var rtemp = {
                    type: reward['type'],
                    name: reward['name'],
                    count: reward['count']
                };
                temp.victory_occupy_reward.push(rtemp);
            }
            for (var _b = 0, _c = row['troops']; _b < _c.length; _b++) {
                var troop = _c[_b];
                var ttemp = {
                    type: troop['type'],
                    defense: troop['defense'],
                    count: troop['count'],
                    attack: troop['attack']
                };
                temp.troops.push(ttemp);
            }
            this.config[key] = temp;
        }
    }
    MapConfig.prototype.get = function (x_id, y_id) {
        var key = x_id + '^' + y_id;
        return this.config[key];
    };
    return MapConfig;
}());
exports.MapConfig = MapConfig;
exports.CityConfigFromGDS = {
    facilityConfig: (_a = {},
        _a[Const_1.CityFacility.Fortress] = new config_1.ConfigContainer(fortressGDS.Config),
        _a[Const_1.CityFacility.MilitaryCenter] = new config_1.ConfigContainer(militaryCenterGDS.Config),
        _a[Const_1.CityFacility.Wall] = new config_1.ConfigContainer(wallGDS.Config),
        _a[Const_1.CityFacility.Store] = new config_1.ConfigContainer(storeGDS.Config),
        _a[Const_1.CityFacility.InfantryCamp] = new config_1.ConfigContainer(infantryCampGDS.Config),
        _a[Const_1.CityFacility.CavalryCamp] = new config_1.ConfigContainer(cavalryCampGDS.Config),
        _a[Const_1.CityFacility.ArcherCamp] = new config_1.ConfigContainer(archerCampGDS.Config),
        _a[Const_1.CityFacility.TrainingCenter] = new config_1.ConfigContainer(trainingCenterGDS.Config),
        _a[Const_1.CityFacility.Home] = new config_1.ConfigContainer(homeGDS.Config),
        _a[Const_1.CityFacility.Hospital] = new config_1.ConfigContainer(hospitalGDS.Config),
        _a[Const_1.CityFacility.Assembly] = new config_1.ConfigContainer(assemblyGDS.Config),
        _a),
    limit: (_b = {},
        _b[Const_1.CityFacility.Fortress] = new FacilityLimit(buildingCount.fortress),
        _b[Const_1.CityFacility.MilitaryCenter] = new FacilityLimit(buildingCount.militarycenter),
        _b[Const_1.CityFacility.Wall] = new FacilityLimit(buildingCount.wall),
        _b[Const_1.CityFacility.Store] = new FacilityLimit(buildingCount.store),
        _b[Const_1.CityFacility.InfantryCamp] = new FacilityLimit(buildingCount.infantrycamp),
        _b[Const_1.CityFacility.CavalryCamp] = new FacilityLimit(buildingCount.cavalrycamp),
        _b[Const_1.CityFacility.ArcherCamp] = new FacilityLimit(buildingCount.archercamp),
        _b[Const_1.CityFacility.TrainingCenter] = new FacilityLimit(buildingCount.trainingcenter),
        _b[Const_1.CityFacility.Home] = new FacilityLimit(buildingCount.home),
        _b[Const_1.CityFacility.Hospital] = new FacilityLimit(buildingCount.hospital),
        _b[Const_1.CityFacility.Assembly] = new FacilityLimit(buildingCount.assembly),
        _b),
};
var BuffTable = /** @class */ (function () {
    function BuffTable(containers) {
        this.config = {};
        //standlize container
        for (var key in containers) {
            var row = containers[key];
            this.config[row.buff_id] = row;
        }
    }
    BuffTable.prototype.get = function (id) {
        return this.config[id];
    };
    return BuffTable;
}());
exports.BuffTable = BuffTable;
exports.parameterConfig = new Parameter(parameterGDS);
exports.GeneralConfigFromGDS = {
    qualification: new config_1.ConfigContainer(qualificationGDS.Config),
    buff: new BuffTable(buffGDS.Config),
    parameter: exports.parameterConfig
};
function getMapOffset(mapId) {
    var offsets = { x: 10, y: 10, rows: 21, cols: 11, maxSize: 21 };
    for (var _i = 0, _a = mapListGDS['Config']; _i < _a.length; _i++) {
        var item = _a[_i];
        if (item['map_id'] === mapId) {
            offsets.x = item.cols - 1;
            offsets.y = (item.rows - 1) / 2;
            offsets.maxSize = Math.max(item.rows, item.cols);
            offsets.rows = item.rows;
            offsets.cols = item.cols;
        }
    }
    return offsets;
}
exports.getMapOffset = getMapOffset;
function loadMapGDS(mapId) {
    var list = {
        1: mapGDS1,
        2: mapGDS2,
        3: mapGDS3,
        4: mapGDS4,
        5: mapGDS5,
        6: mapGDS6
    };
    return list[mapId] || mapGDS1;
}
exports.loadMapGDS = loadMapGDS;
function getMapConfigFromGDS(mapId) {
    mapId = mapId || 1;
    var mapGDS = loadMapGDS(mapId);
    // console.log('mapId dataconfig:', mapId, mapGDS);
    var MapConfigFromGDS = new MapConfig(mapGDS);
    return MapConfigFromGDS;
}
exports.getMapConfigFromGDS = getMapConfigFromGDS;
// export var MapConfigFromGDS = new MapConfig(mapGDS)
function GenBlockDefenseTroop(x_id, y_id, mapId) {
    var MapConfigFromGDS = getMapConfigFromGDS(mapId);
    var row = MapConfigFromGDS.get(x_id, y_id);
    var troops = row.troops;
    var re = [];
    for (var _i = 0, troops_1 = troops; _i < troops_1.length; _i++) {
        var troop = troops_1[_i];
        var temp = {
            username: '',
            generalId: -1,
            generalLevel: 1,
            generalType: troop.type,
            attack: troop.attack,
            defense: troop.defense,
            troops: troop.count,
            unionId: 0,
            iconId: -1
        };
        re.push(temp);
    }
    return re;
}
exports.GenBlockDefenseTroop = GenBlockDefenseTroop;
var SeasonConfig = /** @class */ (function () {
    function SeasonConfig(obj) {
        var list = obj['Config'];
        this.config = [];
        for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
            var seasonConf = list_1[_i];
            var season = {
                seasonId: '',
                chain: '',
                show_season_victory_reward: [],
                show_rank_reward: [],
                show_occupy_reward: [],
                season_reservation: 0,
                season_ready: 0,
                season_open: 0,
                season_end: 0,
                rank_reward: [],
                activities: [],
                id: seasonConf['id']
            };
            for (var _a = 0, _b = (seasonConf['dailyactivity'] || []); _a < _b.length; _a++) {
                var item = _b[_a];
                var actConf = {
                    relativeTime: item['day'],
                    startTime: 0,
                    type: item['activity']
                };
                season.activities.push(actConf);
                // console.log('season.activities', season.activities);
            }
            for (var _c = 0, _d = seasonConf['show_season_victory_reward']; _c < _d.length; _c++) {
                var item = _d[_c];
                season.show_season_victory_reward.push(item);
            }
            for (var _e = 0, _f = seasonConf['show_rank_reward']; _e < _f.length; _e++) {
                var item = _f[_e];
                season.show_rank_reward.push(item);
            }
            for (var _g = 0, _h = seasonConf['rank_reward']; _g < _h.length; _g++) {
                var item = _h[_g];
                season.rank_reward.push(item);
            }
            this.config.push(season);
        }
    }
    SeasonConfig.prototype.get = function (id) {
        return this.config[id - 1];
    };
    return SeasonConfig;
}());
exports.SeasonConfig = SeasonConfig;
exports.SeasonConfigFromGDS = new SeasonConfig(seasonGDS);
var RechargeConfigs = /** @class */ (function () {
    function RechargeConfigs(obj) {
        this.config = [];
        for (var _i = 0, _a = (obj['Config'] || []); _i < _a.length; _i++) {
            var item = _a[_i];
            this.config.push(item);
        }
    }
    RechargeConfigs.prototype.get = function (id) {
        for (var i = 0; i < this.config.length; i++) {
            if (this.config[i].internal_id == id) {
                return this.config[i];
            }
        }
        return undefined;
    };
    return RechargeConfigs;
}());
exports.RechargeConfigs = RechargeConfigs;
exports.RechargeConfigFromGDS = new RechargeConfigs(rechargeGDS);
exports.minMorale = 80;
exports.maxMorale = 120;
exports.normalMorale = 100;
exports.moraleReduceGap = 15 * 60;
var StrategyBuyConfig = /** @class */ (function () {
    function StrategyBuyConfig(obj) {
        this.config = [];
        for (var _i = 0, _a = obj['Config']; _i < _a.length; _i++) {
            var item = _a[_i];
            this.config.push(item['need_gold']);
        }
    }
    StrategyBuyConfig.prototype.getMaxTimes = function () {
        return this.config.length;
    };
    return StrategyBuyConfig;
}());
exports.StrategyBuyConfig = StrategyBuyConfig;
exports.StrategyBuyConfigFromGDS = new StrategyBuyConfig(strategyBuyGDS);
var ActivityTypeConfig = /** @class */ (function () {
    function ActivityTypeConfig(obj) {
        this.config = [];
        for (var _i = 0, _a = obj['Config']; _i < _a.length; _i++) {
            var item = _a[_i];
            this.config.push(item);
        }
    }
    ActivityTypeConfig.prototype.get = function (type) {
        return this.config[type - 1];
    };
    return ActivityTypeConfig;
}());
exports.ActivityTypeConfig = ActivityTypeConfig;
exports.ActivityTypeConfigFromGDS = new ActivityTypeConfig(activityTypeGDS);
var VipConfig = /** @class */ (function () {
    function VipConfig(obj) {
        this.config = [];
        for (var _i = 0, _a = obj['Config']; _i < _a.length; _i++) {
            var item = _a[_i];
            this.config.push(item);
        }
    }
    VipConfig.prototype.get = function (type) {
        return this.config[type - 1];
    };
    return VipConfig;
}());
exports.VipConfig = VipConfig;
exports.vipConfigFromGDS = new VipConfig(vipGDS);
var OfferConfig = /** @class */ (function () {
    function OfferConfig(obj) {
        this.config = [];
        for (var _i = 0, _a = obj['Config']; _i < _a.length; _i++) {
            var item = _a[_i];
            this.config.push(item);
        }
    }
    OfferConfig.prototype.get = function (id) {
        var res;
        for (var _i = 0, _a = this.config; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item['offer_id'] === id) {
                res = item;
            }
        }
        return res;
    };
    return OfferConfig;
}());
exports.OfferConfig = OfferConfig;
exports.offerConfigFromGDS = new OfferConfig(offerGDS);
