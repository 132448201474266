"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMapState = exports.GetInitStateMap = exports.GetInitState = exports.validBlockIds = exports.InitState = void 0;
var buildingCountConfig = require("../../gds/building_count.json");
var Const_1 = require("../Const");
var qualificationGDS = require("../../gds/general.json");
var mapListGDS = require("../../gds/map_list.json");
var state_1 = require("../../Core/state");
var DataConfig_1 = require("../DataConfig");
exports.InitState = (_a = {},
    _a[Const_1.StateName.Capitals] = {},
    _a[Const_1.StateName.City] = {
        facilities: {},
        resources: (_b = {},
            _b[Const_1.ResouceType.Silver] = {
                lastUpdate: -1,
                value: 10000,
            },
            _b[Const_1.ResouceType.Troop] = {
                lastUpdate: -1,
                value: 1000,
            },
            _b),
        recruit: [],
        gold: 0,
        lastAddTestTime: -1,
        userActivity: [],
        guideStep: [],
        firstLogin: -1,
        injuredTroops: {
            updateTime: -1,
            today: 0,
            value: 0
        },
        rewardClaimed: {},
        buyOfferRecords: {}
    },
    _a[Const_1.StateName.GlobalCod] = {
        cods: {}
    },
    _a[Const_1.StateName.General] = {
        generalList: {},
        defense_general: -1,
        defenseBlockList: [],
        unionId: 1,
        glory: 0,
        iconId: -1,
        morale: {
            lastUpdate: -1,
            value: 100,
        },
        unionInit: false,
        lastBattle: -1,
        userScores: {},
        cods: {},
        codGeneralIdsMap: {}
    },
    //TODO: add default defender info
    _a[Const_1.StateName.DefenderInfo] = {
        generalId: -1,
        generalLevel: 1,
        generalType: 1,
        attack: 100,
        defense: 100,
        troop: 0,
        silver: 0,
        glory: 0,
        defenseMaxTroop: 0,
        unionId: 1,
        fortressLevel: 1,
        isProtected: false
    },
    _a[Const_1.StateName.MapGlobalInfo] = {
        campInfo: [],
        campInfo_1: [],
        campInfo_2: [],
        campInfo_3: [],
        campInfo_4: [],
        campMembers: [],
        unionWinId: 0,
        seasonEnd: false
    },
    _a[Const_1.StateName.SeasonConfig] = {
        haveSet: false,
        season_reservation: 0,
        season_ready: 0,
        season_open: 0,
        season_end: 0,
        rankConfigFromTo: [],
        rankConfigValue: [],
        unionRewardValue: 0,
        rankRewardValue: 0,
        seasonId: '',
        chain: '',
        mapId: 0
    },
    _a[Const_1.StateName.TokenPriceInfo] = {
        initial: { "ETH": 0, "USDT": 0, "BTC": 0, "BNB": 0 },
        current: { "ETH": 0, "USDT": 0, "BTC": 0, "BNB": 0 },
        lastUpdate: 0
    },
    _a[Const_1.StateName.RewardGloablState] = {
        unionGloryRankInfo: [[], [], [], []],
        globalGloryRankInfo: [],
        contractAddressInput: [],
        contractGloryInput: [],
        unionGlorySum: 0,
        unionGlorySumRuntime: [0, 0, 0, 0],
        unionWinId: 0,
        seasonEnd: false,
        unionRewardResult: [],
        gloryRewardResult: [],
    },
    _a[Const_1.StateName.Strategy] = {
        strategyPoint: {
            lastUpdate: -1,
            value: Const_1.MaxStrategyPoint,
        },
        buyTimes: {
            lastUpdate: -1,
            value: 0,
        },
        store: {
            able: false,
            beginTime: 0
        },
        protect: {
            able: false,
            beginTime: 0
        },
        protect1: {
            able: false,
            beginTime: 0
        }
    },
    _a[Const_1.StateName.Activity] = {
        activityData: [],
        sumValue: [],
        haveSendReward: []
    },
    _a);
var gInitState = {};
exports.validBlockIds = [];
var _inited = false;
function GetInitState(from) {
    if (!_inited) {
        //city state
        for (var key in Const_1.CityFacility) {
            var CityAnyType = Const_1.CityFacility[key];
            var maxCount = buildingCountConfig[CityAnyType]['max_count'];
            console.log('GetInitState city.data facilities:', Const_1.CityFacility, { key: key, CityAnyType: CityAnyType }, buildingCountConfig, maxCount);
            if (!isNaN(maxCount)) {
                exports.InitState[Const_1.StateName.City].facilities[CityAnyType] = Array(maxCount).fill(1);
            }
        }
        //general state
        var len = qualificationGDS.Config.length;
        exports.InitState[Const_1.StateName.General].unionId = 1;
        for (var i = 0; i < len; i++) {
            if (qualificationGDS.Config[i].general_from == 1) {
                var row = qualificationGDS.Config[i];
                var generalInfo = {
                    id: row.general_id,
                    level: 1,
                    able: false,
                    skill_levels: new Array(row.general_skill.length).fill(1),
                    stamina: {
                        value: row.stamina,
                        lastUpdate: -1
                    }
                };
                exports.InitState[Const_1.StateName.General].generalList[row.general_id + ""] = generalInfo;
            }
        }
        for (var i = 0; i < 4; i++) {
            exports.InitState[Const_1.StateName.MapGlobalInfo].campMembers.push([]);
        }
        exports.InitState[Const_1.StateName.MapGlobalInfo].campMembers[0].push('test');
        exports.InitState[Const_1.StateName.MapGlobalInfo].unionWinId = 0;
        var activityLen = DataConfig_1.SeasonConfigFromGDS.get(1).activities.length;
        for (var i = 0; i < activityLen; i++) {
            exports.InitState[Const_1.StateName.Activity].activityData.push([]);
            exports.InitState[Const_1.StateName.Activity].sumValue.push(0);
            exports.InitState[Const_1.StateName.Activity].haveSendReward.push(false);
        }
        for (var _i = 0, _a = mapListGDS['Config']; _i < _a.length; _i++) {
            var mapItem = _a[_i];
            var mapId = mapItem['map_id'];
            exports.InitState = Object.assign(exports.InitState, GetInitStateMap(mapId, 'GetInitState'));
        }
        _inited = true;
    }
    return (0, state_1.copyObj)(exports.InitState);
}
exports.GetInitState = GetInitState;
function GetInitStateMap(mapId, from) {
    console.log('GetInitStateMap args:', { mapId: mapId, from: from });
    mapId = mapId || 1;
    //city state
    var mapOffset = (0, DataConfig_1.getMapOffset)(mapId);
    console.log('GetInitState mapId offset:', { mapId: mapId, mapOffset: mapOffset });
    var rows = mapOffset.rows, cols = mapOffset.cols;
    // let maxSize = mapOffset.maxSize;
    // let maxlen = Math.floor((maxSize + 1)/ 2);
    var mapsArr = [];
    for (var i = 0; i < rows; i++) {
        console.log('GetInitState MapGlobalInfo:', { mapId: mapId, i: i, ylen: cols - i % 2 });
        mapsArr.push(new Array(cols - i % 2).fill(null).map(function () {
            return { unionId: 0,
                attackEndTime: -1,
                protectEndTime: -1 };
        }));
    }
    var campInfoKey = 'campInfo_' + mapId;
    exports.InitState[Const_1.StateName.MapGlobalInfo][campInfoKey] = mapsArr;
    console.log('GetInitState ', { campInfoKey: campInfoKey }, exports.InitState[Const_1.StateName.MapGlobalInfo][campInfoKey]);
    return GetMapState(mapId);
}
exports.GetInitStateMap = GetInitStateMap;
var _ginit = false;
function GetMapState(mapId) {
    if (!_ginit) {
        mapId = mapId || 1;
        var campInfoKey = 'campInfo_' + mapId;
        var mapGDS = (0, DataConfig_1.loadMapGDS)(mapId);
        console.log('GetMapState mapId:', mapId, mapGDS);
        var time = parseInt(new Date().getTime() / 1000 + '');
        var mapOffset = (0, DataConfig_1.getMapOffset)(mapId);
        console.log('GetMapState mapId offset:', { mapId: mapId, mapOffset: mapOffset });
        var capitalsKey = 'capitals_' + mapId;
        exports.InitState[Const_1.StateName.Capitals] = exports.InitState[Const_1.StateName.Capitals] || {};
        exports.InitState[Const_1.StateName.Capitals][capitalsKey] = exports.InitState[Const_1.StateName.Capitals][capitalsKey] || {};
        for (var blockId in mapGDS) {
            var blockGlobalUniKey = "".concat(Const_1.StateName.BlockInfo, ":").concat(mapId, ":").concat(blockId);
            var row = mapGDS[blockId];
            var blockIds = blockId.split('^');
            var x_id = parseInt(blockIds[0]);
            var y_id = parseInt(blockIds[1]);
            var xIndex = (mapOffset.cols - 1 + x_id - Math.abs(x_id % 2)) / 2;
            var yIndex = (mapOffset.rows - 1) / 2 - y_id;
            //captital blocks
            if (row['type'] == 2) {
                exports.InitState[Const_1.StateName.Capitals][capitalsKey][blockId] = row;
            }
            var unionId = 0;
            if (row['type'] == 3) {
                unionId = row['parameter'];
                var yBlocks = exports.InitState[Const_1.StateName.MapGlobalInfo][campInfoKey][yIndex];
                yBlocks[xIndex] = yBlocks[xIndex] || { unionId: 0, attackEndTime: -1, protectEndTime: -1 };
                yBlocks[xIndex].unionId = unionId;
                exports.InitState[Const_1.StateName.MapGlobalInfo][campInfoKey][yIndex] = yBlocks;
                console.log('GetMapState gInitState init:', { blockId: blockId, xIndex: xIndex, yIndex: yIndex, unionId: unionId });
            }
            gInitState[blockGlobalUniKey] = {
                id: blockGlobalUniKey,
                x_id: x_id,
                y_id: y_id,
                belong: {
                    unionId: unionId,
                    updateTime: -1
                },
                defenseList: [],
                durability: row['durability'],
                defaultDefense: (0, DataConfig_1.GenBlockDefenseTroop)(x_id, y_id, mapId),
                lastAttachTime: -1,
                remainSilver: row['silver_total_number']
            };
            exports.validBlockIds.push(blockGlobalUniKey);
        }
    }
    console.log('GetMapState gInitState:', gInitState);
    return (0, state_1.copyObj)(gInitState);
}
exports.GetMapState = GetMapState;
